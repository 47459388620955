import { SliderViewDefault, SliderViewNews, SliderViewPlay } from "types/Media/Slider"
import { ContentGridProps } from "types/Page/Content"
import Grid from "components/Content/Grid"
import AniTitle from "pages/_commons/AniTitle"

import "./index.scss"

const ContentGrid = (props: ContentGridProps) => {
  const className = "component-content mode-grid"

  return (
    <>
      <AniTitle
        title={props.title}
        subtitle={props.lastCallPlay}
        className={className}
        isLoading={props.isLoading}
        filters={props.filters}
        onFiltersChange={props.onFiltersChange}
      />
      <Grid
        slides={props.newItemsPlay}
        className={className}
        type={props.type || "player"}
        onClick={props.onClick}
        slidesPerView={props.type === "player" ? SliderViewPlay : (props.type === "news" ? SliderViewNews : SliderViewDefault)}
      />
    </>
  )
}

export default ContentGrid