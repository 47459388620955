import { IonBadge, IonIcon } from "@ionic/react"
import { radioOutline, play } from "ionicons/icons"

import { AnimeTabs } from "types/Anime"
import locales from "config/Locales"
import Animate from "utils/Animate"

export const onlyTV = (props: AnimeTabs) => {
  if (props.content.ctype !== "tv") return <></>

  return (
    <>
      <IonBadge color={!props.content.enddateAgoNum?.passed ? "success" : "danger"}>
        <IonIcon icon={radioOutline} className={Animate.setAnimation("bounceIn")} />
        {locales._get(`content.details-is-${!props.content.enddateAgoNum?.passed ? "not-" : ""}finished`)}
      </IonBadge>
      {(!props.content.nextepisodeAgoNum?.passed && !props.content.enddateAgoNum?.passed) &&
        <IonBadge className="episode-date" color="secondary">
          {locales._get("content.details-next-episode")}&nbsp;
          {props.content.nextepisodeAgo === "" ? props.content.nextepisodeStr : props.content.nextepisodeAgo}
        </IonBadge>
      }
      {props.content.enddateAgoNum?.passed &&
        <IonBadge className="episode-date" color="tertiary">
          {locales._get("content.details-end-date-at")}&nbsp;
          {props.content.enddateStr}
        </IonBadge>
      }
      {props.episodes?.media?.current?.play?.length === 0 &&
        <IonBadge color="secondary">
          <IonIcon icon={play} />
          {locales._get("content.no-media")}
        </IonBadge>
      }
    </>
  )
}