//import { AdLoadInfo, AdMobRewardItem } from "@capacitor-community/admob"
import { Capacitor } from "@capacitor/core"

import { AdLoadInfo, AdMobRewardItem } from "types/Media/Ads/AdMob"
import { RemoveAdPositionProps } from "types/Media/Ads"
import { universalDateLargeFormat } from "types/Utils/Date"
import { SoftnyaAdsBannerItem } from "types/Media/Ads/SoftnyaAds"
import settings from "config/Settings"
import JDate from "utils/JDate"
import AdsVideo from "../../Video"

export const removeAdPosition = (props: RemoveAdPositionProps) => {
  const today = JDate.getTodayTimestamp()
  const targetCoins: number = 30
  const _settings = new settings()
  const ads: AdsVideo = new AdsVideo()
  let currentTime: number = today
  if (props.position === "LARGE") currentTime = _settings.getadslocklargetime
  else if (props.position === "CONTENT") currentTime = _settings.getadslockcontenttime
  else if (props.position === "VIDEO") currentTime = _settings.getadslockvideotime
  if (currentTime < today || currentTime <= 0) currentTime = today
  const addTime = (percent: number = 100) => {
    if (percent > 100) percent = 100
    if (percent <= 0) return
    const date: Date = currentTime > 0 ? new Date(currentTime) : new Date()
    date.setSeconds(date.getSeconds() + ((settings.ads_remove_interval * percent) / 100))
    const timestamp: number = JDate.timestamp(JDate.format(date, universalDateLargeFormat))
    if (props.position === "LARGE") _settings.adslocklargetime = timestamp
    else if (props.position === "CONTENT") _settings.adslockcontenttime = timestamp
    else if (props.position === "VIDEO") _settings.adslockvideotime = timestamp
    props.setAdsLockLarge && props.setAdsLockLarge(JDate.agoNum(_settings.getadslocklargetime))
    props.setAdsLockContent && props.setAdsLockContent(JDate.agoNum(_settings.getadslockcontenttime))
    props.setAdsLockVideo && props.setAdsLockVideo(JDate.agoNum(_settings.getadslockvideotime))
  }
  const showVideo = (targetCoins: number, earnedCoins: number, onDone: Function, onFail: Function) => {
    if ((!props.setShowSoftnyaVideo || !props.setSoftnyaAds) && !Capacitor.isNativePlatform()) return onFail()
    props.setShowingAds && props.setShowingAds(true)
    ads.showRewardVideo((item: AdMobRewardItem, withAds: boolean) => {
      if (!withAds) {
        (earnedCoins || 0) === 0 ? onFail() : onDone(earnedCoins)//if fails second time at least give the coins
        props.setShowingAds && props.setShowingAds(false)
        return
      }
      earnedCoins += (item?.amount || 0)
      if (earnedCoins > 0) {
        onDone(earnedCoins)
        props.setShowingAds && props.setShowingAds(false)
      } else return onFail()//if earned 0 coins, fail
    }, (info: AdLoadInfo, video?: SoftnyaAdsBannerItem) => {
      if (video) {
        props.setShowSoftnyaVideo && props.setShowSoftnyaVideo(true)
        props.setSoftnyaAds && props.setSoftnyaAds(video)
      }
    })
  }
  showVideo(targetCoins, 0, (earnedCoins: number) => {//target gained
    addTime(((earnedCoins || 0) / targetCoins) * 100)
    props.setShowSuccessReward && props.setShowSuccessReward(true)
    setTimeout(() => props.setShowSuccessReward && props.setShowSuccessReward(false), 4000)
  }, () => {//target lost
    props.setShowFailedReward && props.setShowFailedReward(true)
    setTimeout(() => props.setShowFailedReward && props.setShowFailedReward(false), 4000)
  })
  //addTime()
}