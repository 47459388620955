import { create } from "zustand"
import { devtools } from "zustand/middleware"

import { isOpenElements } from "types/Commons/Modal"

let activeModal = ""

const useModal = create(devtools((set: any) => ({
  isOpen: {},
  openModal: (id: string) => set((state: isOpenElements) => {
    state.isOpen[id] = true
    activeModal = id
    return state
  }) as Function,
  closeModal: (id: string) => set((state: isOpenElements) => {
    state.isOpen[id] = false
    return state
  }) as Function,
  closeActiveModal: () => set((state: isOpenElements) => {
    state.isOpen[activeModal] = false
    return state
  }) as Function,
}), { name: "useModal" }))

export default useModal
