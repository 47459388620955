import {
  IonButtons,
  IonToolbar,
  IonButton,
  IonModal,
  IonHeader,
  IonContent,
  IonIcon,
  IonCol,
  IonGrid,
  IonRow,
  IonText,
} from "@ionic/react"
import { closeOutline } from "ionicons/icons"
import { useRef } from "react"

import { defaultModalProps, ModalProps } from "types/Commons/Modal"
import useModal from "store/useModal"
import Image from "components/Media/Image"
import useAds from "store/useAds"

import "./index.scss"

export const closeModal = () => {
  const close = document.getElementById("modal-close")
  close?.click()
}

const Modal: React.FC<ModalProps> = (props) => {
  const modal = useRef<HTMLIonModalElement>(null)
  const isOpen: boolean = useModal((state: any) => state.isOpen)
  const closeActiveModal = useModal((state: any) => state.closeActiveModal)
  const setShowBanner = useAds((state: any) => state.setShowBanner)

  return (
    <IonModal
      ref={modal}
      trigger={props.id}
      isOpen={isOpen}
      className={`component-modal modal-type-${props.color || "default"}`}
      initialBreakpoint={props.initialBreakpoint || defaultModalProps.initialBreakpoint}
      onIonModalWillPresent={() => setShowBanner(false)}
      onIonModalWillDismiss={() => setShowBanner(true)}
    >
      <IonHeader>
        <IonToolbar mode="md" color={props.color}>
          <IonButtons slot="start">
            <IonButton onClick={() => {
              modal.current?.dismiss()
              closeActiveModal()
            }} slot="start" id="modal-close">
              <IonIcon slot="icon-only" icon={closeOutline} />
            </IonButton>
            {props.title ? (
              <IonText slot="end">
                <h3 style={{ margin: "0.5rem" }}>{props.title}</h3>
              </IonText>
            ) : (
              <></>
            )}
          </IonButtons>
        </IonToolbar>
        {props.img ? (
          <IonToolbar mode="md" color={props.color}>
            <IonGrid>
              <IonRow class="ion-justify-content-center">
                <IonCol size="10">
                  <Image src={props.img} />
                </IonCol>
              </IonRow>
            </IonGrid>
          </IonToolbar>
        ) : (
          <></>
        )}

      </IonHeader>
      <IonContent scrollY={props.scroll}>
        {props.children}
      </IonContent>
    </IonModal>
  )
}

Modal.defaultProps = defaultModalProps

export default Modal