import { Suspense, useCallback, useEffect, useState } from "react"
import { IonInfiniteScroll, IonInfiniteScrollContent } from "@ionic/react"

import { Endpoints } from "api/Endpoints"
import { defaultContentProps, ContentProps } from "types/Page/Content"
import { recentContentGridLimit, recentContentSlideLimit } from "api/routes/params/Media"
import { getLastCall, removePageHistory } from "components/Connect/Exports"
import { Slide } from "types/Media/Slider"
import { loadContent } from "./Methods/loadContent"
import { Responses } from "types/Connect"
import AnimeContentParser from "../AnimeContent"
import useAnime from "store/useAnime"
import Connect from "components/Connect"
import locales from "config/Locales"
import JDate from "utils/JDate"
import Loader from "pages/_commons/Loader"
import ContentGrid from "./Grid"
import ContentSlider from "./Slider"
import EndOfList from "./EndOfList"

import "./index.scss"
import { AniTitleFilterProps } from "types/Page/AniTitle"

const Content = (props: ContentProps) => {
  const [items, setItems] = useState<Slide[]>([])
  const [loaded, setLoaded] = useState(false)
  const [lastCall, setLastCall] = useState("")
  const [isLoading, setIsLoading] = useState(false)
  const [lastCallNow, setLastCallNow] = useState<number>(0)
  const [pageContentPlay, setPageContentPlay] = useState<number>(0)
  const [limit] = useState<number>((props.mode === "grid" && props.type === "item") ? (props.gridLimit || recentContentGridLimit) : (props.slideLimit || recentContentSlideLimit))
  const [pageFromRemote, setPageFromRemote] = useState<number>(-1)
  const [endOfList, setEndOfList] = useState<boolean>(false)
  const [canRefreshGrid] = useState<boolean>(props.refreshGrid || false)
  const [params, setParams] = useState(props.params || {})
  const setIsOpen = useAnime<Function>((state: any) => {
    if (props.type === "news") return state.setIsOpenNews
    return state.setIsOpen
  })
  const setId = useAnime<Function>((state: any) => {
    if (props.type === "news") return state.setNid
    return state.setId
  })
  const setEid = useAnime<Function>((state: any) => state.setEid)
  const refreshGrids = useAnime<boolean>((state: any) => state.refreshGrids)
  const setRefreshGrids = useAnime<Function>((state: any) => state.setRefreshGrids)

  const filterParams = (filters: AniTitleFilterProps[]) => {
    let _params = { ...props.params }//init fresh params
    filters.forEach(async (filter: AniTitleFilterProps, index: number) => {
      if (filter.selected)
        _params = {
          ..._params,
          [filter.key]: filter.selected,
        }
      if (index === filters.length - 1) {
        await setItems([])
        await setParams(_params)
        loadContentParams(() => setIsLoading(false), true, _params, true)
      }
    })
  }

  useEffect(() => {
    if (!refreshGrids) return
    if (!canRefreshGrid) {
      setRefreshGrids(false)
      return
    }
    setItems(() => {
      loadContentParams(() => setIsLoading(false), true)
      setRefreshGrids(false)
      return []
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [refreshGrids])

  useEffect(() => {
    if (props.mode === "grid") {
      const _params = { ...params, pag: pageContentPlay, limit: limit }
      removePageHistory(Endpoints.recent, _params)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.watchLastCall, params])

  useEffect(() => {
    if (!props.resetPage) return
    setPageContentPlay(0)
    loadContentParams((isFromCache: boolean) => setIsLoading(isFromCache), true)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.resetPage])

  useEffect(() => setLoaded(false), [props.loadContent])

  const updateLastCall = async (params: {}) => {
    const newCall = await getLastCall(Connect.getQuery({ endpoint: props.endpoint || "", params }))
    setLastCall(`${locales._get("last_call")}: ${JDate.ago(newCall > lastCallNow ? newCall : lastCallNow)}`)
    setLastCallNow(newCall > lastCallNow ? newCall : lastCallNow)

    setTimeout(() => updateLastCall(params), 10000)
  }

  const loadContentParams = useCallback((callback?: (isFromCache: boolean) => void, reset?: boolean, _params?: {}, forceReload: boolean = false) => {
    setIsLoading(true)
    setEndOfList(() => {
      const loadparams: {} = (forceReload && _params) ? _params : { ...params, ..._params, }
      loadContent({
        props: {
          ...props, params: loadparams, cachedContent: (response: Responses) => {
            setItems((prevItems: Slide[]) => {
              if (prevItems.length > 0 && !forceReload) return prevItems
              response.data?.results.map((item: Slide) => {
                item = AnimeContentParser.fitSlideContent({
                  item,
                  setIsOpen: setIsOpen,
                  setId: setId,
                  setEid: setEid,
                })
                return item
              })
              return response.data?.results as Slide[] || [] as Slide[]
            })
          }
        }, setPageContentPlay, setEndOfList, setItems, updateLastCall,
        setPageFromRemote, pageFromRemote, pageContentPlay: reset === true ? 0 : pageContentPlay, items, limit, callback,
        setIsOpen, setId, setEid,
      })
      return endOfList
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pageContentPlay, pageFromRemote, setId, setEid, params])

  useEffect(() => {
    if (loaded) return

    setLoaded(true)
    loadContentParams((isFromCache: boolean) => setIsLoading(isFromCache))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loaded])

  return (
    <>
      <Suspense fallback={<><Loader text={locales._get("content")} /></>}>
        {props.mode === "slider" &&
          <>
            <ContentSlider
              title={props.title || ""}
              lastCall={lastCall}
              newItems={items}
              href={props.href || ""}
              type={props.type}
              isLoading={isLoading}
              slidesPerView={props.slidesPerView}
              onClick={props.onClick}
            />
          </>
        }
        {props.mode === "grid" &&
          <>
            <ContentGrid
              title={props.title || ""}
              lastCallPlay={lastCall}
              newItemsPlay={items}
              type={props.type}
              isLoading={isLoading}
              onClick={props.onClick}
              filters={props.filters}
              onFiltersChange={filterParams}
            />
            {(endOfList && props.showEndOfList && items.length > 0) &&
              <EndOfList itemCount={items.length} />
            }
            <IonInfiniteScroll
              threshold="40%"
              onIonInfinite={(ev: any) => {
                if (items.length === 0) {
                  ev.target.complete()
                  return
                }
                loadContentParams((isFromCache: boolean) => {
                  setIsLoading(isFromCache)
                  ev.target.complete()
                })
              }}
            >
              <IonInfiniteScrollContent></IonInfiniteScrollContent>
            </IonInfiniteScroll>
          </>
        }
      </Suspense>
    </>
  )
}

Content.defaultProps = defaultContentProps

export default Content