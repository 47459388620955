import { useEffect, useState } from "react"
import { IonList } from "@ionic/react"

import { Item } from "components/Content/FormBuilder/Item"
import { HistoryRecord } from "store/useHistoryRecord/interface"
import { compactDateFormat } from "types/Utils/Date"
import { SpinnerBubbles } from "components/Commons/Spinner"
import { SoftnyaAdsPositions } from "types/Media/Ads/SoftnyaAds"
import locales from "config/Locales"
import JDate from "utils/JDate"
import ProfileHistoryEmpty from "../Empty"
import useAnime from "store/useAnime"
import AdsBanner from "components/Media/Ads/Banner"

import "./index.scss"

const ProfileHistoryMap = (props: { userHistory: HistoryRecord[], isLoading: boolean }) => {
  const setIsOpenAnime: Function = useAnime<Function>((state: any) => state.setIsOpen)
  const setIsOpenCharacter: Function = useAnime<Function>((state: any) => state.setIsOpenCharacter)
  const setIsOpenStaff: Function = useAnime<Function>((state: any) => state.setIsOpenStaff)
  const setId: Function = useAnime<Function>((state: any) => state.setId)
  const setEid: Function = useAnime<Function>((state: any) => state.setEid)
  const setChid: Function = useAnime<Function>((state: any) => state.setChid)
  const setStid: Function = useAnime<Function>((state: any) => state.setStid)
  const [userHistory, setUserHistory] = useState([] as HistoryRecord[])
  let adPosition: SoftnyaAdsPositions = "CONTENT_D"

  const adsPosition = (): SoftnyaAdsPositions => {
    if (adPosition === "CONTENT_A") adPosition = "CONTENT_B"
    else if (adPosition === "CONTENT_B") adPosition = "CONTENT_C"
    else if (adPosition === "CONTENT_C") adPosition = "CONTENT_D"
    else if (adPosition === "CONTENT_D") adPosition = "CONTENT_A"
    return adPosition
  }

  useEffect(() => setUserHistory(props.userHistory?.slice(0).reverse()), [props.userHistory])

  if (props.isLoading) return <div className="history-map-spinner">
    <SpinnerBubbles />
    <p>{locales._get("loading")}</p>
  </div>
  if (userHistory.length === 0) return (
    <>
      <ProfileHistoryEmpty />
      <AdsBanner type="softnyacontent" softnyaposition="DETAILS_TOP" />
    </>
  )
  return (
    <>
      <AdsBanner type="softnyacontent" softnyaposition="DETAILS_TOP" />
      <IonList>
        <Item
          title={locales._get("profile.history-title")}
          subtitle={locales._get("profile.history-subtitle")}
          asHeading={true}
        />
        {userHistory.map((item: HistoryRecord, index: number) => {
          const date = JDate.toDate(item.date)
          const compactDate = JDate.format(date || JDate.now(), compactDateFormat)
          const parseSerie = () =>
            ((item.serie || 0) > 0 ? item.serie : "")
          const parseProvider = () =>
            ((item.provider || "") !== "" ? `(${item.provider})` : "").trim()
          return (
            <>
              <Item
                title={`${index + 1}. ${item.title || ""}`}
                subtitle={`${locales
                  ._get(`profile.history-type-${item.type}`, { "%s": parseSerie, "%t": parseProvider }).trim()}:
              ${compactDate !== "" ? compactDate : JDate.format(JDate.now())}`}
                image={item.image}
                imageBk={item.imageBk}
                key={index}
                type="button"
                onClick={() => {
                  if ((item?.cid || 0) === 0) return
                  if (item.type === "content" || item.type === "episode") {
                    (item?.eid || 0) > 0 && setEid(item.eid)
                    setId(item.cid)
                    setIsOpenAnime(true)
                  } else if (item.type === "character") {
                    setChid(item.cid)
                    setIsOpenCharacter(true)
                  } else if (item.type === "staff") {
                    setStid(item.cid)
                    setIsOpenStaff(true)
                  }
                }}
              />
              {(index % 10 === 0 && index > 0) && <AdsBanner type="softnyacontent" softnyaposition={adsPosition()} />}
            </>
          )
        })}
      </IonList>
    </>
  )
}

export default ProfileHistoryMap