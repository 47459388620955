import axios from "axios"

import {
  checkInterval,
  ConnectMethodOptionsProps,
  ConnectMethodProps,
  ConnectOptions,
  defaultConnectOptions,
  Responses
} from "types/Connect"
import { isAvailableNetwork } from "config/Settings/MyNetwork/Methods/isAvailableNetwork"
import { getQueryFormat, getLastCall, getQueryLocal } from "./Exports"
import { StorageItems } from "utils/Storage/StorageItems/Storage"
import { delayAndReturn } from "utils/Function"
import { AppSettingsID } from "utils/AppSettings"
import { getPlatform } from "config/Settings/Device"
import Settings, { isDev } from "config/Settings"
import settings from "config/Settings"
import User from "components/Users/User"
import UserInfo from "components/Users/User"
import JDate from "utils/JDate"
import StorageUtils from "utils/Storage"
import Strings from "utils/Strings"

class Connect extends StorageItems {
  static host = () => `${window.location.protocol}//${window.location.hostname}:${window.location.port}`

  public static post = async (props: ConnectMethodOptionsProps): Promise<Responses> => {
    props.options = { ...defaultConnectOptions, ...props.options }
    props.options.cached = false
    return await Connect.method({ method: "post", ...props })
  }

  public static put = async (props: ConnectMethodOptionsProps): Promise<Responses> => {
    props.endpoint = props.endpoint.replace(settings.apiUrl, `${settings.apiUrl}put.`)
    props.params = { ...props.params, ...props.body }
    // set on post by the moment
    return await Connect.post(props)
  }

  public static get = async (props: ConnectMethodOptionsProps): Promise<Responses> =>
    await Connect.method({ method: "get", ...props })

  public static postcached = async (props: { endpoint: string, params?: any, body?: any, options?: ConnectOptions }): Promise<Responses> => {
    props.options = { ...defaultConnectOptions, ...props.options }
    props.options.cached = true
    return await Connect.method({ method: "post", ...props })
  }

  public static getQuery = (props: ConnectMethodOptionsProps): string =>
    `${props.endpoint}${props.endpoint.indexOf("?") === -1 ? "?" : "&"}${isDev ? "dev=&" : ""}${new URLSearchParams(props.params || {})}`

  public static removeCached = (key: string) => {
    StorageUtils.removeItem(key)
    StorageUtils.removeItem(`lastcall-${key}`)
    //ionic storage
    Connect.removeItem(key)
    Connect.removeItem(`lastcall-${key}`)
  }

  private static method = async (props: ConnectMethodProps): Promise<Responses> => {
    const isNetwork = await isAvailableNetwork()
    props.options = { ...defaultConnectOptions, ...props.options }
    props.body = { ...props.body, version: AppSettingsID.getSetting("version", getPlatform()) }
    const axiosInstance = axios.create({})

    const bodyData = new FormData()
    const headers: any = Connect.getHeaders()
    for (let key in props.body) bodyData.append(key, props.body[key])
    //Add headers to body
    for (let key in headers) bodyData.append(key, headers[key])

    const query = Connect.getQuery({ endpoint: props.endpoint, params: props.params })

    const queryFormat = getQueryFormat(query, props.options?.permanent)

    const getInterval = (interval: checkInterval) => {
      if (interval === "shorter") return settings.checkShorterInterval
      if (interval === "day") return settings.checkDayInterval
      if (interval === "month") return settings.checkMonthInterval
      return settings.checkInterval
    }

    const interval = getInterval(props.options.checkInterval || "short")
    const checkInterval = interval * 1000 * 60//minutes to miliseconds
    const lastCall = await getLastCall(query)
    const shouldCall = lastCall < Date.now() - checkInterval
    const returnCached = (
      (!shouldCall && lastCall > 0 && !props.options?.force && props.options?.cached) ||
      props.options?.onlyCached) || !isNetwork
    // eslint-disable-next-line no-console
    isDev && console.warn(`${props.method.toLocaleUpperCase()}${!returnCached ? " (CALL)" : " (CACHE)"}`,
      `${query.substring(0, 1) === "/" ? this.host() : ""}${query}`,
      "lastCall:", JDate.ago(lastCall),
      "nextCall:", JDate.ago(lastCall + checkInterval),
      "Headers:", headers,
      "Body:", props.body)

    const cached: Responses = await getQueryLocal(queryFormat)
    props.options?.cachedContent && props.options?.cachedContent(cached)
    if (returnCached) return cached
    // eslint-disable-next-line no-console
    isDev && console.log("%c Calling API ", "font-size:10px;background:darkred;color:white;")
    StorageItems.setItem(`lastcall-${queryFormat}`, Date.now().toString())

    const thenResponse = async (response: Responses): Promise<Responses> => {
      try {
        if (typeof response.data === "string") {
          response = JSON.parse(await StorageItems.getItem(queryFormat) || JSON.stringify(response))
        }
        if (response.status < 300 && typeof response.data !== "string") StorageItems.setItem(queryFormat, JSON.stringify(response))
        if (response.status >= 300 || !response.status) AppSettingsID.setSetting("error_response", {
          ...response,
          status: response.status || 400,
          endpoint: `(${props.method.toLocaleUpperCase()}) ${query}`,
          headers: headers,
          body: props.body,
        })
        if (response.data?.token) {
          const user = User.getActiveUser()
          if (user.token !== response.data?.token) {
            user.token = response.data?.token
            User.updateUser({ user, callback: () => { }, callRefresher: false })
          }
        }
        if (response.data?.is_latest !== undefined) {
          const isLatest: boolean = response.data?.is_latest
          AppSettingsID.setSetting("isLatest", isLatest)
        }
        AppSettingsID.setSetting("latest", response.data?.latest || AppSettingsID.getSetting("version", "Web"))
      } catch (e) {
        //isDev && console.warn("Error parsing response in Connect:", e)
        if (props.options?.cached) return getQueryLocal(queryFormat)
      }
      return response
    }
    const catchResponse = async (response: any) =>
      await delayAndReturn(
        thenResponse(response.response || []), Strings.getRandomBetween(3000, 1000))

    if (props.method === "get")
      return await axiosInstance.get(query, { headers }).then((response: Responses) => thenResponse(response))
        .catch((response: Responses) => catchResponse(response))
    else if (props.method === "post")
      return await axiosInstance.post(query, props.options.bodyencoded ? new URLSearchParams(props.body) : bodyData).then((response: Responses) => thenResponse(response))
        .catch(async (response: Responses) => await catchResponse(response))
    else if (props.method === "put")
      return await axiosInstance.post(query, props.options.bodyencoded ? new URLSearchParams(props.body) : bodyData).then((response: Responses) => thenResponse(response))
        .catch((response: Responses) => catchResponse(response))
    else {
      if (props.options?.cached) return getQueryLocal(queryFormat) || []
      return {} as Responses
    }
  }

  static getHeaders = (): {} => {
    const apikey = Settings.apikey || ""
    const headers = {
      Authorization: Connect.getToken(),
      Username: User.getUsername(),
      "APIKEY": apikey,
    }
    return headers
  }

  static getToken = (): string => {
    const token = UserInfo.getToken()
    return token ? `Bearer ${token}` : ""
  }
}

export default Connect