import { IonItem, IonLabel, IonList, IonTitle } from "@ionic/react"
import { useEffect, useState } from "react"

import { getHistorySearch } from "store/useHistorySearch"
import { HistorySearch } from "store/useHistorySearch/interface"
import locales from "config/Locales"
import Image from "components/Media/Image"

import "./index.scss"

const RecentSearch = () => {
  const [historySearch, setHistorySearch] = useState<HistorySearch[]>([] as HistorySearch[])

  useEffect(() => {
    getHistorySearch().then((historyRecord: HistorySearch[]) => {
      setHistorySearch(historyRecord.slice(0).reverse())
    })
  }, [])

  const setQuery = (query: string) => {
    const input = document.querySelector("ion-searchbar input") as HTMLInputElement
    input.value = query
    input.dispatchEvent(new Event("input"))
  }

  if (historySearch.length === 0) return <></>
  return (
    <div className="component-recent-search">
      <IonTitle>{locales._get("content.search-lasts")}</IonTitle>
      <IonList>
        <>
          {historySearch.map((history: HistorySearch, index: number) => {
            const query = history.query.replaceAll("+", " ")
            return (
              <IonItem
                color="tertiary"
                button
                detail={true}
                onClick={() => setQuery(query)}
              >
                {history.image && (
                  <Image src={history.image} alt={history.date} slot="start" />
                )}
                <IonLabel>{index + 1}. {query}</IonLabel>
              </IonItem>
            )
          })}
        </>
      </IonList>
    </div>
  )
}

export default RecentSearch