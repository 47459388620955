import { IonList } from "@ionic/react"

import { AnimeTabs } from "../../../../../types/Anime"
import { Item } from "./Item"
import StudiosButtons, { StudiosOtherButtons } from "../../../Anime/Buttons/Studios"
import locales from "../../../../../config/Locales"

import "./index.scss"

const AnimeInformation = (props: AnimeTabs) => {
  const { content } = props

  const seen = (): string => {
    if (content.lastSeenStr !== "" || content.lastSeenStr === locales._get("content.no-last-seen")) return ""

    return `(${locales._get("content.details-last-seen", content.lastSeenStr)})`
  }

  return (
    <div className="component-anime-information">
      <IonList>
        <Item
          title={locales._get("content.details-title-spanish")}
          content={content.title_spanish}
        />
        <Item
          title={locales._get("content.details-title-english")}
          content={content.title_english}
        />
        <Item
          title={locales._get("content.details-title-romaji")}
          content={content.title_romaji}
        />
        <Item
          title={locales._get("content.details-title-others")}
          content={content.titleOtherStr}
        />
        <Item
          title={locales._get("content.details-studio")}
          contentNode={<>
            {content.studioid?.id && <StudiosButtons content={content} />}
          </>}
        />
        {(content?.studiosids || [])?.length > 0 &&
          <Item
            title={locales._get("content.details-studios")}
            contentNode={<>
              <StudiosOtherButtons content={content} />
            </>}
          />
        }
        {content.ctype === "tv" &&
          <Item
            title={locales._get("content.details-last-episode")}
            content={`${content.lastEpisodeStr} ${seen()}`}
          />
        }
        {content.seasonyearStr &&
          <Item
            title={locales._get("content.details-season")}
            content={`${content.seasonStr} ${content.seasonyearStr}`}
          />
        }
        {(content.ctype === "tv" && !content.nextepisodeAgoNum?.passed && !content.enddateAgoNum?.passed) &&
          <Item
            title={locales._get("content.details-next-episode")}
            content={`${content.nextepisodeStr} ${content.nextepisodeAgo !== "" ? `(${content.nextepisodeAgo})` : ""}`}
          />
        }
        <Item
          title={locales._get(content.ctype === "tv" ? "content.details-start-date" : "content.details-launch-date")}
          content={content.startdateStr}
        />
        {content.ctype === "tv" &&
          <Item
            title={locales._get("content.details-end-date")}
            content={content.enddateStr}
          />
        }
      </IonList>
    </div>
  )
}

export default AnimeInformation