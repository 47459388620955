import locales from "config/Locales"

export const paramsRecentForYou = { "area[]": "contents", "order": "episode", "foryou": "", "recentbadge": "" }

export const paramsRecentNewContent = { "area[]": "contents" }

export const paramsRecentNewContentPlay = { "area[]": "contents", "order": "episode", "viewed": "", "counting": "" }

export const paramsRecentNewContentPlayFormated = { ...paramsRecentNewContentPlay, recentbadge: "" }

export const limitsContinueWatching = { "slide": 20, "grid": 50 }

const paramsRecentBy = { "area[]": "contents", "reversehref": "", "reversegroup": "", "nobadge": "", "order": "episode" }

export const paramsRecentByGender = { ...paramsRecentBy, "gender": 0 }

export const paramsRecentByStudio = { ...paramsRecentBy, "studio": 0 }

export const recentContentGridLimit = 21

export const recentContentSlideLimit = 20

export const paramsLang = { "lang": locales.currentLang }