import { ParentalControlAlertProps } from "types/Anime"
import { isParentalContentBlocked } from "../Methods/isParentalContentBlocked"
import Notification from "components/Commons/Notification"
import locales from "config/Locales"

import "./index.scss"
import { IonBadge, IonIcon } from "@ionic/react"
import { handLeftOutline } from "ionicons/icons"

const ParentalControlAlert = (props: ParentalControlAlertProps) => {
  if (props.content.isadult === 0 || !props.content.isadult) return <></>

  if (isParentalContentBlocked(props.content))
    return (
      <Notification
        title={locales._get("content.details-parental-control")}
        subtitle={locales._get("content.details-parental-control-description")}
        className="component-parental-control-alert"
        color="danger"
        compact={true}
      />
    )
  else
    return (
      <IonBadge
        className="component-parental-control-alert"
        color="danger"
      >
        <IonIcon icon={handLeftOutline} />
        {locales._get("content.is-18")}
      </IonBadge>
    )
}

export default ParentalControlAlert