import { IonBadge } from "@ionic/react"

import { calcRelevanceLevel } from "components/Media/drawSlideItems/RelevanceAlert"
import locales from "config/Locales"

export const relevance = (relevance: number, search: string) => {
  if (relevance === 0 || search === "") return <></>
  return (
    <div>
      <IonBadge color="secondary">
        <>{locales._get("content.search")}: {search.replaceAll("+", " ")}</>
      </IonBadge>
      <IonBadge color="primary" className={`${calcRelevanceLevel(relevance || 0)}`}>
        <>{locales._get("content.relevance")}: {relevance}</>
      </IonBadge>
    </div>
  )
}