import { useHistory } from "react-router-dom"

import { MenuSelector } from "config/Menu/MenuSelector"
import Picker from "components/Commons/Picker"

const HeaderPicker = () => {
  const history = useHistory()

  const onClickRedirect = (href: string) => {
    history.push(href)
  }

  return (
    <Picker
      items={MenuSelector}
      onClickPicker={onClickRedirect}
      selected={window.location.pathname}
    />
  )
}

export default HeaderPicker