import { Slide } from "types/Media/Slider"
import { fullDateFormat } from "types/Utils/Date"
import Card from ".."
import JDate from "utils/JDate"

export const News = (props: { item: Slide, onClick?: Function }) => {
  const { conditionalBadge, contentOverTitle, contentOverHeader } = props.item
  props.item.img = props.item?.img2 || props.item?.img
  if (props.item.title?.indexOf("ion-badge") === -1)
    props.item.title = `
      <ion-badge color="primary">${JDate.format(JDate.toDate(props.item.publish_up || ""), fullDateFormat)}</ion-badge>
      ${props.item?.title}
    `

  return (
    <Card
      item={props.item}
      onClick={props.onClick}
      className={`card-news ion-relative ${props.item.className || ""}`}
      thumb="250x140"
      contentBellowImg={
        <>
          {conditionalBadge && conditionalBadge()}
        </>
      }
      contentOverHeader={
        <>
          {contentOverHeader && contentOverHeader()}
        </>
      }
      contentOverTitle={
        <>
          {contentOverTitle && contentOverTitle()}
        </>
      }
    />
  )
}

News.defaultProps = {
  showIcon: true,
}