import settings from "config/Settings"

const routes = {
  /**
   * @api {post} /login Login user
   * @apiName Login
   * @apiGroup User
   * @apiDescription Login user with email and password
   * 
   * @apiBody {String} username Username
   * @apiBody {String} password Password
   */
  login: `${settings.apiUrl}user.login`,

  signup: `${settings.apiUrl}user.signup`,
  /**
   * @api {post} /user.forgot-password?email=:email Forgot password
   * @apiName Forgot password
   * @apiGroup User
   * @apiDescription Send email to user with password reset link
   * 
   * @apiParam {String} email Email
   */
  reset: `${settings.apiUrl}user.forgot-password`,
  profile: `${settings.apiUrl}user.profile`,
  /**
   * @api {post} /user.refresh?username:username&token:token Refresh token
   * @apiName Refresh token
   * @apiGroup User
   * @apiDescription Check if token is active, if not, generate new
   * 
   * @apiParam {String} email Email
   */
  refresh: `${settings.apiUrl}user.refresh`,

  guest: `${settings.apiUrl}guest`,
}

export default routes