import { IonAccordionGroup } from "@ionic/react"
import { useEffect, useState } from "react"

import { AnimeEpisodeDetails, AnimeEpisodesMedia, AnimeTabs } from "types/Anime"
import Skeleton from "components/Commons/Skeleton"
import AnimeEpisodesSetItems from "./SetItems"
import AnimeEpisodesAccordion from "./Accordion"

const AnimeEpisodesTabs = (props: AnimeTabs) => {
  const [tabGroups] = useState<number>(30)
  const [episodesLoaded, setEpisodesLoaded] = useState<boolean>(false)
  const [episodes, setEpisodes] = useState<AnimeEpisodeDetails[]>(props.episodes?.episodes || [] as AnimeEpisodeDetails[])

  useEffect(() => {
    if (props.episodes?.episodes && !episodesLoaded) {
      setEpisodesLoaded(true)
      setEpisodes(props.episodes?.episodes)
    }
  }, [props.episodes, episodesLoaded])

  const params = {
    episodesLoaded: episodesLoaded,
    episodes: episodes,
    current: props.episodes?.media?.current || {} as AnimeEpisodesMedia,
    changeEpisode: props.changeEpisode,
  }

  if (episodes.length === 0) return <><Skeleton /></>

  return (
    <IonAccordionGroup className="component-anime-episodes-tabs">
      {episodes.length < tabGroups && <AnimeEpisodesSetItems {...params} />}
      {(props.content?.last_episode || 0) >= tabGroups && <AnimeEpisodesAccordion {...params} tabGroups={tabGroups} />}
    </IonAccordionGroup>
  )
}

export default AnimeEpisodesTabs