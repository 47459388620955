import locales from "config/Locales"

class LoaderOptions {
  static openLoader = (text?: string) => {
    const loader = document.querySelector(".component-screenloader") as HTMLElement
    const imageElement = loader.querySelector("ion-img") as HTMLIonImgElement
    if (imageElement) imageElement.src = LoaderOptions.getRandomLoaderImage()
    loader.classList?.remove("hidden")
    LoaderOptions.loaderText(locales._get(text || "loading"))
  }

  static closeLoader = () => {
    const loader = document.querySelector(".component-screenloader") as HTMLElement
    loader?.classList?.add("hidden")

    //restore default button and text
    LoaderOptions.canClose(true)
    LoaderOptions.loaderText(locales._get("loading"))
  }

  static loaderText = (text: string) => {
    const loader = document.querySelector(".component-screenloader") as HTMLElement
    const textElement = loader.querySelector(".text") as HTMLElement
    textElement.innerText = text
  }

  static canClose = (enabled: boolean) => {
    const loader = document.querySelector(".component-screenloader") as HTMLElement
    const closeButton = loader.querySelector(".close-button") as HTMLElement
    if (!closeButton) return

    if (enabled)
      closeButton.classList?.remove("hidden")
    else
      closeButton.classList?.add("hidden")
  }

  static getRandomLoaderImage = () => {
    const images = ["clap", "dingdong", "eating", "popcorn", "sing"]
    const random = Math.floor(Math.random() * images.length)

    return `/assets/img/loader/${images[random]}.gif`
  }
}

export default LoaderOptions