import settings from "config/Settings"
import { useEffect, useState } from "react"

import { ModalMusicControlsProps } from "types/Anime/AnimeModalMedia"
import { buildControls, clearControls } from "./Methods/buildControls"

const MusicControls = (props: ModalMusicControlsProps) => {
  const [MusicControls] = useState<any>((window as any).MusicControls)

  useEffect(() => {
    if (new settings().showNotificationControls === 0) return
    buildControls(props, MusicControls)
    return () => clearControls(MusicControls)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.episodes, props.content, MusicControls])

  useEffect(() => {
    if (!MusicControls) return
    MusicControls.updateElapsed({
      elapsed: props.elapsed,
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.elapsed])

  return (
    <></>
  )
}

export default MusicControls