import { Animations } from "types/Utils/Animate"

class Animate {
  public static setAnimationBase = () => "animate__animated"

  public static setAnimationFast = () => "animate__faster"
  /**
   * @breaf Require to import "animate.css", `npm i animate.css`
   * @param animation Animation string type
   * @returns Class string to generate animation
   */
  public static setAnimation = (animation: Animations, fast: boolean = false, withBaseClass: boolean = true): string =>
    (`${withBaseClass ? Animate.setAnimationBase() : ""} animate__${animation} ${fast === true ? Animate.setAnimationFast() : ""}`).trim()

  /**
   * @breaf Generates an array with animations format
   * @param animations Animation string type
   * @returns Array with animations
   */
  public static getAnimations = (animations: Animations[]): string[] => {
    const _animations: string[] = []
    animations?.map((animation: Animations) =>
      _animations.push(Animate.setAnimation(animation)))
    return _animations
  }
}

export default Animate