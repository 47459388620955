import { AnimeTabs } from "types/Anime"
import { allButTV } from "./Methods/allButTV"
import { onlyTV } from "./Methods/onlyTV"
import { LastSeen } from "./Methods/lastSeen"
import { relevance } from "./Methods/relevance"
import useHistorySearch from "store/useHistorySearch"

import "./index.scss"

const AnimeState = (props: AnimeTabs) => {
  const currentSearch = useHistorySearch<string>((state: any) => state.currentSearch)
  const currenRelevance = useHistorySearch<number>((state: any) => state.currentRelevance)

  return (
    <div className="component-anime-state">
      {onlyTV(props)}
      {allButTV(props)}
      <LastSeen {...props} />
      {relevance(currenRelevance || 0, currentSearch || "")}
    </div>
  )
}

export default AnimeState