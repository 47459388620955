import { IonGrid } from "@ionic/react"

import List from "./List"
import Profile from "./Profile"
import AdsBanner from "components/Media/Ads/Banner"

const Account = () => {

  return (
    <IonGrid>
      <Profile />
      <AdsBanner type="softnyacontent" softnyaposition="DETAILS_TOP" />
      <List />
      <AdsBanner type="softnyacontent" softnyaposition="DETAILS_BOTTOM" />
    </IonGrid>
  )
}

export default Account
