import { IonContent, IonIcon, IonModal } from "@ionic/react"
import { chevronDownCircleOutline, informationCircleOutline, linkOutline, logoGithub, logoGooglePlaystore, rocketOutline } from "ionicons/icons"
import { useEffect, useState } from "react"

import { AppSettingsID } from "utils/AppSettings"
import { IsLatestProps } from "types/Page/IsLatestShow"
import { AniyukiDance, XiaomiLogo } from "types/Anime/Images"
import { share } from "components/Commons/Social"
import { Item } from "components/Content/FormBuilder/Item"
import locales from "config/Locales"
import settings from "config/Settings"
import OverCard from "components/Content/OverCard"
import AniHeader from "../AniHeader"
import IsLatestShowFab from "./ShowFab"

import "./index.scss"

export const showIsLatest = () =>
  AppSettingsID.getSetting("isLatest", true)

export const showLatestForce = () =>
  AppSettingsID.getSetting("showLatestForce", false)

const IsLatest = (props: IsLatestProps) => {
  const [isLatestShown] = useState<boolean>(AppSettingsID.getSetting("isLatestShown", false) === true)
  const [isLatest] = useState<boolean>(showIsLatest())
  const [latest] = useState<string>(AppSettingsID.getSetting("latest", locales._get("latest-version")))
  const [appName] = useState<string>(locales._get("appname"))
  const [showAlert, setShowAlert] = useState<boolean>((!isLatest && !isLatestShown))
  const [forceShow, setForceShow] = useState<boolean>(props.forceShow || false)

  useEffect(() => {
    AppSettingsID.setSetting("isLatestShown", !isLatest)
    if (isLatest) AppSettingsID.setSetting("showLatestFab", true)
  }, [isLatest])

  useEffect(() => setShowAlert(props.showAlert || false), [props.showAlert])

  useEffect(() => {
    if (!showAlert) props.onClosed && props.onClosed()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showAlert])

  useEffect(() => {
    if (!showLatestForce()) return
    setShowAlert(true)
    setForceShow(true)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showLatestForce])

  if (isLatest && !forceShow) return <></>
  return (
    <>
      <IonModal
        isOpen={showAlert}
        onDidDismiss={() => {
          setShowAlert(false)
          AppSettingsID.setSetting("showLatestForce", false)
        }}
        onDidPresent={() => setShowAlert(true)}
        className="component-alert-is-latest window-modal"
      >
        <IonContent>
          <AniHeader
            showLogo={false}
            onClick={() => setShowAlert(false)}
          />
          <OverCard
            image={AniyukiDance}
            title={locales._get("latest-version.title", { "%s": latest, "%t": appName })}
            description={<>
              <IonIcon
                icon={chevronDownCircleOutline}
                size="large"
              />
            </>}
          />
          <div className="ion-padding inner-container">
            <div>
              <Item
                title={locales._get("latest-version.your-version", appName)}
                subtitle={AppSettingsID.getSetting("version", locales._get("latest-version.unkonown"))}
                icon={informationCircleOutline}
              />
              <Item
                title={locales._get("latest-version.latest")}
                subtitle={latest}
                icon={rocketOutline}
              />
              <p>{locales._get("latest-version.desc")}</p>
              <Item
                title={locales._get("latest-version.download", latest)}
                subtitle={locales._get("latest-version.google-play")}
                icon={logoGooglePlaystore}
                type="button"
                onClick={() => share.openPlayStore()}
              />
              <Item
                title={locales._get("latest-version.download", latest)}
                subtitle={locales._get("latest-version.github")}
                icon={logoGithub}
                type="button"
                onClick={() => window.open(settings.linkgithub, "_blank")}
              />
              <Item
                title={locales._get("latest-version.download", latest)}
                subtitle={locales._get("latest-version.xiaomi")}
                icon={XiaomiLogo}
                type="button"
                onClick={() => window.open(settings.linkXiami, "_blank")}
              />
              <Item
                title={locales._get("latest-version.download", latest)}
                subtitle={locales._get("latest-version.website")}
                icon={linkOutline}
                type="button"
                onClick={() => window.open(settings.linkweb, "_blank")}
              />
            </div>
          </div>
        </IonContent>
      </IonModal>
      <IsLatestShowFab
        onClickShowLatest={() => setShowAlert(true)}
      />
    </>
  )
}

export default IsLatest