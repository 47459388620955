import { Endpoints } from "api/Endpoints"
import { Responses } from "types/Connect"
import { AnimeRelatedRecommendedResponse } from "types/Anime/AnimeRelatedRecommended"
import Connect from "components/Connect"
import AnimeContentParser from "pages/_commons/Content/AnimeContent"

export const getRelatedRecommended = async (cid: number, force: boolean = false): Promise<AnimeRelatedRecommendedResponse> => {
  const response: Responses = await Connect.postcached({
    endpoint: Endpoints.detailsrelatedrecommended,
    params: { id: cid },
    options: { force: force },
  })
  if (!response.data?.results && !force) return getRelatedRecommended(cid, true)
  return AnimeContentParser.fitAnimeRelatedRecommended(response.data?.results as AnimeRelatedRecommendedResponse)
}