import { IonCard, IonCardHeader, IonCardTitle, IonCardSubtitle, IonCardContent } from "@ionic/react"
import Image from "components/Media/Image"

import { OverCardProps } from "types/Content"

import "./index.scss"

const OverCard = (props: OverCardProps) => {
  return (
    <IonCard className={`component-overcard ${props.className || ""}`}>
      <Image src={props.image || ""} />
      <div className="overcontent">
        <IonCardHeader>
          <IonCardTitle>{props.title}</IonCardTitle>
          <IonCardSubtitle>{props.subtitle}</IonCardSubtitle>
        </IonCardHeader>
        {props.description &&
          <IonCardContent>
            {props.description}
          </IonCardContent>
        }
      </div>
    </IonCard>
  )
}

export default OverCard