import { useEffect, useState } from "react"

import { OneZeroTypes } from "types"
import { Item } from "../../../../components/Content/FormBuilder/Item"
import locales from "config/Locales"
import settings from "config/Settings"

const SettingsSearch = () => {
  const [clearSearchOnFocus, setClearSearchOnFocus] = useState<OneZeroTypes>(new settings().clearSearchOnFocus)
  const [maxHistorySearch, setMaxHistorySearch] = useState<number>(new settings().maxHistorySearch)

  useEffect(() => {
    new settings().clearSearchOnFocus = clearSearchOnFocus
  }, [clearSearchOnFocus])

  useEffect(() => {
    new settings().maxHistorySearch = maxHistorySearch
  }, [maxHistorySearch])

  return (
    <>
      <Item
        title={locales._get("profile.settings-search")}
        asSubHeading={true}
      />
      <Item
        title={locales._get("profile.settings-search-clear")}
        subtitle={locales._get("profile.settings-search-clear-subtitle")}
        type="toggle"
        value={clearSearchOnFocus}
        onChange={(e: any) => setClearSearchOnFocus(e.target.checked ? 1 : 0)}
      />
      <Item
        title={locales._get("profile.settings-search-limit")}
        subtitle={locales._get("profile.settings-search-limit-subtitle")}
        type="range"
        value={maxHistorySearch}
        valueRange={{
          min: 0,
          max: 50,
          step: 10,
        }}
        onChange={(e: any) => setMaxHistorySearch(e.target.value)}
      />
    </>
  )
}

export default SettingsSearch