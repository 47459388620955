import { create } from "zustand"
import { devtools } from "zustand/middleware"

const MenuOptions = create(devtools((set) => ({
  isVisible: true,
  hideMenu: () => set({ isVisible: false }),
  showMenu: () => set({ isVisible: true }),
  toggleMenu: () => set((state: { isVisible: boolean }) => ({ isVisible: !state.isVisible })),
}), { name: "menu-options" }))

export default MenuOptions