import { Slide } from "types/Media/Slider"
import AnimeTopBadge from "pages/Discover/Anime/TopBadge"

import "./index.scss"

export const IsTop = (item: Slide) => {

  return (
    <span className="component-anime-top-badge">
      <AnimeTopBadge top={item.top || 0} />
    </span>
  )
}