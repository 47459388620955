import { useEffect, useState } from "react"

import { Endpoints } from "api/Endpoints"
import { paramsLang } from "api/routes/params/Media"
import { Responses } from "types/Connect"
import { Slide, SlideInfo } from "types/Media/Slider"
import { formatResults } from "pages/_commons/Content/formatResults"
import AnimeContentParser from "pages/_commons/Content/AnimeContent"
import Connect from "components/Connect"
import locales from "config/Locales"
import Loader from "pages/_commons/Loader"
import Gender from "./Gender"
import Studio from "./Studio"
import useAnime from "store/useAnime"
import SoftnyaShuffleAds from "components/Media/drawSlideItems/SoftnyaShuffleAds"

const GendersStudiosView = (props: { isScrolling: boolean, loadContent: boolean }) => {
  const [genders, setGenders] = useState<SlideInfo[]>([] as SlideInfo[])
  const [genderList, setGenderList] = useState<Slide[][]>([] as Slide[][])
  const [studios, setStudios] = useState<SlideInfo[]>([] as SlideInfo[])
  const [studioList, setStudioList] = useState<Slide[][]>([] as Slide[][])
  const [loaded, setLoaded] = useState<boolean>(false)
  const [isListLoaded, setIsListLoaded] = useState<boolean>(false)
  const [isScrolling, setIsScrolling] = useState<boolean>(false)
  const setIsOpen = useAnime<Function>((state: any) => state.setIsOpen)
  const setId = useAnime<Function>((state: any) => state.setId)
  const setEid = useAnime<Function>((state: any) => state.setEid)
  let studioIndex = -1

  useEffect(() => setLoaded(false), [props.loadContent])

  useEffect(() => {
    if (loaded) return

    setLoaded(true)
    const getCategoriesRemote = async () => {
      Connect.postcached({ endpoint: Endpoints.genderlist, params: paramsLang }).then((res: Responses) => {
        if (res.data?.results) {
          setGenders(AnimeContentParser.fitSlideInfo(res.data.results?.genders || [] as SlideInfo[]))
          setGenderList(res.data.results?.list || [] as Slide[][])
          setStudios(AnimeContentParser.fitSlideInfo(res.data.results?.studios || [] as SlideInfo[]))
          setStudioList(res.data.results?.studioslist || [] as Slide[][])
        }
      })
    }
    getCategoriesRemote()
  }, [loaded])

  useEffect(() => setIsScrolling(props.isScrolling), [props.isScrolling])

  useEffect(() => setIsListLoaded(Array.from(genderList).length > 0), [genderList])

  useEffect(() => {
    studioList?.map((studio: Slide[]) => {
      studio?.map((item: Slide) =>
        item = AnimeContentParser.fitSlideContent({
          item,
          setIsOpen,
          setId,
          setEid,
        }))
      return studio
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [studioList])

  return isListLoaded ? (
    <>
      {genderList?.map((gender: Slide[], index: number) => {
        const posStudio = index % Math.floor(genderList.length / studioList.length) === 0
        if (posStudio) studioIndex += 1
        gender?.map((item: Slide) =>
          item = AnimeContentParser.fitSlideContent({
            item,
            setIsOpen,
            setId,
            setEid,
          }))

        return (
          <>
            <Gender viewitems={gender} info={genders[index] || {} as SlideInfo} isScrolling={isScrolling} key={`gender-${index}`} />
            {(posStudio && studioList?.length > 0) &&
              <>
                <SoftnyaShuffleAds positionIndex={index} itemsPerRow={1} forceShow={true} />
                <Studio viewitems={formatResults(studioList[studioIndex], true) || [] as Slide[]} info={studios[studioIndex] || {} as SlideInfo} isScrolling={isScrolling} sliderType="player" key={`studios-${index}`} />
              </>
            }
          </>
        )
      })}
    </>
  ) : (
    <Loader text={locales._get("genders")} />
  )
}

export default GendersStudiosView
