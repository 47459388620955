import { IonList } from "@ionic/react"
import { checkmarkCircleOutline, constructOutline, listOutline } from "ionicons/icons"
import { useHistory } from "react-router-dom"
import { MenuApp } from "config/Menu/MenuApp"
import { Version } from "config/Settings/Version"
import { Item } from "components/Content/FormBuilder/Item"
import locales from "config/Locales"
import User from "components/Users/User"
import LogOut from "../LogOut"

import "./index.scss"

const List = () => {
  const history = useHistory()
  const iconBase = checkmarkCircleOutline

  return (
    <IonList className="component-user-account-list" mode="md">
      <Item title={locales._get("profile.myhistory")} icon={listOutline} onClick={() => history.push("/profile/history")} />
      <Item title={locales._get("app_settings")} icon={constructOutline} onClick={() => history.push("/profile/settings")} />

      {MenuApp.map((item, index) => (
        <Item title={item.title} icon={item.icon || iconBase} onClick={() => item.href && history.push(item.href)} key={index} />
      ))}

      {User.isLoggedIn() && <LogOut showName={true} />}

      <Item
        baseNode={<Version showOutdated={true} />}
      />
    </IonList>
  )
}

export default List