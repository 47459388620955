import { IonRefresher, IonRefresherContent } from "@ionic/react"
import { chevronDownCircleOutline } from "ionicons/icons"

import locales from "config/Locales"

import "./index.scss"

const Refresh = (props: { callback: Function }) => {
  return (
    <IonRefresher
      className="component-refresher"
      slot="fixed"
      pullMin={100}
      pullMax={200}
      onIonRefresh={(e: any) => {
        const refresher = e.srcElement as HTMLIonRefresherElement
        props.callback(() => {
          refresher.complete()
        })
      }}
    >
      <IonRefresherContent
        pullingIcon={chevronDownCircleOutline}
        pullingText={locales._get("pull_refresh")}
        refreshingSpinner="circles"
        refreshingText={locales._get("refreshing")}
      ></IonRefresherContent>
    </IonRefresher>
  )
}

export default Refresh