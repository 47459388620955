import { IonButton, IonIcon } from "@ionic/react"
import { flashOutline } from "ionicons/icons"
import { useHistory } from "react-router-dom"

export const ToolbarButton = ({ text = "", icon = "", link = "", asBack = false, ...props }) => {
  const history = useHistory()
  const { color, ...rest } = props

  return (
    <IonButton
      fill="solid"
      shape="round"
      size="small"
      onClick={() => asBack ? history.goBack() : history.push(link)}
      color={color ? color : "primary"}
      {...rest}
    >
      <IonIcon icon={icon ? icon : flashOutline} size="small" />
      {text}
    </IonButton>
  )
}