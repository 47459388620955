import { IonIcon } from "@ionic/react"
import { playCircleOutline } from "ionicons/icons"

import { Slide } from "types/Media/Slider"
import Card from ".."

export const Player = (props: { item: Slide, onClick?: Function, showIcon?: boolean }) => {
  const { conditionalBadge, contentOverTitle, contentOverHeader } = props.item
  props.item.img = props.item?.img2 || props.item?.img

  return (
    <Card
      item={props.item}
      onClick={props.onClick}
      className={`card-player ion-relative ${props.item.className || ""}`}
      thumb="250x140"
      contentBellowImg={
        <>
          {conditionalBadge && conditionalBadge()}
          {props.showIcon && <div className="item-player-overlay">
            <IonIcon icon={playCircleOutline} />
          </div>}
        </>
      }
      contentOverHeader={
        <>
          {contentOverHeader && contentOverHeader()}
        </>
      }
      contentOverTitle={
        <>
          {contentOverTitle && contentOverTitle()}
        </>
      }
    />
  )
}

Player.defaultProps = {
  showIcon: true,
}