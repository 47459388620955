import { IonButton, IonIcon, IonText } from "@ionic/react"

import { defaultScreenLoaderProps, ScreenLoaderProps } from "types/Content"
import { SkeletonSingle } from "components/Commons/Skeleton"
import { closeOutline } from "ionicons/icons"
import { SpinnerCircular } from "components/Commons/Spinner"
import LoaderOptions from "./LoaderOptions"
import locales from "config/Locales"
import Image from "components/Media/Image"

import "./index.scss"

const ScreenLoader = (props: ScreenLoaderProps) => {
  return (
    <div className="component-screenloader hidden">
      <SkeletonSingle />
      <IonButton
        fill="clear"
        color="light"
        shape="round"
        className={`close-button ${props.canCancel ? "" : "ion-hidden"}`}
        onClick={() => { LoaderOptions.closeLoader() }}
      >
        <IonIcon icon={closeOutline} size="large" />
      </IonButton>
      <IonText>
        {props.withSpinner &&
          <SpinnerCircular />
        }
        <span className="text">{props.text || locales._get("loading")}</span>
        {props.imagePosition === "center" &&
          <Image src={LoaderOptions.getRandomLoaderImage()} />
        }
      </IonText>
      {props.imagePosition === "floatBase" &&
        <div className="floating-base">
          <Image src={LoaderOptions.getRandomLoaderImage()} />
        </div>
      }
    </div>
  )
}

ScreenLoader.defaultProps = defaultScreenLoaderProps

export default ScreenLoader