import StorageUtils from "utils/Storage"
import en from "./langs/en"
import es from "./langs/es"

class locales {
  static currentLang = StorageUtils.getItem("lang") || "es"

  static en: any = en

  static es: any = es

  static _get(key: string, params?: any, lang = ""): string {
    if (lang === "") lang = locales.currentLang
    const langObj = locales[lang as "en" | "es"]

    if (!langObj[key]) console.warn(`Language key not found: ${key}`)
    return this.replaceParams(langObj[key], params) || key
  }

  private static replaceParams(str: string, params: any) {
    if (params === undefined) return str
    if (typeof params === "string" || typeof params === "number") params = { "%s": params }

    for (const key in params) {
      str = str?.replace(`%${key.replace("%", "")}`, params[key]) || str
    }
    return str
  }
}

export default locales