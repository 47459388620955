import { PublicUrlImg } from "types/Anime/Images"

const AvatarPath = `${PublicUrlImg}avatar/male/`

export const AvatarMale = {
  "avatarmale1": `${AvatarPath}avatar1.jpg`,
  "avatarmale2": `${AvatarPath}avatar2.jpg`,
  "avatarmale3": `${AvatarPath}avatar3.jpg`,
  "avatarmale4": `${AvatarPath}avatar4.jpg`,
  "avatarmale5": `${AvatarPath}avatar5.jpg`,
  "avatarmale6": `${AvatarPath}avatar6.jpg`,
  "avatarmale7": `${AvatarPath}avatar7.jpg`,
  "avatarmale8": `${AvatarPath}avatar8.jpg`,
  "avatarmale9": `${AvatarPath}avatar9.jpg`,
  "avatarmale10": `${AvatarPath}avatar10.jpg`,
}