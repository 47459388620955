import { Capacitor } from "@capacitor/core"
import { Device } from "@ionic-native/device"
import { AndroidFullScreen } from "@awesome-cordova-plugins/android-full-screen"
import { ScreenOrientation } from "@ionic-native/screen-orientation"

import Strings from "utils/Strings"

export const getPlatform = () =>
  Strings.capitalizeFirstLetter(Capacitor.getPlatform().toString())

export const getUserAgent = () =>
  navigator.userAgent

export const deviceInfo = {
  deviceUuid: Capacitor.isNativePlatform() ? Device.uuid : `web-${Strings.getRandomString(10)}`,
  deviceModel: Capacitor.isNativePlatform() ? Device.model : "Browser",
  devicePlatform: Capacitor.isNativePlatform() ? Device.platform : "Browser",
}

export const sendFullScreen = () => {
  if (!Capacitor.isNativePlatform()) return
  AndroidFullScreen.isSupported().then(() =>
    AndroidFullScreen.immersiveMode())
}

export const sendNormalScreen = () => {
  if (!Capacitor.isNativePlatform()) return
  AndroidFullScreen.isSupported().then(() =>
    AndroidFullScreen.showSystemUI())
}

export const landscapeLock = (callback?: Function) => {
  if (!Capacitor.isNativePlatform()) return
  ScreenOrientation.lock(ScreenOrientation.ORIENTATIONS.LANDSCAPE)
    .then((response) => callback && callback(response))
}

export const portraitLock = (callback?: Function) => {
  if (!Capacitor.isNativePlatform()) return
  ScreenOrientation.lock(ScreenOrientation.ORIENTATIONS.PORTRAIT)
    .then((response) => callback && callback(response))
}

export const unlockOrientation = (callback?: Function) => {
  if (!Capacitor.isNativePlatform()) return
  ScreenOrientation.unlock()
  callback && callback()
}