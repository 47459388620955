import settings from "config/Settings"
import { StorageUtilsProps } from "types/Utils/Storage"
import { ionicStorage } from "utils/IonicStorage"
import { StorageItems } from "./StorageItems/LocalStorage"

class StorageUtils extends StorageItems {
  public static isLocalStorageAvailable = (): boolean => {
    try {
      StorageUtils.setItem("test", "test")
      StorageUtils.removeItem("test")
      return true
    } catch (e) {
      return false
    }
  }

  public static maxSizeLocalStorage = (): number => {//kb
    if (!StorageUtils.isLocalStorageAvailable()) return 0

    const setSize = (num: number): string => new Array((num * 1024) + 1).join("a")
    const getSize = () => parseInt(StorageUtils.getItem("permanent-ls-size") || "0")

    if (!StorageUtils.getItem("permanent-ls-size")) {
      let i = 0
      try {
        // Test up to x MB
        for (i = 0; i <= 15000; i += 250) StorageUtils.setItem("test", setSize(i))
      } catch (e) { }
      if (StorageItems.getItem("test")) {
        StorageUtils.removeItem("test")
        StorageUtils.setItem("permanent-ls-size", (i ? i - 250 : 0).toString())
      }
    } else {
      if (getSize() === 0) StorageUtils.removeItem("permanent-ls-size")//fail, try again
    }

    return getSize()
  }

  public static usedKeyLocalStorage = (key: string): number => //kb
    ((window.localStorage[key]?.length * 16) / (8 * 1024))

  public static usedLocalStorage = (): number => {//kb
    if (!StorageUtils.isLocalStorageAvailable()) return 0

    let total = 0
    for (let i = 0; i < StorageUtils.getLength(); i++) {
      const key = StorageUtils.getKey(i)
      if (key) total += StorageUtils.usedKeyLocalStorage(key)
    }
    return total
  }

  public static getStatsLocalStorage = (inKb: boolean = true): StorageUtilsProps => {
    if (!StorageUtils.isLocalStorageAvailable()) return {} as StorageUtilsProps

    const used: number = StorageUtils.usedLocalStorage() * (inKb ? 1 : 1024)
    const maxSize: number = StorageUtils.maxSizeLocalStorage() * (inKb ? 1 : 1024)
    return {
      available: {
        space: maxSize - used,
        percent: 100 - ((used / maxSize) * 100),
      },
      used: {
        space: used,
        percent: (used / maxSize) * 100,
      },
      maxSize: maxSize,
      isFull: used >= maxSize,
    } as StorageUtilsProps
  }

  public static isLocalStorageFull = (): boolean =>
    StorageUtils.getStatsLocalStorage().isFull

  public static getAvailableLocalStorage = (): number =>
    StorageUtils.getStatsLocalStorage().available.space || 0

  public static getAvailablePercentLocalStorage = (): number =>
    StorageUtils.getStatsLocalStorage().available.percent || 0

  public static getUsedLocalStorage = (): number =>
    StorageUtils.getStatsLocalStorage().used.space || 0

  public static getUsedPercentLocalStorage = (): number =>
    StorageUtils.getStatsLocalStorage().used.percent || 0

  static checkCachedContent = async () => {
    //local storage
    const maxCachedPercent = new settings().maxCachedPercent
    const usedStat: StorageUtilsProps = await StorageUtils.getStatsLocalStorage()
    if (usedStat.used.percent < maxCachedPercent) return

    const keys: string[] = await StorageUtils.getCachedKeys()
    const cachedKeys = keys.filter((key) => key.indexOf(settings.apiUrl) > -1)

    //order by oldest
    cachedKeys.sort((a, b) => {
      const aDate = parseInt(StorageUtils.getItem(`lastcall-${a}`) || "0")
      const bDate = parseInt(StorageUtils.getItem(`lastcall-${b}`) || "0")
      return bDate - aDate
    })
    let used = 0
    const maxSizeLocalStorage = usedStat.maxSize
    if (maxSizeLocalStorage === 0) return
    cachedKeys?.map(key => {
      used += StorageUtils.usedKeyLocalStorage(key)
      const percentUsed = (used * 100) / maxSizeLocalStorage
      if (percentUsed > maxCachedPercent) StorageUtils.removeItem(key)
      return key
    })
  }

  static checkInternalContent = async () => {
    // internal storage
    const maxInternalPercent = await ionicStorage.getPercentUsed()
    const usedStat: StorageUtilsProps = await ionicStorage.getStatsInternalStorage()
    if (usedStat.used.percent < maxInternalPercent) return

    const keys: string[] = await ionicStorage.getKeys()
    const internalKeys = keys.filter((key) => key.indexOf(settings.apiUrl) > -1)

    let used = 0
    const maxSizeInternalStorage = usedStat.maxSize
    if (maxSizeInternalStorage === 0) return
    internalKeys?.map(key => async () => {
      used += await ionicStorage.usedKeyInternalStorage(key)
      const percentUsed = (used * 100) / maxSizeInternalStorage
      if (percentUsed > maxInternalPercent) ionicStorage.removeItem(key)
      return key
    })
  }
}

export default StorageUtils