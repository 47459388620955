import { useState } from "react"
import { Capacitor } from "@capacitor/core"
import { IonButton, IonIcon } from "@ionic/react"
import { closeCircleOutline } from "ionicons/icons"

import { SpinnerCircular } from "components/Commons/Spinner"
import { RemoveAdsButtonProps } from "types/Media/Ads"
import JDate from "utils/JDate"
import locales from "config/Locales"
import settings from "config/Settings"
import User from "components/Users/User"

const RemoveAdsButton = (props: RemoveAdsButtonProps) => {
  const [showingAds] = useState(props.showingAds || false)
  const time = props.time.ago / 1000 // seconds
  const timelock = JDate.simplifytime(time)
  const passed = props.time.passed || props.time.ago <= 0
  const [timeinterval] = useState<string>(JDate.simplifytime(settings.ads_remove_interval))

  if (User.isPremium()) return <></>
  if (!passed && props.isOnBanner) return <></>
  if (!Capacitor.isNativePlatform() && props.isOnBanner) return <></>
  return (
    <IonButton
      fill="solid"
      color="secondary"
      size={props.isOnBanner ? "small" : "default"}
      className={props.isOnBanner ? "remove-ads-button-on-banner" : "remove-ads-button"}
      onClick={() => props.onClick(props.position)}
      disabled={showingAds}
    >
      {!showingAds && <IonIcon icon={closeCircleOutline} />}
      {showingAds && <SpinnerCircular />}
      {passed && <div dangerouslySetInnerHTML={{
        __html: locales._get(props.isOnBanner ? "ads.remove-button-in-banner" : "ads.remove-button", timeinterval)
      }} />}
      {!passed && locales._get("ads.remove-button-passed", timelock)}
    </IonButton>
  )
}

export default RemoveAdsButton