import { IonIcon } from "@ionic/react"
import { chevronBackOutline } from "ionicons/icons"

import { AniHeaderProps } from "types/Page/AniHeader"
import { useHistory } from "react-router-dom"
import locales from "config/Locales"
import useAnime from "store/useAnime"

import "./index.scss"

export const HeaderBack = (props: AniHeaderProps) => {
  const history = useHistory()
  const setIsOpen = useAnime<Function>((state: any) => state.setIsOpen)
  const isOpen: boolean = useAnime((state: any) => state.isOpen)

  return (
    <div
      className="component-header-back"
      onClick={() => props.onClick ? props.onClick() : (isOpen ? setIsOpen(false) : history.goBack())}
    >
      <IonIcon
        icon={chevronBackOutline}
      />
      {locales._get("content.back")}
    </div>
  )
}