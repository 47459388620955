import { useEffect, useState } from "react"
import { IonLabel, IonList, IonItem, IonIcon, IonBadge } from "@ionic/react"
import { chevronForwardCircle, play } from "ionicons/icons"

import { EpisodeMediaType } from "types/Anime"
import { AnimeEpisodeDropDownContentProps } from "types/Anime/AnimeEpisodeDropDownContent"
import AlertWithAds from "components/Commons/Alerts/WithAds"
import locales from "config/Locales"
import useAnime from "store/useAnime"

import "./index.scss"

const AnimeEpisodeDropDownContent = (props: AnimeEpisodeDropDownContentProps) => {
  const [showAlert, setShowAlert] = useState<boolean>(false)
  const [list] = useState<EpisodeMediaType[]>(props.type === "download" ? props.mediatypes?.current?.download : props.mediatypes?.current?.play)
  const [selectedMedia, setSelectedMedia] = useState<string>("")
  const lastMediaStore = useAnime<string>((state: any) => state.lastMedia)

  const onClick = (media: EpisodeMediaType) => {
    props.onClick && props.onClick(media)
    const picker = document.querySelector(`#popover-media-picker${props.type === "download" ? "-download" : ""}`) as HTMLIonPopoverElement
    picker?.dismiss()
  }

  useEffect(() => {
    setSelectedMedia(props.selectedMedia || lastMediaStore || "")
    return () => setShowAlert(false)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <>
      <IonLabel>{locales._get("content.media-list")}</IonLabel>
      <IonList className="component-anime-dropdown-content">
        {list?.map((media: EpisodeMediaType, index: number) =>
          <>
            <IonItem key={index} className={selectedMedia === media.provider ? "active" : ""}>
              <IonBadge
                color={media.premium === 1 ? "secondary" : "primary"}
                onClick={() => {
                  onClick(media)
                  if (media.ads > 0) setShowAlert(true)
                }}
              >
                <IonIcon icon={play} />
                <span>
                  {media.provider}
                  {media.ads > 0 ? <small> {locales._get("content.media-ads")} {media.adsStr}</small> : <></>}
                  {media.premium ? <small> {locales._get("content.premium")}</small> : <></>}
                </span>
                <IonIcon icon={chevronForwardCircle} className="float-right" />
              </IonBadge>
            </IonItem>
          </>
        )}
      </IonList>
      <AlertWithAds
        showAlert={showAlert}
      />
    </>
  )
}

export default AnimeEpisodeDropDownContent