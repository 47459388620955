import About from "pages/About"
import Changelog from "pages/Changelog"
import News from "pages/News"
import Store from "pages/Store"
import Support from "pages/Support"
import Tos from "pages/Tos"

const routes = [
  {
    path: "news",
    component: <News />,
  },
  {
    path: "news/:id",
    component: <News />,
  },
  {
    path: "store",
    component: <Store />,
  },
  {
    path: "about",
    component: <About />,
  },
  {
    path: "tos",
    component: <Tos />,
  },
  {
    path: "changelog",
    component: <Changelog />,
  },
  {
    path: "support",
    component: <Support />,
  },
]

export default routes