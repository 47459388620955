import { IonSegmentButton } from "@ionic/react"
import Image from "components/Media/Image"

import { TabSegmentProps } from "types/Anime"
import { SpinnerBubbles } from "components/Commons/Spinner"
import locales from "config/Locales"

import "./index.scss"

const TabSegment = (props: TabSegmentProps) => {
  return (
    <IonSegmentButton
      value={props.name}
      className="anime-tabs-segment"
      disabled={props.lockSegment || false}
    >
      <div>
        {(props.img && !props.lockSegment) && <Image src={props.img} />}
        {props.lockSegment && <SpinnerBubbles />}
        {locales._get(`content.tab-${props.name}`)}
      </div>
    </IonSegmentButton>
  )
}

export default TabSegment