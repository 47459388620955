import { AnimeContent, AnimeEpisodes, EpisodeMediaType } from "./"

export type ModalMediaControlsProps = {
  content?: AnimeContent,
  episodes?: AnimeEpisodes,
  provider?: EpisodeMediaType,
  alterTitle?: string,
  alterSubtitle?: string,
  onClose?: () => void,
  onMediaChange?: (provider: string) => void,
  selected?: string,
  showMenu?: boolean,
  onClick?: Function,
  hasCloseButton?: boolean,
  elapsed?: number,
}

export const ModalMediaControlsDefaultProps = {
  selected: "",
  showMenu: true,
  manualCounter: 0,
}

export type ModalMusicControlsProps = {
  content?: AnimeContent,
  episodes?: AnimeEpisodes,
  provider?: EpisodeMediaType,
  onClose?: () => void,
  onMediaChange?: (provider: string) => void,
  onPrev?: () => void,
  onNext?: () => void,
  elapsed?: number,
}