import { useState } from "react"

import { colorTypes } from "types/index"
import GendersStudiosView from "./GendersStudiosView"
import HeaderSlides from "pages/_commons/Content/HeaderSlides"
import HeaderToolbar from "./HeaderToolbar"
import NewContent from "pages/_commons/Content/NewContent"
import ForYou from "./ForYou"
import ContinueWatching from "pages/_commons/Content/ContinueWatching"
import PageContainer from "pages/_commons/PageContainer"
import Refresher from "components/Content/Refresher"
import UserRefresh from "components/Connect/Refresher"
import LoaderOptions from "components/Content/ScreenLoader/LoaderOptions"
import locales from "config/Locales"
import Popular from "pages/_commons/Content/Popular"
import Alerts from "components/Commons/Alerts"
import AdsBanner from "components/Media/Ads/Banner"
import Meta from "components/Media/Meta"

import "./index.scss"

const Home: React.FC = () => {
  const [isScrolling, setIsScrolling] = useState(false)
  const [watchLastCall, setWatchLastCall] = useState<boolean>(true)
  const [loadContent, setLoadContent] = useState<boolean>(true)
  const [alertMsg, setAlertMsg] = useState<string>("")
  const [alertColor, setAlertColor] = useState<colorTypes>("warning")

  const toggleLastCall = () => setWatchLastCall((prevWatch: boolean) => !prevWatch)

  const toggleLoadContent = () => setLoadContent((prevLoad: boolean) => !prevLoad)

  return (
    <PageContainer
      withProgress={true}
      withToTop={true}
      className="page-discover"
      callbackStart={() => setIsScrolling(true)}
      callbackEnd={() => setIsScrolling(false)}
    >
      <>
        <HeaderSlides />
        <HeaderToolbar />
        <div className="ion-padding inner-container">
          <Refresher
            callback={(closeRefreser: Function) => {
              LoaderOptions.openLoader("refreshing")
              UserRefresh.discover((callbackStatus: number) =>
                UserRefresh.user((callbackUserStatus: number) => {
                  toggleLastCall()
                  toggleLoadContent()
                  closeRefreser()
                  setAlertMsg(locales._get((callbackStatus === 200 && callbackUserStatus === 200) ? "refreshed" : "not_refreshed"))
                  setAlertColor((callbackStatus === 200 && callbackUserStatus === 200) ? "success" : "secondary")
                  LoaderOptions.closeLoader()
                }, true), true)
            }}
          />
          <ForYou watchLastCall={watchLastCall} loadContent={loadContent} />
          <AdsBanner type="softnyacontent" softnyaposition="DETAILS_TOP" />
          <NewContent watchLastCall={watchLastCall} loadContent={loadContent} />
          <ContinueWatching loadContent={loadContent} />
          <Popular watchLastCall={watchLastCall} loadContent={loadContent} />
          <AdsBanner type="softnyacontent" softnyaposition="CONTENT_A" />
          <GendersStudiosView isScrolling={isScrolling} loadContent={loadContent} />
          <AdsBanner type="softnyacontent" softnyaposition="CONTENT_D" />
          <Alerts
            title={alertMsg}
            showAlert={alertMsg !== ""}
            duration={3000}
            color={alertColor}
            position="top"
          />
        </div>
        <Meta
          title={locales._get("discover_new_anime")}
        />
      </>
    </PageContainer>
  )
}

export default Home
