import { useEffect, useState } from "react"

import { AppSettingsTemplates } from "types/Page/AppSettings"
import { OneZeroTypes } from "types/index"
import { Item } from "../../../../components/Content/FormBuilder/Item"
import locales from "config/Locales"
import settings from "config/Settings"

const SettingsMediaPlayer = () => {
  const [playerTemplate, setPlayerTemplate] = useState<string>(new settings().playerTemplate)
  const [playerPip, setPlayerPip] = useState<OneZeroTypes>(new settings().playerPip)

  useEffect(() => {
    new settings().playerTemplate = playerTemplate
  }, [playerTemplate])

  useEffect(() => {
    new settings().playerPip = playerPip
  }, [playerPip])

  return (
    <>
      <Item
        title={locales._get("profile.settings-media-player")}
        asSubHeading={true}
      />
      <Item
        title={locales._get("settings.title-media-player")}
        subtitle={locales._get("settings.title-media-player-subtitle")}
        type="select"
        value={playerTemplate}
        valueSelect={AppSettingsTemplates}
        onChange={(e: any) => setPlayerTemplate(e.detail?.value || playerTemplate)}
      />
      <Item
        title={locales._get("settings.title-media-player-pip")}
        subtitle={locales._get("settings.title-media-player-pip-subtitle")}
        type="toggle"
        value={playerPip}
        onChange={(e: any) => setPlayerPip(e.target.checked ? 1 : 0)}
      />
    </>
  )
}

export default SettingsMediaPlayer