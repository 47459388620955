import { IonLabel, IonList, IonItem, IonIcon } from "@ionic/react"
import { useHistory } from "react-router-dom"
import { cubeOutline } from "ionicons/icons"

import { AnimeContent, AnimeContentGender } from "types/Anime"
import locales from "config/Locales"
import useAnime from "store/useAnime"

import "./index.scss"

const AnimeDropDownBadge = (props: { content: AnimeContent }) => {
  const history = useHistory()
  const setIsOpen = useAnime((state: any) => state.setIsOpen)

  const onClick = (id: number) => {
    history.push(`/anime/gender/${id}`)
    setIsOpen(false)
    const popover = document.querySelector("ion-popover.more-genders") as HTMLIonPopoverElement
    if (popover) popover.dismiss()
  }
  return (
    <>
      <IonLabel>{locales._get("content.more-genders")} ({props.content?.gendersidsRest?.length})</IonLabel>
      <IonList className="component-anime-genders-dropdown-badge">
        {props.content?.gendersidsRest?.map((gender: AnimeContentGender) =>
          <>
            <IonItem button detail={true} onClick={() => onClick(gender.id)}>
              <IonIcon icon={cubeOutline} slot="start" />
              <IonLabel>
                <h3>{gender.title}</h3>
                <p>{gender.description}</p>
              </IonLabel>
            </IonItem>
          </>
        )}
      </IonList>
    </>
  )
}

export default AnimeDropDownBadge