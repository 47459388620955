import { SlideInfo } from "types/Media/Slider"
import { AppSettingsID } from "utils/AppSettings"
import Strings from "utils/Strings"
import AnimeContentParser from "../AnimeContent"

export const saveStudios = (studios: SlideInfo[]) => {
  if (studios.length === 0) return

  AppSettingsID.setSetting("studios-list", AnimeContentParser.fitSlideInfo(studios)?.map((studio: SlideInfo) => {
    studio.title = Strings.encode(studio.title || "")
    return studio
  }))
  AppSettingsID.setSetting("studios-count", studios.length)
}