import { AniTitleFilterProps } from "types/Page/AniTitle"
import locales from "config/Locales"
import User from "components/Users/User"

export const filtersPremium = User.inList("BLOCK_FILTER.PREMIUM") ? {} as AniTitleFilterProps : {
  title: locales._get("filters.premium"),
  type: "check",
  key: "only_premium",
  explain: locales._get("filters.premium-explain"),
} as AniTitleFilterProps

export const filtersPending = User.inList("BLOCK_FILTER.PENDING") ? {} as AniTitleFilterProps : {
  title: locales._get("filters.pending-see"),
  type: "check",
  key: "no_views",
} as AniTitleFilterProps

export const filtersContentType = User.inList("BLOCK_FILTER.CONTENT_TYPE") ? {} as AniTitleFilterProps : {
  title: locales._get("filters.content-type"),
  type: "select",
  key: "content_type",
  selected: "all",
  options: [
    {
      label: locales._get("content.type-pelicula"),
      value: "movie",
    },
    {
      label: locales._get("content.type-tv"),
      value: "series",
    },
    {
      label: locales._get("content.type-ova"),
      value: "ovas",
    },
    {
      label: locales._get("content.type-especial"),
      value: "special",
    },
  ]
} as AniTitleFilterProps