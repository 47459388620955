import { useEffect, useState } from "react"
import { StorageUtilsProps } from "types/Utils/Storage"

import { ionicStorage } from "utils/IonicStorage"
import StorageUtils from "utils/Storage"
import SettingsStorageType from "./StorageType"
import settings from "config/Settings"

const SettingsStorageLimits = () => {
  const [utilsDb, setUtilsDb] = useState<StorageUtilsProps>({} as StorageUtilsProps)
  const [utilsCached, setUtilsCached] = useState<StorageUtilsProps>({} as StorageUtilsProps)

  useEffect(() => {
    ionicStorage.getStatsInternalStorage().then((stats: StorageUtilsProps) => {
      setUtilsDb(stats)
    })
    setUtilsCached(StorageUtils.getStatsLocalStorage(false))
  }, [])

  return (
    <>
      <SettingsStorageType
        type="storage"
        utils={utilsDb}
        maxPercent={new settings().maxDbPercent}
        setMaxPercent={(value: number) => {
          new settings().maxDbPercent = value
        }}
      />
      <SettingsStorageType
        type="localstorage"
        utils={utilsCached}
        maxPercent={new settings().maxCachedPercent}
        setMaxPercent={(value: number) => {
          new settings().maxCachedPercent = value
        }}
      />
    </>
  )
}

export default SettingsStorageLimits