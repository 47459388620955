import { IonBadge } from "@ionic/react"

import { AnimeTabs } from "types/Anime"
import locales from "config/Locales"

export const allButTV = (props: AnimeTabs) => {
  if (props.content.ctype === "tv") return <></>

  return (
    <>
      {props.content.startdateStr !== "" &&
        <IonBadge className="episode-date" color="tertiary">
          {locales._get("content.details-launch-date")}&nbsp;
          {props.content.startdateStr}
        </IonBadge>
      }
    </>
  )
}
