import { useEffect, useState } from "react"

import { Slide } from "types/Media/Slider"
import { AllMightyLaugh } from "types/Anime/Images"
import { getCharactersStaff } from "../../../Anime/Methods/getCharactersStaff"
import { fitSlideContentTypes } from "types/Anime"
import { AnimeCharactersStaffProps } from "types/Anime/AnimeCharacters"
import ContentGrid from "pages/_commons/Content/Content/Grid"
import OverCard from "components/Content/OverCard"
import locales from "config/Locales"
import AnimeContentParser from "pages/_commons/Content/AnimeContent"

import "./index.scss"
import "pages/_commons/Content/Content/index.scss"

const AnimeCharactersStaff = (props: AnimeCharactersStaffProps) => {
  const [items, setItems] = useState<Slide[]>([] as Slide[])
  const [loaded, setLoaded] = useState(false)

  useEffect(() => {
    setLoaded(false)
    getCharactersStaff(props.content?.id, props.type).then((items: Slide[]) => {
      setItems(() => {
        setLoaded(true)
        items.map((item: Slide) => {
          item.subtitle_color = item.subtitle === locales._get("content.no-staff") ? "tertiary" : "primary"
          item = AnimeContentParser.fitSlideContent({
            item,
            setIsOpen: props.setIsOpen,
            setId: props.setId,
          } as fitSlideContentTypes)
          return item
        })
        return items
      })
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.content?.id])

  return (
    <div className="anime-characters-staff">
      {(items.length > 0 || !loaded) ?
        <ContentGrid
          title={""}
          lastCallPlay={""}
          newItemsPlay={items}
          type="item"
        />
        :
        <OverCard
          image={AllMightyLaugh}
          title={locales._get("content.not-characters")}
        />
      }
    </div>
  )
}

export default AnimeCharactersStaff