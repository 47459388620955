import { Endpoints } from "api/Endpoints"
import { Responses } from "types/Connect"
import locales from "config/Locales"
import Connect from "components/Connect"

export const doReset = (props: {
  username: string,
  remembermsg: string,
  setRememberAlert: Function,
  presentAlert: Function,
  history: any,
}) => {
  if (props.remembermsg === locales._get("sendfailed")) return
  if (props.username === "") return props.setRememberAlert(locales._get("sendfailed"))
  props.setRememberAlert("")

  if (props.username) {
    props.setRememberAlert(locales._get("loading"))
    Connect.post({
      endpoint: Endpoints.reset,
      body: {
        email: props.username,
      }
    }).then((response: Responses) => {
      if (response.status === 200) {
        props.setRememberAlert(locales._get("send_email"))
        props.presentAlert({
          header: locales._get("success"),
          message: locales._get("remember_msg", props.username),
          buttons: [locales._get("ok")],
        })
        return
      }
      props.setRememberAlert(locales._get("sendfailed"))
    }).catch(() => {
      props.setRememberAlert(locales._get("sendfailed"))
    })
  }
}