import { Responses } from "types/Connect"
import { StorageItems } from "utils/Storage/StorageItems/Storage"
import Connect from ".."
import User from "components/Users/User"

export const getLastCall = async (query: string, permanent: boolean = false): Promise<number> => {
  return parseInt(await StorageItems.getItem(`lastcall-${getQueryFormat(query, permanent)}`) || "0") || 0
}

export const getQueryLastCall = async (endpoint: string, params?: any, permanent: boolean = false): Promise<number> => {
  const query = Connect.getQuery({ endpoint, params })
  return await getLastCall(query, permanent)
}

export const removePageHistory = async (endpoint: string, params: any, currentPage: number = 2) => {
  let queryCheck = Connect.getQuery({ endpoint, params: { ...params || {}, pag: currentPage } })
  while (await getLastCall(queryCheck) > 0) {
    Connect.removeCached(queryCheck)
    currentPage++
    queryCheck = Connect.getQuery({ endpoint, params: { ...params || {}, pag: currentPage } })
  }
}

export const getQueryFormat = (query: string, permanent: boolean = false): string => {
  let queryFormat = query
  const urlParams = new URLSearchParams(query)
  const replaces = ["Token", "APIKEY"]
  replaces?.map((replace: string) => {
    if (urlParams.has(replace)) queryFormat = queryFormat.replace(`&${replace}=${urlParams.get(replace)}`, "")
    return replace
  })

  const username = User.getUsername()
  const prefix = `user-${username === "" ? "guest" : username}-`

  // options.permanent avoid to be cleared with cache limit time
  return `${permanent ? "permanent-" : prefix}${queryFormat}`
}

export const getQueryLocal = async (queryFormat: string): Promise<Responses> => {
  return JSON.parse(await StorageItems.getItem(queryFormat) || "{}")
}