import { Endpoints } from "api/Endpoints"
import { Responses } from "types/Connect"
import { CharactersStaffContent } from "types/Characters"
import Connect from "components/Connect"
import CharacterParser from "pages/_commons/Content/Character"

export const getStaff = async (props: { stid: number, force?: boolean }): Promise<CharactersStaffContent> => {
  if (props.stid === 0) return {} as CharactersStaffContent
  const response: Responses = await Connect.postcached({
    endpoint: Endpoints.staff,
    params: { id: props.stid },
    options: { checkInterval: "month", force: props.force || false },
  })
  if (!response.data?.result && !(props.force || false)) return getStaff({ stid: props.stid, force: true })
  return CharacterParser.fitCharacterContent(response.data?.result as CharactersStaffContent)
}