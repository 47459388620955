import { Capacitor } from "@capacitor/core"

import { ModalMusicControlsProps } from "types/Anime/AnimeModalMedia"
import { AnimeContent, AnimeEpisodes } from "types/Anime"
import { bgApp } from "pages/_commons/AniHeader/HeaderLogo"
import locales from "config/Locales"

export const buildControls = (_props: ModalMusicControlsProps, MusicControls: any) => {
  if (!Capacitor.isNativePlatform()) return

  MusicControls?.destroy()
  const exists = _props.episodes?.media?.current?.exist === 1
  MusicControls?.create({
    track: exists ?
      `${_props.content?.ctypeStr || ""}: ${_props.episodes?.media?.current?.badge || ""} ${_props.content?.title || ""}` :
      locales._get("content.media-player", locales._get("appname")),
    artist: exists ? _props.content?.studio : "",
    album: exists ? _props.content?.title : "",
    cover: exists ? (_props.content?.img || _props.content?.img2) : bgApp,
    isPlaying: exists ? true : false,
    hasPrev: _props.episodes?.media?.prev?.exist === 1 ? true : false,
    hasNext: _props.episodes?.media?.next?.exist === 1 ? true : false,
    hasClose: false,
    playIcon: "media_play",
    pauseIcon: "media_pause",
    prevIcon: "media_prev",
    nextIcon: "media_next",
    duration: _props.content?.duration || 0,
    elapsed: _props.elapsed || 0,
    hasScrubbing: true,
    dismissable: true,
  })

  MusicControls?.subscribe((events: any) => {
    const message = JSON.parse(events).message
    if (message === "music-controls-next") {
      MusicControls?.destroy()
      _props.onNext && _props.onNext()
    }
    else if (message === "music-controls-previous") {
      MusicControls?.destroy()
      _props.onPrev && _props.onPrev()
    }
    else if (message === "music-controls-seek-to") {
      const seekToInSeconds = JSON.parse(events).position
      MusicControls?.updateElapsed({
        elapsed: seekToInSeconds,
        isPlaying: true,
      })
    }
    else if (message === "music-controls-destroy") { }
  })
  MusicControls?.listen()
}

export const clearControls = (MusicControls: any) => {
  buildControls({
    content: {} as AnimeContent,
    episodes: {} as AnimeEpisodes,
  } as ModalMusicControlsProps, MusicControls)
}