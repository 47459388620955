import Meta from "components/Media/Meta"
import locales from "config/Locales"
import Pager from "pages/_commons/Pager"

const Changelog = () => {

  return (
    <>
      <Pager
        fetchFile="CHANGELOG.md"
        className="page-changelog"
        storageKey="Changelog"
        asMarkdown={true}
      />
      <Meta
        title={locales._get("changelog")}
      />
    </>
  )
}

export default Changelog