import { cartOutline, cloudDownloadOutline, cubeOutline, shareSocialOutline, starSharp, videocamOutline } from "ionicons/icons"

import { menuItems } from "types/Commons/Menu"
import { share } from "components/Commons/Social"
import locales from "config/Locales"
import settings from "config/Settings"
import { AppSettingsID } from "utils/AppSettings"

export const MenuSide = [
  {
    title: locales._get("genders"),
    icon: cubeOutline,
    href: "/anime/genders",
  },
  {
    title: locales._get("studios"),
    icon: videocamOutline,
    href: "/anime/studios",
  },
  {
    title: locales._get("store"),
    icon: cartOutline,
    href: "/store",
  },
  {
    title: locales._get("download-app"),
    icon: cloudDownloadOutline,
    onClick: () => {
      AppSettingsID.setSetting("showLatestForce", true)
      window.location.reload()
    },
  },
  {
    title: locales._get("share-app"),
    icon: shareSocialOutline,
    onClick: () => share.shareOther(settings.linkplaystore),
  },
  {
    title: locales._get("rate-app"),
    icon: starSharp,
    onClick: () => share.rateApp(),
  }
] as menuItems[]