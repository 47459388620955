import { IonBadge, IonIcon } from "@ionic/react"

import { defaultDropDownBadge, DropDownBadgeProps } from "types/Commons/DropDownBadge"
import DropDownContainer from "components/Commons/DropDownContainer"

const DropDownBadge = (props: DropDownBadgeProps) => {
  return (
    <>
      <IonBadge
        color={props.color}
        className={`button component-dropdown-badge ${props.className || ""}`}
        onClick={() => props.onClick && props.onClick()}
        id="badge-popover"
      >
        {props.icon &&
          <IonIcon icon={props.icon} />
        }
      </IonBadge>
      <DropDownContainer
        trigger="badge-popover"
        className={`component-dropdown-badge-popover ${props.className || ""}`}
      >
        {props.children || <></>}
      </DropDownContainer>
    </>
  )
}

DropDownBadge.defaultProps = defaultDropDownBadge

export default DropDownBadge