import PageContainer from "pages/_commons/PageContainer"
import Meta from "components/Media/Meta"
import locales from "config/Locales"

import "./index.scss"

const Store = () => {
  return (
    <PageContainer className="page-store">
      <iframe
        src="https://tienda.animeplay.us"
        title="AnimePlay Store"
        scrolling="no"
      />
      <Meta
        title={locales._get("store")}
      />
    </PageContainer>
  )
}

export default Store