import Anime from "./Anime"
import App from "./App"
import Base from "./Base"
import Search from "./Search"
import User from "./User"

const routes = [
  ...Anime,
  ...App,
  ...Base,
  ...Search,
  ...User,
]

export default routes