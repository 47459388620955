import { AnimeContent } from "types/Anime"
import { CharactersStaffContent } from "types/Characters"
import Strings from "utils/Strings"
import AnimeContentParser from "../AnimeContent"

class CharacterParser {
  public static fitCharacterContent = (content: CharactersStaffContent, maxList: number = 5): CharactersStaffContent => {
    if (!content) return {} as CharactersStaffContent
    if (content.formated === true) return content
    content.title = Strings.capitalizeFirstLetter(Strings.encode(content.title || ""))
    content.content = Strings.encode(content.content || "")
    content.in_content?.map((_content: AnimeContent) =>
      AnimeContentParser.fitAnimeContent(_content, maxList))
    content.formated = true
    return content
  }
}

export default CharacterParser