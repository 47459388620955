import { IonIcon, IonItem, IonLabel, IonThumbnail } from "@ionic/react"

import { menuItems } from "types/Commons/Menu"
import { PickerModalListProps } from "types/Commons/Picker"
import User from "components/Users/User"
import Image from "components/Media/Image"

import "./index.scss"

const PickerModalList = (props: PickerModalListProps) => {
  return (
    <>
      {props.items?.map((option: menuItems, index: number) => (
        <>
          {((option.onlyUser && User.isLoggedIn()) || !option.onlyUser) &&
            <IonItem
              className="component-picker-modal-list-item"
              onClick={() => (props.onClick && props.onClick(option.href)) || (option.onClick && option.onClick())}
              key={index}
              button={(props.onClick || option.onClick) ? true : false}
              detail={(props.onClick || option.onClick) ? true : false}
              color={((option.title === props.selected || option.href === props.selected) && props.selected !== "") ? "secondary" : ""}
            >
              {option.icon &&
                <IonIcon icon={option.icon} slot="start" size="medium" />
              }
              {option.image &&
                <IonThumbnail slot="start">
                  <Image src={option.image} />
                </IonThumbnail>
              }
              <IonLabel>
                <h2>
                  {props.showItemIndex &&
                    `${index + 1}. `
                  }
                  <div dangerouslySetInnerHTML={{ __html: option.title }} />
                  {option.subtitle &&
                    <span className="item-subtitle"
                      dangerouslySetInnerHTML={{ __html: option.subtitle }} />
                  }
                </h2>
                {option.description &&
                  <p dangerouslySetInnerHTML={{ __html: option.description }} />
                }
              </IonLabel>
            </IonItem>
          }
        </>
      ))}
    </>
  )
}

export default PickerModalList