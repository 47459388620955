import { Animations } from "types/Utils/Animate"
import { Slide, SliderType, SliderViewDefault } from "./Slider"

export type HorizontalSliderTypes = "item" | "player" | "news"

export type HorizontalSliderProps = {
  slides: Slide[]
  slidesPerView: SliderType,
  type: HorizontalSliderTypes,
  className?: string,
  name?: string,
  checkHeight: boolean,
  asSlider?: boolean,
  animation?: Animations,
  onEmptySlider?: Function,
  onClick?: Function,
}

export const defaultHorizontalSliderProps = {
  slidesPerView: SliderViewDefault,
  slides: [],
  type: "item",
  name: "",
  checkHeight: false,
  asSlider: false,
}

export type RelevanceAlertProps = {
  item: Slide,
}