import { ReactNode } from "react"

import { colorTypes, OneZeroTypes } from ".."
import { Animations } from "types/Utils/Animate"
import { targetTypes } from "types/Commons/Menu"

// pela-cul type is included to correct it in formatResults function
export type contentTypes = "tv" | "ova" | "pelicula" | "" | "pela-cul"

export type contextTypes = "article" | "contents" | "viewed" | "episodes" | "characters" | "actors" | "studios"

export type Slide = {
  id?: number,
  cid?: number,
  rid?: number,
  image?: string,
  title?: string,
  title_spanish?: string,
  title_english?: string,
  title_romaji?: string,
  subtitle?: string,
  subtitle_color?: colorTypes,
  description?: string,
  content?: string,
  fullcontent?: string,
  context?: contextTypes,
  className?: string,
  badge?: string,
  badgecolor?: string,
  badgeclass?: string,
  recentbadge?: "1" | "0",
  viewed?: OneZeroTypes,
  conditionalBadge?: Function,
  contentOverTitle?: Function,
  contentOverHeader?: Function,
  href?: string,
  onClick?: Function,
  onClicked?: Function,
  target?: targetTypes,
  img?: string,
  img2?: string,
  img3?: string,
  imgBk?: string,
  locked?: OneZeroTypes,
  isadult?: OneZeroTypes,
  top?: number,
  ctype?: contentTypes,
  slidesPerView?: { s: number, m: number, l: number, xl: number },
  children?: ReactNode,
  publish_up?: string,
  params?: {
    img?: string,
    img2?: string,
    img3?: string,
  },
  episode_no?: number,
  episodes?: number,
  sort_direction?: "sort" | "relevance",
  relevance?: number,
}

export type SlideInfo = {
  id?: number,
  title?: string,
  description?: string,
  principal?: OneZeroTypes,
  isadult?: OneZeroTypes,
}

export const SliderViewPlay = { s: 2, m: 2, l: 3, xl: 4 }

export const SliderViewContinue = { s: 1, m: 1, l: 2, xl: 4 }

export const SliderViewNews = { s: 1, m: 2, l: 2, xl: 2 }

export const SliderViewDefault = { s: 3, m: 3, l: 4, xl: 5 }

export type SliderType = { s: number, m: number, l: number, xl: number }

export type SlidersProps = {
  slides: Slide[],
  slideHeight?: string,
}

export type SlideShowProps = {
  initialSlide?: number,
  speed?: number,
  autoplay?: boolean,
  className?: string,
  delay?: number,
  loop?: boolean,
  slidesPerView: SliderType,
  slides: Slide[],
  slideHeight?: string,
  animation?: Animations,
  onSlideDidChange?: Function,
}

export const defaultSlideShowProps = {
  initialSlide: 0,
  speed: 400,
  autoplay: true,
  delay: 4000,
  loop: true,
  slidesPerView: { s: 1, m: 2, l: 3, xl: 3 },
  slides: [],
  slideHeight: "14.5rem",
}