import { Slide } from "types/Media/Slider"
import viewedContent, { IsLastContent } from "pages/_commons/ViewedContent"

export const formatResults = (results: Slide[], excludeOverTitle: boolean = false): Slide[] => {
  results?.map((item: Slide) => {
    if (item.ctype === "pela-cul") item.ctype = "pelicula"
    if ((item?.episodes || 0) === 1 && item?.ctype !== "tv" && item.badge) delete item.badge
    if (item.badge && item.recentbadge === "1") item.badgeclass = "is-recent"
    item.conditionalBadge = viewedContent(item)
    item.contentOverTitle = IsLastContent(item, !excludeOverTitle)
    if (item.viewed) item.viewed = item.viewed.toString() === "1" ? 1 : 0//parse as number 1 or 0
    return item
  })

  return results
}
