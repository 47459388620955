import { entityToChar } from "./entityToChar"

import "./index.scss"

class Strings {
  static capitalizeFirstLetter = (string: string) =>
    string.charAt(0).toUpperCase() + string.slice(1)

  static getRandomString = (length: number = 10, characters: string = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789"): string => {
    let result = ""
    const charactersLength = characters.length
    for (let i = 0; i < length; i++) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength))
    }
    return result
  }

  static getRandomNumber = (length: number = 10): string =>
    Strings.getRandomString(length, "0123456789")

  static getRandomBetween = (max: number, min: number = 0): number =>
    Math.floor(Math.random() * (max - min + 1) + min)

  static encode = (str: string): string => {
    if (!str || str === "undefined" || str === undefined) return ""
    let newStr = ""
    try {
      newStr = Strings.replaceHtmlEntities(decodeURIComponent(escape(str))) || str
    } catch (e) {
      //console.log("String error", e)
      newStr = Strings.replaceHtmlEntities(str) || ""
    }
    newStr = newStr.trim()

    const openTag = "<span class='exclamation'>></span>"
    const closeTag = "<span class='exclamation'><</span>"
    newStr = newStr
      .replace(/__(.*?)__/g, "<b>$1</b>")
      .replace(/\*\*(.*?)\*\*/g, "<b>$1</b>")
      .replace(/\n/g, "<br />")
      .replace(/~!/g, openTag)
      .replace(/!~/g, closeTag)

    newStr = newStr.replace(/\[(.*?)\]\((.*?)\)/g, "<span class='content-between-brackets'>$1</span>")

    if (str !== newStr) return Strings.encode(newStr)//repeat until nothing change
    return newStr
  }

  static replaceHtmlEntities = (text: string) => {
    return text.replace(/&#?[0-9a-zA-Z]+;/g, (s) => {
      const entity = s.substring(1, s.length - 1)
      let char
      if (entity.charAt(0) === "#") {
        if (entity.charAt(1) === "x") {
          char = String.fromCharCode(parseInt(entity.substring(2), 16))
        } else {
          char = String.fromCharCode(parseInt(entity.substring(1), 10))
        }
      } else {
        char = entityToChar[entity] || entityToChar[`${entity};`]
      }
      return char || s
    })
  }

  static stripTags = (text: string) => {
    return text.replace(/<\/?[^>]+(>|$)/g, "")
  }

  static maxLength = (text: string, length: number = 100) => {
    return text.length > length ? text.substring(0, length) + "..." : text
  }

  static minifySize = (size: number, decimal?: number): string => {
    if (!size || size === undefined) return "0"

    let sufix = ""
    if (size < 1024) decimal = 0
    if (size > 1024) {
      size = size / 1024
      sufix = "Kb"
    }
    if (size > 1024) {
      size = size / 1024
      sufix = "Mb"
    }
    if (size > 1024) {
      size = size / 1024
      sufix = "Gb"
    }
    return `${size.toFixed(decimal ? decimal : 0)}${sufix}`
  }

  static minifyFloat = (num: number, decimal?: number): string => {
    if (!num || num === undefined) return "0"

    let sufix = ""
    if (num < 1000) decimal = 0
    if (num > 1000) {
      num = num / 1000
      sufix = "K"
    }
    if (num > 1000) {
      num = num / 1000
      sufix = "M"
    }
    if (num > 1000) {
      num = num / 1000
      sufix = "B"
    }
    return `${num.toFixed(decimal ? decimal : 0)}${sufix}`
  }

  static parseParams = (id: string, params: {}, callback: (value: number) => void) =>
    JSON.parse(JSON.stringify(params), (key: string, value: string) => {
      if (key === id) callback(parseInt(value))
    })

  static arrayRemoveEmpty = (arr: any[]) => arr.filter((n) => n)
}

export default Strings