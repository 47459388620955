import { IonIcon, IonHeader } from "@ionic/react"

import { Slide } from "types/Media/Slider"
import Image from "components/Media/Image"

export const WelcomeSlide = (props: Slide) => {
  return (
    <div>
      {props.image && <Image src={props.image} />}
      {props.badge && <IonIcon icon={props.badge} />}

      <IonHeader>
        {props.title}
      </IonHeader>
      {props.children && <p>
        <>
          {props.children}
        </>
      </p>}
    </div>
  )
}