import { ReactNode } from "react"
import { ThumbSizes } from ".."
import { Slide } from "types/Media/Slider"

export type CardProps = {
  item: Slide,
  className?: string,
  contentBellowImg?: ReactNode,
  contentOverTitle?: ReactNode,
  contentOverHeader?: ReactNode,
  thumb?: ThumbSizes,
  onClick?: Function,
}

export type OverCardProps = {
  image?: string,
  title?: string,
  subtitle?: string,
  description?: string | ReactNode,
  className?: string,
}

export type ScreenLoaderProps = {
  text?: string,
  withSpinner?: boolean,
  canCancel?: boolean,
  imagePosition?: "center" | "floatBase" | "none",
}

export const defaultScreenLoaderProps = {
  withSpinner: true,
  canCancel: false,
}