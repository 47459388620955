import { menuItems } from "types/Commons/Menu"
import { SlideInfo } from "types/Media/Slider"
import { AppSettingsID } from "utils/AppSettings"
import { setHeaderStudioLink } from "pages/Discover/HeaderToolbar"
import Strings from "utils/Strings"

export const getStudiosList = (id?: string): SlideInfo[] =>
  AppSettingsID.getSetting(id || "studios-list", [] as SlideInfo[])

export const getStudiosListMenuItems = (): menuItems[] => {
  const studios = getStudiosList()

  let _studios = [] as menuItems[]
  Array.from(studios as SlideInfo[])?.map((studio: SlideInfo) => {
    _studios.push({
      title: Strings.encode(studio.title || ""),
      href: setHeaderStudioLink(studio.id || 0),
    })
    return studio
  })

  return _studios
}

export const getStudio = (gid: number): SlideInfo => {
  const studios = getStudiosList()
  const studio = Array.from(studios as SlideInfo[]).find((studio: SlideInfo) => studio.id === gid) as SlideInfo

  return studio
}