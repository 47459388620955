import { ReactNode } from "react"

export type ModalProps = {
  id: string,
  title?: string,
  children?: ReactNode,
  color?: string,
  img?: string,
  scroll?: boolean,
  initialBreakpoint?: number,
}

export const defaultModalProps: ModalProps = {
  id: "modal",
  title: "",
  children: <></>,
  color: "",
  img: "",
  scroll: true,
  initialBreakpoint: 1,
}

type isOpenItem = {
  [key: string]: boolean,
}

export type isOpenElements = {
  isOpen: isOpenItem,
}
