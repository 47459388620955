import { AnimeCharacterStaffType } from "types/Anime/AnimeCharacters"
import { CharactersStaffContent } from "types/Characters"
import { Endpoints } from "api/Endpoints"
import { Responses } from "types/Connect"
import { Slide } from "types/Media/Slider"
import Connect from "components/Connect"
import AnimeContentParser from "pages/_commons/Content/AnimeContent"

export const getCharactersStaff = async (cid: number, type: AnimeCharacterStaffType, force: boolean = false): Promise<Slide[]> => {
  const response: Responses = await Connect.postcached({
    endpoint: type === "characters" ? Endpoints.detailscharacters : Endpoints.detailsstaff,
    params: { id: cid },
    options: { force: force },
  })
  if (!response.data?.results && !force) return getCharactersStaff(cid, type, true)
  const results: CharactersStaffContent[] = AnimeContentParser.fitAnimeCharactersStaff(response.data?.results as CharactersStaffContent[])
  return (results as Slide[]) || [] as Slide[]
}