import { Endpoints } from "api/Endpoints"
import { paramsRecentNewContent, paramsRecentNewContentPlay } from "api/routes/params/Media"
import { filtersPremium, filtersContentType } from "types/Content/filters"
import { AniTitleFilterProps } from "types/Page/AniTitle"
import { ContentProps, defaultContentProps } from "types/Page/Content"
import locales from "config/Locales"
import Content from "../Content"

const NewContent = (props: ContentProps) => {
  const showContent: boolean = props.show === "newcontent" || props.show === "all" || !props.show
  const showContentPlay: boolean = props.show === "newcontentplay" || props.show === "all" || !props.show
  const filters: AniTitleFilterProps[] = props.mode === "grid" ? [
    ...[filtersPremium],
    ...[filtersContentType],
  ] : []

  return (
    <>
      {showContent &&
        <Content
          title={locales._get("new_in", locales._get("appname"))}
          href="/home"
          endpoint={Endpoints.recent}
          params={paramsRecentNewContent}
          watchLastCall={props.watchLastCall}
          resetPage={props.resetPage}
          loadContent={props.loadContent}
          type="item"
          mode={props.mode || "slider"}
          filters={filters}
        />
      }
      {showContentPlay &&
        <Content
          title={locales._get("new_content")}
          href="/home/anime"
          endpoint={Endpoints.recent}
          params={paramsRecentNewContentPlay}
          watchLastCall={props.watchLastCall}
          loadContent={props.loadContent}
          resetPage={props.resetPage}
          type="player"
          mode={props.mode || "slider"}
          filters={filters}
        />
      }
    </>
  )
}

NewContent.defaultProps = defaultContentProps

export default NewContent