import { IonBadge, IonButton, IonIcon, IonLabel } from "@ionic/react"
import { chevronBackCircle, chevronForwardCircle, eye } from "ionicons/icons"
import { useEffect, useState } from "react"

import { AnimeEpisodesMedia } from "types/Anime"
import { AnimeMediaOptionsProps } from "types/Anime/AnimeMediaOptions"
import locales from "config/Locales"
import AnimeMediaOptionsFollow from "./Follow"
import User from "components/Users/User"

import "./index.scss"
import AnimeMediaOptionsCantView from "./CantView"

const AnimeMediaOptions = (props: AnimeMediaOptionsProps) => {
  const [current, setCurrent] = useState<AnimeEpisodesMedia>(props.episodes?.media?.current || {} as AnimeEpisodesMedia)
  const [prev, setPrev] = useState<AnimeEpisodesMedia>(props.episodes?.media?.prev || {} as AnimeEpisodesMedia)
  const [next, setNext] = useState<AnimeEpisodesMedia>(props.episodes?.media?.next || {} as AnimeEpisodesMedia)

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => setCurrent(props.episodes?.media?.current || {} as AnimeEpisodesMedia), [props.episodes?.media?.current])

  useEffect(() => setPrev(props.episodes?.media?.prev || {} as AnimeEpisodesMedia), [props.episodes?.media?.prev])

  useEffect(() => setNext(props.episodes?.media?.next || {} as AnimeEpisodesMedia), [props.episodes?.media?.next])

  return (
    <div className="anime-media-options">
      <AnimeMediaOptionsFollow {...props} />
      {User.inList("VIEW_ANIME") ?
        <>
          <IonBadge color="secondary">
            <IonIcon icon={eye} />
            <IonLabel>{current?.hits}</IonLabel>
          </IonBadge>
          {props.showButtons && <>
            <IonButton
              id="anime-media-options-previous"
              size="small"
              color="tertiary"
              disabled={(prev?.exist !== 1 && prev?.eid === 0) || props.disabled}
              onClick={() => props.onPrevious && props.onPrevious()}
            >
              <IonIcon icon={chevronBackCircle} />
              {locales._get("content.media-previous")}
            </IonButton>
            <IonButton
              id="anime-media-options-next"
              size="small"
              color="tertiary"
              disabled={(next?.exist !== 1 && next?.eid === 0) || props.disabled}
              onClick={() => props.onNext && props.onNext()}
            >
              {locales._get("content.media-next")}
              <IonIcon icon={chevronForwardCircle} />
            </IonButton>
          </>}
        </>
        :
        <AnimeMediaOptionsCantView />}
    </div>
  )
}

export default AnimeMediaOptions