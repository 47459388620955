import { NotRelevant } from "types/Anime/Images"
import { RelevanceAlertProps } from "types/Media/HorizontalSlider"
import { Slide } from "types/Media/Slider"
import Strings from "utils/Strings"
import settings from "config/Settings"
import OverCard from "components/Content/OverCard"
import locales from "config/Locales"

import "./index.scss"

export const calcRelevanceLevel = (relevance: number): string => {
  const prefix = "relevances"
  if (relevance >= settings.relevance.high) return `${prefix} high`
  if (relevance >= settings.relevance.medium) return `${prefix} medium`
  if (relevance >= settings.relevance.low) return `${prefix} low`
  return `${prefix} very-low`
}

export const setRelevance = (_item: Slide): Slide => {
  const item = _item
  if (item.sort_direction !== "relevance") return item
  item.contentOverHeader = () => {
    return (
      <>
        {item.sort_direction === "relevance" && (item.relevance || 0) > 0 &&
          <div
            className={`component-relevance-card-item ${calcRelevanceLevel(item.relevance || 0)}`}
            data-relevance={item.relevance || 0}
          >
            {locales._get("content.relevance")}:
            {Strings.minifyFloat(item.relevance || 0, 0)}
          </div>
        }
      </>
    )
  }
  return item
}

const RelevanceAlert = (props: RelevanceAlertProps) => {
  if (props.item.sort_direction !== "relevance") return <></>
  if ((props.item.relevance || 0) > 0) return <></>

  return (
    <div className="component-slide-item relevance">
      <OverCard
        title={locales._get("content.search-not-relevant")}
        subtitle={locales._get("content.search-not-relevant-subtitle")}
        image={NotRelevant}
      />
    </div>
  )
}

export default RelevanceAlert