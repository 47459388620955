import { useEffect, useState } from "react"
import { IonBadge } from "@ionic/react"

import { AnimeEpisodes, AnimeTabs, responseEpisodes } from "types/Anime"
import { getEpisodes } from "../../Methods/getEpisodes"
import locales from "config/Locales"
import useAnime from "store/useAnime"
import User from "components/Users/User"

export const LastSeen = (props: AnimeTabs) => {
  const [lastSeenDate, setLastSeenDate] = useState<string>("")
  const [episodes, setEpisodes] = useState<AnimeEpisodes>(props.episodes || {} as AnimeEpisodes)
  const lastSeenSerie = useAnime<number>((state: any) => state.lastSeenSerie)
  const setLastSeenSerie = useAnime<Function>((state: any) => state.setLastSeenSerie)

  useEffect(() => {
    setLastSeenDate(`${episodes?.lastSeenDateStr} (${episodes?.lastSeenDateAgo})`)
  }, [episodes?.lastSeenDateStr, episodes?.lastSeenDateAgo])

  useEffect(() => {
    if (!props.episodes || lastSeenSerie === 0) return
    setEpisodes({} as AnimeEpisodes)
    getEpisodes({ cid: props.content?.id, eid: episodes?.id, force: true }).then((data: responseEpisodes) =>
      setEpisodes(data?.episodes || {} as AnimeEpisodes))
    setLastSeenSerie(0)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [lastSeenSerie])

  useEffect(() => setEpisodes(props.episodes || {} as AnimeEpisodes), [props.episodes])

  if (
    props.episodes?.last_seen_date === "" ||
    props.episodes?.last_seen_date === undefined ||
    props.episodes?.lastSeenDateAgo === locales._get("date.never") ||
    !User.inList("VIEW_ANIME")
  ) return <></>

  if (!episodes?.last_seen) return <></>
  return (
    <div>
      <IonBadge className="episode-date" color="tertiary">
        <div dangerouslySetInnerHTML={{ __html: locales._get("content.last-seen-at", { "%s": lastSeenDate, "%t": `${episodes?.last_seen}` }) }} />
      </IonBadge>
    </div>
  )
}