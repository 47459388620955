import { IonIcon } from "@ionic/react"
import { ellipse } from "ionicons/icons"
import { ReactNode } from "react"

import "./index.scss"

const Breadcrumb = (props: { children: ReactNode }) => {
  return (
    <span className="component-breadcrumb">
      {props.children}
      <IonIcon slot="separator" className="separator" icon={ellipse}></IonIcon>
    </span>
  )
}

export default Breadcrumb