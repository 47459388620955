import { useEffect, useState } from "react"

import { isAvailableNetwork } from "./Methods/isAvailableNetwork"
import { isMeteredNetwork } from "./Methods/isMeteredNetwork"
import useIsMetered from "store/useIsMetered"
import useHasNetwork from "store/useHasNetwork"
import locales from "config/Locales"
import AlertHasNetwork from "components/Commons/Alerts/HasNetwork"
import { colorTypes } from "types/index"

let isNotifiedMetered = false
let isNotifiedNetwork = false

export const MyNetwork = () => {
  const isMeteredStore: boolean = useIsMetered((state: any) => state.isMetered)
  const setIsMeteredStore = useIsMetered((state: any) => state.setIsMetered)
  const [isMetered, setIsMetered] = useState(isMeteredStore)
  const hasNetworkStore: boolean = useHasNetwork((state: any) => state.hasNetwork)
  const setHasNetworkStore = useHasNetwork((state: any) => state.setHasNetwork)
  const [hasNetwork, setHasNetwork] = useState(hasNetworkStore)
  const [title, setTitle] = useState<string>("")
  const [color, setColor] = useState<colorTypes>("primary")

  useEffect(() => {
    isMeteredNetwork().then((_isMetered) => {
      setIsMetered((currentMetered: boolean) => {
        if (currentMetered !== _isMetered) isNotifiedMetered = false
        setIsMeteredStore(_isMetered)
        return _isMetered
      })
    })
    isAvailableNetwork().then((_hasNetwork) => {
      setHasNetwork((currentNetwork: boolean) => {
        if (currentNetwork !== _hasNetwork) isNotifiedNetwork = false
        setHasNetworkStore(_hasNetwork)
        return _hasNetwork
      })
    })
  }, [setHasNetworkStore, setIsMeteredStore])

  useEffect(() => {
    if (!isMetered || isNotifiedMetered) return
    isNotifiedMetered = true

    setTitle(locales._get("network.metered-alert"))
    setColor("warning")
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isMetered, AlertHasNetwork, title])

  useEffect(() => {
    if (hasNetwork || isNotifiedNetwork) return
    isNotifiedNetwork = true

    setTitle(locales._get("network.unavailable-alert"))
    setColor("danger")
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hasNetwork, AlertHasNetwork, title])

  return (
    <AlertHasNetwork
      showAlert={!hasNetwork || isMetered}
      title={title}
      color={color}
    />
  )
}
