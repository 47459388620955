import { logoFacebook, logoPinterest, logoTwitter, logoWhatsapp, shareSocialOutline } from "ionicons/icons"

import { AnimeTopBarProps } from "types/Anime"
import { share } from "../../../../components/Commons/Social"
import User from "components/Users/User"
import Buttons from "components/Commons/Buttons"
import AnimeTopBarShareDropDown from "./ShareDropDown"

import "./index.scss"

const AnimeTopBar = (props: AnimeTopBarProps) => {
  if (!User.inList("VIEW_ANIME")) return <></>
  return (
    <div className="component-anime-topbar">
      <Buttons
        buttons={[
          {
            text: "",
            icon: logoFacebook,
            onClick: () => share.shareFacebook(props.link),
          },
          {
            text: "",
            icon: logoTwitter,
            onClick: () => share.shareTwitter(props.link),
          },
          {
            text: "",
            icon: logoPinterest,
            onClick: () => share.sharePinterest(props.link, props.image || ""),
          },
          {
            text: "",
            icon: logoWhatsapp,
            onClick: () => share.shareWhatsapp(props.link),
          },
          {
            text: "",
            icon: shareSocialOutline,
            onClick: () => { },
            children: <AnimeTopBarShareDropDown {...props} />
          },
        ]}
      />
    </div>
  )
}

export default AnimeTopBar