import { useEffect, useState } from "react"
import { cubeOutline } from "ionicons/icons"
import { useHistory } from "react-router"

import { AnimeContent, AnimeContentGender } from "types/Anime"
import { genderDescriptionFormat } from "pages/_commons/Content/GerdersList/getGenderList"
import { DropDownButtonProps } from "types/Commons/DropDownButton"
import { DropButton } from "./Methods/Button"
import Buttons from "components/Commons/Buttons"
import useAnime from "store/useAnime"

const GendersButtons = (props: { content: AnimeContent }) => {
  const [genderButtons, setGenderButtons] = useState<DropDownButtonProps[]>([] as DropDownButtonProps[])
  const history = useHistory()
  const setIsOpen = useAnime((state: any) => state.setIsOpen)

  useEffect(() => {
    setGenderButtons((props.content.gendersids || [])?.map((item: AnimeContentGender) => {
      return {
        ...DropButton({
          id: item.id,
          title: item.title,
          icon: cubeOutline,
          description: genderDescriptionFormat(item),
          onClick: () => {
            history.push(`/anime/gender/${item.id}`)
            setIsOpen(false)
          },
          prefix: "genders",
        }),
        icon: cubeOutline,
      }
    }))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.content])

  return (
    <>
      {genderButtons?.length > 0 &&
        <div className="gender-list">
          <Buttons
            buttons={genderButtons}
          />
        </div>
      }
    </>
  )
}

export default GendersButtons