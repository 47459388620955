import { ContentEndOfListProps } from "types/Page/Content"
import { IonBadge, IonLabel } from "@ionic/react"
import { Vegeta } from "types/Anime/Images"
import OverCard from "components/Content/OverCard"
import locales from "config/Locales"

import "./index.scss"

const EndOfList = (props: ContentEndOfListProps) => {
  return (
    <div className="component-end-of-list">
      <OverCard
        image={Vegeta}
        title={locales._get("component.infinite.end-of-list")}
      />
      {props.itemCount &&
        <IonLabel>
          <IonBadge>
            <b>{locales._get("component.infinite.item-count")}:</b>&nbsp;
            {locales._get(`component.infinite.item-count${props.itemCount === 1 ? "-single" : ""}-result`,
              { "%s": props.itemCount })}
          </IonBadge>
        </IonLabel>
      }
    </div>
  )
}

export default EndOfList