import { useEffect, useState } from "react"

import { CharactersStaffContent } from "types/Characters"
import { Slide } from "types/Media/Slider"
import useHistoryRecord from "store/useHistoryRecord"
import Grid from "components/Content/Grid"
import useAnime from "store/useAnime"
import JDate from "utils/JDate"
import User from "components/Users/User"

const Characters = (props: CharactersStaffContent) => {
  const [content, setContent] = useState<Slide[]>([] as Slide[])
  const setIsOpenCharacter = useAnime((state) => state.setIsOpenCharacter)
  const addHistoryRecord = useHistoryRecord<Function>((state: any) => state.addHistoryRecord)
  const setId = useAnime((state) => state.setChid)
  const setEid = useAnime((state) => state.setEid)
  const setMid = useAnime((state) => state.setMid)

  useEffect(() => {
    if (!props.characters) return setContent([] as Slide[])
    setContent(props.characters?.map((_content: CharactersStaffContent) => {
      return {
        ..._content,
        onClick: () => {
          setId(_content.id)
          setEid(1)
          setMid(0)
          setIsOpenCharacter(true)
          addHistoryRecord({
            path: window.location.pathname,
            title: _content?.title || "",
            cid: _content.id || 0,
            eid: 0,
            mid: 0,
            serie: 0,
            provider: "",
            image: _content?.img2 || "",
            imageBk: _content?.img || "",
            type: "character",
            date: JDate.now(),
            user: User.getActiveUser(),
          })
        }
      } as Slide
    }))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.in_content])

  return (
    <Grid slides={content} />
  )
}

export default Characters