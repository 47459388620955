import { ReactNode } from "react"
import { colorTypes, slotPositions } from ".."

export type AniHeaderProps = {
  name?: string,
  color?: colorTypes,
  slot?: slotPositions,
  startButton?: ReactNode,
  endButtons?: ReactNode,
  primaryButtons?: ReactNode,
  secondaryButtons?: ReactNode,
  showLogo?: boolean,
  onClick?: Function,
}

export type ButtonRoundedProps = {
  icon: string,
  id?: string,
  onClick?: () => void,
}

export const defaultAniHeaderProps: AniHeaderProps = {
  name: "",
  color: "tertiary",
  slot: "start",
  startButton: null,
  endButtons: null,
  primaryButtons: null,
  showLogo: true,
}