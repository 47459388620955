import AdsBanner from "components/Media/Ads/Banner"
import { useEffect, useState } from "react"

import { SoftnyaAdsPositions, SoftnyaShuffleAdsProps } from "types/Media/Ads/SoftnyaAds"
import settings from "config/Settings"

import "./index.scss"

const SoftnyaShuffleAds = (props: SoftnyaShuffleAdsProps) => {
  const [showEveryRows] = useState<number>(settings.softnyashoweveryrows)
  const [showAd] = useState<boolean>((props.positionIndex > 0 && (props.positionIndex % (props.itemsPerRow * showEveryRows) === 0)) || (props.forceShow || false))
  const [index] = useState<number>((props.positionIndex / (props.itemsPerRow * showEveryRows)) % 4)
  const [position, setPosition] = useState<SoftnyaAdsPositions>("CONTENT_A")

  useEffect(() => {
    let _position = position
    if (index === 0) _position = "CONTENT_D"
    if (index === 1) _position = "CONTENT_A"
    if (index === 2) _position = "CONTENT_B"
    if (index === 3) _position = "CONTENT_C"
    setPosition(_position as SoftnyaAdsPositions)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.positionIndex])

  return (
    <>
      {showAd && <div className="component-slide-item ad">
        <AdsBanner type="softnyacontent" softnyaposition={position} />
      </div>}
    </>
  )
}

export default SoftnyaShuffleAds