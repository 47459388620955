import { AnimeComment } from "types/Anime"
import { Responses } from "types/Connect"
import { Endpoints } from "api/Endpoints"
import Connect from "components/Connect"
import AnimeContentParser from "pages/_commons/Content/AnimeContent"

export const getComments = async (props: { cid: number, force?: boolean }): Promise<AnimeComment[]> => {
  if (props.cid === 0) return [] as AnimeComment[]
  const response: Responses = await Connect.postcached({
    endpoint: Endpoints.detailscomments,
    params: { id: props.cid },
    options: { checkInterval: "shorter", force: props.force || false },
  })
  if (!response.data?.results && !(props.force || false)) return getComments({ cid: props.cid, force: true })
  return AnimeContentParser.fitAnimeComments(response.data?.results as AnimeComment[])
}