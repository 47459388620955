import { useEffect, useState } from "react"

import { getGenderListMenuItems } from "pages/_commons/Content/GerdersList/getGenderList"
import { menuItems } from "types/Commons/Menu"
import HeaderToolbar from "components/Commons/HeaderToolbar"
import Loader from "pages/_commons/Loader"
import locales from "config/Locales"
import settings from "config/Settings"

export const setHeaderGenderLink = (genderId: number) =>
  `/anime/gender/${genderId || 0}`

export const setHeaderStudioLink = (studioId: number) =>
  `/anime/studio/${studioId || 0}`

export const loadGenderItemsFromRemote = (maxCallbackTimes: number): menuItems[] => {
  const _genders = getGenderListMenuItems(true, () =>
    maxCallbackTimes > 0 && loadGenderItemsFromRemote(maxCallbackTimes - 1)) as menuItems[]
  return _genders
}

const AniHeaderToolbar = () => {
  const [items, setItems] = useState<menuItems[]>([])

  useEffect(() => {
    setItems(loadGenderItemsFromRemote(settings.maxCallbackTimes))
  }, [])

  return (
    <>
      {items.length === 0 ?
        <Loader text={locales._get("component.header-toolbar-text")} />
        :
        <HeaderToolbar items={items} />
      }
    </>
  )
}

export default AniHeaderToolbar