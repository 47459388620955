import { filterResults } from "components/Commons/Searchbar"
import { menuItems } from "types/Commons/Menu"

export const pushData = (
  results: menuItems[],
  onDisplay: menuItems[],
  setOnDisplay: React.Dispatch<React.SetStateAction<menuItems[]>>,
  setInfiniteDisabled: React.Dispatch<React.SetStateAction<boolean>>,
  setLoaded: React.Dispatch<React.SetStateAction<boolean>>,
  selected?: string,
) => {
  const start = onDisplay.length
  const end = onDisplay.length + 20
  setOnDisplay((data: menuItems[]) => {
    const filtered = filterResults(selected?.split(" ") || [], results.slice(start, end))
    const newData = [...data, ...filtered]
    //filter repeated items
    const unique = newData.filter((item, index) => newData.findIndex(i => i.title === item.title) === index)
    setInfiniteDisabled(unique.length === results.length)
    setLoaded(true)
    return unique
  })
}