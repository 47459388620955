import { useEffect, useState } from "react"
import { IonButtons, IonButton, IonIcon, IonItem, IonLabel, IonList, IonTitle, IonToggle, IonSelect, IonSelectOption, useIonAlert } from "@ionic/react"
import { filterOutline, informationCircleOutline } from "ionicons/icons"

import { AniTitleFilterProps, AniTitleProps } from "types/Page/AniTitle"
import Strings from "utils/Strings"
import locales from "config/Locales"
import DropDownContainer from "components/Commons/DropDownContainer"

import "./index.scss"

const Filters = (props: AniTitleProps) => {
  const [filterId] = useState<string>(`filter-${Strings.getRandomString(5)}`)
  const [filters, setFilters] = useState<AniTitleFilterProps[]>([] as AniTitleFilterProps[])
  const [presentAlert] = useIonAlert()

  const removeBlockedFilters = (filters: AniTitleFilterProps[]): AniTitleFilterProps[] =>
    filters.filter((f: AniTitleFilterProps) => f.key)

  const changeSelected = (filter: AniTitleFilterProps, index: number) => {
    const _filters = filters.map((f: AniTitleFilterProps, i: number) => {
      if (i === index) {
        return {
          ...f,
          selected: filter.selected,
        }
      }
      return f
    })
    setFilters(removeBlockedFilters(_filters))
    props.onFiltersChange?.(_filters)
  }

  useEffect(() =>
    setFilters(removeBlockedFilters(props.filters || [])), [props.filters])

  if ((filters?.length || 0) === 0) return <></>
  return (
    <>
      <IonButtons slot="primary">
        <IonButton id={filterId}>
          <IonIcon slot="icon-only" icon={filterOutline}></IonIcon>
        </IonButton>
      </IonButtons>
      <DropDownContainer trigger={filterId} className="component-ani-title-filters" side="bottom">
        <IonTitle>{locales._get("filters.title")}:</IonTitle>
        <IonList lines="none">
          {filters?.map((filter: AniTitleFilterProps, index: number) => {
            if (!filter.key) return <></>
            return (
              <IonItem key={index}>
                {filter.explain &&
                  <IonIcon slot="start" icon={informationCircleOutline}
                    onClick={() => {
                      presentAlert({
                        header: filter.title,
                        message: filter.explain,
                        buttons: [
                          {
                            text: locales._get("commons.alerts.close"),
                            role: "cancel",
                          }
                        ]
                      })
                    }}
                  />
                }
                <IonLabel>
                  {filter.title}
                </IonLabel>
                {filter.type === "check" &&
                  <IonToggle slot="end" checked={filter.selected === true}
                    onIonChange={(e) => changeSelected({
                      ...filter,
                      selected: e.detail.checked,
                    }, index)}
                  />}
                {filter.type === "select" &&
                  <IonSelect slot="end" value={filter.selected}
                    onIonChange={(e) => changeSelected({
                      ...filter,
                      selected: e.detail.value,
                    }, index)}
                  >
                    <IonSelectOption value="all">{locales._get("filters.all")}</IonSelectOption>
                    {filter.options?.map((option, index) => (
                      <IonSelectOption key={index} value={option.value}>{option.label}</IonSelectOption>
                    ))}
                  </IonSelect>}
              </IonItem>
            )
          })}
        </IonList>
      </DropDownContainer>
    </>
  )
}

export default Filters