import { IonCard, IonCardContent, IonCardHeader, IonCardSubtitle, IonCardTitle } from "@ionic/react"

import { defaultNotificationProps, NotificationProps } from "types/Commons/Notification"

import "./index.scss"

const Notification = (props: NotificationProps) => {
  const { title, subtitle, children, color, className, titlealign, subtitlealign, htmlalign, compact } = props
  return (
    <IonCard
      color={color}
      className={`component-notification ${className} ${compact && "is-compact"}`}
    >
      {(title || subtitle) &&
        <IonCardHeader>
          {title &&
            <IonCardTitle className={`nt-title-${titlealign} ${subtitle && "has-subtitle"}`}>{title}</IonCardTitle>
          }
          {subtitle &&
            <IonCardSubtitle className={`nt-subtitle-${subtitlealign}`}>{subtitle}</IonCardSubtitle>
          }
        </IonCardHeader>
      }
      {children &&
        <IonCardContent className={`nt-html-${htmlalign}`}>
          {children}
        </IonCardContent>
      }
    </IonCard>
  )
}

Notification.defaultProps = defaultNotificationProps

export default Notification