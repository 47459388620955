import { IonContent, IonFab, IonFabButton, IonIcon, IonProgressBar } from "@ionic/react"
import { arrowUpOutline } from "ionicons/icons"
import { useState } from "react"

import Animate from "utils/Animate"
import User from "components/Users/User"

import "./index.scss"

type WindowSizeTypes = {
  y: number
}

const ScrollContent = ({
  children = <></>,
  toTop = true,
  callbackUp = (e: any, percentage: number) => { },
  callbackDown = (e: any, percentage: number) => { },
  callbackStart = (e: any) => { },
  callbackEnd = (e: any) => { },
  callbackTop = (e: any, percentage: number) => { },
  callbackBottom = (e: any, percentage: number) => { },
  withProgress = true,
  withToTop = false,
  ...props
}) => {
  const [y, setY] = useState<WindowSizeTypes>({ y: window.scrollY })
  const [toTopClass, setToTopClass] = useState<string>(`ion-hidden ${Animate.setAnimation("fadeOutDown")}`)
  const [progress, setProgress] = useState<number>(0)
  const [hasProgress, setHasProgress] = useState<boolean>(false)
  const [progressType] = useState<"determinate" | "indeterminate">("determinate")
  const [buffered] = useState<number>(0.02)//percent
  const componentId = "component-content-scroll-container"

  const scrollToTop = () => {
    const doc = document?.getElementById(componentId) as HTMLIonContentElement
    doc.scrollToTop(500)
  }

  return (
    <IonContent
      fullscreen={false}
      id={componentId}
      scrollEvents={true}

      onIonScroll={async (e: any) => {
        const window = e.detail
        const scrollElement = await e.srcElement.getScrollElement()
        const percentage = (window.scrollTop) / (scrollElement.scrollHeight - e.srcElement.offsetHeight * 1.2)
        setProgress(percentage)

        //add 10% if user is not logged because of the footer bar
        const atBottom = 0.95 - (User.isLoggedIn() ? 0 : 0.1)
        if (percentage < 0.01) {
          callbackTop(e, percentage)
          if (toTopClass === Animate.setAnimation("fadeOutDown")) return
          setToTopClass(Animate.setAnimation("fadeOutDown"))
        } else if (percentage > atBottom) {
          callbackBottom(e, percentage)
        } else if (y > window.scrollTop) {
          callbackUp(e, percentage)
        } else if (y < window.scrollTop) {
          callbackDown(e, percentage)
          if (toTopClass === Animate.setAnimation("fadeInUp")) return
          setToTopClass(Animate.setAnimation("fadeInUp"))
        }

        setY(window.scrollTop)
      }}

      onIonScrollStart={(e: any) => {
        callbackStart(e)
        setHasProgress(true)
      }}

      onIonScrollEnd={(e: any) => {
        callbackEnd(e)
        setTimeout(() => setHasProgress(false), 1000)
      }}

      {...props}
      className={`component-scroll-content ${props?.className || ""}`}
    >
      <>
        {children}
        {(withToTop && toTop) && (
          <div className="scroll-to-top">
            <IonFab
              vertical="bottom"
              horizontal="end"
              slot="fixed"
              id={`top-${componentId}`}
              className={toTopClass}
            >
              <IonFabButton onClick={() => scrollToTop()}>
                <IonIcon icon={arrowUpOutline} />
              </IonFabButton>
            </IonFab>
          </div>
        )}
        {withProgress &&
          <IonProgressBar
            className={`fixed-progress ${hasProgress ? "with-progress" : ""}`}
            type={progressType}
            value={progress}
            buffer={progress + (progress * buffered)}
          />
        }
      </>
    </IonContent>
  )
}

export default ScrollContent