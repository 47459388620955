import { PublicUrlImg } from "types/Anime/Images"
import { AvatarHeroesKimetzu } from "./Heroes/Kimetzu"
import { AvatarHeroesNaruto } from "./Heroes/Naruto"
import { AvatarHeroesOnePiece } from "./Heroes/OnePiece"
import { AvatarHeroesOnePunchMan } from "./Heroes/OnePunchMan"

export const AvatarHeroes = {
  ...AvatarHeroesOnePunchMan,
  ...AvatarHeroesKimetzu,
  ...AvatarHeroesOnePiece,
  ...AvatarHeroesNaruto,
  "waifu1": `${PublicUrlImg}avatar/heroes/waifu1.jpg`,
  "waifu2": `${PublicUrlImg}avatar/heroes/waifu2.jpg`,
}