import { Suspense, useCallback, useEffect, useState } from "react"

import { Slide, SliderViewDefault, SliderViewPlay } from "types/Media/Slider"
import { GendersStudiosViewProps } from "types/Page/GendersStudiosView"
import HorizontalSlider from "components/Media/HorizontalSlider"
import locales from "config/Locales"
import AniTitle from "pages/_commons/AniTitle"
import Loader from "pages/_commons/Loader"
import Strings from "utils/Strings"

const ViewList = (props: GendersStudiosViewProps) => {
  const [slides, setSlides] = useState<Slide[]>([])
  const id = props.key || `slides-${Strings.encode(props.info?.title).toLowerCase().replaceAll(" ", "-")}`
  const { type } = props

  const checkViewPort = useCallback(() => {
    if (!id) return
    try {
      const target = document?.querySelector(`#${id}`) as HTMLDivElement
      const bounding = target?.getBoundingClientRect()
      const innerHeight = (window.innerHeight || document.documentElement.clientHeight)
      const innerWidth = (window.innerWidth || document.documentElement.clientWidth)
      const outBound = 2

      if (
        bounding?.top > innerHeight * -outBound &&
        bounding?.left >= 0 &&
        bounding?.right <= innerWidth &&
        bounding?.bottom < innerHeight * outBound &&
        slides.length === 0
      ) {
        setSlides(props.viewitems)
      } else if (
        bounding?.top <= innerHeight * -outBound ||
        bounding?.bottom >= innerHeight * outBound
      ) {
        setSlides([])
      }
    } catch (error) { }
  }, [id, props.viewitems, slides.length])

  useEffect(() => {
    if (props.isScrolling) checkViewPort()
  }, [props.isScrolling, checkViewPort])

  return (
    <div className={`component-${type || "gender"}-view`}>
      <>
        {props.viewitems?.length > 0 && (
          <>
            <Suspense fallback={<><Loader text={locales._get("content")} /></>}>
              <div id={id}></div>
              <AniTitle
                title={Strings.encode(props.info?.title)}
                subtitle={locales._get(`subtitle-view-${type || "gender"}`)}
                href={`/anime/${type || "gender"}/${props.info?.id}`}
              />
              <HorizontalSlider
                slides={slides}
                checkHeight={props.isScrolling} name={Strings.encode(props.info?.title).toLowerCase().replaceAll(" ", "-")}
                slidesPerView={props.slidesPerView || (props.sliderType === "player" ? SliderViewPlay : SliderViewDefault)}
                type={props.sliderType || "item"}
              />
            </Suspense>
          </>
        )}
      </>
    </div>
  )
}

export default ViewList