import { create } from "zustand"
import { devtools, persist } from "zustand/middleware"

import { ionicStorage } from "utils/IonicStorage"
import { HistoryRecord } from "./interface"
import { Endpoints } from "api/Endpoints"
import settings from "config/Settings"
import User from "components/Users/User"
import Connect from "components/Connect"

const maxHistoryRecords = new settings().maxHistoryRecords

const useHistoryRecord = create(persist(devtools((set) => ({
  historyRecord: [] as HistoryRecord[],
  setHistoryRecord: (historyRecord: HistoryRecord[]) => set(() => ({
    historyRecord: historyRecord,
  })),
  addHistoryRecord: (historyRecord: HistoryRecord) => set((state: any) => ({
    // replace if last is same
    historyRecord:
      maxHistoryRecords === 0 ? [] :
        historyRecord?.cid > 0 ?
          (
            state.historyRecord.slice(-1)[0]?.cid === historyRecord.cid &&
            state.historyRecord.slice(-1)[0]?.type === historyRecord.type
          )
            ? state.historyRecord.slice(0, -1).concat(historyRecord)
            : state.historyRecord.concat(historyRecord).slice(-maxHistoryRecords)
          : state.historyRecord,
  })),
})), {
  name: "historyRecord",
  getStorage: () => ionicStorage,
}))

export const getHistoryRecord = async (username: string): Promise<HistoryRecord[]> => {
  const historyRecord: string = await ionicStorage.getItem("historyRecord")
  if (historyRecord) {
    const userHistory = JSON.parse(historyRecord).state?.historyRecord?.filter((item: HistoryRecord) => item.user?.username === username) || [] as HistoryRecord[]
    return userHistory as HistoryRecord[]
  }
  return [] as HistoryRecord[]
}

export const saveLastHistoryRecordRemote = async () => {
  const historyRecords: HistoryRecord[] = await getHistoryRecord(User.getUsername())
  const lastHistoryRecord: HistoryRecord = historyRecords.slice(-1)[0]
  if (!lastHistoryRecord) return
  const { cid, eid, mid, type } = lastHistoryRecord
  await Connect.put({
    endpoint: Endpoints.contenthistoryrecord,
    body: {
      cid, eid, mid, type,
    }
  })
}

export default useHistoryRecord