import { ReactNode } from "react"

import locales from "config/Locales"
import { colorTypes } from ".."

export type AppSettingsItemTypes = "toggle" | "label" | "button" | "range" | "select"

export type AppSettingsItemValues = string | number | boolean

export type AppSettingsSelectValues = {
  id: string,
  title: string,
}

export type AppSettingsItemValueRange = {
  min?: number,
  max?: number,
  step?: number,
}

export type AppSettingsItemProps = {
  title?: string | ReactNode,
  subtitle?: ReactNode,
  asHeading?: boolean,
  asSubHeading?: boolean,
  className?: string,
  icon?: string,
  image?: string,
  imageBk?: string,
  baseImage?: string,
  color?: colorTypes,
  value?: AppSettingsItemValues,
  valueRange?: AppSettingsItemValueRange,
  valueSelect?: AppSettingsSelectValues[],
  valueSufix?: string,
  counter?: boolean,
  type?: AppSettingsItemTypes,
  stacked?: boolean,
  onChange?: Function,
  onChanging?: Function,
  onClick?: Function,
  baseNode?: ReactNode,
  endNode?: ReactNode,
}

const AppSettingsTitleLanguageArray = ["spanish", "english", "romaji"]
export const AppSettingsTitleLanguage: AppSettingsSelectValues[] =
  AppSettingsTitleLanguageArray.map(lang => ({
    id: lang,
    title: locales._get(`settings.title-language-${lang}`),
  })) as AppSettingsSelectValues[]

export const AppSettingsInitialPageGlobal: AppSettingsSelectValues[] = [
  {
    id: "default",
    title: locales._get("settings.initial-page-default"),
  },
  {
    id: "/home",
    title: locales._get("new_in", locales._get("appname")),
  },
  {
    id: "/home/anime",
    title: locales._get("new_content"),
  },
  {
    id: "/home/popular",
    title: locales._get("popular_content", locales._get("appname")),
  },
]

export const AppSettingsInitialPageUser: AppSettingsSelectValues[] = [
  {
    id: "/home/foryou",
    title: locales._get("for_you"),
  },
  {
    id: "/home/continue",
    title: locales._get("continue_watching"),
  },
]

export const AppSettingsTemplates: AppSettingsSelectValues[] = [
  {
    id: "default",
    title: locales._get("profile.settings.templates.default"),
  },
  {
    id: "city",
    title: locales._get("profile.settings.templates.city"),
  },
  {
    id: "fantasy",
    title: locales._get("profile.settings.templates.fantasy"),
  },
  {
    id: "fantasy nyan",
    title: `${locales._get("profile.settings.templates.fantasy")} Nyan`,
  },
  {
    id: "forest",
    title: locales._get("profile.settings.templates.forest"),
  },
  {
    id: "sea",
    title: locales._get("profile.settings.templates.sea"),
  }
]

export const AppSettingsInitialPage: AppSettingsSelectValues[] = [
  ...AppSettingsInitialPageGlobal,
  ...AppSettingsInitialPageUser,
]