import { useEffect, useState } from "react"
import { useHistory, useParams } from "react-router-dom"

import { getStudio, getStudiosListMenuItems } from "pages/_commons/Content/GerdersList/getStudiosList"
import { setHeaderStudioLink } from "../HeaderToolbar"
import { paramsRecentByStudio } from "api/routes/params/Media"
import { SliderViewDefault } from "types/Media/Slider"
import { cubeOutline } from "ionicons/icons"
import { Endpoints } from "api/Endpoints"
import { menuItems } from "types/Commons/Menu"
import { colorTypes } from "types/index"
import Picker from "components/Commons/Picker"
import HeaderSlides from "pages/_commons/Content/HeaderSlides"
import PageContainer from "pages/_commons/PageContainer"
import Content from "pages/_commons/Content/Content"
import Skeleton from "components/Commons/Skeleton"
import Refresher from "components/Content/Refresher"
import UserRefresh from "components/Connect/Refresher"
import LoaderOptions from "components/Content/ScreenLoader/LoaderOptions"
import locales from "config/Locales"
import HeaderToolbar from "components/Commons/HeaderToolbar"
import Strings from "utils/Strings"
import Alerts from "components/Commons/Alerts"
import AdsBanner from "components/Media/Ads/Banner"
import Meta from "components/Media/Meta"

const StudioView = () => {
  const [isScrollingBottom, setIsScrollingBottom] = useState(false)
  const [title, setTitle] = useState<string>("")
  const [studios, setStudios] = useState<menuItems[]>([])
  const [paramsStudio, setParamsStudio] = useState<{} | null>(null)
  const [watchLastCall, setWatchLastCall] = useState<boolean>(true)
  const [loadContent, setLoadContent] = useState<boolean>(true)
  const [resetPage, setResetPage] = useState<boolean>(true)
  const [sid, setSid] = useState<number>(0)
  const [alertMsg, setAlertMsg] = useState<string>("")
  const [alertColor, setAlertColor] = useState<colorTypes>("warning")
  const params = useParams()
  const history = useHistory()

  const toggleLastCall = () => setWatchLastCall((prevWatch: boolean) => !prevWatch)

  const toggleLoadContent = () => setLoadContent((prevLoad: boolean) => !prevLoad)

  useEffect(() => {
    Strings.parseParams("sid", params, (_sid: number) =>
      setSid(_sid))
    if (sid === 0) return
    setParamsStudio({ ...paramsRecentByStudio, ...{ studio: sid } })
    setResetPage((prevReset: boolean) => !prevReset)

    const _studios = getStudiosListMenuItems()
    const _studio = getStudio(sid)

    setStudios(() => {
      _studios?.map((studio: menuItems) => studio.icon = cubeOutline)
      return _studios
    })
    setTitle(_studio?.title || "")
  }, [params, sid])

  useEffect(() => {
    if (!isScrollingBottom) return

    toggleLoadContent()
  }, [isScrollingBottom])

  return (
    <PageContainer
      withProgress={true}
      withToTop={true}
      className="page-discover"
      callbackStart={() => setIsScrollingBottom(false)}
      callbackBottom={() => setIsScrollingBottom(true)}
    >
      <>
        <HeaderSlides />
        <Picker
          items={studios}
          color="secondary"
          selected={setHeaderStudioLink(sid)}
          onClickPicker={(href: string) => {
            history.replace(href)
          }}
        />
        <HeaderToolbar asBack={true} />
        <div className="ion-padding inner-container">
          <Refresher
            callback={(closeRefreser: Function) => {
              if (paramsStudio === null) return closeRefreser()

              LoaderOptions.openLoader("refreshing")
              UserRefresh.genderlist(sid, (callbackStatus: number) => {
                toggleLastCall()
                toggleLoadContent()
                closeRefreser()
                setAlertMsg(locales._get(callbackStatus === 200 ? "refreshed" : "not_refreshed"))
                setAlertColor(callbackStatus === 200 ? "success" : "secondary")
                LoaderOptions.closeLoader()
              }, true)
            }}
          />
          {paramsStudio === null ? (
            <Skeleton cols={SliderViewDefault.s} />
          ) :
            <>
              <AdsBanner type="softnyacontent" softnyaposition="DETAILS_TOP" />
              <Content
                title={title}
                endpoint={Endpoints.recent}
                params={paramsStudio}
                slidesPerView={SliderViewDefault}
                watchLastCall={watchLastCall}
                loadContent={loadContent}
                resetPage={resetPage}
                type="item"
                mode="grid"
              />
              <Meta
                title={title}
              />
            </>
          }
        </div>
        <Alerts
          title={alertMsg}
          showAlert={alertMsg !== ""}
          duration={3000}
          color={alertColor}
          position="top"
        />
      </>
    </PageContainer>
  )
}

export default StudioView