import { ReactNode } from "react"
import { colorTypes, fillTypes, sizeTypes } from ".."

export type DropDownButtonProps = {
  id?: string,
  text: string,
  prefix?: string,
  subtext?: "spinner" | string,
  icon?: "spinner" | string,
  inlineNode?: ReactNode,
  className?: string,
  inClassName?: string,
  onClick?: Function,
  sameClickAll?: boolean,
  color?: colorTypes,
  size?: sizeTypes,
  fill?: fillTypes,
  disabled?: boolean,
  index?: number,
  children?: ReactNode,
}

export const defaultDropDownButton = {
  color: "primary" as colorTypes,
  size: "large" as sizeTypes,
  fill: "solid" as fillTypes,
  disabled: false,
}