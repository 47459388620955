import { useEffect, useState } from "react"
import { IonBadge, IonPage } from "@ionic/react"

import { makeVisible } from "App"
import { MyNetwork } from "config/Settings/MyNetwork"
import { menuItems } from "types/Commons/Menu"
import { share } from "components/Commons/Social"
import { MenuWithHidden } from "config/Menu"
import ScrollContent from "components/Content/ScrollContent"
import UserBar from "components/Users/UserBar"
import AniHeader from "../AniHeader"
import AdsBanner from "components/Media/Ads/Banner"
import User from "components/Users/User"
import settings, { isDev } from "config/Settings"
import IsLatest from "../IsLatest"
import GA from "utils/GA"

const PageContainer = ({ ...props }) => {
  const [menus] = useState<menuItems[]>(MenuWithHidden as menuItems[])
  const [path] = useState<string>(window.location.pathname)

  const ommit: string[] = [
    "/", "/splash", "/welcome",
  ]


  useEffect(() => {
    makeVisible()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [window.location.pathname])

  useEffect(() => {
    if (ommit.findIndex((str: string) => str === path) >= 0) return
    menus.map((menu: menuItems) => {
      if (menu.href === path) GA.pageview(menu.title, path)
      return menu
    })
    share.showRateOnInterval()

    makeVisible()

    if (isDev) return
    document.addEventListener("contextmenu", (event) => {
      event.preventDefault()
    })
    // eslint-disable-next-line no-console
    console.clear()
    // eslint-disable-next-line no-console
    console.log("%cAccess denied, only for devs", "color: red; font-size: 18px; font-weight: bold;")
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const { children, scrollY, className, showLogo, onClickBack, ...rest } = props
  return (
    <IonPage scroll-y="true">
      <MyNetwork />
      <IsLatest />
      {settings.forceDebug &&
        <IonBadge color="danger" className="force-debug">DEBUG</IonBadge>
      }
      <AdsBanner type="bannerbottom" />
      <AniHeader showLogo={showLogo} onClick={onClickBack} />
      <AdsBanner type="softnyacontent" softnyaposition="TOP" />
      <ScrollContent className={`${!User.isLoggedIn() && "with-user-bar"} ${className || ""}`} {...rest}>
        {children || <></>}
      </ScrollContent>
      <UserBar />
    </IonPage>
  )
}

export default PageContainer