import { create } from "zustand"
import { devtools, persist } from "zustand/middleware"

import { setting } from "types/App"
import StorageUtils from "utils/Storage"

const AppSettings = create(devtools(persist((set) => ({
  setting: {},
  setSetting: (key: string, value: any) => set((state: setting) => {
    state.setting[key] = value
    return state
  }),
}), { name: "permanent-app-settings" })))

export default AppSettings

export class AppSettingsID {
  public static setSetting = (key: string, value: any) =>
    StorageUtils.setItem(`permanent-app-settings-${key}`, JSON.stringify(value))

  public static getSetting = (key: string, defaultValue: any) => {
    const storage = StorageUtils.getItem(`permanent-app-settings-${key}`)
    if (!storage) return defaultValue

    const settings = JSON.parse(storage || "{}")
    if (settings === undefined || settings === null) return defaultValue

    return settings
  }
}