import { menuItems } from "types/Commons/Menu"

export const resetDisplay = (
  setOnDisplay: React.Dispatch<React.SetStateAction<menuItems[]>>,
  setInfiniteDisabled?: React.Dispatch<React.SetStateAction<boolean>>,
  setLoaded?: React.Dispatch<React.SetStateAction<boolean>>,
  callback?: Function,
) => {
  setOnDisplay(() => {
    setInfiniteDisabled && setInfiniteDisabled(() => {
      if (callback) callback()
      setLoaded && setLoaded(false)
      return false
    })
    return []
  })
}