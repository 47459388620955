import { Endpoints } from "api/Endpoints"
import { AnimeEpisodes } from "types/Anime"
import { AnimeToggleViewedProps } from "types/Anime/AnimeToggleViewed"
import { modifyDetailsEpisodes } from "./modifyDetailsEpisodes"
import { updateUserLists } from "./updateUserLists"
import Connect from "components/Connect"
import User from "components/Users/User"

export const toggleViewed = async (props: AnimeToggleViewedProps): Promise<boolean> => {
  if (!User.isLoggedIn()) return false
  const episodesData = await Connect.put({
    endpoint: Endpoints.detailsepisodes,
    body: {
      eid: props.eid,
      mid: props.mid || 0,
      seen: props.seen ? 1 : 0,
      addhits: (props.addHit || false) ? 1 : 0
    }
  })
  let modified: boolean = (episodesData?.status) === 202 ? true : false
  if (modified) {
    const episodes: AnimeEpisodes = await modifyDetailsEpisodes(props)
    modified = episodes?.cid === props.cid
    updateUserLists().then((updated: boolean) => {
      props.onUpdatedUserLists && props.onUpdatedUserLists(updated)
    })
  }
  return modified
}