import { Endpoints } from "api/Endpoints"
import { Responses } from "types/Connect"
import { delayAndReturn } from "utils/Function"
import { AnimeEpisodeDetails, AnimeEpisodes, AnimeEpisodesMediaTypes, responseEpisodes } from "types/Anime"
import Connect from "components/Connect"
import AnimeContentParser from "pages/_commons/Content/AnimeContent"

export const getEpisodes = async (props: { cid: number, eid: number, force?: boolean, onlyCached?: boolean }): Promise<responseEpisodes> => {
  if (props.cid === 0) return {} as responseEpisodes
  const force: boolean = props.force || false
  const responseEpisodes: Responses = await Connect.postcached({
    endpoint: Endpoints.detailsepisodes,
    params: { id: props.cid },
    body: { eid: props.eid },
    options: { force: force },
  })
  if (!responseEpisodes.data?.results && !force) {
    await delayAndReturn(() => { })
    return getEpisodes({ ...props, force: true })
  }
  const episodes: AnimeEpisodes = AnimeContentParser.fitAnimeEpisodes(responseEpisodes.data?.results as AnimeEpisodes)
  const episode: AnimeEpisodeDetails = episodes.episodes?.find((e: AnimeEpisodeDetails) => e.eid === props.eid) || {} as AnimeEpisodeDetails
  const responseMedia: Responses = await Connect.postcached({
    endpoint: Endpoints.detailsepisodesmedia,
    params: { id: props.cid, eidserie: episode.episode },
    options: {
      force: force,
      onlyCached: props.onlyCached || false,
      checkInterval: "day",
    },
  })
  if (!responseMedia.data?.results && !force) {
    await delayAndReturn(() => { })
    return getEpisodes({ ...props, force: true })
  }
  const media: AnimeEpisodesMediaTypes = responseMedia.data?.results?.media || {} as AnimeEpisodeDetails
  return { episodes, media } as responseEpisodes
}
