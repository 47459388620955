import { useState } from "react"
import { IonButton, IonIcon, IonLabel } from "@ionic/react"

import { DropDownButtonProps } from "types/Commons/DropDownButton"
import { SpinnerBubbles } from "components/Commons/Spinner"
import DropDownContainer from "components/Commons/DropDownContainer"
import Strings from "utils/Strings"

const InlineButton = (props: DropDownButtonProps) => {
  const [dropDownId] = useState(`drop-item-${props.id || props.index || 0}`)
  const [trigger] = useState(`menu-popover-dropdown-${props.prefix || "default"}-${props.index || 0}-${Strings.getRandomNumber(10)}`)

  return (
    <>
      <IonButton
        id={props.children ? trigger : ""}
        color={props.color}
        size={props.size}
        fill={props.fill}
        disabled={props.disabled}
        className={`button index-${props.index || 0}`}
        onClick={() => props.onClick && props.onClick()}
      >
        {props.inlineNode || <></>}
        <span className={`inner-drop-button ${props.inClassName}`}>
          {(props.icon && props.icon !== "spinner") &&
            <><IonIcon icon={props.icon} className={props.subtext && "with-subtext"} />&nbsp;</>
          }
          {(props.icon && props.icon === "spinner") &&
            <SpinnerBubbles />
          }
          <IonLabel>
            <div>{props.text}</div>
            {props.subtext && <small>{props.subtext}</small>}
          </IonLabel>
        </span>
      </IonButton>
      {props.children &&
        <>
          <DropDownContainer
            id={dropDownId}
            trigger={trigger}
            className={`component-dropdown-button-popover ${props.className || ""}`}
          >
            {props.children || <></>}
          </DropDownContainer>
        </>
      }
    </>
  )
}

export default InlineButton