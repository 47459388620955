import { IonIcon, IonText } from "@ionic/react"
import { star } from "ionicons/icons"

import locales from "config/Locales"

import "./index.scss"

const PremiumBadge = () => {
  return (
    <div className="premium-badge">
      <IonIcon icon={star} />
      <IonText>{locales._get("content.premium")}</IonText>
    </div>
  )
}

export default PremiumBadge