import { ReactNode } from "react"

export type DropDownSideTypes = "start" | "end" | "top" | "bottom"

export type DropDownAlignmentTypes = "start" | "end" | "center"

export type DropDownContainerProps = {
  id?: string,
  trigger: string,
  className?: string,
  children?: ReactNode,
  onPresent?: Function,
  onDismiss?: Function,
  side: DropDownSideTypes,
  alignment: DropDownAlignmentTypes
}

export const defaultDropDownContainer = {
  side: "bottom" as DropDownSideTypes,
  alignment: "end" as DropDownAlignmentTypes
}