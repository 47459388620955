import { Endpoints } from "api/Endpoints"
import { paramsRecentForYou } from "api/routes/params/Media"
import User from "components/Users/User"
import RefresherCallContent from "../callContent"

class RefresherUser {
  public static foruser = async (response: Function, force: boolean = true) => {
    if (!User.isLoggedIn()) return response(200)

    await RefresherCallContent.callContent(Endpoints.recent, paramsRecentForYou, force, () =>
      RefresherCallContent.callContent(Endpoints.recentviewed, {}, force, (status: number) =>
        response(status)))
  }
}

export default RefresherUser