import { useEffect, useRef, useState } from "react"
import { IonFab, IonFabButton, IonIcon, IonFabList, IonHeader, IonTitle, IonToolbar, IonBadge, IonButton } from "@ionic/react"
import { chevronBackCircle, chevronDown, chevronDownCircle, chevronForwardCircle, chevronUp, chevronUpCircle, close, closeOutline, play, videocamOutline } from "ionicons/icons"

import { ModalMediaControlsDefaultProps, ModalMediaControlsProps } from "types/Anime/AnimeModalMedia"
import { menuItems } from "types/Commons/Menu"
import { AnimeEpisodes, EpisodeMediaType } from "types/Anime"
import { SpinnerBubbles } from "components/Commons/Spinner"
import { PickerModal } from "components/Commons/Picker/Modal"
import useAnime from "store/useAnime"
import Strings from "utils/Strings"
import AlertWithAds from "components/Commons/Alerts/WithAds"
import Alerts from "components/Commons/Alerts"
import locales from "config/Locales"
import ModalMusicControls from "./MusicControls"
import Wave from "components/Media/Wave"
import Animate from "utils/Animate"

import "./index.scss"

let withCountDown = true
const ModalMediaControls = (props: ModalMediaControlsProps) => {
  const modal = useRef<HTMLIonModalElement>(null)
  const [modalId] = useState(`picker-${Strings.getRandomNumber(7)}`)
  const [media, setMedia] = useState<menuItems[]>([] as menuItems[])
  const [selected, setSelected] = useState<string>("")
  const [isHidden, setIsHidden] = useState<boolean>(false)
  const [visibleMenuTime] = useState<number>(10)//seconds
  const [reloading, setReloading] = useState<boolean>(false)
  const [showAlert, setShowAlert] = useState<boolean>(false)
  const [showAlertInfo, setShowAlertInfo] = useState<boolean>(false)
  const setLastMedia = useAnime<Function>((state: any) => state.setLastMedia)
  let currentVisibleMenuTime = visibleMenuTime

  const startCountDownCloseMenu = () => {
    if (!props.showMenu) return
    const countDown = setInterval(() => {
      if (currentVisibleMenuTime > 0 && withCountDown) {
        currentVisibleMenuTime--
        setIsHidden(false)
      } else {
        const button = document.querySelector("#fab-button-drop") as HTMLIonButtonElement
        if (button?.classList.contains("fab-button-close-active")) return
        clearInterval(countDown)
        setIsHidden(true)
      }
    }, 1000)
  }

  const showMenu = () => {
    if (!isHidden) return
    setIsHidden(false)
    currentVisibleMenuTime = visibleMenuTime
    withCountDown = true
    startCountDownCloseMenu()
  }

  const hideMenu = () => {
    if (isHidden || !props.showMenu) return
    setIsHidden(true)
    currentVisibleMenuTime = 0
    withCountDown = false
  }

  const showMedia = () => modal.current?.present()

  const onClickPicker = (optionValue: string) => {
    modal.current?.dismiss()
    setReloading(true)
    setSelected(optionValue)
    showMenu()
    props.onMediaChange && props.onMediaChange(optionValue)
    props.episodes?.media?.current?.play?.map((media: EpisodeMediaType) => {
      if (optionValue === media.id.toString()) {
        media.ads > 0 && setShowAlert(true)
        setLastMedia(media.provider)
      }
      return media
    })
  }

  const onClickButton = (id: string) => {
    const button = document.querySelector(`#${id}`) as HTMLIonButtonElement
    if (button) {
      (window as any).MusicControls?.destroy()
      button.click()
      props.onMediaChange && props.onMediaChange(selected)
    }
  }

  const onPrev = () => onClickButton("anime-media-options-previous")

  const onNext = () => onClickButton("anime-media-options-next")

  useEffect(() => () => setShowAlert(false), [])

  useEffect(() => setSelected(props.selected || ""), [props.selected])

  useEffect(() => {
    const newMedia: menuItems[] = [] as menuItems[]
    props.episodes?.media?.current?.play?.map((_play: EpisodeMediaType) => {
      newMedia.push({
        title: `
          ${_play.provider} 
          ${_play.ads > 0 ? ` <small>${locales._get("content.media-ads")} ${_play.adsStr}</small>` : ""}
          ${_play.premium === 1 ? ` <small>${locales._get("content.premium")}</small>` : ""}
          `,
        href: _play.id.toString(),
        icon: play,
        arrow: chevronForwardCircle,
      })
      return _play
    })
    if (props.content?.trailer?.id && newMedia.length > 0) {
      newMedia.push({
        title: locales._get("content.watch-trailer"),
        href: "0",
        icon: videocamOutline,
        arrow: chevronForwardCircle,
      })
    }
    setMedia(newMedia)
    setSelected(props.provider?.id?.toString() || "")
    setIsHidden(false)
    startCountDownCloseMenu()
    setReloading(false)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.episodes?.media?.current])

  return (
    <div className={`component-anime-media-iframe-controls ${isHidden ? "hidden-menu" : ""}`}>
      <IonHeader>
        <IonToolbar onClick={showMenu}>
          <IonTitle className={`${props.showMenu ? "withMenu" : ""}`}>
            <div className="counterSeen" onClick={() => setShowAlertInfo(true)}>
              <Wave type="circle" className="cursor" onClick={() => setShowAlertInfo(true)} />
              <div className={`time hidden ${Animate.setAnimationBase()} cursor`}></div>
              {props.hasCloseButton &&
                <IonButton
                  className="close-button"
                  fill="solid"
                  shape="round"
                  color="primary"
                  onClick={() => props.onClose && props.onClose()}
                >
                  <IonIcon icon={close} />
                </IonButton>
              }
            </div>
            <div className="titles" onClick={() => props.onClick && props.onClick()}>
              <span>
                {(((props.episodes?.media?.current?.serie && props.episodes?.last_episode) || props.alterTitle) || reloading) ? (
                  <>{props.episodes?.media?.current?.badge || props.alterTitle || ""} </>
                ) : <><SpinnerBubbles /></>}
              </span>
              {(props.content?.title || props.alterSubtitle || reloading) ? (
                <>
                  {props.content?.title || ""}
                  <small>{props.provider?.provider || props.alterSubtitle || ""}</small>
                </>
              ) : (
                <><SpinnerBubbles /></>
              )}
            </div>
          </IonTitle>
        </IonToolbar>
      </IonHeader>
      {props.showMenu &&
        <IonFab slot="fixed" vertical="top" horizontal="end">
          <>
            <IonFabButton
              id="fab-button-drop"
              onClick={showMenu}
              closeIcon={chevronUp}
            >
              <IonIcon color="light" icon={isHidden ? chevronDown : chevronDownCircle}></IonIcon>
            </IonFabButton>
            <IonFabList side="bottom" className="side-controls">
              <IonFabButton
                onClick={showMedia}
                disabled={(props.episodes?.media?.current?.play?.length || 0) === 0}
                className="play"
              >
                <IonIcon icon={play}></IonIcon>
              </IonFabButton>
              <IonFabButton
                onClick={() => onPrev()}
                disabled={(props.episodes?.media?.prev?.exist || 0) === 0}
                className="prev"
              >
                <IonIcon icon={chevronBackCircle}></IonIcon>
              </IonFabButton>
              <IonFabButton
                onClick={() => onNext()}
                disabled={(props.episodes?.media?.next?.exist || 0) === 0}
                className="next"
              >
                <IonIcon icon={chevronForwardCircle}></IonIcon>
              </IonFabButton>
              <IonFabButton onClick={hideMenu} className="up">
                <IonIcon icon={chevronUpCircle}></IonIcon>
              </IonFabButton>
              <IonFabButton onClick={() => props.onClose && props.onClose()} className="close">
                <IonIcon icon={closeOutline}></IonIcon>
              </IonFabButton>
            </IonFabList>
          </>
        </IonFab>
      }
      <IonBadge
        color="secondary"
        className={`seenBadge ${Animate.setAnimationBase()} ${Animate.setAnimationFast()} hidden`}
      >
        {locales._get("content.media-marked-as-seen")}
      </IonBadge>
      {media?.length > 0 &&
        <PickerModal
          modal={modal}
          modalId={modalId}
          onClick={onClickPicker}
          selected={selected}
          items={media}
        />
      }
      <AlertWithAds
        showAlert={showAlert}
      />
      <Alerts
        title={locales._get("content.wave-title")}
        showAlert={showAlertInfo}
        onDismiss={() => setShowAlertInfo(false)}
        buttons={[
          {
            text: locales._get("ok"),
            role: "cancel",
            handler: () => setShowAlert(false)
          }
        ]}
      />
      <ModalMusicControls
        {...props}
        episodes={props.episodes || {} as AnimeEpisodes}
        onNext={onNext}
        onPrev={onPrev}
      />
    </div>
  )
}

ModalMediaControls.defaultProps = ModalMediaControlsDefaultProps

export default ModalMediaControls