import { IonBadge, IonLabel, IonText } from "@ionic/react"

import { AnimeTopBadgeProps } from "types/Anime"
import Animate from "utils/Animate"

import "animate.css"
import "./index.scss"

const AnimeTopBadge = (props: AnimeTopBadgeProps) => {
  if (props.top === 0 || !props.top) return <></>

  return (
    <IonBadge
      className={`component-anime-top-badge ${Animate.setAnimation("jackInTheBox")} top-color-${props.top > 20 ? 20 : props.top}`}
    >
      <IonLabel>Top</IonLabel>
      <IonText>{props.top}</IonText>
    </IonBadge>
  )
}

export default AnimeTopBadge