import { Endpoints } from "api/Endpoints"
import { Responses } from "types/Connect"
import { StorageItems } from "utils/Storage/StorageItems/Storage"
import { limitsContinueWatching, paramsRecentForYou, recentContentGridLimit, recentContentSlideLimit } from "api/routes/params/Media"
import { AnimeUpdateUserListsProps, defaultAnimeUpdateUserListsProps } from "types/Anime/AnimeUpdateUserLists"
import { getQueryFormat } from "components/Connect/Exports"
import Connect from "components/Connect"
import User from "components/Users/User"

export const updateUserLists = async (props?: AnimeUpdateUserListsProps): Promise<boolean> => {
  if (!User.isLoggedIn()) return false
  const _props = { ...defaultAnimeUpdateUserListsProps, ...props, }
  const callSlides = async (props: { limit: number, endpoint?: string, params?: any }): Promise<boolean> => {
    const postQuery = (pag: number) => {
      return {
        endpoint: props.endpoint || Endpoints.recentviewed,
        params: { ...props.params, pag: pag, limit: props.limit },
      }
    }
    const response: Responses = await Connect.post(postQuery(1))
    let finishDo = false
    let pag = 1
    do {
      const query = Connect.getQuery(postQuery(pag++))
      const queryFormat = getQueryFormat(query)
      const localQueryData = JSON.parse(await StorageItems.getItem(queryFormat) || "{}")
      if (localQueryData?.data) Connect.removeCached(queryFormat)
      else finishDo = true
    } while (!finishDo)
    return response?.data?.results ? true : false
  }
  const continueWatchSlide: boolean = _props.continueWatching ? await callSlides({ limit: limitsContinueWatching.slide }) : true
  const continueWatchGrid: boolean = _props.continueWatching ? await callSlides({ limit: limitsContinueWatching.grid }) : true
  const forYouParams = { endpoint: Endpoints.recent, params: paramsRecentForYou }
  const forYouSlide: boolean = _props.forYou ? await callSlides({ ...forYouParams, limit: recentContentSlideLimit }) : true
  const forYouGrid: boolean = _props.forYou ? await callSlides({ ...forYouParams, limit: recentContentGridLimit }) : true
  return continueWatchSlide && continueWatchGrid && forYouSlide && forYouGrid
}