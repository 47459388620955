import { IonList, IonItem } from "@ionic/react"
import { useEffect, useState } from "react"

import { HorizontalSliderProps, defaultHorizontalSliderProps } from "types/Media/HorizontalSlider"
import { ItemThumbs } from "components/Content/Card/Thumbs"
import useWindowSize from "hooks/useWindowSize"
import drawSlideItems from "../drawSlideItems"
import Strings from "utils/Strings"
import Animate from "utils/Animate"

import "./index.scss"

const initialHeight = 3 * 16// initial size in rem

const HorizontalSlider = (props: HorizontalSliderProps) => {
  const windowSize = useWindowSize()
  const [randId, setRandId] = useState<string>(props.name !== "" ? `hs-${props.name}` : "")
  const [slidesPerView, setSlidesPerView] = useState(props.slidesPerView.s)
  const [style, setStyle] = useState({ skeleton: {}, block: {} })
  const [itemHeight, setItemHeight] = useState(initialHeight)

  const resizeBox = () => {
    const firstItem = document.getElementById(`slide-${randId}-0`)
    const firstItemHeight = firstItem?.clientHeight || itemHeight

    if (firstItemHeight === 0) {
      setTimeout(() => {
        resizeBox()
      }, 1500)
      return
    }

    setStyle({
      skeleton: {
        width: `${(100 / slidesPerView).toFixed(4)}vw`,
        height: "8rem",
      },
      block: {
        width: `calc(${(100 / slidesPerView).toFixed(2)}% - 1.4rem)`
      },
    })

    setItemHeight(firstItemHeight)
  }

  useEffect(() => {
    setRandId((id) => id === "" ? `hs-${Strings.getRandomNumber(7)}` : id)
  }, [setRandId])

  useEffect(() => {
    if (windowSize.loaded) {
      if (windowSize.breakpoint === "s") setSlidesPerView(props.slidesPerView.s)
      else if (windowSize.breakpoint === "m") setSlidesPerView(props.slidesPerView.m)
      else if (windowSize.breakpoint === "l") setSlidesPerView(props.slidesPerView.l)
      else setSlidesPerView(props.slidesPerView.xl)

      resizeBox()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [windowSize.width])

  if (props.slides.length === 0) {
    return (
      <IonList className="component-horizontal-slider" id={`#${randId}`}>
        <IonItem>
          <ItemThumbs count={slidesPerView} style={style.skeleton} />
        </IonItem>
      </IonList>
    )
  }

  // Checar Swiper: https://ionicframework.com/docs/react/slides
  return (
    <div
      id={`#${randId}`}
      key={randId}
      className={`component-horizontal-slider ${props.className || ""} ${props.animation || Animate.setAnimation("fadeIn")}`}
    >
      {drawSlideItems(props, slidesPerView, style, randId || "")}
    </div >
  )
}

HorizontalSlider.defaultProps = defaultHorizontalSliderProps

export default HorizontalSlider
