import { IonList, IonListHeader, IonSkeletonText, IonItem, IonThumbnail, IonLabel } from "@ionic/react"

import { SkeletonLineProps, SkeletonProps } from "types/Commons/Skeleton"

import "./index.scss"

export const SkeletonSingle = ({ ...props }) =>
  (<IonSkeletonText animated={true} {...props} />)

export const SkeletonLine = (props: SkeletonLineProps) =>
  <><p><SkeletonSingle style={{ "width": `${props.width || 80}${props.type || "%"}` }} /></p></>

const Skeleton = (props: SkeletonProps) => {
  return (
    <IonList className="component-skeleton">
      {props.cols ? (
        <>
          <IonItem>
            {Array.from(Array(props.cols).keys()).map((col: number) => (
              <IonThumbnail slot="start" style={{
                width: `${100 / (props.cols || 1)}vw`,
                height: `${100 / (props.cols || 1)}vw`
              }}>
                <SkeletonSingle />
              </IonThumbnail>
            ))}
          </IonItem>
        </>) : (
        <>
          <IonListHeader>
            <SkeletonLine width={90} type="px" />
          </IonListHeader>
          <IonItem>
            <IonThumbnail slot="start">
              <SkeletonSingle />
            </IonThumbnail>
            <IonLabel>
              <SkeletonLine />
              <SkeletonLine width={60} />
              <SkeletonLine width={30} />
            </IonLabel>
          </IonItem>
          {props.articleLike &&
            <>
              <IonItem>
                <IonLabel>
                  {[0, 1, 2, 3].map(() =>
                    <SkeletonLine width={100} />
                  )}
                  <SkeletonLine width={60} />
                </IonLabel>
              </IonItem>
            </>
          }
        </>
      )}
    </IonList>
  )
}

export default Skeleton