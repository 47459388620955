import { PublicUrlImg } from "types/Anime/Images"

const AvatarPath = `${PublicUrlImg}avatar/heroes/one-piece/`

export const AvatarHeroesOnePiece = {
  "luffy": `${AvatarPath}luffy.jpg`,
  "zoro": `${AvatarPath}zoro.jpg`,
  "sanji": `${AvatarPath}sanji.jpg`,
  "nami": `${AvatarPath}nami.jpg`,
  "chopper": `${AvatarPath}chopper.jpg`,
}