import { useState } from "react"
import { IonButton, IonCard, IonCardContent, IonCardHeader, IonTitle } from "@ionic/react"
import { useHistory } from "react-router-dom"
import { Swiper, SwiperSlide } from "swiper/react"
import "swiper/scss"
import "@ionic/react/css/ionic-swiper.css"

import { ExploreSliderProps } from "types/Page/ExploreSlider"
import { Slide } from "types/Media/Slider"

import "./index.scss"

const ExploreSlider = (props: ExploreSliderProps) => {
  const [items] = useState<Slide[]>(props.items || [] as Slide[])
  const history = useHistory()

  if (!items) return <></>
  return (
    <>
      <IonTitle>{props.title}</IonTitle>
      <Swiper
        className={`component-no-search-explore ${props.className || ""}`}
        pagination={{ clickable: true }}
        initialSlide={0}
        speed={400}
        slidesPerView={1.2}
        spaceBetween={10}
        loop={true}
        autoplay={{
          delay: 15000,
          disableOnInteraction: false,
        }}
      >
        {items?.map((item, index) => (
          <>
            <SwiperSlide>
              <IonCard
                key={index}
                color={index % 2 === 0 ? props.firstColor || "primary" : props.secondColor || "secondary"}
              >
                <IonCardHeader>
                  <IonTitle>{item.title}</IonTitle>
                </IonCardHeader>
                <IonCardContent>
                  {item.description && <div>{item.description}</div>}
                  <IonButton
                    color={index % 2 === 0 ? props.secondColor || "secondary" : props.firstColor || "primary"}
                    fill="solid"
                    onClick={() => history.push(item?.href || "")}
                  >
                    {props.buttonText}
                  </IonButton>
                </IonCardContent>
              </IonCard>
            </SwiperSlide>
          </>
        ))}
      </Swiper>
    </>
  )
}

export default ExploreSlider