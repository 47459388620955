export class StorageItems {
  public static setItem = (key: string, value: string) => {
    try {
      return localStorage?.setItem(key, value)
    } catch (e) {
      //isDev && console.warn("StorageItems.setItem Error:", e)
      return false
    }
  }

  public static getItem = (key: string): string | null =>
    localStorage.getItem(key)

  public static removeItem = (key: string): boolean => {
    if (!StorageItems.getItem(key)) return false

    localStorage.removeItem(key)
    return true
  }

  public static getKey = (index: number): string | null =>
    localStorage.key(index)

  public static getLength = (): number =>
    localStorage.length

  public static getCachedKeys = (): string[] => {
    const keys: string[] = []
    for (let i = 0; i < StorageItems.getLength(); i++) {
      const key = StorageItems.getKey(i)
      if (key) keys.push(key)
    }
    return keys
  }
}