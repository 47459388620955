import { IonAccordion, IonIcon, IonItem, IonLabel } from "@ionic/react"
import { playCircleOutline } from "ionicons/icons"
import { useState } from "react"

import { AnimeEpisodeDetails } from "types/Anime"
import { AnimeEpisodesAccordionItemProps } from "types/Anime/AnimeEpisodesAccordion"
import Animate from "utils/Animate"
import AnimeEpisodesSetItems from "../../SetItems"

const AnimeEpisodesAccordionItem = (props: AnimeEpisodesAccordionItemProps) => {
  const [isActive, setIsActive] = useState<boolean>(false)
  const from = props.item * props.tabGroups + 1
  const to = props.item * props.tabGroups + props.tabGroups
  const limit = to > props.maxItems ? props.maxItems : to
  const accordionId = `tab-${from}-${limit}`
  const items: AnimeEpisodeDetails[] = props.episodes.slice(from - 1, limit)

  const isCurrentInTab = () =>
    (items.find((item) => item.eid === props.current?.eid)?.eid === props.current?.eid) ? true : false

  return (
    <IonAccordion
      className="component-anime-accordion"
      value={accordionId}
      id={accordionId}
    >
      <IonItem slot="header" color="dark"
        onMouseUp={() => {
          const accordion = document.querySelector(`#${accordionId}`) as HTMLIonAccordionElement
          const isOpen = accordion.classList.contains("accordion-collapsed") || accordion.classList.contains("accordion-collapsing")
          setIsActive(isOpen)
        }}
      >
        <IonLabel>
          {items[0]?.episode} - {items[items.length - 1]?.episode}
          {isCurrentInTab() &&
            <IonIcon
              icon={playCircleOutline}
              className={`current-episode ${Animate.setAnimation("jackInTheBox")}`}
            />
          }
        </IonLabel>
      </IonItem>
      <div slot="content">
        {isActive && <AnimeEpisodesSetItems
          episodesLoaded={props.episodesLoaded}
          episodes={items}
          current={props.current}
          changeEpisode={props.changeEpisode}
        />}
      </div>
    </IonAccordion>
  )
}

export default AnimeEpisodesAccordionItem