import { remove } from "diacritics"

import locales from "config/Locales"
import Strings from "utils/Strings"

import "./searchWords.scss"

export const searchWords = (text: string, currentSearch: string): string => {
  if (currentSearch === "") return text
  currentSearch = currentSearch.replaceAll("+", " ")
  const words = text.split(" ")
  const searchWords = currentSearch.split(" ")
  const newWords = words.map((word) => {
    const normalizedWord = remove(word).toLowerCase()
    const index = searchWords.findIndex((searchWord) =>
      normalizedWord.includes(remove(searchWord).toLowerCase()))
    //console.log(words, searchWords[index], word, index)
    if (index > -1) {
      searchWords.map((searchWord) => {
        const wordIndex = normalizedWord.indexOf(remove(searchWord).toLowerCase())
        if (wordIndex > -1) {
          const id = `search-word-click-${Strings.getRandomString(10)}`
          word = `
          ${word.slice(0, wordIndex)}<span class="search-word" id="${id}">${word.slice(wordIndex, wordIndex + searchWord.length)}</span>${word.slice(wordIndex + searchWord.length)}
          <ion-popover class="component-search-word-popover" trigger="${id}" trigger-action="click">
            <ion-content class="ion-padding"><ion-text>${locales._get("content.search-word-explain", currentSearch)}</ion-text></ion-content>
          </ion-popover>
          `
        }
        return searchWord
      })
    }
    return word
  })
  return newWords.join(" ")
}