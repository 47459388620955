import { useEffect, useState } from "react"
import { IonContent, IonModal } from "@ionic/react"

import { AlertProps } from "types/Commons/Alerts"
import { KonosubaDance } from "types/Anime/Images"
import locales from "config/Locales"
import OverCard from "components/Content/OverCard"
import AniHeader from "pages/_commons/AniHeader"
import UserBar from "components/Users/UserBar"

const AlertUserRequired = (props: AlertProps) => {
  const [showAlert, setShowAlert] = useState<boolean>(false)

  useEffect(() => setShowAlert(props.showAlert), [props.showAlert])

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => props.onShowAlert && props.onShowAlert(showAlert), [showAlert])

  return (
    <>
      <IonModal
        isOpen={showAlert}
        onDidDismiss={() => setShowAlert(false)}
        onDidPresent={() => setShowAlert(true)}
        className="component-alert-with-ads window-modal"
      >
        <IonContent className="">
          <AniHeader
            showLogo={false}
            onClick={() => setShowAlert(false)}
          />
          <OverCard
            image={KonosubaDance}
            title={locales._get("commons.alerts.user-required")}
          />
          <UserBar />
        </IonContent>
      </IonModal>
    </>
  )
}

export default AlertUserRequired