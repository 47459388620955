import { NotFoundImg } from "types/Anime/Images"
import OverCard from "components/Content/OverCard"
import locales from "config/Locales"

const NoResults = () => {
  return (
    <OverCard
      title={locales._get("content.search-results-empty")}
      image={NotFoundImg}
    />
  )
}

export default NoResults