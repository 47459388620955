import { Storage } from "@ionic/storage"

import { StorageUtilsProps } from "types/Utils/Storage"

const store = new Storage()
store.create()

export class StorageItems {
  public static setItem = async (key: string, value: string) => {
    await store.create()
    try {
      return store.set(key, value)
    } catch (e) {
      //isDev && console.warn("StorageItems.setItem Error:", e)
      return false
    }
  }

  public static getItem = async (key: string): Promise<string | null> => {
    await store.create()
    return store.get(key)
  }

  public static removeItem = (key: string): boolean => {
    if (!StorageItems.getItem(key)) return false

    store.remove(key)
    return true
  }

  public static getKey = async (index: number): Promise<string | null> => {
    const keys = await store.keys()
    return keys[index] || null
  }

  public static getLength = (): Promise<number> =>
    store.length()

  public static getCachedKeys = async (): Promise<string[]> => {
    const keys: string[] = []
    for (let i = 0; i < await StorageItems.getLength(); i++) {
      const key = await StorageItems.getKey(i)
      if (key) keys.push(key)
    }
    return keys
  }

  public static usedKeyStorage = (key: string): number => //kb
    ((window.localStorage[key]?.length * 16) / (8 * 1024))

  public static usedStorage = async (): Promise<number> => {//kb
    let total = 0
    for (let i = 0; i < await StorageItems.getLength(); i++) {
      const key = await StorageItems.getKey(i)
      if (key) total += StorageItems.usedKeyStorage(key)
    }
    return total
  }

  public static maxSizeStorage = async (): Promise<number> => {//kb
    const quota = await navigator.storage.estimate()
    return (quota.quota || 0) / 1024
  }

  public static getStatsStorage = async (): Promise<StorageUtilsProps> => {
    if (!store) return {} as StorageUtilsProps

    const used: number = await StorageItems.usedStorage()
    const maxSize: number = await StorageItems.maxSizeStorage()
    return {
      available: {
        space: maxSize - used,
        percent: (used / maxSize) * 100,
      },
      used: {
        space: used,
        percent: (used / maxSize) * 100,
      },
      maxSize: maxSize,
      isFull: used >= maxSize,
    } as StorageUtilsProps
  }
}