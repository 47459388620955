import { useEffect } from "react"
import { IonButton } from "@ionic/react"
import { cloudUpload, happy, phonePortrait } from "ionicons/icons"
import { useHistory } from "react-router"
import { Swiper, SwiperSlide } from "swiper/react"
import "swiper/scss"
import "@ionic/react/css/ionic-swiper.css"

import { NormalLogoSrc } from "types/Anime/Images"
import { WelcomeSlide } from "./WelcomeSlide"
import MenuOptions from "utils/MenuOptions"
import useAds from "store/useAds"
import User from "components/Users/User"
import VideoPlayer from "components/Media/VideoPlayer"
import locales from "config/Locales"

import "./index.scss"

const Welcome = () => {
  const hideMenu = MenuOptions((state: any) => state.hideMenu)
  const showMenu = MenuOptions((state: any) => state.showMenu)
  const history = useHistory()
  const setShowBanner = useAds((state: any) => state.setShowBanner)

  const gotoContent = () => {
    history.replace("/anime")
    if (User.isGold() || User.isPremium()) setShowBanner(false)
    showMenu()
  }

  useEffect(() => {
    if (User.isFree()) setShowBanner(true)
    hideMenu()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <div className="page-welcome">
      <VideoPlayer
        sourceMP4="/assets/media/anime-welcome.mp4"
        maxRandomStartPosition={120}
      />
      <Swiper
        pagination={{ clickable: true }}
        speed={400}
        spaceBetween={10}
      >
        <SwiperSlide>
          <WelcomeSlide
            image={NormalLogoSrc}
            title={locales._get("welcome")}
          >
            <div dangerouslySetInnerHTML={{ __html: locales._get("welcome_desc") }}></div>
            <br />
            <IonButton
              onClick={() => gotoContent()}
              color="tertiary"
            >
              {locales._get("discover_new_anime")}
            </IonButton>
          </WelcomeSlide>
        </SwiperSlide>
        <SwiperSlide>
          <WelcomeSlide
            badge={cloudUpload}
            title={locales._get("save_cloud")}
          >
            {locales._get("save_cloud_desc")}
          </WelcomeSlide>
        </SwiperSlide>
        <SwiperSlide>
          <WelcomeSlide
            badge={phonePortrait}
            title={locales._get("use_devices")}
          >
            {locales._get("use_devices_desc")}
          </WelcomeSlide>
        </SwiperSlide>
        <SwiperSlide>
          <WelcomeSlide
            badge={happy}
            title={locales._get("give_reactions")}
          >
            {locales._get("give_reactions_desc")}
            <br />
            <IonButton
              onClick={() => gotoContent()}
              color="primary"
            >
              {locales._get("watch_anime")}
            </IonButton>
          </WelcomeSlide>
        </SwiperSlide>
      </Swiper>
    </div>
  )
}

export default Welcome