import { Redirect } from "react-router-dom"

import Splash from "pages/Splash"

const routes = [
  {
    path: "splash",
    component: <Splash />,
  },
  {
    path: "login",
    component: <Redirect to="/home" />,
  },
  {
    path: "signup",
    component: <Redirect to="/home" />,
  },
]

export default routes