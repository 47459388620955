import { IonToolbar, IonButtons, IonButton, IonIcon, IonText } from "@ionic/react"
import { closeOutline } from "ionicons/icons"

import { HeaderLogo } from "pages/_commons/AniHeader/HeaderLogo"
import { PickerModalProps } from "types/Commons/Picker"

const PickerModalToolbar = (props: PickerModalProps) => {
  return (
    <IonToolbar mode="md">
      <IonButtons slot="start">
        <IonButton
          onClick={() => props.modal?.current?.dismiss()}
          slot="start" id="modal-close">
          <IonIcon slot="icon-only" icon={closeOutline} />
        </IonButton>
        <IonText slot="end">
          <HeaderLogo />
        </IonText>
      </IonButtons>
    </IonToolbar>
  )
}

export default PickerModalToolbar