const en = {
  "appname": "Anime Play",
  "langcode": "en",
  "langname": "english",
  "settings.title-language": "Idioma del título",
  "settings.title-language-subtitle": "Main language of the title.",
  "settings.title-media-player": "Premium Player Template",
  "settings.title-media-player-subtitle": "This player is visible for Premium content.",
  "settings.title-media-player-pip": "Show PiP",
  "settings.title-media-player-pip-subtitle": "Enable floating video window (Picture in Picture).",
  "settings.parental-control": "Parental control",
  "settings.parental-control-subtitle": "Enable parental control to restrict content",
  "settings.parental-control-confirm": "By doing this you will have access to all the content in the app without restriction, do you want to continue?",
  "settings.show-alert-with-ads": "Show external ads alert",
  "settings.show-alert-with-ads-subtitle": "Show external ads alert in player.",
  "settings.show-alert-with-ads-confirm": "You will no longer see the external ads alert in the player, remember that the playback sources marked with 'ads' are EXTERNAL to Anime Play, do you want to continue?",
  "settings.show-notification-controls": "Controls in notification bar",
  "settings.show-notification-controls-subtitle": "Show video controls in notification bar.",
  "settings.history-save-limit": "Content history limit",
  "settings.history-save-limit-subtitle": "History limit to save in the app.",
  "settings.history-save-limit-confirm": "The established limit is less than the records in the app (<b>%s of %t</b>), this will cause the loss of data in your history, do you want to continue?",
  "settings.accept": "Accept",
  "settings.cancel": "Cancel",
  "settings.title-language-romaji": "Romaji",
  "settings.title-language-english": "English",
  "settings.title-language-spanish": "Spanish",
  "date.month_short_1": "Jan",
  "date.month_short_2": "Feb",
  "date.month_short_3": "Mar",
  "date.month_short_4": "Apr",
  "date.month_short_5": "May",
  "date.month_short_6": "Jun",
  "date.month_short_7": "Jul",
  "date.month_short_8": "Aug",
  "date.month_short_9": "Sep",
  "date.month_short_10": "Oct",
  "date.month_short_11": "Nov",
  "date.month_short_12": "Dec",
  "login": "Log in",
  "logout": "Log out",
  "signup": "Sign up",
  "network.metered-alert": "You are using a metered network. Please be aware that you may be charged for data usage.",
  "network.unavailable-alert": "You are not connected to the internet. Please check your connection and try again.",
  "network.ok": "Ok",
  "change_avatar": "Change avatar",
  "loginfailed": "Login failed",
  "sendfailed": "Send failed",
  "passwordfail": "Password mismatch",
  "passwordminfail": "Password not secure",
  "emailfail": "Email incorrect",
  "your_name": "Your name",
  "username_placeholder": "Username",
  "username_email_placeholder": "Username/Email",
  "email_placeholder": "Email",
  "password_placeholder": "Password",
  "password_confirm_placeholder": "Confirm password",
  "password_min_complex": "<b>Password min:</b><ul><li>8 characters minimum.</li><li>1 lowercase letter minimum.</li><li>1 uppercase letter minimum.</li></ ul>",
  "forgot_msg": "Enter your email address and we'll send you a link to reset your password.",
  "signup_msg": "Enter required information to create your account.",
  "signin_msg": "Enter your username and password to have full access.",
  "signing_in": "Signing in...",
  "signing_up": "Signing up...",
  "forgot_password": "Forgot password?",
  "remember_msg": "If your %s account is correct, you will receive an email with your new temporary password.",
  "send_email": "Send email",
  "user.changing": "Changing user...",
  "user.setting-avatar": "Changing avatar...",
  "latest-version": "latest version",
  "latest-version.title": "The version %s of %t is now available.",
  "latest-version.desc": "Enter any of these options to download the latest version of your favorite anime app:",
  "latest-version.your-version": "Your version of %s",
  "latest-version.unkonown": "Unknown version",
  "latest-version.latest": "Available version",
  "latest-version.download": "Available download %s",
  "latest-version.google-play": "Google Play",
  "latest-version.xiaomi": "Xiaomi Store",
  "latest-version.github": "Github",
  "latest-version.website": "Website",
  "commons.alerts.user-required": "You must be logged in to access this section.",
  "commons.alerts.with-ads": "The selected medium includes ADS EXTERNAL to Anime Play, if you want to disable this notification go to app settings.",
  "commons.alerts.close": "Close",
  "commons.alerts.retry": "Retry",
  "welcome": "Welcome",
  "welcome_desc": "You have already logged in to our app, now you can enjoy its full potential. Scroll <span>right</span> for more information.",
  "discover_new_anime": "Discover new anime",
  "save_cloud": "Save everything in the cloud",
  "save_cloud_desc": "We have our own data storage servers, so you can be sure that you will always have your history and configuration available at hand.",
  "use_devices": "Use your account on different devices",
  "use_devices_desc": "With the same user you can enter from several devices and synchronize all your progress in real time.",
  "give_reactions": "Give reactions to your favorite content",
  "give_reactions_desc": "You can express your opinion! Whether or not you like a piece of content, you can tell everyone how you feel. You can also follow your favorite content and you will see only what interests you.",
  "watch_anime": "Watch anime",
  "loading": "Loading...",
  "success": "Success!",
  "ok": "OK",
  "releases": "Releases",
  "discover": "Discover",
  "search": "Search",
  "news": "News",
  "genders": "Genders",
  "studios": "Studios",
  "store": "Anime Play Store",
  "share-app": "Share app",
  "rate-app": "Rate 5 stars",
  "view-playstore": "View in Play Store",
  "download-app": "Download App",
  "profile": "Profile",
  "about": "About the app",
  "tos": "Privacy policy",
  "changelog": "Changelog",
  "support": "Support",
  "footer-text": "Mis Gamers | Anime Play %s",
  "version": "Version %s",
  "new_in": "New in %s",
  "new_content": "New episodes",
  "popular_content": "Popular in %s",
  "episode": "Episode",
  "for_you": "New for you",
  "continue_watching": "Continue watching",
  "error_loading": "Error loading content.",
  "see_more": "See more",
  "loading_content": "Loading %s...",
  "content": "Content",
  "slides": "Slides",
  "more_from_studio": "More from %s",
  "subtitle-view-gender": "Gender list",
  "subtitle-view-studio": "Studio list",
  "app_settings": "General settings",
  "no_following": "Any follow yet?",
  "no_following_subtitle": "Follow content to see it here!",
  "last_call": "Last call",
  "error.content-title-status": "There was an error!",
  "error.content-description-status": "Failed to connect to the server, please try again.",
  "error.content-title": "The connection failed!",
  "error.content-description": "Please check your internet and try again.",
  "error.modal-title": "Error report",
  "error.modal-subtitle": "Administrators only",
  "error.description": "Error description",
  "error.no-description": "No description",
  "error.end-point": "End point",
  "error.no-end-point": "No end point",
  "error.body": "Body",
  "error.no-body": "No body",
  "error.headers": "Headers",
  "error.no-headers": "No headers",
  "error.status-code": "Status code",
  "error.no-status-code": "No status code",
  "error.can-send": "You can send this report.",
  "error.can-send-description": "By clicking the button below you will send this report to the %s developers.",
  "error.send": "Send report",
  "error.sending": "Sending report...",
  "error.sent": "Report sent!",
  "error.not-sent": "Report not sent!",
  "date.now": "Now",
  "date.today_from_now": "Today",
  "date.today_ago": "Yesterday",
  "date.seconds_ago": "%s seconds ago",
  "date.minutes_ago": "%s minutes ago",
  "date.hours_ago": "%s hours ago",
  "date.days_ago": "%s days ago",
  "date.months_ago": "%s months ago",
  "date.years_ago": "%s years ago",
  "date.second_ago": "One second ago",
  "date.minute_ago": "One minute ago",
  "date.hour_ago": "One hour ago",
  "date.day_ago": "Yesterday",
  "date.month_ago": "One month ago",
  "date.year_ago": "One year ago",
  "date.seconds": "%s seconds",
  "date.minutes": "%s minutes",
  "date.hours": "%s hours",
  "date.days": "%s days",
  "date.months": "%s months",
  "date.years": "%s years",
  "date.second": "a second",
  "date.minute": "a minute",
  "date.hour": "an hour",
  "date.month": "a month",
  "date.year": "a year",
  "date.seconds_from_now": "In %s seconds",
  "date.minutes_from_now": "In %s minutes",
  "date.hours_from_now": "In %s hours",
  "date.days_from_now": "In %s days",
  "date.months_from_now": "In %s months",
  "date.years_from_now": "In %s years",
  "date.second_from_now": "In one second",
  "date.minute_from_now": "In one minute",
  "date.hour_from_now": "In one hour",
  "date.day_from_now": "Tomorrow",
  "date.month_from_now": "In one month",
  "date.year_from_now": "In one year",
  "date.never": "never",
  "date.unknown": "unknown",
  "pager.not-found": "No content found.",
  "pull_refresh": "Pull to refresh content",
  "refreshing": "Refreshing...",
  "refreshed": "Refreshed content.",
  "not_refreshed": "Error refreshing content.",
  "last_episode": "Last episode",
  "more_episodes": "More episodes",
  "news.soon": "Soon",
  "news.in-construction": "In construction news section.",
  "content.type-tv": "Serie",
  "content.type-pela-cul": "Movie",
  "content.type-ova": "OVA",
  "content.type-especial": "Special",
  "content.type-": "Without specify",
  "content.back": "Back",
  "content.no-staff": "Without staff",
  "content.no_watching": "Nothing seen?",
  "content.no_watching_subtitle": "Search content to watch it here!",
  "content.watch": "Watch now",
  "content.watch-at": "Watch episode %s/%t",
  "content.download": "Download",
  "content.download-at": "Download episode %s/%t",
  "content.download-message": "You will access an external page, we are not responsible for the availability of content, do you want to download episode %s of %t?",
  "content.download-cancel": "Cancel",
  "content.expore": "Explore %s",
  "content.search": "Search",
  "content.search-placeholder": "What do you want to search?",
  "content.search-explain": "Search content by title or description in the field above.",
  "content.search-explain-min-length": "The search must have at least %s characters.",
  "content.search-results": "Search results",
  "content.search-results-empty": "No results found.",
  "content.search-word-explain": "This is a search word <b>\"%s\"</b> that matches the content.",
  "content.search-see": "See listing",
  "content.search-lasts": "Last searches",
  "content.relevance": "Relevance",
  "content.search-not-relevant": "Not relevant",
  "content.search-not-relevant-subtitle": "Search does not match the results as of this point.",
  "content.relevance-what-is": "What is relevance?",
  "content.relevance-what-is-desc": "Relevance is an algorithm that finds the most relevant results for your search based on an index.<br /><br />These indices are classified into: <br /><p><b class=\"%c1\">High:</b> %1a or more.</p><p><b class=\"%c2\">Medium:</b> %2a to less than %1b.</p><p><b class=\"%c3\">Low:</b> %3 to less than %2b.</p><p><b class=\"%c4\">Very low:</b> less than %4.</p> <br />The relevance index is calculated based on the following criteria: <br /><p> - Gives a higher criterion if it is found in the title and its languages .</p><p> - Slightly lower if found in description.</p><p> - Lowest if found in additional fields.</p><p> - Gives the lowest criteria high if the query is exact.</p><p> - Also, gives a high criteria if all words are found in the field.</p><p> - A little lower if at least one word is found .</p><br />If your search matches one or more of these criteria, the result will have a higher relevance index.",
  "content.tab-description": "Description",
  "content.tab-episodes": "Episodes",
  "content.tab-information": "Information",
  "content.tab-trailer": "Trailer",
  "content.tab-staff": "Staff",
  "content.tab-characters": "Characters",
  "content.tab-anime": "Anime",
  "content.tab-recommendations": "Recommendations",
  "content.tab-related": "Related",
  "content.tab-comments": "Comments",
  "content.tab-show-on": "Show on",
  "content.tab-voice-by": "Voice by",
  "content.episode-item": "Episode %s",
  "content.episode-item-at": "Episode %s of %t",
  "content.episode-item-seen": "Seen on %s",
  "content.episode-ads-0": "No ads",
  "content.episode-ads-1": "Low",
  "content.episode-ads-2": "Moderated",
  "content.episode-ads-3": "High",
  "content.more-genders": "More genders",
  "content.no-genders": "Not registered genders",
  "content.see-content": "See content",
  "content.no-studio": "No studio",
  "content.media-list": "Available sources",
  "content.media-previous": "Previous",
  "content.media-next": "Next",
  "content.media-changing": "Changing episode",
  "content.media-ads": "Ads",
  "content.media-player": "Here you will see content of %s",
  "content.media-marked-as-seen": "Marked as seen",
  "content.no-media": "No media available",
  "content.no-media-selected": "No media selected",
  "content.details-title-spanish": "Title (spanish)",
  "content.details-title-english": "Title (english)",
  "content.details-title-romaji": "Title (japanese)",
  "content.details-title-others": "Other titles",
  "content.details-studio": "Studio",
  "content.details-studios": "Other studios",
  "content.details-season": "Season",
  "content.details-is-finished": "Finished",
  "content.details-is-not-finished": "In emission",
  "content.details-last-episode": "Last episode",
  "content.details-last-seen": "My last seen is: %s",
  "content.details-next-episode": "Next episode",
  "content.details-start-date": "Started in",
  "content.details-end-date": "Ended in",
  "content.details-end-date-at": "Ended at",
  "content.details-launch-date": "Airing in",
  "content.details-parental-control": "Restricted content",
  "content.details-parental-control-description": "This content is classified for adults, if you want to unlock this content configure in the app options.",
  "content.season-FALL": "Fall",
  "content.season-SUMMER": "Summer",
  "content.season-WINTER": "Winter",
  "content.season-SPRING": "Spring",
  "content.season-": " ",
  "content.no-last-seen": "Not seen yet",
  "content.last-seen-at": "Last seen at %s episode %t",
  "content.is-18": "Adult content",
  "content.is-18-short": "18+",
  "content.watch-trailer": "Watch trailer",
  "content.watch-trailer-fullscreen": "Fullscreen",
  "content.watch-trailer-on": "Watch trailer on %s",
  "content.watch-trailer-external-media": "external media",
  "content.not-recommendations": "No recommendations",
  "content.not-related": "No related content",
  "content.not-characters": "No characters",
  "content.share-email": "Share by email",
  "content.share-sms": "Share by SMS",
  "content.share-copy": "Copy link",
  "content.share-copy-success": "Link copied to clipboard",
  "content.share-other": "Other options",
  "content.comments": "Comments",
  "content.no_comments": "No comments",
  "content.comments-notice": "Comments are moderated, so they may take time to appear. Remember to be respectful of others, otherwise permission to comment will be restricted.",
  "content.comment-placeholder": "Write a comment...",
  "content.comment-input-label": "You are in episode %s",
  "content.comment-sending": "Sending comment...",
  "content.comment-error": "Error sending comment.",
  "content.comment-published": "Your comment has been published.",
  "content.comment-moderated": "Your comment has been published, but it must be moderated.",
  "content.anonymous": "Anonymous",
  "content.following": "You are following %s",
  "content.not-following": "You stop following %s",
  "content.following-error": "Failed to update follow to %s",
  "content.wave-title": "This counter indicates the remaining time for this content to be marked as seen, it will run automatically and show you a notification.",
  "content.premium": "Premium",
  "profile.history-title": "My history",
  "profile.history-subtitle": "Here you can see your browsing history.",
  "profile.myhistory": "My history",
  "profile.no-history": "No history",
  "profile.no-history-subtitle": "Your browsing history will be saved here.",
  "profile.history-type-content": "Seen anime",
  "profile.history-type-episode": "Episode %s watched %t",
  "profile.history-type-news": "Seen news",
  "profile.history-type-character": "Seen character",
  "profile.history-type-staff": "Seen staff",
  "profile.settings-content": "Content settings",
  "profile.settings-media-player": "Premium Player Settings",
  "profile.settings-title": "General settings",
  "profile.settings-startup": "Startup settings",
  "profile.settings-initial-page": "Initial page",
  "profile.settings-initial-page-subtitle": "Page you want to see when you open the app.",
  "settings.initial-page-default": "Default",
  "profile.settings-subtitle": "Here you can configure your preferences.",
  "profile.settings-edit": "Edit this field",
  "profile.settings-loading-storage": "Loading storage information...",
  "profile.settings-storage-limits": "Internal storage limits",
  "profile.settings-storage-limit-db": "Use max %s of internal storage",
  "profile.settings-storage-limit-db-used": "Storage used: %s of %t => %p%",
  "profile.settings-storage-limit-db-available": "Available storage: %s => %p%",
  "profile.settings-storage-limit": "Use maximum internal storage",
  "profile.settings-storage-limit-subtitle": "Minumum possible: %s%",
  "profile.settings-localstorage-limits": "Cache limits",
  "profile.settings-localstorage-limit-db": "Use max %s of cache",
  "profile.settings-localstorage-limit-db-used": "Cache used: %s of %t => %p%",
  "profile.settings-localstorage-limit-db-available": "Available cache: %s => %p%",
  "profile.settings-localstorage-limit": "Use maximum cache",
  "profile.settings-localstorage-limit-subtitle": "Minumum possible: %s%",
  "profile.settings-search": "Search settings",
  "profile.settings-search-clear": "Clear recent search",
  "profile.settings-search-clear-subtitle": "Check this option to clear the recent search field.",
  "profile.settings-search-limit": "Search history limit",
  "profile.settings-search-limit-subtitle": "History limit to save in the app.",
  "profile.settings.templates.default": "Default",
  "profile.settings.templates.city": "Urban",
  "profile.settings.templates.fantasy": "Fantasy",
  "profile.settings.templates.forest": "Minimal",
  "profile.settings.templates.sea": "Bubble",
  "component.infinite.end-of-list": "There is not content left.",
  "component.infinite.item-count": "Content loaded",
  "component.infinite.item-count-single-result": "One result",
  "component.infinite.item-count-result": "%s results",
  "component.header-toolbar-text": "menu",
  "component.picker-no-items": "Nothing to show.",
  "filters.title": "Filters",
  "filters.all": "All",
  "filters.premium": "Premium",
  "filters.premium-explain": "Show only content that always has available sources, this guarantees that you will find media to play.",
  "filters.pending-see": "Pending to see",
  "filters.content-type": "Content type",
  "videojs.not-supported-message": "This device does not support video player.",
  "videojs.skip": "Skip in %s",
  "videojs.skip-now": "Skip now",
  "videojs.next-episode-in": "Next episode in %s",
  "videojs.error-network": "Network error, try again.",
  "videojs.ok": "Ok",
  "videojs.continue": "Continue from %s",
  "videojs.at-start": "At start",
  "ads.loading": "Loading sponsored space...",
  "ads.click-here": "Click here for more information",
  "ads.remove-title": "Remove sponsored space",
  "ads.remove-title-fail": "Failed to remove sponsored space, there may be no ads available to display, please try again later.",
  "ads.remove-title-success": "You have removed this ad for a limited time, time is added every time you see an ad again. Please update the app to take effect.",
  "ads.remove-button-in-banner": "Remove these ads <b>%s</b> watching a video",
  "ads.remove-button": "Remove these ads",
  "ads.remove-button-passed": "Add time (%s)",
  "ads.remove-text-top": "Remove ad space <b>%s</b> watching a video. Click on the type of ad you want to remove.",
  "ads.remove-text-warning": "<b>NOTE:</b> If a fraction of an hour is added when watching a video, it means that only a small ad was shown. This usually indicates that there are no videos available to display.",
  "ads.banner-large": "Large banner",
  "ads.remove-text-large": "Large banner ad format and other similar sizes. Clicking this button will hide it for <b>%s</b>.",
  "ads.banner-content": "Banner in content",
  "ads.remove-text-content": "Banner ad format in content details. Clicking on this button will hide it by <b>%s</b>.",
  "ads.banner-video": "Video ad",
  "ads.remove-text-video": "Video ad format when entering content. Clicking on this button will hide it by <b>%s</b>.",
  "ads.remove-only-app": "This modality is only available in the mobile app, we invite you to install our app from the available platforms.",
}

export default en