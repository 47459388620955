import { Suspense, useEffect, useState } from "react"

import { Endpoints } from "api/Endpoints"
import { paramsCheckSlides } from "api/routes/params/Content"
import { Responses } from "types/Connect"
import { Slide } from "types/Media/Slider"
import Connect from "components/Connect"
import useHeaderSlides from "store/useHeaderSlides"
import SlideShow from "components/Media/SlideShow"
import AnimeContentParser from "../AnimeContent"
import locales from "config/Locales"
import useAnime from "store/useAnime"
import Loader from "pages/_commons/Loader"

import "./index.scss"

const HeaderSlides = () => {
  const [slides, setSlides] = useState<Slide[]>([])
  const [loaded, setLoaded] = useState<boolean>(false)
  const initialSlide = useHeaderSlides((state: any) => state.initialSlide)
  const setInitialSlide = useHeaderSlides((state: any) => state.setInitialSlide)
  const setIsOpen = useAnime<Function>((state: any) => state.setIsOpen)
  const setId = useAnime<Function>((state: any) => state.setId)

  useEffect(() => {
    if (loaded) return

    setLoaded(true)
    const getSlidesRemote = async () => {
      Connect.postcached({ endpoint: Endpoints.slides, options: paramsCheckSlides }).then((res: Responses) => {
        setSlides(res.data.results as Slide[])
        if (initialSlide > res.data.results.length - 1) setInitialSlide(res.data.results.length - 1)
      }).catch(() => {
        setTimeout(() => {
          getSlidesRemote()
        }, 5000)
      })
    }
    getSlidesRemote()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loaded])

  useEffect(() => {
    slides?.map((item: Slide) =>
      item = AnimeContentParser.fitSlideContent({
        item,
        setIsOpen,
        setId,
      }))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [slides])

  return (
    <Suspense fallback={<><Loader text={locales._get("slides")} /></>}>
      <SlideShow
        slides={slides}
        initialSlide={initialSlide || 0}
        className="component-header-slides"
        slideHeight="auto"
        onSlideDidChange={(e: any) => setInitialSlide(e.srcElement.swiper.realIndex)}
      />
    </Suspense>
  )
}

export default HeaderSlides