export type HeaderProps = {
  name: string,
  html?: React.ReactNode,
  className?: string,
  color?: string,
  slot?: string,
  startButtons?: React.ReactNode,
  endButtons?: React.ReactNode,
  primaryButtons?: React.ReactNode,
  secondaryButtons?: React.ReactNode,
}

export const defaultHeaderProps: HeaderProps = {
  name: "Header",
  className: "",
  html: "",
  color: "primary",
  slot: "start",
  startButtons: null,
  endButtons: null,
  primaryButtons: null,
  secondaryButtons: null,
}