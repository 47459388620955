import { IonInput, IonItem } from "@ionic/react"

const FormItem = ({ ...props }) => {
  const { className, value, type, autocomplete, maxlength, required, ...rest } = props
  return (
    <IonItem>
      <IonInput
        value={value}
        type={type || "text"}
        className={`component-form-item ${className}`}
        autocomplete={autocomplete || "off"}
        autofocus={true}
        clearInput={true}
        maxlength={maxlength || 40}
        required={required || true}
        {...rest}
      />
    </IonItem>
  )
}

export default FormItem