import { IonCol, IonGrid, IonRow } from "@ionic/react"

import { UserProfile } from "types/User"
import { UserAvatar } from "../UseAvatar"
import { setAvatar } from "./setAvatar"
import locales from "config/Locales"
import Modal from "components/Commons/Modal"
import settings from "config/Settings"

export const mapModalUsers = (props: {
  users: UserProfile[],
  randomModalIdAvatar: string,
  closeModal: Function,
  setUsers: Function,
}) => {
  const { setUsers, closeModal } = props

  return props.users?.map((user: UserProfile, index: number) => {
    return (
      <Modal
        id={`${props.randomModalIdAvatar}-${index}`}
        color="dark"
        title={`${locales._get("change_avatar")} / ${user?.username}`}
        scroll={true}>
        <IonGrid className={`component-user-profile-avatars ${props.randomModalIdAvatar}-${index}`}>
          <IonRow className="ion-justify-content-center">
            {Object.values(settings.avatars).map((avatar: string) => {
              return (
                <IonCol
                  size="4" sizeMd="3">
                  <UserAvatar avatar={avatar}
                    className={user?.avatar === avatar && "active-img"}
                    onClick={() => setAvatar({ user, avatar, setUsers, closeModal, index })}
                  />
                </IonCol>
              )
            })}
          </IonRow>
        </IonGrid>
      </Modal>
    )
  })
}