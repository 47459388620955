import { NotFoundImg } from "types/Anime/Images"
import OverCard from "components/Content/OverCard"
import locales from "config/Locales"

const PagerEmtpy = () => {
  return (
    <OverCard
      title={locales._get("pager.not-found")}
      image={NotFoundImg}
    />
  )
}

export default PagerEmtpy