import { Responses } from "types/Connect"
import { Endpoints } from "api/Endpoints"
import { StorageItems } from "utils/Storage/StorageItems/Storage"
import { AnimeEpisodeDetails, AnimeEpisodes } from "types/Anime"
import { AnimeToggleViewedProps } from "types/Anime/AnimeToggleViewed"
import { getQueryFormat, getQueryLocal } from "components/Connect/Exports"
import { universalDateFormat } from "types/Utils/Date"
import AnimeContentParser from "pages/_commons/Content/AnimeContent"
import Connect from "components/Connect"
import JDate from "utils/JDate"

export const modifyDetailsEpisodes = async (props: AnimeToggleViewedProps): Promise<AnimeEpisodes> => {
  const query = Connect.getQuery({
    endpoint: Endpoints.detailsepisodes,
    params: { id: props.cid },
  })
  const queryFormat = getQueryFormat(query)
  const cachedEpisodes: Responses = await getQueryLocal(queryFormat)
  if (cachedEpisodes?.data) {
    const episodes: AnimeEpisodes = AnimeContentParser.fitAnimeEpisodes(cachedEpisodes.data?.results) || {} as AnimeEpisodes
    const episodeIndex: number = episodes.episodes?.findIndex((episode: AnimeEpisodeDetails) => episode.episode === props.serie) || -1
    const seenDate: string = props.seen ? JDate.format(JDate.getToday(), universalDateFormat) : ""
    let episode: AnimeEpisodeDetails = {} as AnimeEpisodeDetails
    if (episodeIndex > -1) {
      episode = episodes.episodes[episodeIndex]
      episodes.episodes[episodeIndex].seen_date = seenDate
    }
    episodes.last_seen_date = seenDate
    const viewedIndex: number = episodes.viewed?.findIndex((viewed: AnimeEpisodeDetails) => viewed.episode === props.serie) || -1 as number
    if (props.seen) {
      episodes.last_seen = props.serie
      if (viewedIndex === -1)
        episodes.viewed.push({
          eid: episode.eid || props.eid,
          episode: episode.episode || props.serie,
          hits: episode?.hits || 0,
          seen_date: seenDate,
        } as AnimeEpisodeDetails)
      else {
        episodes.viewed[viewedIndex].seen_date = seenDate
        episodes.viewed[viewedIndex].seenDateStr = JDate.ago(JDate.timestamp(seenDate), undefined, false)
      }
    } else {
      if (episodes.viewed?.length === 1 && episodes.viewed[0].eid === props.eid) episodes.viewed = [] as AnimeEpisodeDetails[]
      else if (viewedIndex > -1)
        episodes.viewed.splice(viewedIndex, 1)
      const lastSeen: AnimeEpisodeDetails = episodes.viewed[episodes.viewed.length - 1] || {} as AnimeEpisodeDetails
      episodes.last_seen = lastSeen?.episode || episodes.last_seen
    }
    episodes.next_to_see = episodes.last_seen + 1 <= episodes.last_episode ? episodes.last_seen + 1 : episodes.last_episode
    await StorageItems.setItem(queryFormat, JSON.stringify({
      ...cachedEpisodes,
      data: episodes,
    }))
    return episodes
  }
  return {} as AnimeEpisodes
}