import { AnimeEpisodes, AnimeEpisodesMediaTypes, moveEpisodeProps } from "types/Anime"
import { Endpoints } from "api/Endpoints"
import Connect from "components/Connect"
import AnimeContentParser from "pages/_commons/Content/AnimeContent"

export const moveEpisode = async (params: moveEpisodeProps): Promise<AnimeEpisodes> => {
  if (!params.eserie) return params.episodes || {} as AnimeEpisodes
  const cid: number = params.episodes?.cid || params.cid || 0
  if (cid === 0) return params.episodes || {} as AnimeEpisodes
  params.setLoadingNextPrev && params.setLoadingNextPrev(true)
  if (params.keepEpisodesPassed !== true || !params.episodes?.media?.current?.eid) {
    const newEpisodes = await Connect.postcached({
      endpoint: Endpoints.detailsepisodesmedia,
      params: { id: cid, eidserie: params.eserie },
      options: {
        force: params.force || false,
        checkInterval: "day",
      }
    })
    if (params.setLoadingNextPrev && params.episodes) params.episodes.media = newEpisodes.data?.results?.media as AnimeEpisodesMediaTypes || params.episodes.media
  }
  return AnimeContentParser.fitAnimeEpisodes(params.episodes || {} as AnimeEpisodes)
}