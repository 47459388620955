import { Capacitor } from "@capacitor/core"
import { Network } from "@ionic-native/network"

export const isMeteredNetwork = async () => {
  if (!Capacitor.isNativePlatform()) return false

  const networkType = await Network.type
  return networkType === Network.Connection.CELL_2G ||
    networkType === Network.Connection.CELL_3G ||
    networkType === Network.Connection.CELL_4G ||
    networkType === Network.Connection.CELL
}