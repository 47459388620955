import { useCallback, useEffect, useState } from "react"
import { IonModal } from "@ionic/react"

import { AnimeModalContentTypes } from "types/Anime/AnimeModalContent"
import { AdLoadInfo } from "types/Media/Ads/AdMob"
//import { AdLoadInfo } from "@capacitor-community/admob"
import { SoftnyaAdsBannerItem } from "types/Media/Ads/SoftnyaAds"
import LoaderOptions from "components/Content/ScreenLoader/LoaderOptions"
import AdsVideo from "components/Media/Ads/Video"
import Anime from "pages/Discover/Anime"
import useAds from "store/useAds"
import useAnime from "store/useAnime"
import CharactersStaff from "pages/Discover/CharactersStaff"
import NewsDetails from "pages/Discover/NewsDetails"

export const ModalContent = (props: { type?: AnimeModalContentTypes }) => {
  const type = props.type || "details"
  const setShowBanner = useAds((state: any) => state.setShowBanner)
  //const setShowSoftnyaVideo = useAds((state: any) => state.setShowSoftnyaVideo)
  //const setSoftnyaAds = useAds((state: any) => state.setSoftnyaAds)
  const softnyaAds = useAds((state: any) => state.softnyaAds)
  const isOpen: boolean = useAnime((state: any) => {
    if (type === "characters") return state.isOpenCharacter
    if (type === "staff") return state.isOpenStaff
    if (type === "news") return state.isOpenNews
    return state.isOpen
  })
  const setIsOpenDetails = useAnime<Function>((state: any) => {
    return state.setIsOpen
  })
  const setIsOpenNews = useAnime<Function>((state: any) => {
    return state.setIsOpenNews
  })
  const setIsOpen = useAnime<Function>((state: any) => {
    if (type === "characters") return state.setIsOpenCharacter
    if (type === "staff") return state.setIsOpenStaff
    if (type === "news") return state.setIsOpenNews
    return state.setIsOpen
  })
  const id: number = useAnime((state: any) => {
    if (type === "characters") return state.chid
    if (type === "staff") return state.stid
    if (type === "news") return state.nid
    return state.id
  })
  const eid: number = useAnime((state: any) => state.eid)
  const chid: number = useAnime((state: any) => state.chid)
  const stid: number = useAnime((state: any) => state.stid)
  const [ads] = useState(new AdsVideo())

  useEffect(() => {
    if (type !== "news") return
    if (isOpen) setIsOpenDetails(false)
  }, [type, isOpen, setIsOpenDetails])

  const checkPath = useCallback(async () => {
    const path = window.location.pathname
    const _id = id > 0 ? id : (parseInt(path.split("/")[2]) || 0)
    if (type === "details" && _id > 0 && path.split("/")[1] === "anime") {
      setIsOpenDetails(true)
    }
    if (type === "news" && _id > 0 && path.split("/")[1] === "news") {
      setIsOpenNews(true)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id, type, setIsOpenDetails])

  useEffect(() => {
    checkPath()
  }, [id, eid, chid, stid, type, checkPath])

  return (
    <IonModal
      isOpen={isOpen}
      onIonModalDidDismiss={() => setIsOpen(false)}
      onIonModalWillPresent={async () => {
        setShowBanner(false)
        if (softnyaAds.id && ads.SoftnyaAds?.withAds()) LoaderOptions.openLoader("ads.loading")
        ads.showVideo((withAds: boolean) => {//onLoad
          if (withAds) LoaderOptions.openLoader("ads.loading")
          else LoaderOptions.closeLoader()
        }, (info: AdLoadInfo, video?: SoftnyaAdsBannerItem) => {//onLoaded
          if (video) {//dont show softnya video
            //setShowSoftnyaVideo(true)
            //setSoftnyaAds(video)
          }
          LoaderOptions.closeLoader()
        })
      }}
      onIonModalWillDismiss={() => setShowBanner(true)}
    >
      {isOpen &&
        <>
          {type === "details" && <Anime id={id} eid={eid} />}
          {type === "characters" && <CharactersStaff chid={chid} type="characters" />}
          {type === "staff" && <CharactersStaff stid={stid} type="staff" />}
          {type === "news" && <NewsDetails id={id} />}
        </>
      }
    </IonModal>
  )
}