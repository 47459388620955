import { IonButton, IonCol, IonIcon, IonLabel } from "@ionic/react"
import { cameraOutline, swapHorizontalOutline } from "ionicons/icons"

import { UserProfile } from "types/User"
import { UserAvatar } from "../UseAvatar"
import { changeUser } from "./changeUser"
import User from "components/Users/User"

export const mapUsers = (props: {
  users: UserProfile[],
  maxCols: number,
  randomModalIdAvatar: string,
  history: any,
  openModal: Function,
}) => {
  const { history } = props

  return props.users?.map((user: any, index: number) => {
    return (
      <IonCol size={props.maxCols.toString()}>
        <UserAvatar
          avatar={user?.avatar}
          id={`user-avatar-${index}`}
          className={user?.username === User.getUsername() && "active-user"}
          startNode={
            <>
              <IonButton
                shape="round"
                className="change-avatar"
                id={`${props.randomModalIdAvatar}-${index}`}
                onClick={() => {
                  props.openModal(props.randomModalIdAvatar)
                }}
              >
                <IonIcon icon={cameraOutline} />
              </IonButton>
              {props.users.length > 1 && <IonButton
                shape="round"
                className="change-user"
                id={`${props.randomModalIdAvatar}-${index}`}
                onClick={() => changeUser({ user, history })}
              >
                <IonIcon icon={swapHorizontalOutline} />
              </IonButton>}
            </>
          }
        />
        <IonLabel className="username">{user?.username}</IonLabel>
      </IonCol>
    )
  })
}