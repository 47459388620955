import { PublicUrlImg } from "types/Anime/Images"

const AvatarPath = `${PublicUrlImg}avatar/female/`

export const AvatarFemale = {
  "avatarfemale1": `${AvatarPath}avatar1.jpg`,
  "avatarfemale2": `${AvatarPath}avatar2.jpg`,
  "avatarfemale3": `${AvatarPath}avatar3.jpg`,
  "avatarfemale4": `${AvatarPath}avatar4.jpg`,
  "avatarfemale5": `${AvatarPath}avatar5.jpg`,
  "avatarfemale6": `${AvatarPath}avatar6.jpg`,
  "avatarfemale7": `${AvatarPath}avatar7.jpg`,
  "avatarfemale8": `${AvatarPath}avatar8.jpg`,
  "avatarfemale9": `${AvatarPath}avatar9.jpg`,
  "avatarfemale10": `${AvatarPath}avatar10.jpg`,
}