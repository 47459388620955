import Meta from "components/Media/Meta"
import Account from "components/Users/Account"
import locales from "config/Locales"
import PageContainer from "pages/_commons/PageContainer"

const Profile = () => {
  return (
    <PageContainer className="page-profile">
      <Account />
      <Meta
        title={locales._get("profile")}
      />
    </PageContainer>
  )
}

export default Profile