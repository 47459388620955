import { IonBreadcrumb, IonBreadcrumbs, IonIcon, IonText } from "@ionic/react"
import { chevronForwardOutline } from "ionicons/icons"

import { BaseBreadcrumbsProps, BaseBreadcrumbsItem, defaultBaseBreadcrumbsProps, BreadcrumbsProps, BreadcrumbsItem } from "types/Commons/Breadcrumbs"
import Breadcrumb from "./Breadcrumb"

import "./index.scss"

const BaseBreadcrumbs = (props: BaseBreadcrumbsProps) => {
  const { items, max, compact, className } = props
  return (
    <IonBreadcrumbs maxItems={max} className={`component-breadcrumbs is-${compact ? "compact" : "default"} ${className}`}>
      {items?.map((item: BaseBreadcrumbsItem) =>
        <IonBreadcrumb href={item.href || undefined}>
          {item.title}
          <IonIcon slot="separator" icon={item.icon || chevronForwardOutline}></IonIcon>
        </IonBreadcrumb>
      )}
    </IonBreadcrumbs>
  )
}

BaseBreadcrumbs.defaultProps = defaultBaseBreadcrumbsProps

export default BaseBreadcrumbs

export const Breadcrumbs = (props: BreadcrumbsProps) => {
  return (
    <IonText className={`component-breadcrumbs ${props.className || ""} ${props.wrap ? "wrap" : ""}`}>
      {props.items?.map((item: BreadcrumbsItem, index: number) =>
        <Breadcrumb>{item.children || <></>}</Breadcrumb>
      )}
    </IonText>
  )
}