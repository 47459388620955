import { IonList } from "@ionic/react"

import { AnimeEpisodesSetItemsProps } from "types/Anime/AnimeEpisodesSetItems"
import { AnimeEpisodeDetails } from "types/Anime"
import AnimeEpisodeItem from "./Item"

import "./index.scss"

const AnimeEpisodesSetItems = (props: AnimeEpisodesSetItemsProps) => {
  return (
    <IonList className="component-anime-episodes-list-items">
      {
        Array.from(props.episodes).reverse().map((episode: AnimeEpisodeDetails, index: number) =>
          <AnimeEpisodeItem
            episode={episode}
            key={`component-anime-episodes-list-${index}`}
            episodesLoaded={props.episodesLoaded || false}
            changeEpisode={props.changeEpisode}
            {...props}
          />
        )
      }
    </IonList>
  )
}

export default AnimeEpisodesSetItems