import { useState } from "react"

import { NewsImg } from "types/Anime/Images"
import { Endpoints } from "api/Endpoints"
import PageContainer from "pages/_commons/PageContainer"
import Content from "pages/_commons/Content/Content"
import HeaderSlides from "pages/_commons/Content/HeaderSlides"
import OverCard from "components/Content/OverCard"
import locales from "config/Locales"
import Meta from "components/Media/Meta"

const News = () => {
  const [hasContent, setHasContent] = useState<boolean>(true)

  return (
    <PageContainer className="page-news">
      <>
        <HeaderSlides />
        {hasContent ? (
          <Content
            title={locales._get("news")}
            href="/news"
            endpoint={Endpoints.news}
            type="news"
            mode="grid"
            filterRepeated={true}
            onEmptySlider={(isEmpty: boolean) => {
              setHasContent(!isEmpty)
            }}
          />
        ) : (
          <>
            <OverCard
              title={locales._get("news.soon")}
              subtitle={locales._get("news.in-construction")}
              image={NewsImg}
            />
          </>
        )}
      </>
      <Meta
        title={locales._get("news")}
      />
    </PageContainer>
  )
}

export default News