import { setSearchWords } from "pages/Discover/Anime/Methods/setSearchWords"
import { AnimeContent } from "types/Anime"

export const setSearchWordsParams = (str: string, currentSearch: string): string =>
  setSearchWords(str, currentSearch)

export const formatSearchContent = (_content: AnimeContent, currentSearch: string): AnimeContent => {
  return {
    ..._content,
    content: setSearchWordsParams(_content?.content || "", currentSearch),
    title: setSearchWordsParams(_content?.title || "", currentSearch),
    title_spanish: setSearchWordsParams(_content?.title_spanish || "", currentSearch),
    title_english: setSearchWordsParams(_content?.title_english || "", currentSearch),
    title_native: setSearchWordsParams(_content?.title_native || "", currentSearch),
    title_romaji: setSearchWordsParams(_content?.title_romaji || "", currentSearch),
    titleOtherStr: setSearchWordsParams(_content?.titleOtherStr || "", currentSearch),
  }
}