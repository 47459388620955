const es = {
  "appname": "Anime Play App",
  "langcode": "es",
  "langname": "spanish",
  "settings.title-language": "Idioma del título",
  "settings.title-language-subtitle": "Idioma principal del título.",
  "settings.title-media-player": "Plantilla del reproductor Premium",
  "settings.title-media-player-subtitle": "Este reproductor es visible para contenido Premium.",
  "settings.title-media-player-pip": "Mostrar PiP",
  "settings.title-media-player-pip-subtitle": "Habilitar ventana flotante de video (Picture in Picture).",
  "settings.parental-control": "Control parental",
  "settings.parental-control-subtitle": "Habilite el control parental para restringir el contenido",
  "settings.parental-control-confirm": "Haciendo esto se tendrá acceso a todo el contenido en la app sin restricción, ¿deseas continuar?",
  "settings.show-alert-with-ads": "Mostrar alerta de anuncios externos",
  "settings.show-alert-with-ads-subtitle": "Mostrar alerta de anuncios externos en el reproductor.",
  "settings.show-alert-with-ads-confirm": "Ya no verás la alerta de anuncios externos en el reproductor, recuerda que las fuentes de reproducción marcadas con 'anuncios' son EXTERNOS a Anime Play, ¿deseas continuar?",
  "settings.show-notification-controls": "Controles en barra de notificaciones",
  "settings.show-notification-controls-subtitle": "Mostrar controles de video en barra de notificaciones.",
  "settings.history-save-limit": "Límite de historial de contenido",
  "settings.history-save-limit-subtitle": "Límite de historial a guardar en la app.",
  "settings.history-save-limit-confirm": "El límite establecido es menor a los registros en la app (<b>%s de %t</b>), esto causará la pérdida de datos en tu historial, ¿deseas continuar?",
  "settings.accept": "Aceptar",
  "settings.cancel": "Cancelar",
  "settings.title-language-romaji": "Romaji",
  "settings.title-language-english": "Inglés",
  "settings.title-language-spanish": "Español",
  "date.month_short_1": "Ene",
  "date.month_short_2": "Feb",
  "date.month_short_3": "Mar",
  "date.month_short_4": "Abr",
  "date.month_short_5": "May",
  "date.month_short_6": "Jun",
  "date.month_short_7": "Jul",
  "date.month_short_8": "Ago",
  "date.month_short_9": "Sep",
  "date.month_short_10": "Oct",
  "date.month_short_11": "Nov",
  "date.month_short_12": "Dic",
  "login": "Iniciar sesión",
  "logout": "Cerrar sesión",
  "signup": "Registrarme",
  "change_avatar": "Cambiar avatar",
  "network.metered-alert": "Estás usando una red medida. Ten en cuenta que pueden haber cargos por el uso de datos.",
  "network.unavailable-alert": "No hay conexión a internet. Por favor, revisa tu conexión e intenta de nuevo.",
  "network.ok": "Ok",
  "loginfailed": "Error al iniciar sesión",
  "sendfailed": "Error al enviar",
  "passwordfail": "Contraseña incorrecta",
  "passwordminfail": "Contraseña no segura",
  "emailfail": "Correo incorrecto",
  "your_name": "Tu nombre",
  "username_placeholder": "Usuario",
  "username_email_placeholder": "Usuario/Correo electrónico",
  "email_placeholder": "Correo electrónico",
  "password_placeholder": "Contraseña",
  "password_confirm_placeholder": "Confirmar contraseña",
  "password_min_complex": "<b>Contraseña min:</b><ul><li>8 caracteres mínimo.</li><li>1 letra minúscula mínimo.</li><li>1 letra mayúscula mínimo.</li></ul>",
  "forgot_msg": "Ingresa tu correo electrónico y enviaremos un enlace para restablecer tu contraseña.",
  "signup_msg": "Ingresa la información requerida para crear tu cuenta.",
  "signin_msg": "Ingresa tu usuario y contraseña para tener acceso completo.",
  "signing_in": "Iniciando sesión...",
  "signing_up": "Creando tu cuenta...",
  "forgot_password": "¿Olvidaste tu contraseña?",
  "remember_msg": "Si tu cuenta %s es correcta, recibirás un correo con tu nueva contraseña temporal.",
  "send_email": "Enviar correo electrónico",
  "user.changing": "Cambiando usuario...",
  "user.setting-avatar": "Cambiando avatar...",
  "latest-version": "más reciente",
  "latest-version.title": "La versión %s de %t ya está disponible.",
  "latest-version.desc": "Ingresa a cualquiera de estas opciones para descargar la versión más reciente de tu app favorita de anime:",
  "latest-version.your-version": "Tu versión de %s",
  "latest-version.unkonown": "Versión desconocida",
  "latest-version.latest": "Versión disponible",
  "latest-version.download": "Descarga disponible %s",
  "latest-version.google-play": "Google Play",
  "latest-version.xiaomi": "Xiaomi Store",
  "latest-version.github": "GitHub",
  "latest-version.website": "Sitio web",
  "commons.alerts.user-required": "Debes iniciar sesión para acceder a esta sección.",
  "commons.alerts.with-ads": "El medio seleccionado incluye ANUNCIOS EXTERNOS a Anime Play, si deseas desactivar esta notificación ve a configuraciones de la app.",
  "commons.alerts.close": "Cerrar",
  "commons.alerts.retry": "Reintentar",
  "welcome": "Bienvenid@",
  "welcome_desc": "Ya has iniciado sesión en nuestra app, ahora podrás disfrutar de todo el potencial. Desplaza a la <span>derecha</span> para más información.",
  "discover_new_anime": "Descubrir nuevo anime",
  "save_cloud": "Guarda todo en la nube",
  "save_cloud_desc": "Contamos con servidores propios de almacenamiento de datos, así que puedes estar seguro que siempre tendrás disponible tu historial y configuración a la mano.",
  "use_devices": "Usa tu cuenta en diferentes dispositivos",
  "use_devices_desc": "Con tu mismo usuario puedes ingresar desde varios dispositivos y sincronizar en tiempo real todo tu avance.",
  "give_reactions": "Da reacciones a tu contenido favorito",
  "give_reactions_desc": "¡Puedes expresar tu opinión! Si te gusta o no un contenido, puedes decirle a todos lo que sientes. Además podrás seguir tu contenido favorito y verás sólo lo que te interesa.",
  "watch_anime": "Ver anime",
  "loading": "Cargando...",
  "success": "¡Éxito!",
  "ok": "OK",
  "releases": "Lanzamientos",
  "discover": "Descubrir",
  "search": "Buscar",
  "news": "Noticias",
  "genders": "Géneros",
  "studios": "Estudios",
  "store": "Tienda Anime Play",
  "share-app": "Compartir app",
  "rate-app": "Dale 5 estrellas",
  "view-playstore": "Ver en Play Store",
  "download-app": "Descargar App",
  "profile": "Mi Perfil",
  "about": "Acerca de la app",
  "tos": "Políticas de privacidad",
  "changelog": "Historial de cambios",
  "support": "Soporte",
  "footer-text": "Mis Gamers | Anime Play %s",
  "version": "Versión %s",
  "new_in": "Nuevo en %s",
  "new_content": "Nuevos episodios",
  "popular_content": "Popular en %s",
  "episode": "Episodio",
  "for_you": "Nuevo para ti",
  "continue_watching": "Continuar viendo",
  "error_loading": "Error al cargar el contenido.",
  "see_more": "Ver más",
  "loading_content": "Cargando %s...",
  "content": "Contenido",
  "slides": "Diapositivas",
  "more_from_studio": "Más de %s",
  "subtitle-view-gender": "Listado de género",
  "subtitle-view-studio": "Listado de estudio",
  "app_settings": "Ajustes generales",
  "no_following": "¿Sin seguir contenido?",
  "no_following_subtitle": "¡Sigue contenido para verlo aquí!",
  "last_call": "Última consulta",
  "error.content-title-status": "¡Hubo un error!",
  "error.content-description-status": "No se pudo conectar al servidor, intenta nuevamente.",
  "error.content-title": "¡La conexión falló!",
  "error.content-description": "Verifica tu Internet y vuelve a intentarlo.",
  "error.modal-title": "Reporte de errores",
  "error.modal-subtitle": "Sólo administradores",
  "error.description": "Descripción del error",
  "error.no-description": "No se ha proporcionado una descripción.",
  "error.end-point": "End point",
  "error.no-end-point": "No se ha proporcionado un end point.",
  "error.body": "Cuerpo",
  "error.no-body": "No se ha proporcionado un cuerpo.",
  "error.headers": "Encabezados",
  "error.no-headers": "No se han proporcionado encabezados.",
  "error.status-code": "Código de estado",
  "error.no-status-code": "No se ha proporcionado un código de estado.",
  "error.can-send": "Puedes enviar este reporte.",
  "error.can-send-description": "Al hacer click en el botón de abajo enviarás este reporte a los desarrolladores de %s.",
  "error.send": "Enviar reporte",
  "error.sending": "Enviando reporte...",
  "error.sent": "Reporte enviado",
  "error.not-sent": "No se pudo enviar el reporte",
  "date.now": "Ahora",
  "date.today_from_now": "Hoy",
  "date.today_ago": "Ayer",
  "date.seconds_ago": "Hace %s segundos",
  "date.minutes_ago": "Hace %s minutos",
  "date.hours_ago": "Hace %s horas",
  "date.days_ago": "Hace %s días",
  "date.months_ago": "Hace %s meses",
  "date.years_ago": "Hace %s años",
  "date.second_ago": "Hace un segundo",
  "date.minute_ago": "Hace un minuto",
  "date.hour_ago": "Hace una hora",
  "date.day_ago": "Ayer",
  "date.month_ago": "Hace un mes",
  "date.year_ago": "Hace un año",
  "date.seconds": "%s segundos",
  "date.minutes": "%s minutos",
  "date.hours": "%s horas",
  "date.days": "%s días",
  "date.months": "%s meses",
  "date.years": "%s años",
  "date.second": "un segundo",
  "date.minute": "un minuto",
  "date.hour": "una hora",
  "date.month": "un mes",
  "date.year": "un año",
  "date.seconds_from_now": "En %s segundos",
  "date.minutes_from_now": "En %s minutos",
  "date.hours_from_now": "En %s horas",
  "date.days_from_now": "En %s días",
  "date.months_from_now": "En %s meses",
  "date.years_from_now": "En %s años",
  "date.second_from_now": "En un segundo",
  "date.minute_from_now": "En un minuto",
  "date.hour_from_now": "En una hora",
  "date.day_from_now": "Mañana",
  "date.month_from_now": "En un mes",
  "date.year_from_now": "En un año",
  "date.never": "nunca",
  "date.unknown": "desconocido",
  "pager.not-found": "No se encontró contenido.",
  "pull_refresh": "Arrastra hacia abajo para actualizar contenido",
  "refreshing": "Actualizando...",
  "refreshed": "Contenido actualizado.",
  "not_refreshed": "Error al actualizar contenido.",
  "last_episode": "Último episodio",
  "more_episodes": "Más episodios",
  "news.soon": "Próximamente",
  "news.in-construction": "En construcción sección de noticias.",
  "content.type-tv": "Serie",
  "content.type-pelicula": "Película",
  "content.type-ova": "OVA",
  "content.type-especial": "Especial",
  "content.type-": "Sin especificar",
  "content.back": "Volver",
  "content.no-staff": "Sin especificar",
  "content.no_watching": "¿Sin ver nada?",
  "content.no_watching_subtitle": "¡Busca contenido para verlo aquí!",
  "content.watch": "Ver ahora",
  "content.watch-at": "Ver episodio %s/%t",
  "content.download": "Descargar",
  "content.download-at": "Descargar episodio %s/%t",
  "content.download-message": "Accederás a una página externa, no somos responsables de la disponibilidad del contenido, ¿Deseas descargar el episodio %s de %t?",
  "content.download-cancel": "Cancelar",
  "content.expore": "Explorar %s",
  "content.search": "Búsqueda",
  "content.search-placeholder": "¿Qué deseas buscar?",
  "content.search-explain": "Busca contenido por título o descripción en el campo de arriba.",
  "content.search-explain-min-length": "La búsqueda debe tener al menos %s caracteres.",
  "content.search-results": "Resultados de búsqueda",
  "content.search-results-empty": "No se encontraron resultados.",
  "content.search-word-explain": "Esta es una palabra de la búsqueda <b>\"%s\"</b> que coincide con el contenido.",
  "content.search-see": "Ver listado",
  "content.search-lasts": "Últimas búsquedas",
  "content.relevance": "Relevancia",
  "content.search-not-relevant": "No relevante",
  "content.search-not-relevant-subtitle": "La búsqueda no coincide con los resultados a partir de este punto.",
  "content.relevance-what-is": "¿Qué es la relevancia?",
  "content.relevance-what-is-desc": "La relevancia es un algoritmo que busca los resultados más relevantes para tu búsqueda en base a un índice.<br /><br />Estos índices se clasifican en: <br /><p><b class=\"%c1\">Alto:</b> %1a o más.</p><p><b class=\"%c2\">Medio:</b> %2a a menos de %1b.</p><p><b class=\"%c3\">Bajo:</b> %3 a menos de %2b.</p><p><b class=\"%c4\">Muy bajo:</b> menos de %4.</p> <br />El índice de relevancia se calcula en base a los siguientes criterios: <br /><p> - Da un criterio más alto si se encuentra en el título y sus idiomas.</p><p> - Un poco más bajo si se encuentra en la descripción.</p><p> - El más bajo si se encuentra en campos adicionales.</p><p> - Da el criterio más alto si la consulta es exacta.</p><p> - Además, da un criterio alto si todas las palabras se encuentran en el campo.</p><p> - Un poco más bajo si se encuentra al menos una palabra.</p><br />Si tu búsqueda coincide con uno o varios de estos criterios, el resultado tendrá un índice de relevancia más alto.",
  "content.tab-description": "Descripción",
  "content.tab-episodes": "Episodios",
  "content.tab-information": "Información",
  "content.tab-trailer": "Trailer",
  "content.tab-staff": "Reparto",
  "content.tab-characters": "Personajes",
  "content.tab-anime": "Anime",
  "content.tab-recommendations": "Recomendaciones",
  "content.tab-related": "Relacionados",
  "content.tab-comments": "Comentarios",
  "content.tab-show-on": "Aparece en",
  "content.tab-voice-by": "Actor de voz",
  "content.episode-item": "Episodio %s",
  "content.episode-item-at": "Episodio %s de %t",
  "content.episode-item-seen": "Visto el %s",
  "content.episode-ads-0": "Sin anuncios",
  "content.episode-ads-1": "Bajo",
  "content.episode-ads-2": "Moderado",
  "content.episode-ads-3": "Alto",
  "content.more-genders": "Más géneros",
  "content.no-genders": "Sin género registrado",
  "content.see-content": "Ver contenido",
  "content.no-studio": "Sin estudio",
  "content.media-list": "Fuentes disponibles",
  "content.media-previous": "Anterior",
  "content.media-next": "Siguiente",
  "content.media-changing": "Cambiando de episodio",
  "content.media-ads": "Anuncios",
  "content.media-player": "Aquí verás el contenido de %s",
  "content.media-marked-as-seen": "Marcado como visto",
  "content.media-info-bellow": "Más información abajo.",
  "content.no-media": "No hay fuentes disponibles",
  "content.no-media-selected": "No hay fuente seleccionada",
  "content.details-title-spanish": "Título (español)",
  "content.details-title-english": "Título (inglés)",
  "content.details-title-romaji": "Título (japonés)",
  "content.details-title-others": "Otros títulos",
  "content.details-studio": "Estudio",
  "content.details-studios": "Otros estudios",
  "content.details-season": "Temporada",
  "content.details-is-finished": "Finalizado",
  "content.details-is-not-finished": "En emisión",
  "content.details-last-episode": "Último episodio",
  "content.details-last-seen": "Mi último visto es: %s",
  "content.details-next-episode": "Siguiente episodio",
  "content.details-start-date": "Inició en",
  "content.details-end-date": "Finalizado en",
  "content.details-launch-date": "Al aire el",
  "content.details-end-date-at": "Finalizado el",
  "content.details-parental-control": "Contenido restringido",
  "content.details-parental-control-description": "Este contenido está clasificado para mayores de edad, si deseas desbloquear este contenido configura en las opciones de la app.",
  "content.season-FALL": "Otoño",
  "content.season-SUMMER": "Verano",
  "content.season-WINTER": "Invierno",
  "content.season-SPRING": "Primavera",
  "content.season-": " ",
  "content.no-last-seen": "Sin ver aún",
  "content.last-seen-at": "Última vez visto el <b>%s</b> episodio <b>%t</b>",
  "content.is-18": "Contenido para adultos",
  "content.is-18-short": "18+",
  "content.watch-trailer": "Ver trailer",
  "content.watch-trailer-fullscreen": "Pantalla completa",
  "content.watch-trailer-on": "Ver trailer en %s",
  "content.watch-trailer-external-media": "medio externo",
  "content.not-recommendations": "No hay recomendaciones",
  "content.not-related": "No hay contenido relacionado",
  "content.not-characters": "No hay personajes",
  "content.share-email": "Compartir por correo",
  "content.share-sms": "Compartir por SMS",
  "content.share-copy": "Copiar enlace",
  "content.share-copy-success": "Enlace copiado",
  "content.share-other": "Otras opciones",
  "content.comments": "Comentarios",
  "content.no_comments": "No hay comentarios",
  "content.comments-notice": "Los comentarios son moderados, por lo que pueden tardar en aparecer. Recuerda ser respetuoso con los demás, de lo contrario se restringirá el permiso para comentarios.",
  "content.comment-placeholder": "Escribe tu comentario...",
  "content.comment-input-label": "Estas en el episodio %s",
  "content.comment-sending": "Enviando comentario...",
  "content.comment-error": "Error al enviar el comentario.",
  "content.comment-published": "Se ha publicado tu comentario.",
  "content.comment-moderated": "Se ha publicado tu comentario, pero debe ser moderado.",
  "content.anonymous": "Anónimo",
  "content.following": "Estás siguiendo a %s",
  "content.not-following": "Dejaste de seguir a %s",
  "content.following-error": "Error al actualizar seguimiento a %s",
  "content.wave-title": "Este contador indica el tiempo restante para ser marcado este contenido como visto, se ejecutará de manera automática y te mostrará una notificación.",
  "content.premium": "Premium",
  "profile.history-title": "Mi historial",
  "profile.history-subtitle": "Aquí puedes ver tu historial de navegación.",
  "profile.myhistory": "Mi historial",
  "profile.no-history": "No hay historial",
  "profile.no-history-subtitle": "Tu historial de navegación se guardará aquí.",
  "profile.history-type-content": "Anime visto",
  "profile.history-type-episode": "Episodio %s visto %t",
  "profile.history-type-news": "Noticia vista",
  "profile.history-type-character": "Personaje visto",
  "profile.history-type-staff": "Reparto visto",
  "profile.settings-content": "Ajustes de contenido",
  "profile.settings-media-player": "Ajustes del reproductor Premium",
  "profile.settings-startup": "Ajustes de inicio",
  "profile.settings-initial-page": "Página inicial",
  "profile.settings-initial-page-subtitle": "Página que se mostrará al iniciar la app.",
  "settings.initial-page-default": "Predeterminada",
  "profile.settings-title": "Ajustes generales",
  "profile.settings-subtitle": "Aquí puedes configurar tus preferencias.",
  "profile.settings-edit": "Editar este campo",
  "profile.settings-loading-storage": "Cargando información de almacenamiento...",
  "profile.settings-storage-limits": "Límites de almacenamiento interno",
  "profile.settings-storage-limit-db": "Usar máximo %s de almacenamiento interno",
  "profile.settings-storage-limit-db-used": "Almacenamiento usado: %s de %t => %p%",
  "profile.settings-storage-limit-db-available": "Almacenamiento disponible: %s => %p%",
  "profile.settings-storage-limit": "Usar máximo de almacenamiento interno",
  "profile.settings-storage-limit-subtitle": "Mínimo posible: %s%",
  "profile.settings-localstorage-limits": "Límites de caché",
  "profile.settings-localstorage-limit-db": "Usar máximo %s de caché",
  "profile.settings-localstorage-limit-db-used": "Caché usada: %s de %t => %p%",
  "profile.settings-localstorage-limit-db-available": "Caché disponible: %s => %p%",
  "profile.settings-localstorage-limit": "Usar máximo de caché",
  "profile.settings-localstorage-limit-subtitle": "Mínimo posible: %s%",
  "profile.settings-search": "Ajustes de búsqueda",
  "profile.settings-search-clear": "Limpiar búsqueda reciente",
  "profile.settings-search-clear-subtitle": "Marca esta opción para limpiar el campo de búsqueda reciente.",
  "profile.settings-search-limit": "Límite de historial de búsqueda",
  "profile.settings-search-limit-subtitle": "Límite de historial a guardar en la app.",
  "profile.settings.templates.default": "Predeterminado",
  "profile.settings.templates.city": "Urban",
  "profile.settings.templates.fantasy": "Fantasy",
  "profile.settings.templates.forest": "Minimal",
  "profile.settings.templates.sea": "Bubble",
  "component.infinite.end-of-list": "Ya no hay más contenido por cargar.",
  "component.infinite.item-count": "Contenido cargado",
  "component.infinite.item-count-single-result": "Un resultado",
  "component.infinite.item-count-result": "%s resultados",
  "component.header-toolbar-text": "menú",
  "component.picker-no-items": "No hay nada para mostrar.",
  "filters.title": "Filtros",
  "filters.all": "Todos",
  "filters.premium": "Premium",
  "filters.premium-explain": "Muestra sólo contenido que tiene siempre fuentes disponibles, esto garantiza que encontrarás multimedia qué reproducir.",
  "filters.pending-see": "Pendientes para ver",
  "filters.content-type": "Tipo de contenido",
  "videojs.not-supported-message": "Este dispositivo no es compatible con el reproductor de videos.",
  "videojs.skip": "Saltar en %s",
  "videojs.skip-now": "Saltar ahora",
  "videojs.next-episode-in": "Siguiente episodio en %s",
  "videojs.error-network": "Error de red, intenta nuevamente.",
  "videojs.ok": "Ok",
  "videojs.continue": "Continuar de %s",
  "videojs.at-start": "Del comienzo",
  "ads.loading": "Cargando espacio patrocinado...",
  "ads.click-here": "Haz clic aquí para más información",
  "ads.remove-title": "Eliminar espacio patrocinado",
  "ads.remove-title-fail": "Error al eliminar el espacio patrocinado, puede ser que no hayan anuncios disponibles a mostrar, intenta más tarde.",
  "ads.remove-title-success": "Has eliminado este anuncio por tiempo limitado, se va agregando el tiempo cada que vuelves a ver un anuncio. Actualiza la app para que surta efecto.",
  "ads.remove-button-in-banner": "Elimina este anuncio <b>%s</b> viendo un video",
  "ads.remove-button": "Eliminar estos anuncios",
  "ads.remove-button-passed": "Agregar tiempo (%s)",
  "ads.remove-text-top": "Elimina espacios publicitarios <b>%s</b> viendo un video. Da click en el tipo de anuncio que quieras eliminar.",
  "ads.remove-text-warning": "<b>NOTA:</b> Si al ver un video se agrega una fracción de hora, significa que sólo se mostró un anuncio chico. Esto por lo general indica que no hay videos disponibles para mostrar.",
  "ads.banner-large": "Banner grande",
  "ads.remove-text-large": "Formato de anuncio de banner grande u otros tamaños similares. Al dar click en este botón se ocultará por <b>%s</b>.",
  "ads.banner-content": "Banner en contenido",
  "ads.remove-text-content": "Formato de anuncio de banner en detalles del contenido. Al dar click en este botón se ocultará por <b>%s</b>.",
  "ads.banner-video": "Anuncio de video",
  "ads.remove-text-video": "Formato de anuncio de video al ingresar al contenido. Al dar click en este botón se ocultará por <b>%s</b>.",
  "ads.remove-only-app": "Esta modalidad está disponible sólo en la app movil, te invitamos a instalar nuestra app desde las plataformas disponibles.",
}

export default es