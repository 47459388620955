import { useEffect, useState } from "react"
import { useHistory } from "react-router-dom"

import { getHistoryRecord } from "store/useHistoryRecord"
import { HistoryRecord } from "store/useHistoryRecord/interface"
import PageContainer from "pages/_commons/PageContainer"
import User from "components/Users/User"
import ProfileHistoryMap from "./Map"

const ProfileHistory = () => {
  const [userHistory, setUserHistory] = useState([] as HistoryRecord[])
  const [isLoading, setIsLoading] = useState(true)
  const history = useHistory()

  useEffect(() => {
    setIsLoading(true)
    getHistoryRecord(User.getUsername()).then((historyRecord: HistoryRecord[]) => {
      setUserHistory(historyRecord)
      setIsLoading(false)
    })
  }, [])

  return (
    <PageContainer
      withProgress={true}
      withToTop={true}
      className="page-profile-settings"
      showLogo={false}
      onClickBack={() => { history.push("/profile") }}
    >
      <ProfileHistoryMap userHistory={userHistory} isLoading={isLoading} />
    </PageContainer>
  )
}

export default ProfileHistory