import { ContentProps } from "types/Page/Content"
import { ConnectOptions, Responses } from "types/Connect"
import { delayAndReturn } from "utils/Function"
import { Slide } from "types/Media/Slider"
import Connect from "components/Connect"

export const getContentRemote = async (props: {
  order: {},
  precallback?: Function,
  callback: Function,
  page?: number,
  props: ContentProps,
  items: Slide[],
  limit: number,
  updateLastCall: Function,
  setPageFromRemote: Function,
  setEndOfList: Function,
  getContentRemote: Function,
}) => {
  if (!props.props.endpoint) return
  const params = { ...props.order, pag: props.page || 1, limit: props.limit }
  props.updateLastCall(params)
  props.props.onEmptySlider && props.props.onEmptySlider(false)

  const contentParams = {
    endpoint: props.props.endpoint,
    params,
    options: {
      force: props.props.force || false,
      checkInterval: props.props.checkInterval || "short",
      cachedContent: props.props.cachedContent,
    } as ConnectOptions
  }

  const getCached: Promise<Responses> =
    Connect.postcached({
      endpoint: contentParams.endpoint,
      params: contentParams.params,
      options: { ...contentParams.options, onlyCached: true, force: false },
    })
  const retryGetRemote = () =>
    delayAndReturn(() => getContentRemote(props), 5000)
  //get cached first
  if (props.precallback)
    getCached.then((res: Responses) =>
      props.precallback && props.precallback(res.data?.results))
  Connect.postcached(contentParams).then((res: Responses) => {
    if (res.data?.code === 200 && typeof res.data?.results === "object") {
      props.callback(res.data?.results || [])
      if (res.data?.page) props.setPageFromRemote(res.data?.page)
      props.props.onEmptySlider && props.props.onEmptySlider(res.data?.results?.length === 0 && (props.page || 1) <= 1)
      props.setEndOfList(res.data?.results?.length === 0)
      props.updateLastCall(params)
    } else {
      const isWarning: boolean = res?.data?.type === "E_WARNING" || res?.data?.type === "E_NOTICE" || false
      if (isWarning) {
        getCached.then((res: Responses) =>
          props.callback(res.data?.results || []))
        return
      }
      props.setEndOfList(true)
      props.callback([])
      //infinite scroll set to complete
      const infiniteScroll = document.querySelector("ion-infinite-scroll") as HTMLIonInfiniteScrollElement
      if (infiniteScroll) infiniteScroll.complete()
    }
  }).catch(() => retryGetRemote())
}