import { Endpoints } from "api/Endpoints"
import { Responses } from "types/Connect"
import { AnimeContent } from "types/Anime"
import Connect from "components/Connect"
import AnimeContentParser from "pages/_commons/Content/AnimeContent"

export const getContent = async (props: { cid: number, force?: boolean }): Promise<AnimeContent> => {
  if (props.cid === 0) return {} as AnimeContent
  const response: Responses = await Connect.postcached({
    endpoint: Endpoints.details,
    params: { id: props.cid },
    options: { checkInterval: "month", force: props.force || false },
  })
  if (!response.data?.results && !(props.force || false)) return getContent({ cid: props.cid, force: true })
  return AnimeContentParser.fitAnimeContent(response.data?.results as AnimeContent)
}