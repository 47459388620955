import { IonFooter, IonToolbar, IonButtons, IonButton, IonGrid, IonRow, IonCol, IonText } from "@ionic/react"
import { useState } from "react"

import { HeaderLogoSrc } from "types/Anime/Images"
import { UserBarProps, defaultUserBarProps } from "types/User"
import locales from "config/Locales"
import Strings from "utils/Strings"
import Modal from "components/Commons/Modal"
import useModal from "store/useModal"
import Login from "../Login"
import User from "../User"

export const UserModalLogin = (props: { randomModalIdLogin: string }) => {
  return (
    <>
      <Modal
        id={props.randomModalIdLogin}
        color="dark"
        img={HeaderLogoSrc}
        scroll={false}>
        <Login />
      </Modal>
    </>
  )
}

const UserBar: React.FC = (props: UserBarProps) => {
  const [randomModalIdLogin] = useState(`loginform-${Strings.getRandomNumber(7)}`)
  const openModal = useModal((state: any) => state.openModal)
  if (User.isLoggedIn()) return (<></>)

  return (
    <IonFooter className="component-user-bar">
      <IonToolbar mode="ios" color={props.color}>
        <IonGrid>
          <IonRow class="ion-justify-content-center">
            <IonCol>
              <IonButtons>
                <IonButton id={randomModalIdLogin} onClick={() => {
                  openModal(randomModalIdLogin)
                }}>
                  <IonText class="ion-text-uppercase">{locales._get("signup")}&nbsp;/&nbsp;{locales._get("login")}</IonText>
                </IonButton>
              </IonButtons>
            </IonCol>
          </IonRow>
        </IonGrid>
      </IonToolbar>
      <UserModalLogin randomModalIdLogin={randomModalIdLogin} />
    </IonFooter>
  )
}

UserBar.defaultProps = defaultUserBarProps

export default UserBar
