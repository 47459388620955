import { AnimeContent, AnimeEpisodes, EpisodeMediaType } from "types/Anime"
import { isParentalContentBlocked } from "./isParentalContentBlocked"

export const canPlay = (
  content: AnimeContent,
  episodes: AnimeEpisodes,
  media: EpisodeMediaType,
): boolean => {
  if (isParentalContentBlocked(content)) return false
  if (episodes?.media?.current?.play?.length === 0) return false
  if (!episodes?.media?.current?.play[0].id) return false
  if (media?.media === "") return false
  return true
}

export const canDownload = (
  content: AnimeContent,
  episodes: AnimeEpisodes,
  media: EpisodeMediaType,
): boolean => {
  if (isParentalContentBlocked(content)) return false
  if (episodes?.media?.current?.download?.length === 0) return false
  if (!episodes?.media?.current?.download[0].id) return false
  if (media?.media === "") return false
  return true
}