import { menuItems } from "types/Commons/Menu"
import locales from "config/Locales"

export const MenuHidden = [
  {
    title: locales._get("profile.history-title"),
    href: "/profile/history",
  },
  {
    title: locales._get("profile.settings-title"),
    href: "/profile/settings",
  },
  {
    title: locales._get("new_content"),
    href: "/home/anime",
  },
  {
    title: locales._get("for_you"),
    href: "/home/foryou",
  },
  {
    title: locales._get("continue_watching"),
    href: "/home/continue",
  },
  {
    title: locales._get("popular_content", locales._get("appname")),
    href: "/home/popular",
  },
] as menuItems[]