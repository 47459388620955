import { SlideInfo } from "types/Media/Slider"
import { AppSettingsID } from "utils/AppSettings"
import { setHeaderGenderLink } from "pages/Discover/HeaderToolbar"
import { menuItems } from "types/Commons/Menu"
import Strings from "utils/Strings"
import RefresherContent from "components/Connect/Refresher/Content"
import settings from "config/Settings"
import locales from "config/Locales"

export const getGenderList = (id?: string): SlideInfo[] =>
  AppSettingsID.getSetting(id || "gender-list", [] as SlideInfo[])

export const genderDescriptionFormat = (gender: SlideInfo) =>
  `${gender.isadult === 1 ? `<b class="red">[${locales._get("content.is-18-short")}]</b> ` : ""}${Strings.encode(gender.description || "")}`

export const getGenderListMenuItems = (justMain?: boolean, callbackIfFail?: Function): menuItems[] => {
  const genders = getGenderList(justMain ? "gender-main" : undefined)
  const showAdult = new settings().parentalControl === 0

  if (genders.length === 0) {
    RefresherContent.genderstudioslist(() =>
      setTimeout(() =>
        callbackIfFail && callbackIfFail(), settings.callbackTimeout), true)
    return [] as menuItems[]
  }

  let _genders = [] as menuItems[]
  Array.from(genders as SlideInfo[])?.map((gender: SlideInfo) => {
    if ((showAdult && gender.isadult === 1) || gender.isadult === 0)
      _genders.push({
        title: Strings.encode(gender.title || ""),
        description: genderDescriptionFormat(gender),
        href: setHeaderGenderLink(gender.id || 0),
      })
    return gender
  })

  return _genders
}

export const getGender = (gid: number): SlideInfo => {
  const genders = getGenderList()
  const gender = Array.from(genders as SlideInfo[]).find((gender: SlideInfo) => gender.id === gid) as SlideInfo
  gender.description = genderDescriptionFormat(gender)
  return gender
}