import { IonList, IonLabel, IonItem, IonButton, IonIcon } from "@ionic/react"
import { personAddOutline } from "ionicons/icons"
import { useHistory } from "react-router-dom"
import { useState } from "react"

import { UserListHeader } from "../Methods/UserListHeader"
import { doRegister } from "./Methods/doRegister"
import locales from "config/Locales"
import useModal from "store/useModal"
import FormItem from "../Methods/FormItem"

import "./index.scss"
import Animate from "utils/Animate"

const SignupForm = () => {
  const [yourname, setYourname] = useState<string>("")
  const [username, setUsername] = useState<string>("")
  const [password, setPassword] = useState<string>("")
  const [passwordconfirm, setPasswordConfirm] = useState<string>("")
  const [email, setEmail] = useState<string>("")
  const [signupmsg, setSignupmsg] = useState<string>(locales._get("signup"))
  const closeActiveModal = useModal((state: any) => state.closeActiveModal)
  const history = useHistory()

  const setSignupAlert = (msg: string) => {
    if (msg === "") {
      setSignupmsg(locales._get("signup"))
      return
    }
    setSignupmsg(msg)
    setTimeout(() => {
      setSignupmsg(locales._get("signup"))
    }, 3000)
  }

  return (
    <div className={`component-login signup input-format-square ${Animate.setAnimation("fadeIn", true)}`}>
      <IonList>
        <UserListHeader html={locales._get("signup_msg")} />
        <FormItem
          value={yourname}
          onIonChange={(e: any) => setYourname(e.target.value || "")}
          className={signupmsg === locales._get("sendfailed") ? "inputRequiredError" : ""}
          placeholder={locales._get("your_name")}
        />
        <FormItem
          value={username}
          onIonChange={(e: any) => setUsername(e.target.value || "")}
          className={signupmsg === locales._get("sendfailed") ? "inputRequiredError" : ""}
          placeholder={locales._get("username_placeholder")}
        />
        <FormItem
          value={email}
          onIonChange={(e: any) => setEmail(e.target.value || "")}
          className={signupmsg === locales._get("sendfailed") || signupmsg === locales._get("emailfail") ? "inputRequiredError" : ""}
          inputMode="email"
          placeholder={locales._get("email_placeholder")}
        />
        <FormItem
          value={password}
          type="password"
          onIonChange={(e: any) => setPassword(e.detail.value || "")}
          className={signupmsg === locales._get("sendfailed") ||
            signupmsg === locales._get("passwordfail") ||
            signupmsg === locales._get("passwordminfail") ? "inputRequiredError" : ""}
          autocomplete="new-password"
          placeholder={locales._get("password_placeholder")}
        />
        <FormItem
          value={passwordconfirm}
          type="password"
          onIonChange={(e: any) => setPasswordConfirm(e.detail.value || "")}
          className={signupmsg === locales._get("sendfailed") ||
            signupmsg === locales._get("passwordfail") ||
            signupmsg === locales._get("passwordminfail") ? "inputRequiredError" : ""}
          autocomplete="new-password"
          placeholder={locales._get("password_confirm_placeholder")}
        />
        <IonItem>
          <IonLabel>
            <div dangerouslySetInnerHTML={{ __html: locales._get("password_min_complex") }} />
          </IonLabel>
        </IonItem>
        <IonItem>
          <IonButton
            color={signupmsg !== locales._get("signup") ? "danger" : "secondary"}
            className="large-top"
            expand="full"
            fill="solid"
            size="default"
            onClick={() => doRegister({ yourname, username, password, passwordconfirm, signupmsg, email, setSignupAlert, closeActiveModal, history })}
          >
            <IonIcon icon={personAddOutline} slot="start"></IonIcon>
            {signupmsg}
          </IonButton>
        </IonItem>
      </IonList>
    </div>
  )
}

export default SignupForm