import { recentContentSlideLimit } from "api/routes/params/Media"
import { delayAndReturn } from "utils/Function"
import Connect from "components/Connect"
import Strings from "utils/Strings"

class RefresherCallContent {
  public static callContent = async (endpoint: string, paramsEndPoint: {}, force: boolean = false, callback: Function, limit?: number) => {
    const params = { pag: 1, limit: limit || recentContentSlideLimit }
    const sendCallback = (status: number) =>
      delayAndReturn(callback(status), Strings.getRandomBetween(3000, 1000))
    await Connect.postcached({ endpoint, params: { ...paramsEndPoint, ...params }, options: { force: force } })
      .then(() => sendCallback(200)).catch(() => sendCallback(400))
  }
}

export default RefresherCallContent