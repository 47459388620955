import { IonList } from "@ionic/react"
import { useHistory } from "react-router-dom"

import { Item } from "../../../components/Content/FormBuilder/Item"
import PageContainer from "pages/_commons/PageContainer"
import AdsBanner from "components/Media/Ads/Banner"
import locales from "config/Locales"
import SettingsContent from "./Content"
import SettingsMediaPlayer from "./MediaPlayer"
import SettingsSearch from "./Search"
import SettingsStorageLimits from "./StorageLimits"

import "./index.scss"

const ProfileSettings = () => {
  const history = useHistory()

  return (
    <PageContainer
      className="page-profile-settings"
      showLogo={false}
      onClickBack={() => { history.push("/profile") }}
    >
      <AdsBanner type="softnyacontent" softnyaposition="DETAILS_TOP" />
      <IonList>
        <Item
          title={locales._get("profile.settings-title")}
          subtitle={locales._get("profile.settings-subtitle")}
          asHeading={true}
        />
        <SettingsContent />
        <SettingsMediaPlayer />
        <SettingsSearch />
        <SettingsStorageLimits />
      </IonList>
      <AdsBanner type="softnyacontent" softnyaposition="DETAILS_BOTTOM" />
    </PageContainer>
  )
}

export default ProfileSettings