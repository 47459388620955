import {
  accessibilityOutline,
  home,
  playOutline,
  starOutline,
  videocamOutline
} from "ionicons/icons"

import { menuItems } from "types/Commons/Menu"
import locales from "config/Locales"

export const MenuSelector = [
  {
    title: locales._get("new_in", locales._get("appname")),
    icon: home,
    href: "/home",
    onlyUser: false,
  },
  {
    title: locales._get("new_content"),
    icon: videocamOutline,
    href: "/home/anime",
    onlyUser: false,
  },
  {
    title: locales._get("for_you"),
    icon: accessibilityOutline,
    href: "/home/foryou",
    onlyUser: true,
  },
  {
    title: locales._get("continue_watching"),
    icon: playOutline,
    href: "/home/continue",
    onlyUser: true,
  },
  {
    title: locales._get("popular_content", locales._get("appname")),
    icon: starOutline,
    href: "/home/popular",
    onlyUser: false,
  },
] as menuItems[]