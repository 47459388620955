import { IonIcon } from "@ionic/react"
import { heart } from "ionicons/icons"

import { AnimeContent } from "types/Anime"
import { Breadcrumbs } from "components/Commons/Breadcrumbs"
import StudiosButtons from "../Buttons/Studios"
import GendersButtons from "../Buttons/Genders"
import AnimeDropDownBadge from "../DropDownBadge"
import DropDownBadge from "components/Commons/DropDownBadge"

import "./index.scss"

const AnimeBreadcrumbs = (props: { content: AnimeContent }) => {
  const { content } = props

  return (
    <Breadcrumbs
      className="component-anime-breadcrumbs"
      items={[
        { children: content.seasonyear },
        { children: <><IonIcon icon={heart} className="red" />&nbsp;{content.popularityStr}</> },
        { children: content.ctypeStr },
        {
          children:
            <>
              {content.studioid?.id && <StudiosButtons content={content} />}
            </>
        },
        {
          children:
            <>
              <GendersButtons content={content} />
              {(content.gendersidsRest?.length || 0) > 0 &&
                <DropDownBadge
                  color="secondary"
                  className="more-genders"
                >
                  <AnimeDropDownBadge content={content} />
                </DropDownBadge>
              }
            </>
        },
      ]}
    />
  )
}

export default AnimeBreadcrumbs