import { colorTypes } from ".."

export type UserProfile = {
  username: string,
  name?: string,
  email?: string,
  avatar?: string,
  token: string,
  authorityList?: {},
}

export type UserBarProps = {
  color?: colorTypes,
}

export const defaultUserBarProps: UserBarProps = {
  color: "tertiary",
}