import { Swiper, SwiperSlide } from "swiper/react"
import "swiper/scss"
import "@ionic/react/css/ionic-swiper.css"

import { HorizontalSliderProps } from "types/Media/HorizontalSlider"
import { Item } from "components/Content/Card/Item"
import { Player } from "components/Content/Card/Player"
import { News } from "components/Content/Card/News"
import { Slide } from "types/Media/Slider"
import { formatResults } from "pages/_commons/Content/formatResults"
import RelevanceAlert, { setRelevance } from "./RelevanceAlert"
import settings from "config/Settings"
import SoftnyaShuffleAds from "./SoftnyaShuffleAds"

import "./index.scss"

export const setItem = (props: HorizontalSliderProps, item: Slide, index: number) => {
  if (item.params) {
    try {
      item.params = JSON.parse(item.params.toString() || "{}")
      if (item.img && (item.img.slice(-1) === "/" || item.img === "")) item.img = item.params?.img || item.img
      if (item.img2 && (item.img2.slice(-1) === "/" || item.img2 === "")) item.img2 = item.params?.img2 || item.img2
      if (item.img3 && (item.img3.slice(-1) === "/" || item.img3 === "")) item.img3 = item.params?.img3 || item.img3
    } catch (e) { }
  }
  item.className = item.className || props.className || ""
  return (
    <>
      {props.type === "item" &&
        <Item item={item} key={index} onClick={props.onClick} />
      }
      {props.type === "player" &&
        <Player item={item} key={index} />
      }
      {props.type === "news" &&
        <News item={item} key={index} />
      }
    </>
  )
}

export const drawItems = (props: HorizontalSliderProps, style: any, randId: string, slidesPerView: number) => {
  const showAds: boolean = props.className?.indexOf("mode-grid") !== -1
  const showAdult: boolean = new settings().parentalControl === 0
  const className = "component-slide-item"
  const firstIrrelevant = props.slides.find((item) => item.sort_direction === "relevance" && item.relevance === 0)

  return (
    <>
      <Swiper className={`component-slider ${props.asSlider ? "slider" : "div-slider"}`} slidesPerView={slidesPerView * 1.1}>
        {formatResults(props.slides)?.map((item, index) => {
          //if in a search result, include all content
          if (!showAdult && item.isadult === 1 && item.sort_direction !== "relevance") return <></>
          item = setRelevance(item)
          return <>
            {props.asSlider ? (
              <SwiperSlide className={`${className} .swiper-slider`} key={`slider-slide-item-${index}`}>{setItem(props, item, index)}</SwiperSlide>
            ) : (
              <>
                {firstIrrelevant && firstIrrelevant.id === item.id && <RelevanceAlert item={item} />}
                {showAds && <SoftnyaShuffleAds positionIndex={index} itemsPerRow={slidesPerView} />}
                <div className={className} style={style.block} key={`noslider-item${index}`} id={`slide-${randId}-${index}`}>
                  {setItem(props, item, index)}
                </div>
              </>
            )}
          </>
        })}
      </Swiper>
    </>
  )
}

const drawSlideItems = (props: HorizontalSliderProps, slidesPerView: number, style: any, randId: string) => {
  return (
    <>
      {props.asSlider ? (
        <>11
          <Swiper slidesPerView={slidesPerView * 1.1}>{drawItems(props, style, randId, slidesPerView)}</Swiper>
        </>
      ) : (
        <>{drawItems(props, style, randId || "", slidesPerView)}</>
      )}
    </>
  )
}

export default drawSlideItems