import { moveEpisode } from "./moveEpisode"
import { AnimeEpisodes } from "types/Anime"
import settings from "config/Settings"

export const cacheContent = async (
  episodes: AnimeEpisodes,
  cid: number,
  next_serie: number,
  last_episode: number,
  loaded?: (id: number) => void,
  direction: 1 | -1 = 1,
  current_serie: number = -1,
  maxCachedEpisodes: number = settings.maxCachedEpisodes,
): Promise<void> => {
  if (next_serie === 0 && direction === 1) next_serie = 1
  if (current_serie === -1) current_serie = next_serie
  if (next_serie > 0 && next_serie !== current_serie && next_serie <= last_episode)
    await moveEpisode({ episodes, eserie: next_serie })
  loaded && loaded(next_serie)
  if (maxCachedEpisodes <= 0) return
  if (next_serie > last_episode) {
    direction = -1
    next_serie = current_serie
    maxCachedEpisodes++
  }
  if (
    (next_serie <= last_episode && direction === 1) ||
    (next_serie >= 1 && direction === -1)
  ) return cacheContent(episodes, cid, next_serie + direction, last_episode, loaded, direction, current_serie, maxCachedEpisodes - 1)
  return
}