import { ReactNode } from "react"

export type BaseBreadcrumbsProps = {
  items: BaseBreadcrumbsItem[],
  max?: number,
  compact?: boolean,
  className?: string
}

export const defaultBaseBreadcrumbsProps: BaseBreadcrumbsProps = {
  items: [],
  max: 2,
  compact: false
}

export type BaseBreadcrumbsItem = {
  title: string,
  href?: string,
  icon?: string,
}



export type BreadcrumbsProps = {
  items: BreadcrumbsItem[],
  wrap?: boolean,
  className?: string,
}

export type BreadcrumbsItem = {
  children: ReactNode,
}