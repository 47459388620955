import { checkInterval, Responses } from "types/Connect"
import { HorizontalSliderTypes } from "types/Media/HorizontalSlider"
import { Slide, SliderType } from "types/Media/Slider"
import { AniTitleFilterProps } from "./AniTitle"

export type ContentModes = "slider" | "grid"

export type ContentType = "news" | "content" | "episode" | "character" | "staff" | ""

export type ContentProps = {
  title?: string,
  href?: string,
  watchLastCall: boolean,
  loadContent: boolean,
  resetPage?: boolean,
  mode?: ContentModes,
  show?: "newcontent" | "newcontentplay" | "all"
  type?: HorizontalSliderTypes,
  endpoint?: string,
  force?: boolean,
  params?: {},
  slidesPerView?: SliderType,
  checkInterval?: checkInterval,
  onEmptySlider?: Function,
  onClick?: Function,
  gridLimit?: number,
  slideLimit?: number,
  filterRepeated?: boolean,
  filterRepeatedByEpisode?: boolean,
  showEndOfList?: boolean,
  refreshGrid?: boolean,
  cachedContent?: (response: Responses) => void,
  filters?: AniTitleFilterProps[],
}

export const defaultContentProps = {
  mode: "slider",
  params: {},
  resetPage: false,
  filterRepeated: false,
  filterRepeatedByEpisode: false,
  showEndOfList: true,
  force: false,
} as ContentProps

export type ContentGridProps = {
  title: string,
  lastCallPlay: string,
  newItemsPlay: Slide[],
  type?: HorizontalSliderTypes,
  onEmptySlider?: Function,
  isLoading?: boolean,
  onClick?: Function,
  filters?: AniTitleFilterProps[],
  onFiltersChange?: (filters: AniTitleFilterProps[]) => void,
}

export type ContentSliderProps = {
  title: string,
  lastCall: string,
  href: string,
  newItems: Slide[],
  type?: HorizontalSliderTypes,
  slidesPerView?: SliderType,
  onEmptySlider?: Function,
  isLoading?: boolean,
  onClick?: Function,
}

export type ContentEndOfListProps = {
  itemCount?: number,
}