export type Responses = {
  data: any,
  status: number,
  statusText: string,
  headers?: any,
  request?: any,
  config?: any,
  endpoint?: string,
  body?: { [key: string]: string | number | boolean },
}

export type Methods = "get" | "post" | "put" | "delete" | "patch" | "options" | "head"

export type ConnectOptions = {
  bodyencoded?: boolean,
  force?: boolean,
  cached?: boolean,
  onlyCached?: boolean,
  permanent?: boolean,
  checkInterval?: checkInterval,
  cachedContent?: (response: Responses) => void,
}

export const defaultConnectOptions: ConnectOptions = {
  bodyencoded: false,
  force: false,
  cached: true,
  onlyCached: false,
  permanent: false,
  checkInterval: "short",
}

export type ConnectMethodOptionsProps = {
  endpoint: string,
  body?: any,
  params?: any,
  options?: ConnectOptions
}

export type ConnectArgumentsProps = {
  [key: string]: any,
}

export type ConnectMethodProps = {
  method: Methods,
  endpoint: string,
  body?: ConnectArgumentsProps,
  params?: ConnectArgumentsProps,
  options?: ConnectOptions
}

export type checkInterval = "shorter" | "short" | "day" | "month"