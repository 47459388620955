//import { AdMob, AdOptions, AdLoadInfo, InterstitialAdPluginEvents } from "@capacitor-community/admob"

import { AdLoadInfo } from "types/Media/Ads/AdMob"

export const interstitial = async (onLoaded: (info: AdLoadInfo) => void): Promise<void> => {
  /*AdMob.addListener(InterstitialAdPluginEvents.Loaded, (info: AdLoadInfo) =>
    onLoaded(info))

  const options: AdOptions = {
    adId: new settings().getadmob_interstitialid,
    isTesting: true
    // npa: true
  }
  await AdMob.prepareInterstitial(options)
  await AdMob.showInterstitial()*/
}