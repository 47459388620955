import { IonSearchbar, IonToolbar } from "@ionic/react"
import { trashBin } from "ionicons/icons"
import { useEffect, useState } from "react"

import { menuItems } from "types/Commons/Menu"
import { SearchbarProps, SearchQuery } from "types/Commons/Searchbar"

import "./index.scss"

export const filterResults = (query: SearchQuery, data: menuItems[]) => {
  if (query.length === 0) return data
  if (query.length === 1) return data.filter((d: menuItems) =>
    (d.title.toLowerCase().indexOf(query[0]) > -1 || (d.description && d.description.toLowerCase().indexOf(query[0]) > -1)))
  return data.filter((d: menuItems) => {
    let match = 0
    query.map((q: string) => {
      if (d.title.toLowerCase().indexOf(q) > -1 || (d.description && d.description.toLowerCase().indexOf(q) > -1)) match++
      return q
    })
    return match === query.length
  })
}


const Searchbar = (props: SearchbarProps) => {
  const [search, setSearch] = useState<string>("")
  const [results, setResults] = useState<menuItems[]>([...props.data || []])

  const handleChange = (e: Event) => {
    let query: SearchQuery = []
    const target = e.target as HTMLIonSearchbarElement
    if (target) {
      target.value = target.value!.trim()
      query = target.value!.toLowerCase().split(" ")
    }
    setSearch(target.value!.toLowerCase())
    props.callbackChange && props.callbackChange(target.value!.toLowerCase())

    const results: menuItems[] = filterResults(query, props.data || [])
    setResults(results)
  }

  useEffect(() => {
    props.callbackResults && props.callbackResults(results as menuItems[], search)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [results, search])

  return (
    <IonToolbar className="component-searchbar">
      <IonSearchbar
        color={props.highlight ? "medium" : undefined}
        animated={props.animated || true}
        placeholder={props.placeholder || undefined}
        clearIcon={props.clearIcon || trashBin}
        debounce={props.debounce || 500}
        inputmode="search"
        onIonChange={(e: Event) => handleChange(e)}
        onIonFocus={props.onFocus}
        onIonBlur={props.onBlur}
      />
    </IonToolbar>
  )
}

export default Searchbar