import { IonButton, IonButtons, IonHeader, IonIcon, IonLabel, IonTitle, IonToolbar } from "@ionic/react"
import { starOutline } from "ionicons/icons"
import { useHistory } from "react-router-dom"

import { SpinnerCircular } from "components/Commons/Spinner"
import { AniTitleProps } from "types/Page/AniTitle"
import locales from "config/Locales"
import Animate from "utils/Animate"
import Filters from "./Filters"

import "./index.scss"

const AniTitle = (props: AniTitleProps) => {
  const history = useHistory()

  return (
    <IonHeader className={`component-ani-title ${props.className || ""}`}>
      <IonToolbar>
        <IonTitle slot="start" className={Animate.setAnimation("slideInLeft")}>{props.title}</IonTitle>
        {props.href && (
          <IonButtons slot="end" className={Animate.setAnimation("slideInRight")}>
            <IonButton onClick={() => props.href && history.push(props.href)}>
              {locales._get("see_more")}
            </IonButton>
          </IonButtons>
        )}
        {props.isLoading && (
          <IonLabel slot="end">
            <SpinnerCircular />
          </IonLabel>
        )}
        <Filters
          {...props}
          onFiltersChange={props.onFiltersChange}
        />
      </IonToolbar>
      {props.subtitle && (
        <IonToolbar className="subtitle">
          <IonTitle slot={props.href ? "end" : "start"} className={Animate.setAnimation("fadeInDown")}>
            <IonIcon icon={starOutline} />
            {props.subtitle}
            <IonIcon icon={starOutline} />
          </IonTitle>
        </IonToolbar>
      )}
    </IonHeader>
  )
}

export default AniTitle