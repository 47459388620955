import { GendersStudiosViewProps } from "types/Page/GendersStudiosView"
import locales from "config/Locales"
import ViewList from "../ViewList"

const Studio = (props: GendersStudiosViewProps) => {
  const { info, ...rest } = props
  const { id, title } = info
  const _info = {
    id: id,
    title: locales._get("more_from_studio", title),
  }

  return (
    <ViewList info={_info} type="studio" {...rest} />
  )
}

export default Studio