import { IonIcon, IonItem, IonLabel, IonList, useIonToast } from "@ionic/react"
import { ReactNode, useEffect, useState } from "react"
import { copyOutline, mailOutline, shareSocialOutline } from "ionicons/icons"
import { CanShareResult } from "@capacitor/share"

import { AnimeTopBarProps } from "types/Anime"
import { share } from "../../../../../components/Commons/Social"
import { SpinnerCircular } from "components/Commons/Spinner"
import Alerts from "components/Commons/Alerts"
import locales from "config/Locales"

import "./index.scss"

const AnimeTopBarShareDropDown = (props: AnimeTopBarProps) => {
  const [link] = useState<string>(`${props.title}: ${props.link}`)
  const [showAlert, setShowAlert] = useState<boolean>(false)
  const [checkingShare, setCheckingShare] = useState<boolean>(true)
  const [canShare, setCanShare] = useState<boolean>(false)
  const [toast] = useIonToast()

  const Item = (props: { label: ReactNode, icon?: string, onClick: () => void }) => (
    <IonItem onClick={props.onClick} color="primary">
      {props.icon &&
        <IonIcon icon={props.icon} slot="start" />
      }
      <IonLabel>{props.label}</IonLabel>
    </IonItem>
  )

  useEffect(() => {
    share.canShareOther().then((result: CanShareResult) => {
      setCanShare(result.value)
      setCheckingShare(false)
    })
  }, [])

  return (
    <>
      <IonList lines="full" className="component-anime-topbar-share">
        <Item
          label={locales._get("content.share-email")}
          icon={mailOutline}
          onClick={() => share.shareEmail(link)}
        />
        <Item
          label={locales._get("content.share-copy")}
          icon={copyOutline}
          onClick={() => {
            share.shareCopy(props.link)
            setShowAlert(true)
          }}
        />
        {checkingShare &&
          <Item
            label={<>
              {locales._get("content.share-other")}
              <SpinnerCircular />
            </>}
            icon={shareSocialOutline}
            onClick={() => {
              toast({
                message: locales._get("loading_content", locales._get("content")),
                duration: 2000,
                color: "warning",
                position: "top",
              })
            }}
          />
        }
        {canShare &&
          <Item
            label={locales._get("content.share-other")}
            icon={shareSocialOutline}
            onClick={() => share.shareOther(props.link)}
          />
        }
      </IonList>
      <Alerts
        title={locales._get("content.share-copy-success")}
        duration={2000}
        showAlert={showAlert}
        onShowAlert={() => setShowAlert(false)}
      />
    </>
  )
}

export default AnimeTopBarShareDropDown