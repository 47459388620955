import { IonMenuToggle, IonButton, IonIcon } from "@ionic/react"
import { close } from "ionicons/icons"

export type targetTypes = "_self" | "_blank"

export type menuItems = {
  title: string,
  subtitle?: string,
  description?: string,
  className?: string,
  icon?: string,
  image?: string,
  href: string,
  target?: targetTypes,
  onClick?: Function,
  arrow?: string,
  onlyUser?: boolean,
}

type bgMenuOptions = {
  image?: string,
}

export type MenuProps = {
  type?: "overlay" | "push" | "reveal",
  activeMenu?: string,
  color?: string,
  showHeader?: boolean,
  title?: string,
  side?: "end" | "start",
  items?: menuItems[],
  background?: bgMenuOptions,
  startButtons?: React.ReactNode,
  endButtons?: React.ReactNode,
  children?: React.ReactNode,
  contentTop?: React.ReactNode,
  contentBottom?: React.ReactNode,
}

export const defaultMenuProps: MenuProps = {
  showHeader: true,
  startButtons: (
    <>
      <IonMenuToggle>
        <IonButton>
          <IonIcon icon={close} />
        </IonButton>
      </IonMenuToggle>
    </>
  ),
  endButtons: null,
  children: null,
  contentTop: null,
  contentBottom: null,
}