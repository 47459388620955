import { IonList, IonItem } from "@ionic/react"
import { useEffect, useState } from "react"

import { defaultHorizontalSliderProps, HorizontalSliderProps } from "types/Media/HorizontalSlider"
import { ItemThumbs } from "../Card/Thumbs"
import useWindowSize from "hooks/useWindowSize"
import Strings from "utils/Strings"
import drawSlideItems from "components/Media/drawSlideItems"
import Animate from "utils/Animate"

import "./index.scss"

const Grid = (props: HorizontalSliderProps) => {
  const windowSize = useWindowSize()
  const [style, setStyle] = useState({ skeleton: {}, block: {} })
  const [slidesPerView, setSlidesPerView] = useState(props.slidesPerView.s)
  const randId = props.name !== "" ? props.name : Strings.getRandomNumber(7)//

  useEffect(() => {
    if (windowSize.loaded) {
      if (windowSize.breakpoint === "s") setSlidesPerView(props.slidesPerView.s)
      else if (windowSize.breakpoint === "m") setSlidesPerView(props.slidesPerView.m)
      else if (windowSize.breakpoint === "l") setSlidesPerView(props.slidesPerView.l)
      else setSlidesPerView(props.slidesPerView.xl)
    }
    setStyle({
      skeleton: {
        width: `${(100 / slidesPerView).toFixed(4)}vw`,
        height: "50vw",
        maxHeight: "12rem",
      },
      block: {
        width: `${(100 / slidesPerView).toFixed(2)}%`
      },
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [windowSize.width])

  if (props.slides.length === 0) return (
    <IonList className="component-grid">
      <IonItem>
        <ItemThumbs count={slidesPerView} style={style.skeleton} />
      </IonItem>
    </IonList>
  )

  return (
    <div
      className={`component-grid ${props.className || ""} ${props.className || ""} ${props.animation || Animate.setAnimation("fadeIn")}`}
    >
      {drawSlideItems(props, slidesPerView, style, randId || "")}
    </div >
  )
}

Grid.defaultProps = defaultHorizontalSliderProps

export default Grid