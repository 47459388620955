import { useEffect, useState } from "react"
import { IonCol, IonGrid, IonRow } from "@ionic/react"
import { Capacitor } from "@capacitor/core"

import { SoftnyaAdsPositions } from "types/Media/Ads/SoftnyaAds"
import { checklock } from "../AdMob/Methods/checklock"
import settings, { isDev } from "config/Settings"
import useWindowSize from "hooks/useWindowSize"
import User from "components/Users/User"

import "./index.scss"

export const showAdsTerra = (position?: SoftnyaAdsPositions): boolean => {
  const bannerlargelock: boolean = checklock(new settings().getadslocklargetime)
  const bannercontentlock: boolean = checklock(new settings().getadslockcontenttime)
  if (isDev) return false
  if (User.inList("BLOCK_ADSTERRA") || (position === "TOP" && Capacitor.isNativePlatform())) return false
  if (position === "TOP" && bannerlargelock) return false
  if (position !== "TOP" && bannercontentlock) return false
  return true
}

const AdsTerra = (props: { position: SoftnyaAdsPositions | undefined, className?: string, isDemo?: boolean, }) => {
  const [cols, setCols] = useState<number[]>([1, 2, 3, 4])
  const windowSize = useWindowSize()

  useEffect(() => {
    if (windowSize.loaded) {
      if (windowSize.breakpoint === "s") setCols([1])
      else if (windowSize.breakpoint === "m") setCols([1, 2])
      else setCols([1, 2, 3, 4])
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [windowSize.width])

  if (!showAdsTerra(props.position) && !props.isDemo) return <></>
  return (
    <div className={`ads-terra ion-text-center ${props.position} ${props.className || ""}`}>
      <div className="component-softnya-ads-banner component-softnya-ads-code">
        {props.position === "DETAILS_BOTTOM" &&// 19491141
          <iframe src="//rattlekindergartencultivate.com/watchnew?key=5afc8e51d0c1610738f0f7e20008e52f" title="19491141" width="468" height="60" scrolling="no"></iframe>
        }
        {props.position === "DETAILS_TOP" &&// 19503732
          <iframe src="//rattlekindergartencultivate.com/watchnew?key=dd9970e302bb1776e728168b40a7fae3" title="19503732" width="320" height="50" scrolling="no"></iframe>
        }
        {props.position === "LOGIN" &&// 19503752
          <iframe src="//rattlekindergartencultivate.com/watchnew?key=2c2e0dca548e833469911c7b1dead326" title="19503752" width="300" height="250" scrolling="no"></iframe>
        }
        {(props.position === "CONTENT_B" || props.position === "CONTENT_PLAYER") &&// 19503761
          <div id="container-4ed53680121851a02b5757581c4f2483"></div>
        }
        {(props.position === "CONTENT_A" || props.position === "CONTENT_D") &&// 19503740
          <IonGrid>
            <IonRow>
              {cols.map(i =>
                <IonCol key={i}>
                  <iframe src="//rattlekindergartencultivate.com/watchnew?key=1f8e0c0d153118198e48f4f2ae2e1616" title="19503740" width="160" height="300" scrolling="no"></iframe>
                </IonCol>
              )}
            </IonRow>
          </IonGrid>
        }
      </div>
    </div>
  )
}

export default AdsTerra