import { UaEventOptions } from "react-ga4/types/ga4"
import ReactGA from "react-ga4"

import { AppSettingsID } from "utils/AppSettings"
import settings, { isDev } from "config/Settings"
import locales from "config/Locales"
import User from "components/Users/User"

ReactGA.initialize([
  {
    trackingId: !isDev ? settings.GAid : "UA-000000000-0",
    gaOptions: {
      userId: User.getUsername("guest").toLowerCase(),
      contentGroup1: AppSettingsID.getSetting("version", "Web"),
    },
  },
])


class GA {
  static logs = (event: string, label: string, action?: string) => {
    ReactGA._testMode = isDev
    isDev && console.warn(`GA event ${event}${action ? ` | action ${action}` : ""} | label: ${label}`)
  }

  static pageview = (title: string, page?: string) => {
    const _page = page || window.location.pathname
    if (!title || title === locales._get("appname")) return
    const GAid = `${title}-${_page}`
    if (GAid === AppSettingsID.getSetting("ga_pv", "")) return
    AppSettingsID.setSetting("ga_pv", GAid)

    GA.logs(_page, title)
    ReactGA.send({ hitType: "pageview", page: _page, title: title })
  }

  static event = (props: UaEventOptions) => {
    const { category, action, label, value, nonInteraction, transport } = props
    if (!category || !action || label === locales._get("appname")) return
    const GAid = `${label}-${action}`
    if (GAid === AppSettingsID.getSetting(`ga_${action}`, "")) return
    AppSettingsID.setSetting(`ga_${action}`, GAid)

    GA.logs(category, label || "", action)
    ReactGA.send({ hitType: "event", eventCategory: category, eventAction: action, eventLabel: label, eventValue: value, nonInteraction, transport })
  }

  static search = (search: string) => {
    GA.event({ category: "Search", action: "search", label: search })
  }
}

export default GA