import { useEffect, useState } from "react"

import { WindowSizeTypes, breakpoints } from "types/Hooks/WindowSize"

const useWindowSize = () => {
  const [windowSize, setWindowSize] = useState<WindowSizeTypes>({
    width: 0,
    height: 0,
    isPortrait: false,
    isLandScape: false,
    breakpoint: undefined,
    inViewPort: false,
    loaded: false,
  })

  useEffect(() => {
    const handleResize = () => {
      setWindowSize({
        width: window.innerWidth,
        height: window.innerHeight,
        isPortrait: window.innerHeight > window.innerWidth,
        isLandScape: window.innerHeight < window.innerWidth,
        breakpoint: window.innerWidth < breakpoints.s ? "s" : window.innerWidth < breakpoints.m ? "m" : "l",
        inViewPort: document.visibilityState === "visible",
        loaded: true,
      })
    }

    window.addEventListener("resize", handleResize)
    window.addEventListener("load", handleResize)

    handleResize()

    return () => window.removeEventListener("resize", handleResize)
  }, [])

  return windowSize
}

export default useWindowSize
