//import { BannerAdPosition, BannerAdSize } from "@capacitor-community/admob"

export type AdMobBannerProps = {
  adSize?: any,
  position?: any,
  margin?: number,
  isTesting?: boolean,
}

//admob dependencies declarations (all that must be on dependencies)

export enum BannerAdSize {
  BANNER = "BANNER",
  FULL_BANNER = "FULL_BANNER",
  LARGE_BANNER = "LARGE_BANNER",
  MEDIUM_RECTANGLE = "MEDIUM_RECTANGLE",
  LEADERBOARD = "LEADERBOARD",
  ADAPTIVE_BANNER = "ADAPTIVE_BANNER",
  SMART_BANNER = "SMART_BANNER",
}

export enum BannerAdPosition {
  TOP_CENTER = "TOP_CENTER",
  CENTER = "CENTER",
  BOTTOM_CENTER = "BOTTOM_CENTER",
}

export interface AdOptions {
  adId: string,
  isTesting?: boolean,
  margin?: number,
  npa?: boolean,
}

export interface BannerAdOptions extends AdOptions {
  adSize?: BannerAdSize,
  position?: BannerAdPosition,
}

export interface AdLoadInfo {
  adUnitId: string,
}

export interface AdMobRewardItem {
  type: string,
  amount: number,
}