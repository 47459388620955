import { Endpoints } from "api/Endpoints"
import Meta from "components/Media/Meta"
import locales from "config/Locales"
import Pager from "pages/_commons/Pager"

const Support = () => {
  return (
    <>
      <Pager
        endpoint={Endpoints.support}
        className="page-support"
        storageKey="Support"
        asMarkdown={true}
      />
      <Meta
        title={locales._get("support")}
      />
    </>
  )
}

export default Support