import { Endpoints } from "api/Endpoints"
import { deviceInfo } from "config/Settings/Device"
import { Responses } from "types/Connect"
import { UserProfile } from "types/User"
import locales from "config/Locales"
import Connect from "components/Connect"
import LoaderOptions from "components/Content/ScreenLoader/LoaderOptions"
import User from "components/Users/User"
import Refresher from "components/Connect/Refresher"
import RedirectURL from "utils/Redirect"

export const doLogin = (props: {
  username: string,
  password: string,
  loginmsg: string,
  setLoginAlert: Function,
  closeActiveModal: Function,
  history: any,
}) => {
  if (props.loginmsg === locales._get("loginfailed")) return
  if (props.username === "" || props.password === "") return props.setLoginAlert(locales._get("loginfailed"))
  props.setLoginAlert("")

  if (props.username && props.password) {
    props.setLoginAlert(locales._get("loading"))
    LoaderOptions.openLoader("signing_in")
    Connect.post({
      endpoint: Endpoints.login,
      body: {
        ...deviceInfo,
        username: props.username,
        password: props.password,
      }
    }).then(async (response: Responses) => {
      if (response.data?.code === 200 && response.data?.token !== "") {
        User.setActiveUser({
          user: response.data as UserProfile,
          callback: () => {
            Refresher.user(() => {
              props.closeActiveModal()
              LoaderOptions.closeLoader()
              props.history.push("/welcome")
              RedirectURL()
            }, true)
          }
        })
        return
      }
      LoaderOptions.closeLoader()
      props.setLoginAlert(locales._get("loginfailed"))
    }).catch((e: any) => {
      LoaderOptions.closeLoader()
      props.setLoginAlert(locales._get("loginfailed"))
    })
  }
}