import {
  IonBadge,
  IonCard,
  IonCardContent,
  IonCardHeader,
  IonCardSubtitle,
  IonCardTitle,
  IonItem,
} from "@ionic/react"
import { memo, useEffect, useState } from "react"
import { useHistory } from "react-router-dom"

import { CardProps } from "types/Content"
import { ThumbsImage } from "types/Anime/Images"
import { Thumbnail } from "./Thumbs"
import { Slide } from "types/Media/Slider"
import Strings from "utils/Strings"
import settings from "config/Settings"
import Image from "components/Media/Image"

import "./index.scss"

/**
 * Renders a Card component.
 *
 * @param {CardProps.item} props - The item to render.
 * @param {CardProps.className} props.className - The className to add to the component.
 * @param {CardProps.contentBellowImg} props.contentBellowImg - A React Node rendered bellow the image.
 * @param {CardProps.contentOverTitle} props.contentOverTitle - A React Node rendered over the title.
 * @param {CardProps.contentOverHeader} props.contentOverHeader - A React Node rendered over the header.
 * @param {CardProps.thumb} props.thumb - The image to render in the card.
 * @param {CardProps.onClick} props.onClick - The onClick callback, param with item to click.
 * @return {JSX.Element} The rendered Card component.
 */
export const Card = (props: CardProps) => {
  const [imgSrc, setImgSrc] = useState("")
  const [imgBG, setImgBG] = useState({ backgroundImage: `url(${props.item?.img}` || "none" })
  const [itemClass, setItemClass] = useState("")
  const history = useHistory()

  /**
   * Set the background image and update the item class.
   *
   * @return {type} states to image src and class
   */
  const setBG = () => {
    setImgBG({ backgroundImage: `url(${imgSrc})` })
    setItemClass("ion-hidden")
  }

  /**
   * Builds the URL for an image.
   *
   * @param {string} img - The URL of the image.
   * @return {string} The complete URL of the image.
   */
  const buildImg = (img: string): string =>
    `${img?.indexOf("http") === 0 ? "" : settings.apiUrl}${img}`

  useEffect(() => {
    setImgSrc(buildImg(props.item?.img || ""))
  }, [setImgSrc, props.item?.img])

  return (
    <IonCard
      onClick={() => {
        props.item.onClick ? props.item.onClick() : history.push(props.item?.href || "")
        props.onClick && props.onClick(props.item || {} as Slide)
      }}
      className={`component-card skeleton-text-animated ${props.item?.locked === 1 ? "blured" : ""} ${props.className || ""}`}
      data-id={props.item?.id || 0}
      data-cid={props.item?.cid || 0}
      data-title={props.item?.title || ""}
    >
      <div className={`background ${props.item?.locked === 1 ? "blured" : ""}`}
        style={imgBG}
        data-src={imgSrc}
      >
      </div>
      <Image
        alt={props.item?.title}
        src={ThumbsImage(props.thumb)}
        className="ion-invisible"
        isblured={props.item?.locked === 1}
        onIonImgDidLoad={() => setBG()}
        onIonImgWillLoad={() => setBG()}
        onIonError={() => setBG()}
      />
      <IonItem className={itemClass}><Thumbnail /></IonItem>
      {props.contentBellowImg || <></>}
      {props.contentOverHeader || <></>}
      <IonCardHeader>
        <div className="content-over-title">{props.contentOverTitle || <></>}</div>
        {props.item?.title && (
          <IonCardTitle>
            <div>
              {props.item?.badge &&
                <IonBadge
                  className={props.item?.badgeclass || ""}
                  color={props.item?.badgecolor || "primary"}
                ><span>{props.item?.badge}</span></IonBadge>
              }
              <span dangerouslySetInnerHTML={{ __html: props.item?.title }} />
            </div>
          </IonCardTitle>
        )}
        {props.item?.subtitle && (
          <IonCardSubtitle color={props.item?.subtitle_color || "primary"}>{props.item?.subtitle}</IonCardSubtitle>
        )}
      </IonCardHeader>

      {
        props.item?.description && (
          <IonCardContent>
            <div dangerouslySetInnerHTML={{ __html: Strings.maxLength(Strings.stripTags(props.item?.description), settings.maxCardDescriptionLength) }} />
          </IonCardContent>
        )
      }
      <Image
        className="component-card-image-bg-checker"
        src={props.item?.img || ""}
        srcBk={props.item?.params?.img || ""}
        onIonError={() => setImgSrc(buildImg(props.item?.params?.img || ""))}
      />
    </IonCard >
  )
}

export default memo(Card)