import {
  home,
  videocamOutline,
  search,
  newspaperOutline,
  personCircleOutline
} from "ionicons/icons"

import { menuItems } from "types/Commons/Menu"
import locales from "config/Locales"

export const MenuBottom = [
  {
    title: locales._get("releases"),
    icon: home,
    href: "/home",
  },
  {
    title: locales._get("discover"),
    icon: videocamOutline,
    href: "/anime",
  },
  {
    title: locales._get("search"),
    icon: search,
    href: "/search",
  },
  {
    title: locales._get("news"),
    icon: newspaperOutline,
    href: "/news",
  },
  {
    title: locales._get("profile"),
    icon: personCircleOutline,
    href: "/profile",
  },
] as menuItems[]