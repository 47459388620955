import { create } from "zustand"
import { devtools } from "zustand/middleware"

import { SoftnyaAdsBannerItem } from "types/Media/Ads/SoftnyaAds"

const useAds = create(devtools((set: any) => ({
  showBanner: false,
  setShowBanner: (showBanner: boolean) => set({ showBanner }),
  showSoftnyaVideo: false,
  setShowSoftnyaVideo: (showSoftnyaVideo: boolean) => set({ showSoftnyaVideo }),
  softnyaAds: {} as SoftnyaAdsBannerItem,
  setSoftnyaAds: (softnyaAds: SoftnyaAdsBannerItem) => set({ softnyaAds }),
}), { name: "useAds" }))

export default useAds