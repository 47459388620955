import { useEffect, useState } from "react"

import { AnimeRelatedRecommendedProps, AnimeRelatedRecommendedResponse } from "types/Anime/AnimeRelatedRecommended"
import { Slide } from "types/Media/Slider"
import { AllMightyLaugh } from "types/Anime/Images"
import { getRelatedRecommended } from "../../../Anime/Methods/getRelatedRecommended"
import { fitSlideContentTypes } from "types/Anime"
import ContentGrid from "pages/_commons/Content/Content/Grid"
import OverCard from "components/Content/OverCard"
import locales from "config/Locales"
import AnimeContentParser from "pages/_commons/Content/AnimeContent"
import useAnime from "store/useAnime"

import "./index.scss"
import "pages/_commons/Content/Content/index.scss"

const AnimeRelatedRecommended = (props: AnimeRelatedRecommendedProps) => {
  const [items, setItems] = useState<Slide[]>([] as Slide[])
  const [loaded, setLoaded] = useState(false)
  const setId = useAnime<Function>((state: any) => state.setId)
  const setEid = useAnime<Function>((state: any) => state.setEid)

  useEffect(() => {
    setLoaded(false)
    getRelatedRecommended(props.content?.id).then((response: AnimeRelatedRecommendedResponse) => {
      setItems(() => {
        setLoaded(true)
        let items: Slide[] = [] as Slide[]
        if (props.type === "recommendations") items = response.recommended || [] as Slide[]
        else items = response.related || [] as Slide[]
        items.map((item: Slide) => {
          item = AnimeContentParser.fitSlideContent({
            item,
            setId: setId,
            setEid: setEid,
          } as fitSlideContentTypes)
          item.onClicked = () => setItems([] as Slide[])
          return item
        })
        return items
      })
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.content?.id, props.type])

  return (
    <div className="anime-related-recommended">
      {(items.length > 0 || !loaded) ?
        <ContentGrid
          title={""}
          lastCallPlay={""}
          newItemsPlay={items}
          type="item"
        />
        :
        <OverCard
          image={AllMightyLaugh}
          title={locales._get(`content.not-${props.type}`)}
        />
      }
    </div>
  )
}

export default AnimeRelatedRecommended