import { IonText } from "@ionic/react"
import { SpinnerCircular } from "components/Commons/Spinner"

import locales from "config/Locales"

import "./index.scss"

const Loader = (props: { text?: string, spinnerPosition?: "top" | "inline" | "bottom" }) => {
  const { text, spinnerPosition } = props

  const Spinner = () => {
    return <><SpinnerCircular /></>
  }
  return (
    <div className="ion-padding ion-text-center component-loader">
      {spinnerPosition === "top" && <Spinner />}
      <IonText>
        {spinnerPosition === "inline" && <Spinner />}
        {!spinnerPosition && <Spinner />}
        {locales._get("loading_content", { "%s": (text ? text : "") })}
      </IonText>
      {spinnerPosition === "bottom" && <Spinner />}
    </div>
  )
}

export default Loader