import { NewsDetailsContent } from "types/NewsDetails"
import { fullDateFormat } from "types/Utils/Date"
import JDate from "utils/JDate"
import Strings from "utils/Strings"

class NewsParser {
  public static fitNewsContent = (content: NewsDetailsContent): NewsDetailsContent => {
    if (!content) return {} as NewsDetailsContent
    if (content.formated === true) return content
    content.title = Strings.capitalizeFirstLetter(Strings.encode(content.title || ""))
    content.content = Strings.encode(content.content || "")
    if (content.publish_up) content.publish_up = JDate.format(JDate.toDate(content.publish_up), fullDateFormat)
    content.formated = true
    return content
  }
}

export default NewsParser