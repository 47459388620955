import { SliderViewDefault, SliderViewPlay } from "types/Media/Slider"
import { ContentSliderProps } from "types/Page/Content"
import HorizontalSlider from "components/Media/HorizontalSlider"
import AniTitle from "pages/_commons/AniTitle"

const ContentSlider = (props: ContentSliderProps) => {
  const className = "component-content mode-slider"

  return (
    <>
      <AniTitle
        title={props.title}
        subtitle={props.lastCall}
        className={className}
        href={props.href}
        isLoading={props.isLoading}
      />
      <HorizontalSlider
        slides={props.newItems}
        className={className}
        type={props.type || "item"}
        onClick={props.onClick}
        slidesPerView={props.slidesPerView ? props.slidesPerView : (props.type === "player" ? SliderViewPlay : SliderViewDefault)}
      />
    </>
  )
}

export default ContentSlider