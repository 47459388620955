import { useEffect, useState } from "react"

import { SettingsStorageTypeProps } from "types/Page/Profile"
import { SpinnerCircular } from "components/Commons/Spinner"
import { Item } from "../../../../components/Content/FormBuilder/Item"
import locales from "config/Locales"
import Strings from "utils/Strings"

const SettingsStorageType = (props: SettingsStorageTypeProps) => {
  const [minFreeSpace] = useState<number>(50)//mb
  const [minFreeSpacePercent, setMinFreeSpacePercent] = useState<number>(5)
  const [used, setUsed] = useState<string>("0")
  const [usedNum, setUsedNum] = useState<number>(0)
  const [minUse, setMinUse] = useState<number>(0)
  const [available, setAvailable] = useState<string>("0")
  const [availablePercent, setAvailablePercent] = useState<number>(0)
  const [total, setTotal] = useState<string>("0")
  const [totalNum, setTotalNum] = useState<number>(0)
  const [maxPercent, setMaxPercent] = useState<number>(props.maxPercent)
  const [calcMaxPercent, setCalcmaxPercent] = useState<number>(maxPercent)

  useEffect(() => {
    if (!props.utils) return
    const maxSizeMb = (props.utils?.maxSize || 0) / 1024 / 1024
    let _minFreeSpacePercent = minFreeSpacePercent
    if (maxSizeMb >= minFreeSpace) {
      _minFreeSpacePercent = (minFreeSpace * 100) / (maxSizeMb > 0 ? maxSizeMb : 1)
      setMinFreeSpacePercent(_minFreeSpacePercent)
    }
    setUsed(Strings.minifySize(props.utils?.used?.space || 0))
    setUsedNum(Math.ceil(props.utils?.used?.percent || 0))
    setMinUse(Math.ceil((props.utils?.used?.percent || 0) + _minFreeSpacePercent))
    setAvailable(Strings.minifySize(props.utils?.available?.space || 0))
    setAvailablePercent(Math.floor(props.utils?.available?.percent || 0))
    setTotal(Strings.minifySize(props.utils?.maxSize || 0))
    setTotalNum(props.utils?.maxSize || 0)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.utils])

  useEffect(() =>
    // eslint-disable-next-line react-hooks/exhaustive-deps
    props.setMaxPercent(maxPercent), [maxPercent])

  return (
    <>
      <Item
        title={locales._get(`profile.settings-${props.type}-limits`)}
        asSubHeading={true}
      />
      {total === "0" ?
        <Item
          title={<>
            <SpinnerCircular />
            {locales._get("profile.settings-loading-storage")}
          </>}
        />
        : <>
          <Item
            title={locales._get(`profile.settings-${props.type}-limit-db-used`, { "%s": used, "%t": total, "%p": usedNum.toString() })}
          />
          <Item
            title={locales._get(`profile.settings-${props.type}-limit-db-available`, { "%s": available, "%p": availablePercent })}
          />
          <Item
            title={locales._get(`profile.settings-${props.type}-limit`, total)}
            subtitle={locales._get(`profile.settings-${props.type}-limit-subtitle`, minUse)}
            type="range"
            value={maxPercent}
            valueSufix={`%<br /><small>${Strings.minifySize(totalNum * (calcMaxPercent / 100))}</small>`}
            valueRange={{
              min: minUse,
              max: 98,
            }}
            onChanging={(e: any) => {
              setCalcmaxPercent(e.detail.value)
            }}
            onChange={(e: any) => {
              setMaxPercent(e.target.value)
              setCalcmaxPercent(e.detail.value)
            }}
          />
        </>}
    </>
  )
}

export default SettingsStorageType