import { Endpoints } from "api/Endpoints"
import { Responses } from "types/Connect"
import { NewsDetailsContent } from "types/NewsDetails"
import Connect from "components/Connect"
import NewsParser from "pages/_commons/Content/NewsDetails"

export const getNews = async (props: { nid: number, force?: boolean }): Promise<NewsDetailsContent> => {
  if (props.nid === 0) return {} as NewsDetailsContent
  const response: Responses = await Connect.postcached({
    endpoint: Endpoints.newsDetails,
    params: { id: props.nid },
    options: { checkInterval: "month", force: props.force || false },
  })
  if (!response.data?.result && !(props.force || false)) return getNews({ nid: props.nid, force: true })
  return NewsParser.fitNewsContent(response.data?.result as NewsDetailsContent)
}