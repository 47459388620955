//import { AdMob, RewardAdOptions, AdLoadInfo, RewardAdPluginEvents, AdMobRewardItem } from "@capacitor-community/admob"

import { AdMobRewardItem } from "types/Media/Ads/AdMob"
//import settings from "config/Settings"

export const rewardVideo = async (onRewarded: (item: AdMobRewardItem) => void): Promise<AdMobRewardItem> => {
  /*AdMob.addListener(RewardAdPluginEvents.Loaded, (info: AdLoadInfo) => {
    // Subscribe prepared rewardVideo
  })

  AdMob.addListener(RewardAdPluginEvents.Rewarded, (rewardItem: AdMobRewardItem) =>
    onRewarded(rewardItem))

  AdMob.addListener(RewardAdPluginEvents.FailedToShow, () =>
    onRewarded({} as AdMobRewardItem))

  AdMob.addListener(RewardAdPluginEvents.FailedToLoad, () =>
    onRewarded({} as AdMobRewardItem))

  AdMob.addListener(RewardAdPluginEvents.Dismissed, () =>
    onRewarded({} as AdMobRewardItem))

  const options: RewardAdOptions = {
    adId: new settings().getadmob_rewardid,
    isTesting: true
    // npa: true
    // ssv: {
    //   userId: "A user ID to send to your SSV"
    //   customData: JSON.stringify({ ...MyCustomData })
    //}
  }
  await AdMob.prepareRewardVideoAd(options)*/
  const rewardItem = {
    type: "reward",
    amount: 0,
  } as AdMobRewardItem //await AdMob.showRewardVideoAd()
  onRewarded(rewardItem)
  return rewardItem
}