//import { AdLoadInfo, AdMob, AdMobRewardItem, BannerAdPosition } from "@capacitor-community/admob"
import { Capacitor } from "@capacitor/core"

import { banner, bannerhide } from "./Methods/banner"
import { interstitial } from "./Methods/interstitial"
import { rewardVideo } from "./Methods/rewardVideo"
import { AdLoadInfo, AdMobBannerProps, AdMobRewardItem } from "types/Media/Ads/AdMob"
import settings, { isDev } from "config/Settings"
import User from "components/Users/User"
import Strings from "utils/Strings"

import "./index.scss"

class AdMobClass {
  isStarted = false
  isTesting = new settings().adsIsTesting

  withAds = (): boolean =>
    (!User.inList("PREMIUM") && !User.inList("GOLD") && Capacitor.isNativePlatform()) ||
    (this.isTesting && Capacitor.isNativePlatform())

  start = async (isTesting?: boolean): Promise<boolean> => {
    this.isTesting = isTesting || this.isTesting
    if (!this.withAds()) return false
    /*const { status } = await AdMob.trackingAuthorizationStatus()
    if (status === "notDetermined") {

    }
    await AdMob.initialize({
      requestTrackingAuthorization: true,
      testingDevices: ["2077ef9a63d2b398840261c8221a0c9b"],
      initializeForTesting: this.isTesting,
    })
    isDev && console.warn("Start Admob", AdMob)*/
    this.isStarted = true
    return true
  }

  addTopClass = (className: string) => {
    const body = document.body
    if (body) body.classList.add(className)
  }

  removeTopClass = (className: string) => {
    const body = document.body
    if (body) body.classList.remove(className)
  }

  showBanner = async (props?: AdMobBannerProps) => {
    if (!this.isStarted) return
    const options: AdMobBannerProps = await banner({
      ...props,
      isTesting: props?.isTesting || this.isTesting,
    })
    this.addTopClass(`admob-banner-${options.position}`)
    isDev && console.warn("Display Admob banner", options)
  }

  hideBanner = async () => {
    bannerhide()
    this.removeTopClass("admob-banner-TOP_CENTER")
    this.removeTopClass("admob-banner-BOTTOM_CENTER")
    this.removeTopClass("admob-banner-CENTER")
    isDev && console.warn("Hide Admob banner")
  }

  showBannerTop = async (props?: AdMobBannerProps) => { }
  //this.showBanner({ ...props, position: BannerAdPosition.TOP_CENTER })

  showBannerBottom = async (props?: AdMobBannerProps) => { }
  //this.showBanner({ ...props, position: BannerAdPosition.BOTTOM_CENTER })

  showBannerCenter = async (props?: AdMobBannerProps) => { }
  //this.showBanner({ ...props, position: BannerAdPosition.CENTER })

  showInterstitial = async (onLoaded: (info: AdLoadInfo) => void) => {
    this.isStarted ? await interstitial((info: AdLoadInfo) => onLoaded(info)) :
      onLoaded({} as AdLoadInfo)
    isDev && console.warn("Display Admob interstitial")
  }

  showRewardVideo = async (onRewarded: (item: AdMobRewardItem) => void) => {
    this.isStarted ? await rewardVideo((item: AdMobRewardItem) => onRewarded(item)) :
      onRewarded({} as AdMobRewardItem)
    isDev && console.warn("Display Admob reward video")
  }

  showRandomVideo = async (onRewarded: (item: AdMobRewardItem) => void, onLoaded: (info: AdLoadInfo) => void) =>
    Strings.getRandomBetween(0, 100) < 100 ?//just show interstitial for now
      this.showInterstitial(onLoaded) :
      this.showRewardVideo(onRewarded)
}

export default AdMobClass