import { IonButton, IonIcon } from "@ionic/react"

import { DropDownButtonProps } from "types/Commons/DropDownButton"
import { DropButtonprops } from "types/Anime/AnimeDropButton"
import locales from "config/Locales"

import "./index.scss"

export const DropButton = (props: DropButtonprops): DropDownButtonProps => {
  return {
    text: props.title,
    fill: "outline",
    id: `drop-item-${props.prefix || ""}-${props.id}`,
    className: "drop-item",
    prefix: props.prefix,
    children: <>
      <b>
        {props.icon && <IonIcon icon={props.icon} />}
        {props.title}
      </b>
      <p><div dangerouslySetInnerHTML={{ __html: props.description || "" }} /></p>
      <IonButton
        fill="solid"
        color="primary"
        className={`drop-item-button-${props.prefix || ""}-${props.id}`}
        onClick={() => {
          props.onClick && props.onClick()
          const popover = document.querySelector(`.drop-item-button-${props.prefix || ""}-${props.id}`)?.parentElement?.closest(".component-dropdown-container") as HTMLIonPopoverElement
          popover && popover.dismiss()
        }}
      >
        {locales._get("content.see-content")}
      </IonButton>
    </>
  }
}