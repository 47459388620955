import { IonPopover, IonContent } from "@ionic/react"

import { defaultDropDownContainer, DropDownContainerProps } from "types/Commons/DropDownContainer"

import "./index.scss"

const DropDownContainer = (props: DropDownContainerProps) => {
  return (
    <IonPopover
      id={`popover-${props.id || "default"}`}
      trigger={props.trigger}
      className={`component-dropdown-container ${props.className}`}
      side={props.side}
      alignment={props.alignment}
      onIonPopoverDidPresent={() => props.onPresent && props.onPresent()}
      onIonPopoverDidDismiss={() => props.onDismiss && props.onDismiss()}
    >
      <IonContent className="ion-padding">
        {props.children || <></>}
      </IonContent>
    </IonPopover>
  )
}

DropDownContainer.defaultProps = defaultDropDownContainer

export default DropDownContainer