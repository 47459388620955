import { IonButton, IonCol, IonGrid, IonIcon, IonRow } from "@ionic/react"
import { addCircleOutline } from "ionicons/icons"
import { useState } from "react"
import { useHistory } from "react-router-dom"

import { UserProfile } from "types/User"
import { UserModalLogin } from "components/Users/UserBar"
import { closeModal } from "components/Commons/Modal"
import { mapUsers } from "./Methods/mapUsers"
import { mapModalUsers } from "./Methods/mapModalUsers"
import Strings from "utils/Strings"
import useModal from "store/useModal"
import settings from "config/Settings"
import User from "components/Users/User"

import "./index.scss"

const Profile = () => {
  const [randomModalIdLogin] = useState<string>(`loginform-${Strings.getRandomNumber(7)}`)
  const [randomModalIdAvatar] = useState<string>("avatarform")
  const [maxCols] = useState<number>(Math.ceil(12 / settings.maxSessons))
  const openModal = useModal<Function>((state: any) => state.openModal)
  const [users, setUsers] = useState<UserProfile[]>(User.getUserList())
  const history = useHistory()

  return (
    <>
      <IonGrid className="component-user-profile">
        <IonRow className="ion-justify-content-center">
          {mapUsers({ users, maxCols, randomModalIdAvatar, history, openModal })}
          {users.length < settings.maxSessons && (
            <IonCol size={maxCols.toString()}>
              <IonButton
                id={randomModalIdLogin}
                fill="clear"
                expand="full"
                onClick={() => {
                  openModal(randomModalIdLogin)
                }}
              >
                <IonIcon icon={addCircleOutline} />
              </IonButton>
            </IonCol>
          )}
        </IonRow>
      </IonGrid>
      {users.length < settings.maxSessons && <UserModalLogin randomModalIdLogin={randomModalIdLogin} />}
      {mapModalUsers({ users, randomModalIdAvatar, closeModal, setUsers })}
    </>
  )
}

export default Profile