import { IonItem, IonLabel, IonText } from "@ionic/react"

import { AnimeItemTabs } from "types/Anime"

export const Item = (props: AnimeItemTabs) => {
  if (
    (!props.content || props.content === undefined || props.content === "undefined") &&
    (!props.contentNode || props.contentNode === undefined || props.contentNode === "undefined")
  ) return <></>

  return (
    <IonItem onClick={() => props.onClick && props.onClick()}>
      <IonLabel>
        <span dangerouslySetInnerHTML={{ __html: props.title || "" }} />:
      </IonLabel>
      <IonText>
        <span dangerouslySetInnerHTML={{ __html: props.content || "" }} />
        {props.contentNode || <></>}
      </IonText>
    </IonItem>
  )
}