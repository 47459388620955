import { IonIcon, IonItem } from "@ionic/react"
import { useCallback, useEffect, useState } from "react"
import { checkmarkCircleOutline, cloudDownloadOutline, eye, eyeOff } from "ionicons/icons"

import { AnimeEpisodeItemProps, episodeStatus } from "types/Anime/AnimeEpisodesSetItems"
import { toggleViewed } from "../Methods/toggleViewed"
import { responseEpisodes } from "types/Anime"
import { SpinnerCircular } from "components/Commons/Spinner"
import { colorTypes } from "types"
import { getEpisodes } from "pages/Discover/Anime/Methods/getEpisodes"
import { moveEpisode } from "pages/Discover/Anime/Methods/moveEpisode"
import Buttons from "components/Commons/Buttons"
import locales from "config/Locales"
import JDate from "utils/JDate"
import User from "components/Users/User"
import AlertUserRequired from "components/Commons/Alerts/UserRequired"
import useAnime from "store/useAnime"
import PremiumBadge from "pages/Discover/Commons/PremiumBadge"

const AnimeEpisodeItem = (props: AnimeEpisodeItemProps) => {
  const lastSeenId = useAnime((state: any) => state.lastSeenId)
  const lastLoadedId = useAnime((state: any) => state.lastLoadedId)
  const setLastLoadedId = useAnime<Function>((state: any) => state.setLastLoadedId)
  const lastLoadingId = useAnime((state: any) => state.lastLoadingId)
  const setLastLoadingId = useAnime<Function>((state: any) => state.setLastLoadingId)
  const setRefreshGrids = useAnime<Function>((state: any) => state.setRefreshGrids)
  const setLastSeenSerie = useAnime<Function>((state: any) => state.setLastSeenSerie)
  const FindViewed = useCallback(() =>
    props.episode.seenDateStr !== "" ? true : false, [props.episode])
  const FindViewedDate = useCallback(() =>
    props.episode.seenDateStr === "" ? "" : locales._get("content.episode-item-seen", props.episode.seenFullDateStr), [props.episode])
  const [subtitle, setSubtitle] = useState<string>(FindViewedDate())
  const [seen, setSeen] = useState<boolean>(FindViewed())
  const [color, setColor] = useState<colorTypes>()
  const [episodeStatus, setEpisodeStatus] = useState<episodeStatus>("pending")
  const [disabled, setDisabled] = useState<boolean>(true)
  const [showAlertUserRequired, setShowAlertUserRequired] = useState<boolean>(false)

  useEffect(() => setColor(seen ? "primary" : "tertiary"), [seen, props.episodesLoaded])

  useEffect(() => setSeen(FindViewed()), [props.episode, FindViewed])

  useEffect(() => {
    if (props.episode?.eid === lastSeenId) {
      setSeen(true)
      setSubtitle(locales._get("content.episode-item-seen", `${JDate.format(JDate.now())} (${locales._get("date.today_from_now")})`))
      getEpisodes({ cid: props.current?.cid, eid: props.episode?.eid, force: true })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [lastSeenId])

  useEffect(() => {
    if (lastLoadedId === props.episode?.episode) {
      setEpisodeStatus("loaded")
      setLastLoadingId(0)
      setDisabled(false)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [lastLoadedId])

  useEffect(() => {
    if (lastLoadingId === props.episode?.episode) setEpisodeStatus("loading")
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [lastLoadingId])

  useEffect(() => setSubtitle(FindViewedDate()), [props.episode, FindViewedDate])

  useEffect(() => setDisabled(!props.episodesLoaded), [props.episodesLoaded])

  useEffect(() => {
    if (props.current?.eid !== props.episode?.eid) return
    getEpisodes({ cid: props.current?.cid, eid: props.episode?.eid, onlyCached: true }).then((data: responseEpisodes) => {
      setEpisodeStatus((data.media?.current?.exist === 1 || episodeStatus === "loaded") ? "loaded" : "pending")
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.episode, props.current])

  return (
    <IonItem className={`${episodeStatus} ${props.current?.eid === props.episode?.eid ? "active" : ""} episode-id-${props.episode?.eid}`}>
      <Buttons
        buttons={[
          {
            text: "",
            subtext: props.episode.hitsStr || "0",
            icon: !disabled ? (seen ? eye : eyeOff) : "spinner",
            color: color,
            fill: "solid",
            disabled: disabled,
            onClick: () => {
              if (!User.isLoggedIn()) {
                setShowAlertUserRequired(true)
                return
              }
              setDisabled(() => {
                toggleViewed({
                  cid: props.current?.cid,
                  eid: props.episode?.eid,
                  serie: props.episode?.episode,
                  seen: !seen,
                  onUpdatedUserLists: (updated: boolean) => setRefreshGrids(updated),
                })
                  .then((modified: boolean) => {
                    getEpisodes({ cid: props.current?.cid, eid: props.episode?.eid, force: true })
                      .then((data: responseEpisodes) => {//update episodes list
                        setEpisodeStatus(data.media?.current?.exist === 1 ? "loaded" : "pending")
                        moveEpisode({//update episode media
                          eserie: props.current?.serie,
                          cid: props.current?.cid,
                          force: true,
                        })
                          .then(() => {
                            setDisabled(false)
                            if (modified) setSeen(() => {
                              setLastSeenSerie(props.episode?.episode)
                              if (seen) props.episode.seenDateStr = JDate.ago(JDate.timestamp(props.episode?.seen_date !== "" ? props.episode.seen_date : JDate.format(JDate.getToday())), undefined, false)
                              else props.episode.seenDateStr = ""
                              return !seen
                            })
                          })
                      })
                  })
                return true
              })
            }
          },
          {
            text: locales._get("content.episode-item", props.episode.episode),
            subtext: seen ? subtitle : "",
            color: color,
            fill: "solid",
            disabled: disabled,
            onClick: () => {
              if (props.current?.eid === props.episode?.eid || lastLoadingId > 0) return
              setEpisodeStatus("loading")
              setLastLoadedId(0)
              setDisabled(() => {
                props.changeEpisode && props.changeEpisode(props.episode.episode)
                return true
              })
            },
            inlineNode: <>
              {props.episode?.premium === 1 &&
                <PremiumBadge />
              }
              <div className={`episode-status ${episodeStatus}`}>
                {episodeStatus === "loaded" && <IonIcon icon={checkmarkCircleOutline} />}
                {episodeStatus === "loading" && <SpinnerCircular />}
                {episodeStatus === "pending" && <IonIcon icon={cloudDownloadOutline} />}
              </div>
            </>
          }
        ]}
      />
      <AlertUserRequired
        showAlert={showAlertUserRequired}
        onShowAlert={(showAlert: boolean) => setShowAlertUserRequired(showAlert)}
      />
    </IonItem>
  )
}

export default AnimeEpisodeItem