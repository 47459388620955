import { IonThumbnail } from "@ionic/react"

import { SkeletonSingle } from "components/Commons/Skeleton"

export const Thumbnail = ({ ...props }) => {
  return (
    <IonThumbnail slot="start" {...props}>
      <SkeletonSingle />
    </IonThumbnail>
  )
}

export const ItemThumbs = ({ count = 0, style = {} }) => {
  const items = []
  for (let i = 0; i < count; i++) {
    items.push(
      <Thumbnail style={style} key={i} />
    )
  }
  return <>{items}</>
}