import { IonContent, IonPage } from "@ionic/react"
import { useEffect, useState } from "react"
import { useHistory } from "react-router-dom"

import { NormalLogoSrc } from "types/Anime/Images"
import { Endpoints } from "api/Endpoints"
import { getQueryFormat, getQueryLocal } from "components/Connect/Exports"
import { VersionContainer } from "config/Settings/Version"
import { sendFullScreen, sendNormalScreen } from "config/Settings/Device"
import useHistorySearch from "store/useHistorySearch"
import settings from "config/Settings"
import Refresher from "components/Connect/Refresher"
import VideoPlayer from "components/Media/VideoPlayer"
import locales from "config/Locales"
import Loader from "pages/_commons/Loader"
import MenuOptions from "utils/MenuOptions"
import Connect from "components/Connect"
import User from "components/Users/User"
import Strings from "utils/Strings"
import Animate from "utils/Animate"
import Image from "components/Media/Image"
import Alerts from "components/Commons/Alerts"

import "./index.scss"

const Splash: React.FC = () => {
  const hideMenu = MenuOptions((state: any) => state.hideMenu)
  const showMenu = MenuOptions((state: any) => state.showMenu)
  const setCurrentSearch = useHistorySearch<Function>((state: any) => state.setCurrentSearch)
  const setCurrentRelevance = useHistorySearch<Function>((state: any) => state.setCurrentRelevance)
  const [loaded, setLoaded] = useState(false)
  const [showLoader, setShowLoader] = useState(true)
  const [animations] = useState<string[]>(Animate.getAnimations(["flip", "rubberBand", "tada", "wobble"]))
  const [animation, setAnimation] = useState<string>("")
  const [showAlert, setShowAlert] = useState<boolean>(false)
  const history = useHistory()

  const getContent = (force: boolean = false) => {
    setShowLoader(true)
    const redirectToApp = () => {
      const initialPage = new settings().initialPage
      showMenu()
      sendNormalScreen()
      setCurrentSearch("")
      setCurrentRelevance(0)
      history.replace(initialPage === "default" ? (`/home/${User.isLoggedIn() ? "foryou" : "anime"}`) : initialPage)
    }

    Refresher.splash(async (response: number) => {
      if (response === 200) {
        setTimeout(() => redirectToApp(), 3000)
      } else {//catch
        const query = Connect.getQuery({ endpoint: Endpoints.slides })
        const local = await getQueryLocal(getQueryFormat(query))
        if (local?.data?.results) {//If some data is downloaded, continue with that
          setTimeout(() => redirectToApp(), 4000)
          return
        }
        setShowLoader(false)
        setShowAlert(true)
      }
    }, force)
  }

  useEffect(() => {
    if (loaded) return
    setShowLoader(true)
    setLoaded(true)
    hideMenu()
    sendFullScreen()

    getContent()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loaded, hideMenu, history, showMenu])

  useEffect(() => {
    const formatAnimation = () => setAnimation(animations[Strings.getRandomBetween(animations.length - 1)])

    formatAnimation()
    const timeout = setInterval(() => {
      formatAnimation()
      const clearAnimation = setTimeout(() => setAnimation(""), 3000)

      return () => clearTimeout(clearAnimation)
    }, 5000)
    return () => clearTimeout(timeout)
  }, [animations])

  return (
    <IonPage scroll-y="false">
      <IonContent fullscreen scrollY={false} scrollX={false} className="page-splash">
        <div className="splash-screen">
          <VideoPlayer
            sourceMP4="/assets/media/anime-splash.mp4"
            maxRandomStartPosition={45}
          />

          <div className="centered-content">
            <Image src={NormalLogoSrc} alt="Ionic logo" className={animation} />
            {showLoader && <Loader />}

            <VersionContainer showOutdated={false} />
          </div>
        </div>
        <Alerts
          title={locales._get("error_loading")}
          showAlert={showAlert}
          color="danger"
          position="middle"
          buttons={[
            {
              text: locales._get("commons.alerts.retry"),
              handler: () => getContent(true)
            }
          ]}
          onShowAlert={(show: boolean) => setShowAlert(show)}
        />
      </IonContent >
    </IonPage >
  )
}

export default Splash