import { defaultDateFormat } from "types/Utils/Date"
import locales from "config/Locales"

export type JDateAgoNumType = {
  ago: number,
  passed: boolean,//future or past
}

class JDate {
  static toDate = (date: string) => {
    if (date === "" || !date) return new Date()
    let dateStr = date.toString().trim().replace(" ", "T").replace("Z", "")
    if (dateStr.indexOf("GMT") !== -1) dateStr = dateStr.split("GMT")[0].trim()
    if (dateStr.indexOf("T") === -1) dateStr += "T00:00:00"
    return new Date(dateStr)
  }

  static now = (): Date => new Date()

  static yesterday = (days: number = -1): Date => {
    let date = new Date()
    date.setDate(date.getDate() + days)
    return date
  }

  static getYear = (): number => new Date().getFullYear()

  static getMonth = (): number => new Date().getMonth() + 1

  static getDay = (): number => new Date().getDate()

  static getHour = (): number => new Date().getHours()

  static getMinute = (): number => new Date().getMinutes()

  static getSecond = (): number => new Date().getSeconds()

  static getZone = (): number => new Date().getTimezoneOffset() / 60

  static getToday = (): Date => new Date()

  static getTodayTimestamp = (): number => new Date().getTime()

  static format = (date: Date, format?: string): string => {
    if (isNaN(date.getMonth())) return ""
    let result = format || defaultDateFormat
    result = result.replace("yyyy", date.getFullYear().toString())
    result = result.replace("yy", date.getFullYear().toString().slice(-2))
    result = result.replace("MMM", locales._get(`date.month_short_${date.getMonth() + 1}`))
    result = result.replace("MM", (date.getMonth() + 1).toString().padStart(2, "0"))
    result = result.replace("dd", date.getDate().toString().padStart(2, "0"))
    result = result.replace("HH", date.getHours().toString().padStart(2, "0"))
    result = result.replace("mm", date.getMinutes().toString().padStart(2, "0"))
    result = result.replace("ss", date.getSeconds().toString().padStart(2, "0"))
    return result
  }

  static timestamp = (date: string): number =>
    date ? JDate.toDate(date).getTime() : 0

  static timestampToDate = (timestamp: number): Date => new Date(timestamp)

  static dayOfYear = (date: Date): number =>
    Math.floor((date.getTime() - new Date(date.getFullYear(), 0, 0).getTime()) / 1000 / 60 / 60 / 24)

  static agoNum = (from: number, to?: number): JDateAgoNumType => {
    if (!to) to = Date.now()
    if (isNaN(from) || isNaN(to) || from === 0) return {
      ago: 0,
      passed: false,
    }
    const diff = to - from > 0 ? to - from : from - to
    const passed = to - from > 0 ? true : false
    return {
      ago: diff,
      passed: passed,
    }
  }

  static ago = (from: number, to?: number, precise: boolean = true): string => {
    if (from === 0) return locales._get("date.never")
    const agoNum = JDate.agoNum(from, to)
    const diff = agoNum.ago
    const sufix = agoNum.passed === true ? "_ago" : "_from_now"
    const seconds = Math.floor(diff / 1000)
    const minutes = Math.floor(seconds / 60)
    const hours = Math.ceil(minutes / 60)
    const days = Math.ceil(hours / 24)
    const months = Math.ceil(days / 30)
    const years = Math.ceil(months / 12)

    const isPlural = (num: number): string => num === 1 ? "" : "s"
    if (diff < 10000) return locales._get("date.now")
    if (seconds < 60 && precise) return locales._get(`date.second${isPlural(seconds)}${sufix}`, seconds)
    if (minutes < 60 && precise) return locales._get(`date.minute${isPlural(minutes)}${sufix}`, minutes)
    if (hours < 24 && precise) return locales._get(`date.hour${isPlural(hours)}${sufix}`, hours)
    if (days < 2 && !precise) {
      const ms_day = 1000 * 60 * 60 * 24
      const fromDate: number = Math.ceil(from / ms_day)
      const toDate: number = Math.ceil(to ? to / ms_day : Date.now() / ms_day)
      let _sufix = "today_ago"
      if (fromDate === toDate) _sufix = "today_from_now"
      else if (fromDate === toDate - 1) _sufix = "day_ago"
      else if (fromDate === toDate + 1) _sufix = "day_from_now"
      else return locales._get(`date.days${sufix}`, 2)
      return locales._get(`date.${_sufix}`)
    }
    if (days < 30) return locales._get(`date.day${isPlural(days)}${sufix}`, days)
    if (months < 12) return locales._get(`date.month${isPlural(months)}${sufix}`, months)
    if (isNaN(years)) return locales._get("date.unknown")
    return locales._get(`date.year${isPlural(years)}${sufix}`, years)
  }

  static simplifytime = (time: number): string => {// time in seconds
    const isPlural = (num: number): string => num === 1 ? "" : "s"
    const minutes = Math.floor(time / 60)
    const hours = Math.floor(minutes / 60)
    const days = Math.floor(hours / 24)
    const months = Math.floor(days / 30)
    const years = Math.floor(months / 12)
    if (years > 0) return `${locales._get(`date.year${isPlural(years)}`, years)}`
    if (months > 0) return `${locales._get(`date.month${isPlural(months)}`, months)}`
    if (days > 0) return `${locales._get(`date.day${isPlural(days)}`, days)}`
    if (hours > 0) return `${locales._get(`date.hour${isPlural(hours)}`, hours)}`
    if (minutes > 0) return `${locales._get(`date.minute${isPlural(minutes)}`, minutes)}`
    return `${time} ${locales._get("date.second")}`
  }

  static timeFormat = (time: number): string => {
    const hours = Math.floor(time / 3600)
    const minutesleft = time % 3600
    const minutes = Math.floor(minutesleft / 60)
    const secondsLeft = minutesleft % 60
    return `${hours > 0 ? `${hours.toString().padStart(2, "0")}:` : ""}${minutes.toString().padStart(2, "0")}:${secondsLeft.toString().padStart(2, "0")}`
  }
}

export default JDate