import {
  IonItem,
  IonList,
  IonListHeader,
  IonThumbnail
} from "@ionic/react"
import { useEffect, useState } from "react"
import { Swiper, SwiperSlide } from "swiper/react"
import { IonText } from "@ionic/react"
import { useHistory } from "react-router-dom"
import { Autoplay, Keyboard, Pagination, Scrollbar, Zoom } from "swiper"
import "swiper/scss"
import "swiper/css/autoplay"
import "swiper/css/pagination"
import "swiper/css/navigation"
import "swiper/css/scrollbar"
import "swiper/css/zoom"
import "@ionic/react/css/ionic-swiper.css"

import { Slide } from "types/Media/Slider"
import { breakpoints } from "types/Hooks/WindowSize"
import { defaultSlideShowProps, SlideShowProps } from "types/Media/Slider"
import { SkeletonSingle } from "components/Commons/Skeleton"
import useWindowSize from "hooks/useWindowSize"
import Animate from "utils/Animate"
import Image from "components/Media/Image"

import "./index.scss"

const SlideShow = (props: SlideShowProps) => {
  const history = useHistory()
  const windowSize = useWindowSize()
  const [slides, setSlides] = useState(1)
  const { className } = props
  const slideStyle = {
    height: props.slideHeight,
  }

  const slideOpts = {
    initialSlide: props.initialSlide,
    speed: props.speed,
    loop: props.loop,
    autoplay: props.autoplay ? { delay: props.delay } : false,
    slidesPerView: slides,
  }

  useEffect(() => {
    if (windowSize.width <= breakpoints.s) {
      setSlides(props.slidesPerView.s)
    } else if (windowSize.width > breakpoints.s && windowSize.width <= breakpoints.m) {
      setSlides(props.slidesPerView.m)
    } else if (windowSize.width > breakpoints.m && windowSize.width <= breakpoints.l) {
      setSlides(props.slidesPerView.l)
    } else if (windowSize.width > breakpoints.l) {
      setSlides(props.slidesPerView.xl)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [windowSize.width])
  if (props.slides.length === 0) return (
    <IonList className={`component-slider ${className}`}>
      <IonListHeader key="slideshow-header">
        <SkeletonSingle style={style.header} />
      </IonListHeader>
      <IonItem key="slideshow-skeleton-item">
        {[0, 1, 2].map((_, index) => (
          <>
            <IonThumbnail slot="start" style={style.block} key={`thumbindex-${index}`}>
              <SkeletonSingle />
            </IonThumbnail>
          </>
        ))}
      </IonItem>
    </IonList>
  )
  return (
    <Swiper
      modules={[Autoplay, Keyboard, Pagination, Scrollbar, Zoom]}
      initialSlide={slideOpts.initialSlide}
      speed={slideOpts.speed}
      loop={slideOpts.loop}
      autoplay={slideOpts.autoplay}
      pagination={{ clickable: true }}
      slidesPerView={slides}
      className={`component-slider ${className} ${props.animation || Animate.setAnimation("fadeIn")}`}
      onSlideChangeTransitionEnd={(e: any) => {
        //props.onSlideDidChange && props.onSlideDidChange(e)
      }}
    >
      {props.slides?.map((item: Slide, index: number) => {
        return (
          <SwiperSlide key={index}>
            <div className="container-img" style={slideStyle}
              onClick={() => {
                if (item.onClick) return item.onClick()
                if (item.target === "_self") history.push(item.href || "")
                else window.open(item.href || "", item.target || "_self")
              }}
            >
              <Image src={item.image || ""} style={slideStyle} />
              {item.title && <IonText class="header-text-container">{item.title}</IonText>}
              {item.badge && <IonText class="sub-header-text-container">{item.badge}</IonText>}
            </div>
          </SwiperSlide>
        )
      })}
    </Swiper>
  )
}

const style = {
  header: {
    width: "calc(100vw - 3rem)",
  },
  block: {
    width: "33vw",
    height: "33vw",
  }
}

SlideShow.defaultProps = defaultSlideShowProps

export default SlideShow
