import { routeItems } from "types/App"
import Discover from "pages/Discover"
import Anime from "pages/Discover/Anime"
import GenderView from "pages/Discover/GenderView"
import StudioView from "pages/Discover/StudioView"
import Genders from "pages/Genders"
import Home from "pages/Home"
import Studios from "pages/Studios"

const routes = [
  {
    path: "home",
    component: <Home view="home" />,
  },
  {
    path: "home/foryou",
    component: <Home view="foryou" />,
  },
  {
    path: "home/continue",
    component: <Home view="continue" />,
  },
  {
    path: "home/anime",
    component: <Home view="anime" />,
  },
  {
    path: "home/popular",
    component: <Home view="popular" />,
  },
  {
    path: "anime",
    component: <Discover />,
  },
  {
    path: "anime/:cid",
    component: <Anime />,
  },
  {
    path: "anime/:cid/episode/:eid",
    component: <Anime />,
  },
  {
    path: "anime/gender/:gid",
    component: <GenderView />,
  },
  {
    path: "anime/studio/:sid",
    component: <StudioView />,
  },
  {
    path: "anime/genders",
    component: <Genders />,
  },
  {
    path: "anime/studios",
    component: <Studios />,
  }
] as routeItems[]

export default routes