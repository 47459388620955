import { checkFormatEmail } from "components/Users/Login/Methods/checkFormatEmail"
import { passwordMinComplex } from "components/Users/Login/Methods/passwordMinComplex"
import { Endpoints } from "api/Endpoints"
import { deviceInfo } from "config/Settings/Device"
import locales from "config/Locales"
import Connect from "components/Connect"
import LoaderOptions from "components/Content/ScreenLoader/LoaderOptions"
import User from "components/Users/User"
import RedirectURL from "utils/Redirect"

export const doRegister = (props: {
  yourname: string,
  username: string,
  password: string,
  passwordconfirm: string,
  signupmsg: string,
  email: string,
  setSignupAlert: Function,
  closeActiveModal: Function,
  history: any,
}) => {
  if (props.signupmsg === locales._get("sendfailed")) return
  if (!checkFormatEmail(props.email)) return props.setSignupAlert(locales._get("emailfail"))
  if (props.password !== props.passwordconfirm) return props.setSignupAlert(locales._get("passwordfail"))
  if (!passwordMinComplex(props.password)) return props.setSignupAlert(locales._get("passwordminfail"))
  if (props.yourname === "" || props.username === "") return props.setSignupAlert(locales._get("sendfailed"))
  props.setSignupAlert("")

  if (props.yourname && props.email && props.username && props.password) {
    props.setSignupAlert(locales._get("loading"))
    LoaderOptions.openLoader("signing_up")
    Connect.post({
      endpoint: Endpoints.signup,
      body: {
        ...deviceInfo,
        name: props.yourname,
        email: props.email,
        username: props.username,
        password: props.password,
      }
    }).then(async (response: any) => {
      if (response.status === 200 && response.data.token) {
        User.setUser({
          username: props.username,
          token: response.data.token,
          authorityList: response.data.authorityList
        })
        User.setDataUser(response.data)
        props.closeActiveModal()
        LoaderOptions.closeLoader()
        props.history.push("/welcome")
        RedirectURL()
        return
      }
      LoaderOptions.closeLoader()
      props.setSignupAlert(locales._get("sendfailed"))
    }).catch(() => {
      LoaderOptions.closeLoader()
      props.setSignupAlert(locales._get("sendfailed"))
    })
  }
}