import { IonIcon } from "@ionic/react"
import { eye, eyeOff } from "ionicons/icons"

import { Slide } from "types/Media/Slider"
import User from "components/Users/User"

import "./index.scss"

export { IsLastContent } from "./IsLastContent"

export { IsTop } from "./IsTop"

const viewedContent = (item: Slide) => {
  if (!User.isLoggedIn()) return

  return item.conditionalBadge = () => {
    if (typeof item.viewed === "undefined") return <></>

    return <div className={`viewed-content ${item.viewed === 1 ? "is-viewed" : "not-viewed"}`}>
      <IonIcon
        icon={item.viewed === 1 ? eye : eyeOff}
      />
    </div>
  }
}

export default viewedContent