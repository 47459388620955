import { HeaderLogoSrc, SmallLogoSrc } from "types/Anime/Images"
import Image from "components/Media/Image"
import settings from "config/Settings"

import Animate from "utils/Animate"

export const bgApp = `${settings.apiUrl}../bg-app.jpg`

export const HeaderLogo = () =>
  <Image className={`header-logo ${Animate.setAnimation("fadeIn")}`} src={HeaderLogoSrc} alt="" />

export const SmallLogo = () =>
  <Image className={`small-logo ${Animate.setAnimation("fadeIn")}`} src={SmallLogoSrc} alt="" />
