import { useIonToast } from "@ionic/react"
import { useEffect, useState } from "react"

import { AlertsGeneralProps } from "types/Commons/Alerts"
import locales from "config/Locales"
import Image from "components/Media/Image"

import "./index.scss"

const Alerts = (props: AlertsGeneralProps) => {
  const [showAlert, setShowAlert] = useState<boolean>(false)
  const [text, setText] = useState<string>("")
  const [image, setImage] = useState<string>(props.image || "")
  const [present] = useIonToast()

  const buildImage = (): string => {
    if (image === "" || !image) return ""
    const css = `
    ion-toast.component-toats-modal::part(message) {
      background-image: url(${image});
    }
    `
    //add css to head
    const head = document.head || document.getElementsByTagName("head")[0]
    const clear = document.getElementById("toast-bg")
    if (clear) clear.remove()
    const style = document.createElement("style") as HTMLStyleElement
    style.id = "toast-bg"
    head.appendChild(style)
    style.appendChild(document.createTextNode(css))
    return ""
  }

  useEffect(() => setShowAlert(props.showAlert), [props.showAlert])

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => props.onShowAlert && props.onShowAlert(showAlert), [showAlert])

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => setText(`${buildImage()}${props.title || ""}`), [props.title, image])

  useEffect(() => {
    if (!showAlert) return

    present({
      header: locales._get("appname"),
      message: text,
      cssClass: `component-${props.cssClass || ""} ${!(image === "" || !image) && "has-image"} component-toats-modal`,
      duration: props.duration || 0,
      color: props.color || "warning",
      translucent: true,
      position: props.position || "bottom",
      buttons: props.buttons,
      onDidDismiss: () => {
        setShowAlert(false)
        props.onDismiss && props.onDismiss()
      },
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showAlert])

  return (
    <>
      <Image
        className="component-alert-image-container"
        src={props.image || props.imageBk || ""}
        srcBk={props.imageBk || ""}
        onIonError={() => setImage(props.imageBk || "")}
      />
    </>
  )
}

export default Alerts