import { create } from "zustand"
import { devtools, persist } from "zustand/middleware"

import { ionicStorage } from "utils/IonicStorage"
import { HistorySearch } from "./interface"
import settings from "config/Settings"
import { Endpoints } from "api/Endpoints"
import Connect from "components/Connect"

const maxHistorySearch = new settings().maxHistorySearch

const useHistorySearch = create(persist(devtools((set) => ({
  historySearch: [] as HistorySearch[],
  setHistorySearch: (historySearch: HistorySearch[]) => set(() => ({
    historySearch: historySearch,
  })),
  addHistorySearch: (historySearch: HistorySearch) => set((state: any) => ({
    // add and keep maxHistorySearch
    historySearch:
      maxHistorySearch === 0 ? [] :
        historySearch?.query?.length > 0 ?
          (
            state.historySearch.slice(-1)[0]?.query === historySearch.query
          )
            ? state.historySearch.slice(0, -1).concat(historySearch)
            : state.historySearch.concat(historySearch).slice(-maxHistorySearch)
          : state.historySearch,
  })),
  currentSearch: "" as string,
  setCurrentSearch: (currentSearch: string) => set(() => ({
    currentSearch: currentSearch,
  })),
  currentRelevance: 0 as number,
  setCurrentRelevance: (currentRelevance: number) => set(() => ({
    currentRelevance: currentRelevance,
  }))
})), {
  name: "historySearch",
  getStorage: () => ionicStorage,
}))

export const getHistorySearch = async (username: string = ""): Promise<HistorySearch[]> => {
  const historySearch: string = await ionicStorage.getItem("historySearch")
  if (historySearch) {
    const userHistory = JSON.parse(historySearch).state?.historySearch?.filter((item: HistorySearch) => item.user?.username === username || username === "") || [] as HistorySearch[]
    return userHistory as HistorySearch[]
  }
  return [] as HistorySearch[]
}

export const saveLastHistorySearchRemote = async () => {
  const historyRecords: HistorySearch[] = await getHistorySearch()
  const lastHistoryRecord: HistorySearch = historyRecords.slice(-1)[0]
  if (!lastHistoryRecord) return
  const { query } = lastHistoryRecord
  await Connect.put({
    endpoint: Endpoints.contenthistorysearch,
    body: { query }
  })
}

export const saveHistorySearchRemote = async (query: string, image?: string) => {
  await Connect.put({
    endpoint: Endpoints.contenthistorysearch,
    body: { query, image }
  })
}

export default useHistorySearch