import { IonItem, IonLabel } from "@ionic/react"
import User from "components/Users/User"
import Redirect from "utils/Redirect"
import locales from "config/Locales"

import "./index.scss"

const LogOut = (props: { showName?: boolean }) => {
  const logOutSession = () => {
    User.logout()
    Redirect()
  }

  return (
    <IonItem href="#" onClick={logOutSession} className="component-user-logout">
      <IonLabel>
        {locales._get("logout")}
        {props.showName && (<>&nbsp;<span>({User.getUsername()})</span></>)}
      </IonLabel>
    </IonItem>
  )
}

export default LogOut
