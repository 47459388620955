export type WindowSizeTypes = {
  width: number,
  height: number,
  isPortrait: boolean,
  isLandScape: boolean,
  breakpoint: string | undefined,
  inViewPort: boolean,
  loaded: boolean,
}

export const breakpoints = {
  s: 440,
  m: 768,
  l: 1024,
}