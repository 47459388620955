import { ReactNode, useEffect, useState } from "react"
import { useParams } from "react-router-dom"
import { IonLabel, IonSegment } from "@ionic/react"

import { CharactersStaffContent, CharactersStaffProps } from "types/Characters"
import { AnimeContent, AnimeSectionTypes } from "types/Anime"
import { getCharacter } from "./Methods/getCharacter"
import { getStaff } from "./Methods/getStaff"
import { ContentType } from "types/Page/Content"
import { contentTypes } from "types/Media/Slider"
import { setSearchWords } from "../Anime/Methods/setSearchWords"
import { formatSearchContent } from "pages/_commons/Methods/formatSearchContent"
import useHistoryRecord, { saveLastHistoryRecordRemote } from "store/useHistoryRecord"
import useHistorySearch from "store/useHistorySearch"
import ErrorHandler from "components/Commons/ErrorHandler"
import PageContainer from "pages/_commons/PageContainer"
import Skeleton from "components/Commons/Skeleton"
import useAnime from "store/useAnime"
import Strings from "utils/Strings"
import JDate from "utils/JDate"
import User from "components/Users/User"
import CharacterParser from "pages/_commons/Content/Character"
import AnimeTopBar from "../Commons/TopBar"
import AnimeDescription from "../Commons/Tabs/Description"
import TabSegment from "../Commons/Tabs/Segment"
import ShowOn from "./ShowOn"
import Characters from "./Characters"

import "./../Anime/index.scss"

const CharactersStaff = (props: CharactersStaffProps) => {
  const [chid, setChid] = useState<number>(0)
  const [stid, setStid] = useState<number>(0)
  const [showError, setShowError] = useState<boolean>(false)
  const [activeSection, setActiveSection] = useState<AnimeSectionTypes>(null)
  const [imgBG, setImgBG] = useState<string>("")
  const [loaded, setLoaded] = useState<boolean>(false)
  const [content, setContent] = useState<CharactersStaffContent>({} as CharactersStaffContent)
  const [contentAnime, setContentAnime] = useState<AnimeContent>({} as AnimeContent)
  const setIsOpenCharacter = useAnime((state) => state.setIsOpenCharacter)
  const setIsOpenStaff = useAnime((state) => state.setIsOpenStaff)
  const chidStore: number = useAnime((state) => state.chid)
  const stidStore: number = useAnime((state) => state.stid)
  const addHistoryRecord = useHistoryRecord<Function>((state: any) => state.addHistoryRecord)
  const currentSearch = useHistorySearch<string>((state: any) => state.currentSearch)
  const params = useParams()

  const addHistoryContent = (args: CharactersStaffContent) => {
    addHistoryRecord({
      path: window.location.pathname,
      title: args?.title || "",
      cid: args.id || 0,
      provider: "",
      image: props.type === "characters" ? (args?.img2 || "") : (args?.img || ""),
      type: props.type as ContentType,
      date: JDate.now(),
      user: User.getActiveUser(),
    })
    saveLastHistoryRecordRemote()
  }

  const setSearchWordsParams = (str: string): string =>
    setSearchWords(str, currentSearch)

  const setTitle = (title: string): ReactNode =>
    <>
      <IonLabel className="title">
        <span dangerouslySetInnerHTML={{ __html: setSearchWordsParams(title) }} />
      </IonLabel>
    </>

  useEffect(() => {
    if (!chid || props.type !== "characters") return
    if (chid === 0)
      if (props.chid) setChid(props.chid)
      else Strings.parseParams("chid", params, (_chid: number) =>
        setChid(_chid))
    if (chid === 0 || isNaN(chid)) return
    setLoaded(false)
    chid !== content?.id && getCharacter({ chid, force: showError }).then((content: CharactersStaffContent) => {
      if (!content?.id) return setShowError(true)
      setContent(() => {
        addHistoryContent(content)
        if (!content?.id) setShowError(true)
        return CharacterParser.fitCharacterContent(content)
      })
      setLoaded(true)
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [params, chid, showError])

  useEffect(() => {
    if (!stid || props.type !== "staff") return
    if (stid === 0)
      if (props.stid) setStid(props.stid)
      else Strings.parseParams("stid", params, (_stid: number) =>
        setStid(_stid))
    if (stid === 0 || isNaN(stid)) return
    setLoaded(false)
    setContent({} as CharactersStaffContent)
    stid !== content?.id && getStaff({ stid, force: showError }).then((content: CharactersStaffContent) => {
      if (!content?.id) return setShowError(true)
      setContent(() => {
        addHistoryContent(content)
        if (!content?.id) setShowError(true)
        return CharacterParser.fitCharacterContent(content)
      })
      setLoaded(true)
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [params, stid, showError])

  useEffect(() => {
    if (!chid && !stid) setContent({} as CharactersStaffContent)
    if (chid === 0 && stid === 0) setContent({} as CharactersStaffContent)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [chid, stid])

  useEffect(() => {
    setImgBG(content?.img3)
    setContentAnime({
      id: content?.id || 0,
      title: content?.title || "",
      content: content?.content || "",
      ctype: "" as contentTypes,
      img: content?.img || "",
      img2: content?.img2 || "",
      img3: content?.img3 || "",
    })
    setActiveSection("description")
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [content])

  useEffect(() => setChid(chidStore), [chidStore])

  useEffect(() => setStid(stidStore), [stidStore])

  return (
    <PageContainer
      className="page-anime-details"
      showLogo={false}
      onClickBack={() => {
        props.type === "characters" && setIsOpenCharacter(false)
        props.type === "staff" && setIsOpenStaff(false)
      }}
    >
      <div className={"background"}
        style={{
          "backgroundImage": `url(${imgBG})`,
        }}
      >
      </div>
      <div className={`ion-padding inner-container ${showError && "withError"}`}>
        <div>
          <ErrorHandler showError={showError}>
            {!loaded && <>
              <Skeleton articleLike={true} />
            </>}
            {loaded &&
              <>
                <AnimeTopBar
                  link={content.share}
                  title={content.title}
                  image={content.img}
                />
                {setTitle(content.title)}
                <IonSegment scrollable={true} value={activeSection?.toString()} onIonChange={(e: any) => {
                  setActiveSection(e.detail.value)
                }}>
                  <TabSegment name="description" />
                  {content.characters &&
                    <TabSegment name="characters" />
                  }
                  {content.staff?.alias &&
                    <TabSegment name="voice-by" />
                  }
                  <TabSegment name={props.type === "characters" ? "show-on" : "anime"} />
                </IonSegment>
                <div className="segments">
                  {activeSection === "description" &&
                    <AnimeDescription content={formatSearchContent(contentAnime, currentSearch)} />
                  }
                  {activeSection === "characters" &&
                    <Characters {...content} />
                  }
                  {(activeSection === "show-on" || activeSection === "anime") &&
                    <ShowOn {...content} />
                  }
                  {activeSection === "voice-by" &&
                    <AnimeDescription
                      content={{
                        id: content.staff?.id || 0,
                        title: content.staff?.title || "",
                        content: content.staff?.content || "",
                        img: content.staff?.img || "",
                        img2: content.staff?.img2 || "",
                        img3: content.staff?.img3 || "",
                      } as AnimeContent}
                      contentTop={
                        <>
                          {content.staff?.title &&
                            <>{setTitle(content.staff?.title || "")}</>
                          }
                        </>
                      }
                    />
                  }
                </div>
              </>
            }
          </ErrorHandler>
        </div>
      </div>
    </PageContainer>

  )
}

export default CharactersStaff