import { PublicUrlImg } from "types/Anime/Images"

const AvatarPath = `${PublicUrlImg}avatar/heroes/one-punchman/`

export const AvatarHeroesOnePunchMan = {
  "saitama": `${AvatarPath}saitama.jpg`,
  "garou": `${AvatarPath}garou.jpg`,
  "genos": `${AvatarPath}genos.jpg`,
  "tatsumaki": `${AvatarPath}tatsumaki.jpg`,
  "boros": `${AvatarPath}boros.jpg`,
}