import { useState } from "react"
import { IonButton, IonCard, IonCardContent, IonIcon, IonItem, IonLabel, IonList, IonModal } from "@ionic/react"
import { Capacitor } from "@capacitor/core"

import { AppSettingsID } from "utils/AppSettings"
import { RemoveAdPositionProps } from "types/Media/Ads"
import { informationCircleOutline, videocamOffOutline } from "ionicons/icons"
import { AdsBannerLarge, InBarWindow, MeiDancing, NotFoundImg } from "types/Anime/Images"
import { removeAdPosition } from "./Methods/removeAdPosition"
import settings, { isDev } from "config/Settings"
import JDate, { JDateAgoNumType } from "utils/JDate"
import useAds from "store/useAds"
import RemoveAdsButton from "./RemoveAdsButton"
import IsLatest from "../../../../pages/_commons/IsLatest"
import OverCard from "components/Content/OverCard"
import PageContainer from "../../../../pages/_commons/PageContainer"
import locales from "config/Locales"
import AdsBanner from "components/Media/Ads/Banner"
import Alerts from "components/Commons/Alerts"
import Image from "components/Media/Image"

import "./index.scss"
import User from "components/Users/User"

const RemoveAds = () => {
  const [showModalAds, setShowModalAds] = useState(false)
  const [showModalUpdate, setShowModalUpdate] = useState(false)
  const [showFailedReward, setShowFailedReward] = useState(false)
  const [showSuccessReward, setShowSuccessReward] = useState(false)
  const [showingAds, setShowingAds] = useState(false)
  const [adslocklarge, setAdsLockLarge] = useState<JDateAgoNumType>(JDate.agoNum(new settings().getadslocklargetime))
  const [adslockcontent, setAdsLockContent] = useState<JDateAgoNumType>(JDate.agoNum(new settings().getadslockcontenttime))
  const [adslockvideo, setAdsLockVideo] = useState<JDateAgoNumType>(JDate.agoNum(new settings().getadslockvideotime))
  const [latest] = useState<string>(AppSettingsID.getSetting("latest", locales._get("latest-version")))
  const [time] = useState<string>(JDate.simplifytime(settings.ads_remove_interval))
  const setShowSoftnyaVideo = useAds((state: any) => state.setShowSoftnyaVideo)
  const setSoftnyaAds = useAds((state: any) => state.setSoftnyaAds)

  const showAdRemoval = () => setShowModalAds(true)

  const callRemoveAdPosition = (props: RemoveAdPositionProps) => {
    setShowingAds(true)
    removeAdPosition({
      position: props.position,
      setAdsLockLarge,
      setAdsLockContent,
      setAdsLockVideo,
      setShowingAds,
      setShowSuccessReward,
      setShowFailedReward,
      setSoftnyaAds,
      setShowSoftnyaVideo,
    })
  }

  if (User.isPremium()) return <></>
  return (
    <>
      <IonButton
        id="remove-ads"
        fill="clear"
        shape="round"
        color="danger"
        onClick={() => showAdRemoval()}
      >
        AD
      </IonButton>
      <IonModal
        className="remove-ads-modal"
        isOpen={showModalAds}
        onIonModalDidDismiss={() => setShowModalAds(false)}
      >
        <PageContainer
          className="page-remove-ads"
          showLogo={false}
          onClickBack={() => setShowModalAds(false)}
        >
          <div className="ion-padding inner-container ion-text-center">
            <h2>{locales._get("ads.remove-title")}</h2>
            {(Capacitor.isNativePlatform() || isDev) &&
              <>
                <IonCard color="secondary">
                  <IonCardContent>
                    <IonList>
                      <IonItem>
                        <IonIcon icon={informationCircleOutline} size="large" slot="start" />
                        <IonLabel>
                          <div className="ion-margin-bottom" dangerouslySetInnerHTML={{ __html: locales._get("ads.remove-text-top", time) }} />
                        </IonLabel>
                      </IonItem>
                    </IonList>
                  </IonCardContent>
                </IonCard>
                <IonCard color="primary">
                  <IonCardContent>
                    <IonList>
                      <IonItem>
                        <IonIcon icon={informationCircleOutline} size="large" slot="start" />
                        <IonLabel>
                          <div className="ion-margin-bottom" dangerouslySetInnerHTML={{ __html: locales._get("ads.remove-text-warning") }} />
                        </IonLabel>
                      </IonItem>
                    </IonList>
                  </IonCardContent>
                </IonCard>
              </>
            }
            {!Capacitor.isNativePlatform() &&
              <IonCard color="danger">
                <IonCardContent>
                  <OverCard
                    image={InBarWindow}
                    subtitle={locales._get("ads.remove-only-app", time)}
                  />
                  <IonButton
                    fill="solid"
                    size="small"
                    color="secondary"
                    onClick={() => setShowModalUpdate(true)}
                  >
                    {locales._get("latest-version.download", latest)}
                  </IonButton>
                </IonCardContent>
              </IonCard>
            }
            {(Capacitor.isNativePlatform() || isDev) &&
              <>
                <IonCard color="tertiary" className="banner-card">
                  <h3>{locales._get("ads.banner-large")}</h3>
                  {!Capacitor.isNativePlatform() && <AdsBanner type="softnyacontent" softnyaposition="TOP" isDemo={true} />}
                  {Capacitor.isNativePlatform() && <Image src={AdsBannerLarge} />}
                  <div className="ion-margin-bottom" dangerouslySetInnerHTML={{ __html: locales._get("ads.remove-text-large", time) }} />
                  <RemoveAdsButton
                    onClick={() => callRemoveAdPosition({ position: "LARGE" })}
                    position="LARGE"
                    time={adslocklarge}
                    showingAds={showingAds}
                  />
                </IonCard>
                <IonCard color="tertiary" className="banner-card">
                  <h3>{locales._get("ads.banner-content")}</h3>
                  <AdsBanner type="softnyacontent" softnyaposition="DETAILS_TOP" isDemo={true} />
                  <AdsBanner type="softnyacontent" softnyaposition="CONTENT_B" isDemo={true} />
                  <div className="ion-margin-bottom" dangerouslySetInnerHTML={{ __html: locales._get("ads.remove-text-content", time) }} />
                  <RemoveAdsButton
                    onClick={() => callRemoveAdPosition({ position: "CONTENT" })}
                    position="CONTENT"
                    time={adslockcontent}
                    showingAds={showingAds}
                  />
                </IonCard>
                <IonCard color="tertiary" className="banner-card">
                  <h3>{locales._get("ads.banner-video")}</h3>
                  <div className="video-icon-container">
                    <IonIcon icon={videocamOffOutline} color="light" />
                  </div>
                  <div className="ion-margin-bottom" dangerouslySetInnerHTML={{ __html: locales._get("ads.remove-text-video", time) }} />
                  <RemoveAdsButton
                    onClick={() => callRemoveAdPosition({ position: "VIDEO" })}
                    position="VIDEO"
                    time={adslockvideo}
                    showingAds={showingAds}
                  />
                </IonCard>
              </>
            }
          </div>
          {!Capacitor.isNativePlatform() &&
            <IsLatest
              showAlert={showModalUpdate}
              forceShow={true}
              onClosed={() => setShowModalUpdate(false)}
            />
          }
        </PageContainer>
      </IonModal>
      <Alerts
        title={locales._get(showSuccessReward ? "ads.remove-title-success" : "ads.remove-title-fail")}
        image={showSuccessReward ? MeiDancing : NotFoundImg}
        showAlert={showSuccessReward || showFailedReward}
        color={showSuccessReward ? "success" : "danger"}
        duration={4000}
        buttons={[
          {
            text: locales._get("ok"),
          }
        ]}
      />
    </>
  )
}

export default RemoveAds