import { useEffect, useState } from "react"
import { Helmet } from "react-helmet"

import locales from "config/Locales"
import settings from "config/Settings"

export type MetaProps = {
  title: string,
  description?: string,
  image?: string,
  link?: string,
  type?: string,
}

const Meta = (props: MetaProps) => {
  const [structuredData, setStructuredData] = useState<any>({})
  const [type] = useState<string>(props.type || (props.description ? "article" : "website") || "website")
  const [link] = useState<string>(props.link || settings.cdnUrl?.replace("cdn.", "www.") || "")

  useEffect(() => {
    setStructuredData({
      "@context": "https://schema.org",
      "@type": type,
      headline: props.title,
      description: props.description || "",
      image: props.image || "",
      url: link,
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props])

  return (
    <Helmet>
      <title>{props.title || ""} | {locales._get("appname")}</title>
      <meta name="description" content={props.description || ""} />
      <meta property="og:title" content={`${props.title} | ${locales._get("appname")}`} />
      <meta property="og:description" content={props.description || ""} />
      <meta property="og:type" content={type} />
      <meta property="og:image" content={props.image || ""} />
      <meta property="og:url" content={link} />
      <meta property="og:site_name" content={locales._get("appname")} />
      <meta property="og:locale" content={locales._get("langcode")} />
      <script type="application/ld+json">
        {JSON.stringify(structuredData)}
      </script>
    </Helmet>
  )
}

export default Meta