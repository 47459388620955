import { useEffect, useState } from "react"

import { Endpoints } from "api/Endpoints"
import { paramsRecentForYou } from "api/routes/params/Media"
import { getLastCall } from "components/Connect/Exports"
import { filtersContentType, filtersPending, filtersPremium } from "types/Content/filters"
import { ContentProps } from "types/Page/Content"
import Connect from "components/Connect"
import User from "components/Users/User"
import locales from "config/Locales"
import JDate from "utils/JDate"
import AniTitle from "pages/_commons/AniTitle"
import WithoutFollow from "./WithoutFollow"
import Content from "pages/_commons/Content/Content"

const ForYou = (props: ContentProps) => {
  const [hasContent, setHasContent] = useState<boolean>(true)
  const [query] = useState<string>(Connect.getQuery({ endpoint: Endpoints.recent, params: paramsRecentForYou }))
  const [lastCall, setLastCall] = useState<string>("")

  useEffect(() => {
    if (!User.isLoggedIn()) return

    getLastCall(query).then((lastCall: number) =>
      setLastCall(`${locales._get("last_call")}: ${JDate.ago(lastCall)}`))
  }, [props.watchLastCall, query])

  return (
    <>
      {User.isLoggedIn() && (
        <>
          {hasContent ? (
            <Content
              title={locales._get("for_you")}
              href="/home/foryou"
              endpoint={Endpoints.recent}
              params={paramsRecentForYou}
              type="player"
              mode={props.mode || "slider"}
              refreshGrid={true}
              filterRepeatedByEpisode={true}
              onEmptySlider={(isEmpty: boolean) => {
                setHasContent(!isEmpty)
              }}
              filters={props.mode === "grid" ? [
                ...[filtersPremium],
                ...[filtersPending],
                ...[filtersContentType],
              ] : []}
            />
          ) : (
            <>
              <AniTitle
                title={locales._get("for_you")}
                subtitle={lastCall}
                href="/home/foryou"
              />
              <WithoutFollow />
            </>
          )}
        </>
      )
      }
    </>
  )
}

export default ForYou