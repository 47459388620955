import { useState } from "react"
import { IonFab, IonFabButton, IonFabList, IonIcon, IonNote } from "@ionic/react"
import { chevronForwardCircle, cloudDownloadOutline, eyeOffOutline } from "ionicons/icons"

import { IsLatestShowProps } from "types/Page/IsLatestShow"
import { AppSettingsID } from "utils/AppSettings"
import { showIsLatest } from ".."
import locales from "config/Locales"

import "./index.scss"

const IsLatestShowFab = (props: IsLatestShowProps) => {
  const [isLatest] = useState<boolean>(showIsLatest())
  const [latest] = useState<string>(AppSettingsID.getSetting("latest", locales._get("latest-version")))
  const [appName] = useState<string>(locales._get("appname"))
  const [showLatestFab, setShowLatestFab] = useState<boolean>(AppSettingsID.getSetting("showLatestFab", true))

  if (isLatest || !showLatestFab) return <></>
  return (
    <IonFab slot="fixed" vertical="center" horizontal="start" className="component-alert-is-latest-fab">
      <IonFabButton
        onClick={() => {

        }}
      >
        <IonIcon icon={chevronForwardCircle}></IonIcon>
      </IonFabButton>
      <IonNote>
        {locales._get("latest-version.title", { "%s": latest, "%t": appName })}
      </IonNote>
      <IonFabList side="end">
        <IonFabButton
          onClick={() => props.onClickShowLatest()}
        >
          <IonIcon icon={cloudDownloadOutline}></IonIcon>
        </IonFabButton>
        <IonFabButton
          onClick={() => {
            AppSettingsID.setSetting("showLatestFab", false)
            setShowLatestFab(false)
          }}
        >
          <IonIcon icon={eyeOffOutline}></IonIcon>
        </IonFabButton>
      </IonFabList>
    </IonFab>
  )
}

export default IsLatestShowFab