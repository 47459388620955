//import { AdMob, BannerAdOptions, BannerAdSize, BannerAdPosition, BannerAdPluginEvents, AdMobBannerSize } from "@capacitor-community/admob"

import { AdMobBannerProps, BannerAdOptions, BannerAdPosition, BannerAdSize } from "types/Media/Ads/AdMob"
import settings from "config/Settings"

export const banner = async (props?: AdMobBannerProps): Promise<any> => {
  /*AdMob.addListener(BannerAdPluginEvents.Loaded, () => {
    // Subscribe Banner Event Listener
  })

  AdMob.addListener(BannerAdPluginEvents.SizeChanged, (size: AdMobBannerSize) => {
    // Subscribe Change Banner Size
  })*/
  const options: BannerAdOptions = {
    adId: new settings().getadmob_bannerid,
    adSize: props?.adSize || BannerAdSize.ADAPTIVE_BANNER,
    position: props?.position || BannerAdPosition.BOTTOM_CENTER,
    margin: props?.margin || 0,
    isTesting: props?.isTesting || false,
    // npa: true
  }
  //AdMob.showBanner(options)
  return options
}

export const bannerhide = async () => {
  //AdMob.removeBanner()
}