import { ReactNode } from "react"

import { OneZeroTypes } from ".."
import { JDateAgoNumType } from "utils/JDate"
import { contentTypes, Slide } from "types/Media/Slider"

export type AnimeProps = {
  id?: number,
  eid?: number,
}

export type AnimeContentParams = {
  img: string,
  img2: string,
}

export type AnimeTopBarProps = {
  link: string,
  title?: string,
  image?: string,
}

export type AnimeCommentsProps = {
  content: AnimeContent,
  episode: AnimeEpisodesMedia,
  changeEpisode?: changeEpisodeFunction,
}

export type TabSegmentProps = {
  name: string,
  img?: string,
  lockSegment?: boolean,
}

export type AnimeTabs = {
  content: AnimeContent,
  episodes?: AnimeEpisodes,
  currentSearch?: string,
  changeEpisode?: changeEpisodeFunction,
  openTrailer?: () => void,
  contentTop?: ReactNode,
  contentBottom?: ReactNode,
  excludeMedia?: boolean,
}

export type AnimeItemTabs = {
  title: string,
  content?: string,
  contentNode?: ReactNode,
  onClick?: Function,
}

export type AnimeEpisodeDetails = {
  episode: number,
  eid: number,
  hits: number,
  hitsStr: string,
  seen_date: string,
  seenDateStr: string,
  seenFullDateStr?: string,
  premium?: OneZeroTypes,
}

export type AnimeTopBadgeProps = {
  top: number,
}

export type AnimeContentTrailer = {
  id: number,
  site: string,
  thumbnail: string,
  url: string,
  external: string,
}

export type AnimeContentGender = {
  id: number,
  title: string,
  principal: OneZeroTypes,
  ondiscover: OneZeroTypes,
  description: string,
  isadult: OneZeroTypes,
}

export type AnimeContentStudio = {
  id: number,
  title: string,
}

export type AnimeContentFollow = {
  follow: number,
  my: OneZeroTypes,
}

export const defaultFollow = {
  follow: 0,
  my: 0,
} as AnimeContentFollow

export type AnimeContent = {
  id: number,
  title: string,
  content: string | null,
  ctype: contentTypes,
  ctypeStr?: string,
  seasonyear?: number,
  seasonyearStr?: string,
  img?: string,
  img2?: string,
  img3?: string,
  following?: AnimeContentFollow,
  genders?: string[],
  gendersids?: AnimeContentGender[],
  gendersRest?: string[],
  gendersidsRest?: AnimeContentGender[],
  gendersStr?: string,
  studio?: string,
  studioid?: AnimeContentStudio,
  studios?: string[],
  studiosids?: AnimeContentStudio[],
  studiosRest?: string[],
  studiosStr?: string,
  top?: number,
  duration?: number,
  popularity?: number,
  popularityStr?: string,
  nextepisode?: string,
  nextepisodeStr?: string,
  nextepisodeAgo?: string,
  nextepisodeAgoNum?: JDateAgoNumType,
  isadult?: OneZeroTypes,
  title_romaji?: string,
  title_english?: string,
  title_spanish?: string,
  title_native?: string,
  title_other?: string[],
  titleOtherStr?: string,
  season?: AnimeSeasonTypes,
  seasonStr?: string,
  startdate?: string,
  startdateStr?: string,
  enddate?: string,
  enddateStr?: string,
  enddateAgoNum?: JDateAgoNumType,
  willenddate?: string,
  last_episode?: number,
  id_last_episode?: number,
  lastEpisodeStr?: string,
  lastSeenStr?: string,
  trailer?: AnimeContentTrailer,
  share?: string,
  params?: AnimeContentParams,
  formated?: boolean,
}

export type AnimeComment = {
  id: number,
  cid: number,
  eid: number,
  serie: number,
  header: string,
  subheader: string,
  comment: string,
  name: string,
  avatar: string,
  email: string,
  created: string,
  createdStr: string,
  createdAgo: string,
  createdAgoNum: JDateAgoNumType,
  zone: string,
  parent_id: number,
  depth: number,
}

export type EpisodeMediaType = {
  id: number,
  title: string,
  content: string,
  provider: string,
  media: string,
  ads: AnimeMediaAds,
  adsStr: string,
  premium?: OneZeroTypes,
}

export type AnimeEpisodesMedia = {
  exist: OneZeroTypes,
  hits: number,
  eid: number,
  cid: number,
  last_episode: number,
  serie: number,
  badge: string,
  ads: AnimeMediaAds,
  adsStr: string,
  download: EpisodeMediaType[],
  play: EpisodeMediaType[],
}

export type AnimeEpisodesMediaTypes = {
  current: AnimeEpisodesMedia,
  next: AnimeEpisodesMedia,
  prev: AnimeEpisodesMedia,
}

export type AnimeEpisodes = {
  id: number,
  cid: number,
  last_seen: number,
  id_last_seen: number,
  last_seen_date: string,
  lastSeenDateStr: string,
  lastSeenDateAgo: string,
  last_episode: number,
  next_to_see: number,
  id_next_to_see: number,
  lastSeenStr: string,
  media: AnimeEpisodesMediaTypes,
  episodes: AnimeEpisodeDetails[],
  viewed: AnimeEpisodeDetails[],
}

export type fitSlideContentTypes = {
  item: Slide,
  setIsOpen: Function,
  setId: Function,
  setEid?: Function,
}

export type moveEpisodeProps = {
  episodes?: AnimeEpisodes,
  eserie: number,
  cid?: number,
  setLoadingNextPrev?: React.Dispatch<React.SetStateAction<boolean>>,
  force?: boolean,
  keepEpisodesPassed?: boolean,
}

export type ParentalControlAlertProps = {
  content: AnimeContent,
}

export type responseEpisodes = {
  episodes?: AnimeEpisodes,
  media?: AnimeEpisodesMediaTypes,
}


export type changeEpisodeFunction = (episode: number) => void

export type AnimeSectionTypes = "description" | "episodes" | "information" | "trailer" | "staff" | "characters" | "recommendations" | "related" | "show-on" | "anime" | "voice-by" | null

export type AnimeSeasonTypes = "FALL" | "SPRING" | "SUMMER" | "WINTER" | ""

export type AnimeMediaAds = 0 | 1 | 2 | 3