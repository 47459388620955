import { SoftnyaAdsBannerItem, SoftnyaAdsBannerProps } from "types/Media/Ads/SoftnyaAds"
import { Endpoints } from "api/Endpoints"
import { Responses } from "types/Connect"
import Connect from "components/Connect"
import User from "components/Users/User"
import settings, { isDev } from "config/Settings"

class SoftnyaAdsClass {
  isStarted = false
  isTesting = new settings().adsIsTesting

  withAds = (): boolean =>
    !User.inList("PREMIUM") || !User.isLoggedIn() ||
    this.isTesting

  start = async (): Promise<boolean> => {
    if (!this.withAds()) return false
    this.isStarted = true
    return true
  }

  showVideo = async (): Promise<SoftnyaAdsBannerItem> => {
    if (!this.isStarted) await this.start()
    const video: Responses = await Connect.postcached({
      endpoint: Endpoints.adsvideo,
      options: { checkInterval: "shorter" },
    })
    isDev && console.warn("Display Softnya video", video)
    return video.data as SoftnyaAdsBannerItem
  }

  showBanner = async (props: SoftnyaAdsBannerProps): Promise<SoftnyaAdsBannerItem> => {
    if (!props.position || !this.isStarted) return {} as SoftnyaAdsBannerItem
    const banner: Responses = await Connect.postcached({
      endpoint: Endpoints.adsbanner,
      body: { ad: props.position },
      options: { checkInterval: "shorter" },
    })
    isDev && console.warn("Display Softnya banner", banner)
    return banner.data as SoftnyaAdsBannerItem
  }
}

export default SoftnyaAdsClass