import { Slide } from "types/Media/Slider"
import { getContentRemote } from "./getContentRemote"
import { ContentProps } from "types/Page/Content"
import { isParentalContentBlocked } from "pages/Discover/Anime/Methods/isParentalContentBlocked"
import AnimeContentParser from "pages/_commons/Content/AnimeContent"

export const loadContent = (props: {
  props: ContentProps,
  setPageContentPlay: Function,
  setEndOfList: Function,
  setItems: Function,
  updateLastCall: Function,
  setPageFromRemote: Function,
  pageFromRemote: number,
  pageContentPlay: number,
  items: Slide[],
  limit: number,
  callback?: (isFromCache: boolean) => void,
  setIsOpen: Function,
  setId: Function,
  setEid: Function,
}) => {
  const response = (results: Slide[], isFromCache: boolean) => {
    if (!results) return []
    if (isFromCache && props.pageFromRemote > 0) return []//if is from cache and page is not first, dont load
    results?.map((item: Slide) => {
      item.locked = 0
      if (item.isadult === 1 && isParentalContentBlocked(item)) item.locked = 1
      item = AnimeContentParser.fitSlideContent({
        item,
        setIsOpen: props.setIsOpen,
        setId: props.setId,
        setEid: props.setEid,
      })
      return item
    })
    props.setItems((items: Slide[]) => {
      const newItems = [...items, ...results]
      //first pages have repeated items, force filter on page 1-3
      const mustFilter = props.pageContentPlay <= 3 && props.props.type === "item" && props.props.mode === "grid"
      if (props.props.filterRepeated || props.props.filterRepeatedByEpisode || mustFilter) {
        const newItemsFiltered: Slide[] = []
        newItems?.map((item: Slide) => {
          if (props.props.type === "item" && typeof item.cid === "undefined") item.cid = item.id
          if (!newItemsFiltered.find((i: Slide) => {
            if (props.props.type === "item" && typeof i.cid === "undefined") i.cid = i.id
            return (
              (i.cid === item.cid && i.episode_no === item.episode_no && props.props.filterRepeatedByEpisode) ||
              (i.cid === item.cid && !props.props.filterRepeatedByEpisode)
            )
          })) newItemsFiltered.push(item)
          return item
        })
        props.setEndOfList(newItemsFiltered.length === items.length)
        return newItemsFiltered
      }
      return newItems
    })
    props.callback && props.callback(false)
  }
  props.setPageContentPlay(props.pageFromRemote > 0 ? props.pageFromRemote : props.pageContentPlay + 1)
  getContentRemote({
    order: props.props.params || {},
    precallback: (results: Slide[]) => response(results, true),
    callback: (results: Slide[]) => response(results, false),
    page: props.pageContentPlay + 1,
    props: props.props,
    items: props.items, limit: props.limit,
    updateLastCall: props.updateLastCall,
    setPageFromRemote: props.setPageFromRemote,
    setEndOfList: props.setEndOfList, getContentRemote,
  })
}
