import { StateStorage } from "zustand/middleware"
import { Storage } from "@ionic/storage"
import { StorageUtilsProps } from "types/Utils/Storage"
import Strings from "utils/Strings"
import settings from "config/Settings"

export class IonicStorage implements StateStorage {
  private storage = new Storage()

  constructor() {
    this.storage.create()
  }

  getItem(key: string): Promise<any> {
    return this.storage.get(key)
  }

  setItem(key: string, value: any): Promise<any> {
    return this.storage.set(key, value)
  }

  removeItem(key: string): Promise<any> {
    return this.storage.remove(key)
  }

  usedKeyInternalStorage = async (key: string): Promise<number> => {
    const value = await this.storage.get(key)
    if (typeof value === "string")
      return value.length * 2 // Two bytes per character in UTF-16 encoding
    else if (typeof value === "object" && value !== null)
      return JSON.stringify(value).length * 2
    else
      return String(value).length * 2
  }

  async getUsed(asNumber?: boolean): Promise<string | number> {
    const keys = await this.storage.keys()
    let size: number = 0
    for (let i = 0; i < keys.length; i++) {
      size += await this.usedKeyInternalStorage(keys[i])
    }
    const sizeRounded: number = Math.round(size)
    return asNumber === true ? sizeRounded : Strings.minifySize(sizeRounded)
  }

  getStatsInternalStorage = async (): Promise<StorageUtilsProps> => {
    const used: number = await this.getUsed(true) as unknown as number
    const maxSizeFull: number = await this.getTotal(true) as unknown as number
    const maxFreeSpace: number = settings.maxFreeSpace * 1024 * 1024// Convert to bytes
    const maxSize: number = maxSizeFull > maxFreeSpace ? maxFreeSpace : maxSizeFull
    return {
      available: {
        space: maxSize - used,
        percent: 100 - ((used / maxSize) * 100),
      },
      used: {
        space: used,
        percent: (used / maxSize) * 100,
      },
      maxSize: maxSize,
      isFull: used >= maxSize,
    } as StorageUtilsProps
  }

  getKeys = async (): Promise<string[]> => {
    return await this.storage.keys()
  }

  async getTotal(asNumber?: boolean): Promise<string | number> {
    const { quota } = await navigator.storage.estimate()
    const sizeRounded: number = Math.round(quota || 0)
    return asNumber ? sizeRounded : Strings.minifySize(sizeRounded)
  }

  async getAvailable(asNumber?: boolean): Promise<string | number> {
    const used: number = await this.getUsed(true) as unknown as number
    const total: number = await this.getTotal(true) as unknown as number
    const sizeRounded: number = Math.round(total - used)
    return asNumber ? sizeRounded : Strings.minifySize(sizeRounded)
  }

  async getPercentUsed(): Promise<number> {
    const used: number = await this.getUsed(true) as unknown as number
    const total: number = await this.getTotal(true) as unknown as number
    return Math.round((used / total) * 100)
  }
}

export const ionicStorage = new IonicStorage()