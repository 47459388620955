import { useEffect, useState } from "react"
import { IonButton, IonIcon } from "@ionic/react"
import { heartCircleOutline, heartDislikeCircleOutline } from "ionicons/icons"

import { AnimeMediaOptionsProps } from "types/Anime/AnimeMediaOptions"
import { SpinnerCircular } from "components/Commons/Spinner"
import { Responses } from "types/Connect"
import { sendFollow } from "../../Methods/sendFollow"
import { colorTypes, OneZeroTypes } from "types/index"
import locales from "config/Locales"
import AlertUserRequired from "components/Commons/Alerts/UserRequired"
import Alerts from "components/Commons/Alerts"
import useAnime from "store/useAnime"
import User from "components/Users/User"

const AnimeMediaOptionsFollow = (props: AnimeMediaOptionsProps) => {
  const [following, setFollowing] = useState<boolean>(false)
  const [loading, setLoading] = useState<boolean>(false)
  const [showAlertUserRequired, setShowAlertUserRequired] = useState<boolean>(false)
  const [showAlert, setShowAlert] = useState<boolean>(false)
  const [alertText, setAlertText] = useState<string>("")
  const [alertColor, setAlertColor] = useState<colorTypes>("success")
  const [img, setImg] = useState<string>(props.content?.img || "")
  const [imgBk, setImgBk] = useState<string>(props.content?.params?.img || "")
  const setRefreshGrids = useAnime<Function>((state: any) => state.setRefreshGrids)

  useEffect(() => setFollowing((props.content?.following?.my || 0) === 1), [props.content?.following])

  useEffect(() => {
    setImg(props.content?.img || "")
    setImgBk(props.content?.params?.img || "")
    return () => {
      setImg("")
      setImgBk("")
    }
  }, [props.content])

  const sendFollowParams = async (follow: boolean) => {
    if (!User.isLoggedIn()) return setShowAlertUserRequired(true)
    setLoading(true)
    sendFollow(follow, props.content?.id).then((response: Responses) => {
      setLoading(false)
      if (response.status === 201) {
        const my: OneZeroTypes = response?.data?.following?.my || 0
        setFollowing(my === 1)
        setAlertColor(my === 0 ? "warning" : "success")
        setAlertText(locales._get(`content.${my === 0 ? "not-" : ""}following`, props.content?.title))
        setShowAlert(true)
        setRefreshGrids(true)
      } else {
        setAlertColor("danger")
        setAlertText(locales._get("content.following-error", props.content?.title))
        setShowAlert(true)
      }
    })
  }

  const setFollow = () => sendFollowParams(true)

  const setUnfollow = () => sendFollowParams(false)

  return (
    <>
      <IonButton
        className="component-anime-media-options-follow"
        color={following ? "primary" : "tertiary"}
        size="small"
        onClick={() => following ? setUnfollow() : setFollow()}
      >
        {loading ?
          <SpinnerCircular />
          :
          <IonIcon icon={following ? heartCircleOutline : heartDislikeCircleOutline} />
        }
      </IonButton>
      <Alerts
        title={alertText}
        image={img}
        imageBk={imgBk}
        showAlert={showAlert}
        color={alertColor}
        position="bottom"
        duration={3000}
        onShowAlert={(show: boolean) => setShowAlert(show)}
      />
      <AlertUserRequired
        showAlert={showAlertUserRequired}
        onShowAlert={(showAlert: boolean) => setShowAlertUserRequired(showAlert)}
      />
    </>
  )
}

export default AnimeMediaOptionsFollow