import { SocialSquema, SocialSquemaName } from "types/Commons/Social"

export const squemas = {
  facebook: {
    android: "com.facebook.katana",
    ios: "fb://",
    device: "fb://composer/?link=",
    web: "https://www.facebook.com/sharer/sharer.php?u=",
  },
  twitter: {
    android: "com.twitter.android",
    ios: "twitter://",
    device: "twitter://post?message=",
    web: "https://twitter.com/intent/tweet?text=",
  },
  google: {
    android: "com.google.android.apps.plus",
    ios: "comgoogleplus://",
    device: "com.google.android.apps.plus://share?url=",
    web: "https://currents.google.com/share?url=",
  },
  pinterest: {
    android: "pinterest://",
    ios: "pinterest://",
    device: "pinterest://pin/create/button/?url=",
    web: "http://pinterest.com/pin/create/button/?url=",
  },
  whatsapp: {
    android: "whatsapp://",
    ios: "whatsapp://",
    device: "whatsapp://send?text=",
    web: "https://wa.me/?text=",
  },
}

export const getSquema = (name: SocialSquemaName): SocialSquema => {
  if (name === "facebook") return squemas.facebook
  if (name === "twitter") return squemas.twitter
  if (name === "google") return squemas.google
  if (name === "pinterest") return squemas.pinterest
  if (name === "whatsapp") return squemas.whatsapp
  return {} as SocialSquema
}