import { AppSettingsID } from "utils/AppSettings"
import Strings from "utils/Strings"
import JDate from "utils/JDate"
import settings from ".."

export class settings_ads {
  get adsIsTesting(): boolean {
    return settings.forceDebug
  }
  static admob_bannerid: string[] = ["ca-app-pub-8306990184519644/8161995541"]
  static admob_interstitialid: string[] = ["ca-app-pub-8306990184519644/2877879271"]
  static admob_rewardid: string[] = ["ca-app-pub-8306990184519644/6625552590"]
  static ads_remove_interval: number = 60 * 60// 1 hour
  get getadslocklargetime(): number {
    return AppSettingsID.getSetting("adslocklargetime", JDate.getTodayTimestamp())
  }
  set adslocklargetime(value: number) {
    AppSettingsID.setSetting("adslocklargetime", value)
  }
  get getadslockcontenttime(): number {
    return AppSettingsID.getSetting("adslockcontenttime", JDate.getTodayTimestamp())
  }
  set adslockcontenttime(value: number) {
    AppSettingsID.setSetting("adslockcontenttime", value)
  }
  get getadslockvideotime(): number {
    return AppSettingsID.getSetting("adslockvideotime", JDate.getTodayTimestamp())
  }
  set adslockvideotime(value: number) {
    AppSettingsID.setSetting("adslockvideotime", value)
  }
  get getadmob_bannerid(): string {
    return settings_ads.admob_bannerid[Strings.getRandomBetween(0, settings_ads.admob_bannerid.length - 1)]
  }
  get getadmob_interstitialid(): string {
    return settings_ads.admob_interstitialid[Strings.getRandomBetween(0, settings_ads.admob_interstitialid.length - 1)]
  }
  get getadmob_rewardid(): string {
    return settings_ads.admob_rewardid[Strings.getRandomBetween(0, settings_ads.admob_rewardid.length - 1)]
  }
  static softnyashoweveryrows: number = 8
  get adsvideoshowaverage(): number {
    return AppSettingsID.getSetting("adsvideoshowaverage", 45)
  }
  set adsvideoshowaverage(value: number) {
    AppSettingsID.setSetting("adsvideoshowaverage", value)
  }
  get softnyaadsvideoshowaverage(): number {
    return AppSettingsID.getSetting("softnyaadsvideoshowaverage", 60)
  }
  set softnyaadsvideoshowaverage(value: number) {
    AppSettingsID.setSetting("softnyaadsvideoshowaverage", value)
  }
  get softnyaadsvideotimeout(): number {
    return AppSettingsID.getSetting("softnyaadsvideotimeout", 17)
  }
  set softnyaadsvideotimeout(value: number) {
    AppSettingsID.setSetting("softnyaadsvideotimeout", value)
  }
}