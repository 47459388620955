import { getQueryFormat, getQueryLocal } from "components/Connect/Exports"
import { AnimeContent, AnimeContentFollow, defaultFollow } from "types/Anime"
import { StorageItems } from "utils/Storage/StorageItems/Storage"
import { Endpoints } from "api/Endpoints"
import { Responses } from "types/Connect"
import { updateUserLists } from "../../Commons/Tabs/Episodes/SetItems/Methods/updateUserLists"
import AnimeContentParser from "pages/_commons/Content/AnimeContent"
import Connect from "components/Connect"

export const sendFollow = async (follow: boolean, cid: number): Promise<Responses> => {
  const contentData: Responses = await Connect.put({
    endpoint: Endpoints.detailsfollow,
    body: {
      id: cid,
      follow: follow ? 1 : 0,
    }
  })
  const query = Connect.getQuery({
    endpoint: Endpoints.details,
    params: { id: cid },
  })
  const queryFormat = getQueryFormat(query)
  const cachedContent: Responses = await getQueryLocal(queryFormat)
  if (contentData?.status === 201) {
    if (cachedContent?.data) {
      const content: AnimeContent = AnimeContentParser.fitAnimeContent(cachedContent?.data?.results) || {} as AnimeContent
      if (!content.following?.follow || !content.following?.my)
        content.following = {
          follow: 0,
          my: 0,
        } as AnimeContentFollow
      const following: AnimeContentFollow = contentData?.data?.following || defaultFollow
      content.following.follow = following.follow
      content.following.my = follow ? 1 : 0
      if (!contentData.data?.following?.follow || !contentData.data?.following?.my)
        contentData.data.following = defaultFollow
      contentData.data.following.follow = following.follow
      contentData.data.following.my = follow ? 1 : 0
      await StorageItems.setItem(queryFormat, JSON.stringify({
        ...cachedContent,
        data: content,
      }))
    }
    updateUserLists({
      forYou: true,
      continueWatching: false,
    })
  } else {
    contentData.data = {
      following: cachedContent.data?.results?.following || defaultFollow,
    }
  }
  return contentData
}