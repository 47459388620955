import { NarutoRun } from "types/Anime/Images"
import OverCard from "components/Content/OverCard"
import locales from "config/Locales"

const WithoutFollow = () => {
  return (
    <OverCard
      title={locales._get("no_following")}
      subtitle={locales._get("no_following_subtitle")}
      image={NarutoRun}
    />
  )
}

export default WithoutFollow