import { IonLabel, IonSegment, useIonAlert } from "@ionic/react"
import { useEffect, useState } from "react"
import { cloudDownloadOutline, playCircle } from "ionicons/icons"
import { useHistory, useParams } from "react-router-dom"

import { Endpoints } from "api/Endpoints"
import { AnimeContent, AnimeEpisodes, AnimeEpisodesMedia, AnimeProps, AnimeSectionTypes, EpisodeMediaType, responseEpisodes } from "types/Anime"
import { isParentalContentBlocked } from "./Methods/isParentalContentBlocked"
import { setSearchWords } from "./Methods/setSearchWords"
import { canDownload, canPlay } from "./Methods/canPlayDownload"
import { moveEpisode } from "./Methods/moveEpisode"
import { cacheContent } from "./Methods/cacheContent"
import { getEpisodes } from "./Methods/getEpisodes"
import { getContent } from "./Methods/getContent"
import { ContentType } from "types/Page/Content"
import { Responses } from "types/Connect"
import { colorTypes } from "types/index"
import { formatSearchContent } from "pages/_commons/Methods/formatSearchContent"
import { share } from "components/Commons/Social"
import useHistoryRecord, { saveLastHistoryRecordRemote } from "store/useHistoryRecord"
import Connect from "components/Connect"
import GA from "utils/GA"
import JDate from "utils/JDate"
import Strings from "utils/Strings"
import Animate from "utils/Animate"
import ErrorHandler from "components/Commons/ErrorHandler"
import AnimeTopBar from "../Commons/TopBar"
import PageContainer from "pages/_commons/PageContainer"
import AnimeBreadcrumbs from "./Breadcrumbs"
import locales from "config/Locales"
import User from "components/Users/User"
import DropDownButton from "components/Commons/DropDownButton"
import Skeleton from "components/Commons/Skeleton"
import Image from "components/Media/Image"
import TabSegment from "../Commons/Tabs/Segment"
import AnimeDescription from "../Commons/Tabs/Description"
import AnimeEpisodesTabs from "../Commons/Tabs/Episodes"
import AnimeInformation from "../Commons/Tabs/Information"
import AnimeMediaOptions from "./MediaOptions"
import ParentalControlAlert from "./ParentalControlAlert"
import AnimeEpisodeDropDownContent from "./DropDownContent"
import AnimeSubtitle from "./Subtitle"
import AnimeState from "./State"
import AnimeTopBadge from "./TopBadge"
import useHistorySearch from "store/useHistorySearch"
import useAnime from "store/useAnime"
import AnimeTrailer from "../Commons/Tabs/Trailer"
import AnimeRelatedRecommended from "../Commons/Tabs/RelatedRecommended"
import AnimeCharactersStaff from "../Commons/Tabs/CharactersStaff"
import AnimeComments from "./Comments"
import AdsBanner from "components/Media/Ads/Banner"
import Meta from "components/Media/Meta"

import "./index.scss"

const Anime = (props: AnimeProps) => {
  const [cid, setCid] = useState<number>(0)
  const [eid, setEid] = useState<number>(0)
  const [presentAlert] = useIonAlert()
  const [errorTriggered, setErrorTriggered] = useState(false)
  const [showError, setShowError] = useState<boolean>(false)
  const [imgBG, setImgBG] = useState<string>("")
  const [dropdownClass, setDropdownClass] = useState<string>("")
  const [loaded, setLoaded] = useState<boolean>(false)
  const [loadingNextPrev, setLoadingNextPrev] = useState<boolean>(false)
  const [loadedEpisodes, setLoadedEpisodes] = useState<boolean>(false)
  const [activeSection, setActiveSection] = useState<AnimeSectionTypes>(null)
  const [content, setContent] = useState<AnimeContent>({} as AnimeContent)
  const [episodes, setEpisodes] = useState<AnimeEpisodes>({} as AnimeEpisodes)
  const [episode, setEpisode] = useState<number>(0)
  const [showEpisodes, setShowEpisodes] = useState<boolean>(true)
  const [lastMediaPlay, setLastMediaPlay] = useState<string>("")
  const [lastMediaDownload, setLastMediaDownload] = useState<string>("")
  const [lastMediaPlayColor, setLastMediaPlayColor] = useState<colorTypes>("primary")
  const [lastMediaDownloadColor, setLastMediaDownloadColor] = useState<colorTypes>("primary")
  const [mediaPlay, setMediaPlay] = useState<EpisodeMediaType>({} as EpisodeMediaType)
  const [canPlayEpisode, setCanPlayEpisode] = useState<boolean>(true)
  const [canDownloadEpisode, setCanDownloadEpisode] = useState<boolean>(true)
  const addHistoryRecord = useHistoryRecord<Function>((state: any) => state.addHistoryRecord)
  const currentSearch = useHistorySearch<string>((state: any) => state.currentSearch)
  const isOpen = useAnime<boolean>((state: any) => state.isOpenMedia)
  const setIsOpen = useAnime<Function>((state: any) => state.setIsOpenMedia)
  const setIsOpenDetails = useAnime<Function>((state: any) => state.setIsOpen)
  const setIsOpenCharacter = useAnime<Function>((state: any) => state.setIsOpenCharacter)
  const setIsOpenStaff = useAnime<Function>((state: any) => state.setIsOpenStaff)
  const setMidModal = useAnime<Function>((state: any) => state.setMid)
  const setEidModal = useAnime<Function>((state: any) => state.setEid)
  const setChidModal = useAnime<Function>((state: any) => state.setChid)
  const setStidModal = useAnime<Function>((state: any) => state.setStid)
  const setLastLoadedId = useAnime<Function>((state: any) => state.setLastLoadedId)
  const setLastLoadingId = useAnime<Function>((state: any) => state.setLastLoadingId)
  const cidStore = useAnime<number>((state: any) => state.id)
  const lastMediaStore = useAnime<string>((state: any) => state.lastMedia)
  const setLastMediaStore = useAnime<Function>((state: any) => state.setLastMedia)
  const lockEpisodes: boolean = !canPlayEpisode || loadingNextPrev
  const lockEpisodesDownload: boolean = !canDownloadEpisode || loadingNextPrev
  const params = useParams()
  const history = useHistory()

  const refreshCanPlay = () => {
    setCanPlayEpisode(canPlay(content, episodes, mediaPlay))
    setDropdownClass(Animate.setAnimation(canPlayEpisode ? "tada" : "jello"))
  }

  const refreshCanDownload = () => {
    setCanDownloadEpisode(canDownload(content, episodes, mediaPlay))
    setDropdownClass(Animate.setAnimation(canDownloadEpisode ? "tada" : "jello"))
  }

  const setEpisodeDetails = () => {
    setEpisode(episodes.media?.current?.serie || 1)
    refreshCanPlay()
    refreshCanDownload()
    setLoadingNextPrev(false)
    setLastMediaPlay(() => {
      if (episodes.media?.current?.play.length === 0) return ""
      const lastMediaExists: number = (episodes.media?.current?.play?.find((play) =>
        play.provider === lastMediaStore) || {} as EpisodeMediaType)?.id || 0
      if (lastMediaStore !== "" && lastMediaExists > 0) return lastMediaStore
      return episodes.media?.current?.play[0]?.provider || ""
    })
    setLastMediaDownload(() => {
      if (episodes.media?.current?.download.length === 0) return ""
      const lastMediaExists: number = (episodes.media?.current?.download?.find((download) =>
        download.provider === lastMediaStore) || {} as EpisodeMediaType)?.id || 0
      if (lastMediaStore !== "" && lastMediaExists > 0) return lastMediaStore
      return episodes.media?.current?.download[0]?.provider || ""
    })
  }

  const openTrailer = () => {
    setIsOpen(true)
    setEidModal(0)
    setMidModal(0)
    setActiveSection("description")
  }

  const thenResponsemoveEpisode = (newEpisodes: AnimeEpisodes) =>
    setEpisodes(() => {
      setEpisodeDetails()
      setLastLoadedId(newEpisodes.media?.current?.serie || 0)
      return newEpisodes
    })

  const addHistoryContent = (_content: AnimeContent, type: ContentType, _episode?: AnimeEpisodesMedia) => {
    if (type === "episode" && (_episode?.serie || 0) === 0) return
    addHistoryRecord({
      path: window.location.pathname,
      title: _content?.title || "",
      cid: _content.id || 0,
      eid: _episode?.eid || 0,
      mid: 0,
      serie: _episode?.serie || 0,
      provider: "",
      image: _content?.img2 || "",
      imageBk: _content?.params?.img || "",
      type: type,
      date: JDate.now(),
      user: User.getActiveUser(),
    })
    saveLastHistoryRecordRemote()
  }

  const changeEpisode = (episode: number) => {
    if (loadingNextPrev) {
      setLastLoadedId(episode)
      return
    }
    setLastLoadingId(episode)
    moveEpisode({ episodes, eserie: episode, setLoadingNextPrev })
      .then((newEpisodes: AnimeEpisodes) =>
        thenResponsemoveEpisode(newEpisodes)
      )
  }

  const setSearchWordsParams = (str: string): string =>
    setSearchWords(str, currentSearch)

  useEffect(() => {
    if (!episodes?.episodes || !content?.last_episode || !isOpen) return
    setShowError(false)
    if (episodes?.media?.current?.eid === eid || episodes?.media?.current?.cid === cid) return
    const lastEpisode = episodes?.episodes?.find((episode) =>
      episode.episode === content.last_episode)
    if (!lastEpisode)
      getEpisodes({ cid, eid, force: true }).then((data: responseEpisodes) =>
        setEpisodes(data?.episodes || {} as AnimeEpisodes))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [content, episodes])

  const getPathId = () => {
    const path: string[] = window.location.pathname.split("/")
    if (path[1] !== "anime") return 0
    return parseInt(path[2]) || 0
  }

  const updateInfo = () => {
    const _cid = getPathId()
    if (_cid > 0 && _cid !== cid) setCid(_cid)
    if (cid === 0)
      if (props.id) setCid(props.id)
      else Strings.parseParams("cid", params, (_cid: number) =>
        setCid(_cid))
    if (eid === 0)
      if (props.eid && (props.eid || 0) > 0) setEid(props.eid)
      else Strings.parseParams("eid", params, (_eid: number) =>
        _eid > 0 && setEid(_eid))
    if (cid === 0 || isNaN(cid)) return
    setEpisodes({} as AnimeEpisodes)
    setImgBG("")
    setLoaded(false)
    cid !== content?.id && getContent({ cid, force: showError }).then((content: AnimeContent) => {
      setContent(() => {
        addHistoryContent(content, "content")
        GA.pageview(content.title_romaji || content.title)
        if ((content?.id || 0) === 0) setShowError(true)
        return content
      })
      setLoaded(true)
    })
    if (!User.isLoggedIn())
      setEpisode(1)
    cid !== episodes?.cid && getEpisodes({ cid, eid }).then((data: responseEpisodes) => {
      const episodes: AnimeEpisodes = data.episodes || {} as AnimeEpisodes
      const episode: number = episodes?.next_to_see || episodes?.last_seen || 1
      cacheContent(episodes, cid, episode, episodes.last_episode, (loaded: number) =>
        setLastLoadedId(loaded))
      moveEpisode({ episodes, eserie: episode, setLoadingNextPrev, keepEpisodesPassed: true })
        .then((newEpisodes: AnimeEpisodes) =>
          thenResponsemoveEpisode(newEpisodes))
      setLoadedEpisodes(true)
    })
  }

  useEffect(() => {
    const play: EpisodeMediaType = episodes.media?.current?.play?.find((media: EpisodeMediaType) => media.provider === lastMediaPlay) || {} as EpisodeMediaType
    const download: EpisodeMediaType = episodes.media?.current?.download?.find((media: EpisodeMediaType) => media.provider === lastMediaDownload) || {} as EpisodeMediaType
    setLastMediaPlayColor(play?.premium === 1 ? "secondary" : "primary")
    setLastMediaDownloadColor(download?.premium === 1 ? "secondary" : "primary")
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [lastMediaPlay, lastMediaDownload])

  useEffect(() => {
    updateInfo()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [params, cid, eid, showError])

  useEffect(() => {
    const _cid = getPathId()
    _cid === 0 && setCid(cidStore)
  }, [cidStore])

  useEffect(() => {
    setActiveSection("description")
    setShowEpisodes(content.ctype === "tv" || (content.last_episode || 0) > 1)
    setImgBG(content?.img3 || "")
  }, [content])

  useEffect(() => {
    setEpisodeDetails()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [episodes])

  useEffect(() => {
    if (lastMediaPlay === "") return
    setMediaPlay(() => {
      const newMedia: EpisodeMediaType = episodes.media?.current?.play?.find((media: EpisodeMediaType) => media.provider === lastMediaPlay) || {} as EpisodeMediaType
      if (isOpen) {
        setEidModal(episodes?.media?.current?.eid || 0)
        setMidModal(newMedia?.id || 0)
      }
      return newMedia
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [lastMediaPlay, episode])

  useEffect(() => {
    refreshCanPlay()
    refreshCanDownload()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [content, episodes, mediaPlay])

  useEffect(() => {
    if (lastMediaPlay === lastMediaStore) return
    setLastMediaPlay(lastMediaStore)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [lastMediaStore])

  useEffect(() => {
    if (episode === 0 || !content.last_episode) return
    if (episode > content.last_episode) {
      setContent({
        ...content,
        last_episode: episode,
        lastEpisodeStr: episode.toString(),
      })
      setEpisodes({
        ...episodes,
        last_episode: episode,
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [episode])

  return (
    <PageContainer
      className="page-anime-details"
      showLogo={false}
      onClickBack={() => {
        const _cid = getPathId()
        _cid > 0 && history.push("/anime")
        setIsOpenDetails(false)
      }}
    >
      <div className={`background ${isParentalContentBlocked(content) ? "blured" : ""}`}
        style={{
          "backgroundImage": `url(${imgBG})`,
        }}
      >
      </div>
      <div className={`ion-padding inner-container ${showError && "withError"}`}>
        <div>
          <ErrorHandler showError={showError}>
            {!loaded && <>
              <Skeleton articleLike={true} />
            </>}
            {loaded &&
              <>
                <AnimeTopBar
                  link={content.share || ""}
                  title={content.title}
                  image={content.img}
                />
                <IonLabel className="title">
                  <AnimeTopBadge top={content.top || 0} />
                  <span dangerouslySetInnerHTML={{ __html: setSearchWordsParams(content.title) }} />
                  {(content.ctype === "tv" || (content.last_episode || 0) > 1) &&
                    <>
                      &nbsp;
                      <small>
                        {(loadedEpisodes && !loadingNextPrev) &&
                          <>({locales._get("content.episode-item-at", { "%s": episode, "%t": content.last_episode })})</>
                        }
                      </small>
                    </>
                  }
                </IonLabel>
                <AnimeSubtitle
                  content={content}
                  episodes={episodes}
                  currentSearch={currentSearch}
                />
                <AnimeState content={content} episodes={episodes} />
                <AdsBanner type="softnyacontent" softnyaposition="DETAILS_TOP" force={true} />
                {User.inList("VIEW_ANIME") &&
                  <div className="anime-dropdown-container">
                    <DropDownButton
                      id="media-picker"
                      color={lastMediaPlayColor}
                      text={`${locales._get("content.watch")}${lastMediaPlay === "" ? "" : ` [${lastMediaPlay}]`}`}
                      subtext={
                        (content.ctype === "tv" || (content.last_episode || 0) > 1) ?
                          (!loadedEpisodes || loadingNextPrev) ? "" : locales._get("content.watch-at", { "%s": episode, "%t": content.last_episode })
                          : ""}
                      icon={(!loadedEpisodes || loadingNextPrev) ? "spinner" : playCircle}
                      inClassName={dropdownClass}
                      size="large"
                      onClick={() => {
                        setIsOpen(true)
                        setEidModal(episodes?.media?.current?.eid || 0)
                        setMidModal(mediaPlay?.id || 0)
                        addHistoryContent(content, "episode", episodes?.media?.current || {} as EpisodeMediaType)
                      }}
                      disabled={lockEpisodes}
                    >
                      {(episodes?.media?.current?.play?.length > 0 && !loadingNextPrev) &&
                        <AnimeEpisodeDropDownContent
                          mediatypes={episodes.media}
                          selectedMedia={lastMediaPlay}
                          onClick={(episode: EpisodeMediaType) =>
                            setLastMediaPlay(() => {
                              setLastMediaStore(episode.provider)
                              return episode.provider
                            })}
                        />
                      }
                    </DropDownButton>
                    <DropDownButton
                      id="media-picker-download"
                      color={lastMediaDownloadColor}
                      text={`${locales._get("content.download")}${lastMediaDownload === "" ? "" : ` [${lastMediaDownload}]`}`}
                      subtext={
                        (content.ctype === "tv" || (content.last_episode || 0) > 1) ?
                          (!loadedEpisodes || loadingNextPrev) ? "" : locales._get("content.download-at", { "%s": episode, "%t": content.last_episode })
                          : ""}
                      icon={(!loadedEpisodes || loadingNextPrev) ? "spinner" : cloudDownloadOutline}
                      inClassName={dropdownClass}
                      size="large"
                      onClick={() =>
                        presentAlert({
                          header: locales._get("appname"),
                          subHeader: locales._get("content.download"),
                          message: locales._get("content.download-message", {
                            "%s": episodes?.media?.current?.serie || "?",
                            "%t": content?.title || "?",
                          }),
                          buttons: [
                            {
                              text: locales._get("content.download-cancel"),
                              role: "cancel",
                            },
                            {
                              text: locales._get("content.download"),
                              handler: () => {
                                const media: EpisodeMediaType = episodes?.media?.current?.download?.find((media: EpisodeMediaType) => media.provider === lastMediaDownload) || {} as EpisodeMediaType
                                share.open(media.media)
                              }
                            }
                          ]
                        })}
                      disabled={lockEpisodesDownload}
                    >
                      {(episodes?.media?.current?.download?.length > 0 && !loadingNextPrev) &&
                        <AnimeEpisodeDropDownContent
                          mediatypes={episodes.media}
                          selectedMedia={lastMediaDownload}
                          type="download"
                          onClick={(episode: EpisodeMediaType) =>
                            setLastMediaDownload(() => {
                              return episode.provider
                            })}
                        />
                      }
                    </DropDownButton>
                  </div>
                }
                <AnimeMediaOptions
                  showButtons={showEpisodes}
                  content={content}
                  episodes={episodes}
                  disabled={loadingNextPrev}
                  onPrevious={() => setDropdownClass(() => {
                    moveEpisode({ episodes, eserie: episodes?.media?.prev?.serie, setLoadingNextPrev })
                      .then((newEpisodes: AnimeEpisodes) =>
                        thenResponsemoveEpisode(newEpisodes)
                      )
                    return ""
                  })}
                  onNext={() => setDropdownClass(() => {
                    moveEpisode({ episodes, eserie: episodes?.media?.next?.serie, setLoadingNextPrev })
                      .then((newEpisodes: AnimeEpisodes) =>
                        thenResponsemoveEpisode(newEpisodes)
                      )
                    return ""
                  })}
                />
                <ParentalControlAlert content={content} />
                <AnimeBreadcrumbs content={content} />
                <AnimeComments
                  content={content}
                  episode={episodes?.media?.current || {} as AnimeEpisodesMedia}
                  changeEpisode={(episode: number) => changeEpisode(episode)}
                />

                <IonSegment scrollable={true} value={activeSection?.toString()} onIonChange={(e: any) => {
                  setActiveSection(e.detail.value)
                }}>
                  <TabSegment name="description" />
                  {(showEpisodes) && <TabSegment name="episodes" lockSegment={loadingNextPrev} />}
                  <TabSegment name="information" />
                  {content.trailer?.id ? <TabSegment name="trailer" img={content.trailer?.thumbnail} /> : ""}
                  <TabSegment name="recommendations" />
                  <TabSegment name="related" />
                  <TabSegment name="characters" />
                  <TabSegment name="staff" />
                </IonSegment>
                <div className="segments">
                  {activeSection === "description" &&
                    <AnimeDescription content={formatSearchContent(content, currentSearch)} />
                  }
                  {activeSection === "episodes" &&
                    <AnimeEpisodesTabs
                      content={content}
                      episodes={episodes}
                      changeEpisode={(episode: number) => changeEpisode(episode)}
                    />
                  }
                  {activeSection === "information" &&
                    <AnimeInformation content={formatSearchContent(content, currentSearch)} />
                  }
                  {activeSection === "trailer" &&
                    <AnimeTrailer content={content} openTrailer={openTrailer} />
                  }
                  {activeSection === "recommendations" &&
                    <AnimeRelatedRecommended content={content} type="recommendations" />
                  }
                  {activeSection === "related" &&
                    <AnimeRelatedRecommended content={content} type="related" />
                  }
                  {activeSection === "characters" &&
                    <AnimeCharactersStaff
                      content={content}
                      setIsOpen={setIsOpenCharacter}
                      setId={setChidModal}
                      type="characters"
                    />
                  }
                  {activeSection === "staff" &&
                    <AnimeCharactersStaff
                      content={content}
                      setIsOpen={setIsOpenStaff}
                      setId={setStidModal}
                      type="staff"
                    />
                  }
                </div>
              </>
            }
            <AdsBanner type="softnyacontent" softnyaposition="DETAILS_BOTTOM" force={true} />
          </ErrorHandler>
        </div>
      </div>
      <Image
        className="component-anime-image-bg-checker"
        src={content?.img3 || ""}
        srcBk={content?.params?.img2 || ""}
        onIonError={() => {
          setImgBG(content?.params?.img2 || "")
          if (errorTriggered) return
          Connect.put({
            endpoint: Endpoints.detailsupdateimage,
            body: { id: content.id, from: "params.img2", to: "img3" },
          }).then((response: Responses) => {
            setErrorTriggered(true)
            if (response.status === 200 && response.data?.image) setImgBG(response.data?.image)
          })
        }}
      />
      <Meta
        title={content.title}
        description={content?.content || ""}
        image={content?.img || ""}
        link={content?.share || ""}
      />
    </PageContainer>
  )
}

export default Anime