import { ErrorHandlerProps } from "types/Commons/ErrorHandler"
import ErrorHandlerErrorMessage from "./ErrorMessage"

const ErrorHandler = (props: ErrorHandlerProps) => {
  if (props.showError) return <ErrorHandlerErrorMessage />
  return (
    <>{props.children}</>
  )
}

export default ErrorHandler