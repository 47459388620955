import { useEffect, useState } from "react"

import { OneZeroTypes } from "types/index"
import { Endpoints } from "api/Endpoints"
import { toggleNote } from "./Methods/toggleNote"
import { paramsRecentNewContent } from "api/routes/params/Media"
import { Slide } from "types/Media/Slider"
import useHistorySearch, { saveHistorySearchRemote } from "store/useHistorySearch"
import GA from "utils/GA"
import locales from "config/Locales"
import settings from "config/Settings"
import Searchbar from "components/Commons/Searchbar"
import User from "components/Users/User"
import Content from "pages/_commons/Content/Content"
import PageContainer from "pages/_commons/PageContainer"
import AniTitle from "pages/_commons/AniTitle"
import NoResults from "./NoResults"
import RelevanceInfo from "./RelevanceInfo"
import NoSearch from "./NoSearch"
import Meta from "components/Media/Meta"

import "./index.scss"

const Search = () => {
  const [query, setQuery] = useState<string>("")
  const [minQueryLength] = useState<number>(3)
  const [resetPage, setResetPage] = useState<boolean>(true)
  const [hasContent, setHasContent] = useState<boolean>(false)
  const addHistorySearch = useHistorySearch<Function>((state: any) => state.addHistorySearch)
  const setCurrentSearch = useHistorySearch<Function>((state: any) => state.setCurrentSearch)
  const setCurrentRelevance = useHistorySearch<Function>((state: any) => state.setCurrentRelevance)

  const toggleResetPage = () => {
    setResetPage((prevPage: boolean) => !prevPage)
    setHasContent(true)
  }

  const saveHistorySearch = () => {
    if (!hasContent || query === "") return
    setTimeout(() => {
      const cards = document.querySelectorAll(".component-grid ion-card") as NodeListOf<HTMLElement>
      if (cards && cards.length === 0) saveHistorySearch()

      const img = cards[0]?.querySelector("div.background") as HTMLDivElement
      const src = img?.dataset["src"] || ""
      if (src === "") return
      addHistorySearch({
        query: query,
        image: src,
        user: User.getActiveUser(),
      })
      saveHistorySearchRemote(query, src)
      GA.search(query.replaceAll("+", " "))
    }, 1000)
  }

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => saveHistorySearch(), [query])

  useEffect(() => {
    setCurrentSearch(query)
    return () => {
      setCurrentSearch("")
      setCurrentRelevance(0)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [query, setCurrentSearch])

  return (
    <PageContainer
      withProgress={true}
      withToTop={true}
      className="page-search"
    >
      <Searchbar
        placeholder={locales._get("content.search-placeholder")}
        highlight={true}
        debounce={2500}
        callbackChange={(_query: string) => {
          toggleResetPage()
          setQuery(_query.replaceAll(" ", "+"))
          if (_query.length < minQueryLength) return
          const searchbar = document.querySelector("ion-searchbar") as HTMLIonSearchbarElement
          searchbar && searchbar.setAttribute("disabled", "true")
          setTimeout(() => searchbar && searchbar.removeAttribute("disabled"), 300)
        }}
        onFocus={(e: any) => {
          const clearSearchOnFocus: OneZeroTypes = new settings().clearSearchOnFocus
          toggleNote(false)
          if (clearSearchOnFocus === 0) return
          setQuery("")
          e.target.value = ""
        }}
        onBlur={() => toggleNote(true)}
      />
      <div className="ion-padding inner-container">
        {query.length >= minQueryLength ?
          <>
            {hasContent ? (
              <>
                <RelevanceInfo />
                <Content
                  title={locales._get("content.search-results")}
                  endpoint={Endpoints.recent}
                  params={{ ...paramsRecentNewContent, "w": query }}
                  resetPage={resetPage}
                  type="item"
                  mode="grid"
                  watchLastCall={false}
                  force={true}
                  onClick={(item: Slide) => {
                    const card = document.querySelector(`ion-card[data-id="${item.id}"]`) as HTMLIonCardElement
                    const relevance = card && card.querySelector(".component-relevance-card-item") as HTMLDivElement
                    const relevanceNum = parseInt(relevance && (relevance.dataset["relevance"] || "0")) as number
                    setCurrentRelevance(relevanceNum)
                  }}
                  onEmptySlider={(isEmpty: boolean) => setHasContent(!isEmpty)}
                />
              </>
            ) : (
              <>
                <AniTitle
                  title={locales._get("content.search-results")}
                />
                <NoResults />
              </>
            )}
          </>
          :
          <NoSearch
            showMinLengthAlert={(query?.length || 0) < 3 && query !== ""}
            minQueryLength={minQueryLength}
          />
        }
      </div>
      <Meta
        title={locales._get("search")}
      />
    </PageContainer>
  )
}

export default Search