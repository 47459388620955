import { Slide } from "types/Media/Slider"
import locales from "config/Locales"
import { IsTop } from "../IsTop"

export const IsLastContent = (item: Slide, showBadge: boolean) => {
  return item.contentOverTitle = () => {
    if (!item.recentbadge && !item.ctype) return <>{IsTop(item)}</>

    if (!showBadge) return <>{IsTop(item)}</>
    return <>
      {IsTop(item)}
      {(item.recentbadge && item.ctype === "tv" && !item.ctype) &&
        <div className={`last-content color-${item.recentbadge === "1" ? "base" : "danger"}`}>
          <span>{locales._get(item.recentbadge === "1" ? "last_episode" : "more_episodes")}</span>
        </div>
      }
      {item.ctype &&
        <div className={`content-type type-${item.ctype}`}>
          <span>{locales._get(`content.type-${item.ctype}`)}</span>
        </div>
      }
    </>
  }
}