import { IonButton, IonButtons, IonIcon, IonImg } from "@ionic/react"
import { expandOutline, openOutline } from "ionicons/icons"

import { AnimeTabs } from "types/Anime"
import locales from "config/Locales"

import "./index.scss"

const AnimeTrailer = (props: AnimeTabs) => {
  if (!props.content.trailer) return <></>

  return (
    <div className="component-anime-trailer">
      <div className="frame-container">
        <iframe
          title="Trailer"
          src={props.content.trailer?.url || ""}
        />
        <IonImg src={props.content.trailer?.thumbnail || ""} />
      </div>
      <IonButtons>
        <IonButton
          color="primary"
          fill="solid"
          onClick={() => window.open(props.content.trailer?.external || props.content.trailer?.url || "", "_blank")}
        >
          {locales._get("content.watch-trailer-on", props.content.trailer?.site || locales._get("content.watch-trailer-external-media"))}
          <IonIcon icon={openOutline} />
        </IonButton>
        <IonButton
          color="secondary"
          fill="solid"
          onClick={() => props.openTrailer && props.openTrailer()}
        >
          {locales._get("content.watch-trailer-fullscreen")}
          <IonIcon icon={expandOutline} />
        </IonButton>
      </IonButtons>
    </div>
  )
}

export default AnimeTrailer