import { Endpoints } from "api/Endpoints"

import { filtersPremium, filtersContentType } from "types/Content/filters"
import { ContentProps, defaultContentProps } from "types/Page/Content"
import locales from "config/Locales"
import Content from "../Content"

const Popular = (props: ContentProps) => {

  return (
    <Content
      title={locales._get("popular_content", locales._get("appname"))}
      href="/home/popular"
      endpoint={Endpoints.popular}
      checkInterval="day"
      watchLastCall={props.watchLastCall}
      loadContent={props.loadContent}
      resetPage={props.resetPage}
      mode={props.mode || "slider"}
      filters={props.mode === "grid" ? [
        ...[filtersPremium],
        ...[filtersContentType],
      ] : []}
      type="item"
    />
  )
}

Popular.defaultProps = defaultContentProps

export default Popular