import { Capacitor } from "@capacitor/core"
import { AppVersion } from "@ionic-native/app-version"
import { useEffect, useState } from "react"
import showdown from "showdown"

import { AppSettingsID } from "utils/AppSettings"
import { getPlatform } from "../Device"
import JDate from "utils/JDate"
import locales from "config/Locales"
import IsLatestCheck from "pages/_commons/IsLatest/CheckLatest"
import settings from ".."

const defaultVersionProps = {
  label: "version",
  showReleaseDate: false,
}

export const getVersionNumber = async () =>
  Capacitor.isNativePlatform() ? await AppVersion.getVersionNumber() : "0.0"

export const getVersionCode = async () =>
  Capacitor.isNativePlatform() ? await AppVersion.getVersionCode() : "0"

export const getVersion = async () =>
  Capacitor.isNativePlatform() ? `${await getVersionNumber()}.${await getVersionCode()}` : getPlatform()

export const Version = (props: { label?: string, showReleaseDate?: boolean, showOutdated: boolean }) => {
  const { label, showReleaseDate } = { ...defaultVersionProps, ...props }
  const [version, setVersion] = useState<string>(getPlatform())
  const [versionCode, setVersionCode] = useState<string>("")
  const [releaseDate] = useState<string>(`(${settings.releaseDate})`)

  useEffect(() => {
    getVersion().then((version) =>
      AppSettingsID.setSetting("version", version))
    //detect if browser
    if (!Capacitor.isNativePlatform()) {
      const converter = new showdown.Converter()
      const regex = /<h4\s*([^>]*)>(.*?)<\/h4>/i
      fetch("CHANGELOG.md").then(res => res.text()).then(text => {
        const html: string = converter.makeHtml(text || "")
        const match = html.match(regex)
        const mdVersion: string = (match && match[2] ? match[2] : "").toString().replace("v.", "")
        mdVersion && setVersion(mdVersion)
      })
      return
    }

    getVersionNumber().then((version: string) => setVersion(version))
    getVersionCode().then((code) => setVersionCode(`rev. ${code}`))
  }, [version])

  return (
    <>
      {label ? locales._get(label, { "%s": version }) : version} {versionCode} {showReleaseDate && releaseDate}
      <IsLatestCheck showOutdated={props.showOutdated} />
    </>
  )
}

export const VersionContainer = (props: { showOutdated: boolean }) => {
  return (
    <div className="component-version-container">
      <div>{locales._get("footer-text", JDate.getYear().toString())}</div>
      <div><b><Version showOutdated={props.showOutdated} /></b></div>
    </div>
  )
}