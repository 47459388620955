import { IonContent, IonModal } from "@ionic/react"
import { useEffect, useState } from "react"
import { renderToString } from "react-dom/server"

import { AnimeContent, AnimeEpisodes, EpisodeMediaType, responseEpisodes } from "types/Anime"
import { AnimeRelatedRecommendedResponse } from "types/Anime/AnimeRelatedRecommended"
import { Slide } from "types/Media/Slider"
import {
  landscapeLock,
  sendFullScreen,
  sendNormalScreen,
  unlockOrientation
} from "config/Settings/Device"
import { getContent } from "pages/Discover/Anime/Methods/getContent"
import { getEpisodes } from "pages/Discover/Anime/Methods/getEpisodes"
import { toggleViewed } from "../../Tabs/Episodes/SetItems/Methods/toggleViewed"
import { getRelatedRecommended } from "pages/Discover/Anime/Methods/getRelatedRecommended"
import JDate from "utils/JDate"
import Animate from "utils/Animate"
import GA from "utils/GA"
import locales from "config/Locales"
import settings from "config/Settings"
import useHistoryRecord, { saveLastHistoryRecordRemote } from "store/useHistoryRecord"
import useAnime from "store/useAnime"
import useAds from "store/useAds"
import Card from "components/Content/Card"
import User from "components/Users/User"
import ModalMediaControls from "./Controls"
import ModalMediaLoading from "./Loading"
import VideoJS from "components/Media/VideoJS"

import "./index.scss"

let currentSeenTime = 0
let countDown: any = null

/**
 * Renders a modal component for displaying media content on iframe or video player.
 *
 * @return {void}
 */
export const ModalMedia = () => {
  const addHistoryRecord = useHistoryRecord<Function>((state: any) => state.addHistoryRecord)
  const isOpen: boolean = useAnime((state: any) => state.isOpenMedia)
  const setIsOpen = useAnime<Function>((state: any) => state.setIsOpenMedia)
  const id: number = useAnime((state: any) => state.id)
  const eid: number = useAnime((state: any) => state.eid)
  const mid: number = useAnime((state: any) => state.mid)
  const setLastSeenId = useAnime<Function>((state: any) => state.setLastSeenId)
  const setRefreshGrids = useAnime<Function>((state: any) => state.setRefreshGrids)
  const setShowBanner = useAds((state: any) => state.setShowBanner)
  const [provider, setProvider] = useState<EpisodeMediaType>({} as EpisodeMediaType)
  const [content, setContent] = useState<AnimeContent>({} as AnimeContent)
  const [recommended, setRecommended] = useState<string>("")
  const [episodes, setEpisodes] = useState<AnimeEpisodes>({} as AnimeEpisodes)
  const [isTrailer, setIsTrailer] = useState<boolean>(false)
  const [selectedMedia, setSelectedMedia] = useState<string>("")
  const [elapsed, setElapsed] = useState<number>(0)//same as timePlayed but on state
  let markAsSeenTime = settings.mediaMarkAsSeenTime//seconds
  let timePlayed = -1

  /**
   * Starts the countdown for marking a video as seen.
   *
   * @param {boolean} restart - Indicates whether the countdown should be restarted.
   */
  const startCountDownMarkSeen = (restart: boolean) => {
    if ((restart || isTrailer) && countDown !== null) {
      clearInterval(countDown)
      countDown = null
    }
    const container = document.querySelector(".counterSeen .time") as HTMLDivElement
    if (countDown !== null || !User.isLoggedIn() || isTrailer) {
      container?.classList.add("hidden")
      return
    }
    const animationDiv = Animate.setAnimation("bounceIn", false, false)
    const animationBadgeIn = Animate.setAnimation("slideInLeft", false, false)
    const animationBadgeOut = Animate.setAnimation("slideOutLeft", false, false)
    const badge = document.querySelector(".seenBadge") as HTMLIonBadgeElement
    currentSeenTime = 0
    countDown = setInterval(() => {
      if (timePlayed === 0) return//is loading VideoJS player
      if (currentSeenTime < markAsSeenTime) {
        if (timePlayed >= 0) currentSeenTime = timePlayed
        else currentSeenTime++
      } else {
        clearInterval(countDown)
        countDown = null
        //mark as seen
        toggleViewed({
          cid: content.id || 0,
          eid,
          mid,
          serie: episodes?.media?.current?.serie || 0,
          seen: true,
          addHit: true,
          onUpdatedUserLists: (updated: boolean) => setRefreshGrids(updated),
        }).then(() => {
          setLastSeenId(eid)
          markDOMSeen(eid)
          addHistoryRecord({
            path: window.location.pathname,
            title: content?.title || "",
            cid: content.id || 0,
            eid: eid || 0,
            mid: mid || 0,
            serie: episodes?.media?.current?.serie || "",
            provider: provider?.title?.split("|")[1].toString().trim() || "",
            image: content?.img2 || "",
            imageBk: content?.params?.img || "",
            type: "episode",
            date: JDate.now(),
            user: User.getActiveUser(),
          })
          saveLastHistoryRecordRemote()
        })
      }
      if (container) {
        const timeLeft: number = markAsSeenTime - currentSeenTime
        container.innerText = `${timeLeft}`
        if (timeLeft > 0) {
          if (timeLeft < settings.mediaMarkAsSeenTime) {
            container.classList.remove("hidden")
            badge?.classList.add("hidden")
          } else {
            container.classList.add("hidden")
          }
        } else {
          container.classList.add("hidden")
          badge?.classList.add(animationBadgeIn)
          badge?.classList.remove("hidden")
          setTimeout(() => {
            badge?.classList.remove(animationBadgeIn)
            badge?.classList.add(animationBadgeOut)
            setTimeout(() => {
              badge?.classList.add("hidden")
              badge?.classList.remove(animationBadgeOut)
            }, 1000)
          }, 4000)
        }
        container.classList.add(animationDiv)
        setTimeout(() => container.classList.remove(animationDiv), 800)
      }
    }, 1000)
    if (!provider?.media) clearInterval(countDown)
  }

  /**
   * Marks as seen by DOM.
   *
   * @param {number} eid - The ID of the episode.
   */
  const markDOMSeen = (eid: number) => {
    const buttonLabel: HTMLElement = document.querySelector(`.component-anime-episodes-tabs ion-item.episode-id-${eid} ion-button.index-0 ion-label small`) as HTMLElement
    if (buttonLabel) {
      const seen: number = parseInt(buttonLabel.innerText || "0")
      buttonLabel.innerText = `${seen + 1}`
    }
  }

  /**
   * Loads the trailer for a given content ID.
   *
   * @param {number} _mid - The ID of the media.
   * @returns {type} states to render an iframe (normally Youtube) showing the trailer.
   */
  const loadTrailer = (_mid: number) => {
    if (!(_mid === 0 && !provider?.id)) {
      setIsTrailer(false)
      return
    }
    if (provider?.title === locales._get("content.watch-trailer")) return
    setIsTrailer(true)
    setProvider(() => {
      setSelectedMedia("0")

      return {
        id: 0,
        title: locales._get("content.watch-trailer"),
        provider: locales._get("content.watch-trailer"),
        media: content.trailer?.url || "",
      } as EpisodeMediaType
    })
  }

  /**
   * Clears the content by setting the episodes, content, and provider to empty objects.
   *
   * @return {Promise<void>} Promise that resolves once the content is cleared.
   */
  const clearContent = async () => {
    await setEpisodes({} as AnimeEpisodes)
    await setContent({} as AnimeContent)
    await setProvider({} as EpisodeMediaType)
  }

  const closeModal = () => setIsOpen(false)

  useEffect(() => {
    if (!isOpen) return
    clearContent().then(() => {
      getEpisodes({ cid: id, eid, force: true }).then((data: responseEpisodes) => {
        setEpisodes(data.episodes as AnimeEpisodes)
        setProvider(data.episodes?.media?.current?.play?.find((play: EpisodeMediaType) => play.id === mid) || {} as EpisodeMediaType)
      })
      getContent({ cid: id }).then((content: AnimeContent) => {
        setContent(content)
        getRelatedRecommended(content?.id || 0).then(async (response: AnimeRelatedRecommendedResponse) => {
          const recomendedCols: string[] = await response?.recommended?.slice(0, 3).map((item: Slide) => {
            return `
              <ion-col class="ion-no-padding">
                <div class="component-content component-slide-item" onclick="javascript:window.location = '/anime/'+${item.id};">
                  ${renderToString(<Card item={item} />)}
                </div>
              </ion-col>
            `
          }) as string[]
          setRecommended(recomendedCols.join(""))
        })
      })
    })
    loadTrailer(mid)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [eid, id, mid, isOpen])

  useEffect(() => {
    loadTrailer(mid)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mid, content, provider])

  useEffect(() => {
    if (!content?.id) return
    GA.pageview(provider?.title || content?.title, "episode-media")
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [provider])

  return (
    <IonModal
      isOpen={isOpen}
      onIonModalWillPresent={() => {
        sendFullScreen()
        landscapeLock()
        setShowBanner(false)
      }}
      onIonModalDidDismiss={() => {
        (window as any).MusicControls?.destroy()
        setIsOpen(false)
        unlockOrientation()
        sendNormalScreen()
        clearContent()
        if (countDown !== null) clearInterval(countDown)
      }}
    >
      <ModalMediaControls
        content={content}
        episodes={episodes}
        provider={provider}
        onClose={closeModal}
        selected={selectedMedia}
        elapsed={elapsed}
        onMediaChange={(_provider: string) => {
          const mid: number = parseInt(_provider)
          if (mid === 0) loadTrailer(mid)
          else setProvider(episodes?.media?.current?.play?.find((play: EpisodeMediaType) => play.id === mid) || {} as EpisodeMediaType)
        }}
      />
      <IonContent
        fullscreen
        scrollY={false}
        color="dark"
        className="component-anime-media-iframe"
      >
        {(provider?.media || "") === "" ? <ModalMediaLoading /> :
          <>
            {provider?.premium === 1 ?
              <VideoJS
                title={provider.title}
                provider={provider.provider}
                media={provider.media}
                poster={content.img2 || ""}
                onReady={() => {
                  startCountDownMarkSeen(countDown !== null)
                  timePlayed = 0
                  setElapsed(0)
                }}
                onStream={(time: number, total: number) => {
                  timePlayed = time
                  setElapsed(total)
                  if (total < settings.mediaMarkAsSeenTime) return
                  markAsSeenTime = Math.floor(total * settings.markAsSeenPercent)
                }}
                onError={() => closeModal()}
                recommended={`
                  <ion-grid>
                    <ion-row>
                      <ion-col>
                        <h3 class="ion-no-margin">
                          ${locales._get("content.tab-recommendations")}
                          <ion-button
                          onclick="javascript:document.querySelector('.recommended-container')?.classList.add('ion-hidden');"
                          >
                            X
                          </ion-button>
                        </h3>
                      </ion-col>
                    </ion-row>
                    <ion-row class="recommended-cols ion-margin-top">
                      ${recommended}
                    </ion-row>
                  </ion-grid>
                `}
              /> :
              <iframe
                title="Show media content"
                src={provider?.media}
                onLoad={() => startCountDownMarkSeen(countDown !== null)}
              />
            }
          </>
        }
      </IonContent>
    </IonModal>
  )
}