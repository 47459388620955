import {
  AnimeContent,
  AnimeEpisodes,
  AnimeEpisodeDetails,
  AnimeEpisodesMedia,
  EpisodeMediaType,
  fitSlideContentTypes,
  AnimeComment,
  AnimeContentFollow,
  AnimeContentStudio,
} from "types/Anime"
import { AnimeRelatedRecommendedResponse } from "types/Anime/AnimeRelatedRecommended"
import { CharactersStaffContent } from "types/Characters"
import { Slide, SlideInfo } from "types/Media/Slider"
import { spliceList } from "./Methods/spliceList"
import { dateFormat } from "./Methods/dateFormat"
import { getContent } from "pages/Discover/Anime/Methods/getContent"
import JDate from "utils/JDate"
import locales from "config/Locales"
import Strings from "utils/Strings"
import settings from "config/Settings"

class AnimeContentParser {
  public static fitAnimeContent = (content: AnimeContent, maxList: number = 5): AnimeContent => {
    if (!content) return {} as AnimeContent
    if (content.formated === true) return content
    const titleLanguage = new settings().titleLanguage
    content.title = Strings.capitalizeFirstLetter(Strings.encode(content.title || ""))
    content.title_romaji = Strings.capitalizeFirstLetter(Strings.encode(content.title_romaji || ""))
    content.title_english = Strings.capitalizeFirstLetter(Strings.encode(content.title_english || ""))
    content.title_spanish = Strings.capitalizeFirstLetter(Strings.encode(content.title_spanish || ""))
    content.title_other = Strings.arrayRemoveEmpty(content.title_other ? Array.from(content.title_other)?.map((item) => Strings.encode(item)) : [])
    content.titleOtherStr = Strings.encode(content.titleOtherStr || "")
    if (titleLanguage === "spanish" && content.title_spanish !== "") content.title = content.title_spanish || content.title
    if (titleLanguage === "english" && content.title_english !== "") content.title = content.title_english || content.title
    content.content = Strings.encode(content.content || "")
    const genderlist = spliceList(content.genders || [], content.gendersRest || [], maxList)
    content.genders = Strings.arrayRemoveEmpty(genderlist[0])
    content.gendersRest = Strings.arrayRemoveEmpty(genderlist[1] || [])
    content.gendersStr = content.genders?.join(", ") || ""
    const genderlistids = content.gendersids?.splice(0, maxList)
    const genderlistidsRest = content.gendersids?.splice(maxList)
    content.gendersids = genderlistids || []
    content.gendersidsRest = genderlistidsRest || []
    if (content.gendersStr === "") content.gendersStr = locales._get("content.no-genders")
    const studiolist = spliceList(content.studios || [], content.studiosRest || [], maxList)
    content.studios = Strings.arrayRemoveEmpty(studiolist[0] || [])
    if (content.studios.length === 0 && (content.studiosids?.length || 0) > 0) {
      content.studiosids?.map((studio: AnimeContentStudio) => {
        content.studios && content.studios.push(studio.title || "")
        return studio
      })
    }
    if (!content.studioid?.id && (content.studiosids?.length || 0) > 0) {
      content.studioid = content.studiosids?.splice(0, 1)[0] || {} as AnimeContentStudio
    }
    content.studiosStr = content.studios?.join(", ") || ""
    content.studiosRest = Strings.arrayRemoveEmpty(content.studiosRest || [])
    content.studiosStr = content.studios?.join(", ") || ""
    if (content.studiosStr === "") content.studiosStr = content.studio
    if (content.studio === "" || content.studio === null) content.studio = locales._get("content.no-studio")
    content.ctypeStr = locales._get(`content.type-${content.ctype || ""}`)
    content.seasonStr = locales._get(`content.season-${content.season}`)
    content.lastSeenStr = content.lastSeenStr === "" ? locales._get("content.no-last-seen") : content.lastSeenStr
    content.nextepisodeAgoNum = JDate.agoNum(JDate.timestamp(content.nextepisode || ""))
    content.nextepisodeAgo = JDate.ago(JDate.timestamp(content.nextepisode || ""), undefined, false)
    if (content.nextepisodeAgo === locales._get("date.now")) content.nextepisodeAgo = locales._get("date.today_from_now")
    if (content.nextepisodeAgo === locales._get("date.never")) content.nextepisodeAgo = locales._get("date.unknown")
    content.nextepisodeStr = dateFormat(content.nextepisode || "")
    if (content.nextepisodeStr === "") {
      content.nextepisodeStr = locales._get("date.unknown")
      content.nextepisodeAgo = ""
    } else if (content.nextepisodeAgo === locales._get("date.today_from_now"))
      content.nextepisodeAgoNum.passed = false
    content.startdateStr = dateFormat(content.startdate || "")
    content.enddateAgoNum = JDate.agoNum(JDate.timestamp(content.enddate || ""))
    if (content.enddateAgoNum.passed === false) {//not ended yet
      content.willenddate = dateFormat(content.enddate || "")
      content.enddate = ""
      if (content.nextepisodeAgoNum.passed === true) {//posible passed date
        content.nextepisodeAgoNum.passed = false
        content.nextepisodeStr = locales._get("date.unknown")
        content.nextepisodeAgo = ""
        //check again for next call
        getContent({ cid: content.id, force: true })
      }
    }
    content.enddateStr = dateFormat(content.enddate || "")
    content.last_episode = parseInt(content.last_episode?.toString() || "0")
    content.duration = parseInt(content.duration?.toString() || "0")
    if (content.content === "") content.content = null
    if (!content.following?.follow || !content.following?.my)
      content.following = {
        follow: 0,
        my: 0,
      } as AnimeContentFollow
    content.formated = true
    return content
  }

  public static fitAnimeEpisodes = (episodes: AnimeEpisodes): AnimeEpisodes => {
    if (!episodes) episodes = { viewed: [] as AnimeEpisodeDetails[] } as AnimeEpisodes

    const parseEpisodeMedia = (mediaItem: AnimeEpisodesMedia): AnimeEpisodesMedia => {
      const parseMedia = (items: EpisodeMediaType[]) => {
        return items?.map((item: EpisodeMediaType) => {
          item.provider = Strings.encode(item.provider || "")
          item.adsStr = `[${locales._get(`content.episode-ads-${item.ads || "0"}`)}]`
          return item
        })
      }
      if (!mediaItem) mediaItem = {} as AnimeEpisodesMedia
      if (!mediaItem?.download) mediaItem.download = [] as EpisodeMediaType[]
      if (!mediaItem?.play) mediaItem.play = [] as EpisodeMediaType[]
      mediaItem.download = parseMedia(mediaItem.download)
      mediaItem.play = parseMedia(mediaItem.play)
      mediaItem.badge = `[${mediaItem?.serie || 0} / ${episodes?.last_episode || 0}]`
      mediaItem.adsStr = `[${locales._get(`content.episode-ads-${mediaItem.ads || "0"}`)}]`
      return mediaItem
    }
    if (episodes?.media) {
      episodes.media.current = parseEpisodeMedia(episodes.media.current)
      episodes.media.next = parseEpisodeMedia(episodes.media.next)
      episodes.media.prev = parseEpisodeMedia(episodes.media.prev)
    } else {
      const emptyMedia = {
        download: [] as EpisodeMediaType[],
        play: [] as EpisodeMediaType[],
      }
      episodes.media = {
        current: emptyMedia as AnimeEpisodesMedia,
        next: emptyMedia as AnimeEpisodesMedia,
        prev: emptyMedia as AnimeEpisodesMedia,
      }
    }
    const checkEpisodes = (episodes: AnimeEpisodeDetails[]): AnimeEpisodeDetails[] =>
      episodes?.map((view: AnimeEpisodeDetails) => {
        const date = dateFormat(view.seen_date || "")
        view.seenDateStr = JDate.ago(JDate.timestamp(view.seen_date), undefined, false)
        if (view.seenDateStr === locales._get("date.never")) view.seenDateStr = ""
        view.seenFullDateStr = `${date} (${view.seenDateStr})`
        view.hitsStr = Strings.minifyFloat(view.hits || 0)
        return view
      })
    if (episodes?.episodes) episodes.episodes = checkEpisodes(episodes.episodes)
    else episodes.episodes = [] as AnimeEpisodeDetails[]
    if (episodes?.viewed) episodes.viewed = checkEpisodes(episodes.viewed)
    else episodes.viewed = [] as AnimeEpisodeDetails[]
    episodes.lastSeenDateStr = dateFormat(episodes.last_seen_date || "")
    episodes.lastSeenDateAgo = JDate.ago(JDate.timestamp(episodes.last_seen_date), undefined, false)
    return episodes
  }

  public static fitAnimeComments = (comments: AnimeComment[]): AnimeComment[] => {
    if (typeof comments === "string" || !comments) return [] as AnimeComment[]
    comments?.map((comment: AnimeComment) => {
      comment.comment = Strings.encode(comment.comment || "")
      comment.createdStr = dateFormat(comment.created || "")
      comment.createdAgo = JDate.ago(JDate.timestamp(comment.created), undefined, false)
      comment.createdAgoNum = JDate.agoNum(JDate.timestamp(comment.created))
      comment.header = `${comment.name || locales._get("content.anonymous")} <i>${locales._get("content.episode-item", comment.serie || "")}</i>`
      comment.subheader = `<small>${comment.createdStr} (${comment.createdAgo})</small>`
      return comment
    })
    return comments
  }

  public static fitAnimeRelatedRecommended = (items: AnimeRelatedRecommendedResponse): AnimeRelatedRecommendedResponse => {
    items.related = items.related?.map((item: Slide) => {
      item = AnimeContentParser.fitSlide(item)
      return item
    })
    items.recommended = items.recommended?.map((item: Slide) => {
      item = AnimeContentParser.fitSlide(item)
      return item
    })
    return items
  }

  public static fitAnimeCharactersStaff = (content: CharactersStaffContent[]): CharactersStaffContent[] => {
    if (!content) return [] as CharactersStaffContent[]
    content.map((item: CharactersStaffContent) => {
      if (item?.subtitle === "none") item.subtitle = locales._get("content.no-staff")
      return item
    })
    return content
  }

  public static fitSlideContent = (props: fitSlideContentTypes): Slide => {
    const { item, setIsOpen, setId, setEid } = props
    if (!item) return {} as Slide
    const titleLanguage = new settings().titleLanguage
    item.title = Strings.capitalizeFirstLetter(Strings.encode(item.title || ""))
    item.title_romaji = Strings.capitalizeFirstLetter(Strings.encode(item.title_romaji || ""))
    item.title_spanish = Strings.capitalizeFirstLetter(Strings.encode(item.title_spanish || ""))
    item.title_english = Strings.capitalizeFirstLetter(Strings.encode(item.title_english || ""))
    if (titleLanguage === "spanish" && item.title_spanish !== "") item.title = item.title_spanish || item.title
    if (titleLanguage === "english" && item.title_english !== "") item.title = item.title_english || item.title
    item.content = Strings.encode(item.content || "")
    item.fullcontent = Strings.encode(item.fullcontent || "")
    item.onClick = () => {
      setIsOpen && setIsOpen(true)
      setId && setId(item.cid || item.id || 0)
      setEid && setEid(item.rid || 0)
      item.onClicked && item.onClicked()
    }
    return item
  }

  public static fitSlideInfo = (slides: SlideInfo[]): SlideInfo[] => {
    return slides?.map((slide: SlideInfo) => {
      slide.title = Strings.capitalizeFirstLetter(Strings.encode(slide.title || ""))
      return slide
    })
  }

  public static fitSlide = (slide: Slide): Slide => {
    return slide
  }
}

export default AnimeContentParser