import LoaderOptions from "components/Content/ScreenLoader/LoaderOptions"
import { UserProfile } from "types/User"
import User from "components/Users/User"

export const setAvatar = (props: {
  user: UserProfile,
  avatar: string,
  setUsers: Function,
  closeModal: Function,
  index?: number
}) => {
  props.user.avatar = props.avatar
  LoaderOptions.openLoader("user.setting-avatar")
  User.updateUser({
    user: props.user,
    callback: () => {
      const avatarContainer = document.querySelector(`#user-avatar-${props.index} ion-img`)
      avatarContainer?.setAttribute("src", props.avatar)
      if (avatarContainer?.closest("ion-avatar")?.classList.contains("active-user")) {
        const avatarProfile = document.querySelector(".component-user-avatar.small ion-img")
        avatarProfile?.setAttribute("src", props.avatar)
      }
      props.setUsers(User.getUserList())
      props.closeModal()
      LoaderOptions.closeLoader()
    }
  })
}