import {
  IonApp,
  IonIcon,
  IonLabel,
  IonPage,
  IonRouterOutlet,
  IonTabBar,
  IonTabButton,
  IonTabs,
  setupIonicReact
} from "@ionic/react"
import { IonReactRouter } from "@ionic/react-router"
import { Route } from "react-router-dom"
import { useEffect, useState } from "react"
import { Capacitor } from "@capacitor/core"

import { MenuBottom } from "./config/Menu/MenuBottom"
import { checkDarkMode } from "./config/Settings/DarkMode"
import { UserAvatar } from "./components/Users/Account/Profile/UseAvatar"
import { ModalContent } from "./pages/Discover/Commons/Modal/Content"
import { ModalMedia } from "./pages/Discover/Commons/Modal/Media"
import routes from "./pages/_commons/Routes"
import AniMenu from "./pages/_commons/AniMenu"
import MenuOptions from "./utils/MenuOptions"
import locales from "./config/Locales"
import User from "./components/Users/User"
import ScreenLoader from "./components/Content/ScreenLoader"
import SoftnyaAdsModal from "components/Media/Ads/Provider/SoftnyaAds/Modal"
import useAds from "store/useAds"
import StorageUtils from "utils/Storage"

/* Core CSS required for Ionic components to work properly */
import "@ionic/react/css/core.css"

/* Basic CSS for apps built with Ionic */
import "@ionic/react/css/normalize.css"
import "@ionic/react/css/structure.css"
import "@ionic/react/css/typography.css"

/* Optional CSS utils that can be commented out */
import "@ionic/react/css/padding.css"
import "@ionic/react/css/float-elements.css"
import "@ionic/react/css/text-alignment.css"
import "@ionic/react/css/text-transformation.css"
import "@ionic/react/css/flex-utils.css"
import "@ionic/react/css/display.css"

import "animate.css"

/* Theme variables */
import "./theme/default.scss"

setupIonicReact()

export const makeVisible = () => {
  // make visible at least one ion-page
  const pages = document.querySelectorAll("ion-router-outlet > .ion-page > .ion-page-invisible")
  if (pages.length > 0) pages[0].classList.remove("ion-page-invisible")
}

const App: React.FC = () => {
  const isVisible = MenuOptions((state: any) => state.isVisible)
  const [activeMenu, setActiveMenu] = useState<string>("")
  const setShowBanner = useAds((state: any) => state.setShowBanner)

  useEffect(() => {
    setActiveMenu(window.location.pathname)
    User.checkSession()
    setShowBanner(true)
    StorageUtils.checkCachedContent()
    StorageUtils.checkInternalContent()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (window.location.pathname === "/") window.location.pathname = "/splash"
    setTimeout(() => makeVisible(), 800)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [window.location.pathname])

  return (
    <IonApp className={`platform-container platform-${Capacitor.isNativePlatform() ? "mobile" : "web"} platform-type-${Capacitor.getPlatform()}`}>
      <IonReactRouter>
        <IonTabs className="theme-default">
          <IonRouterOutlet animated={true}>
            <IonPage id="main-app">
              {routes.map((route, index: number) => (
                <Route path={`/${route.path}`} key={`route-${index}`}>
                  {route.component}
                </Route>
              ))}
            </IonPage>
          </IonRouterOutlet>
          <IonTabBar
            slot="bottom"
            className={isVisible ? "" : "ion-hidden"}
            onClick={() => setActiveMenu(window.location.pathname)}
          >
            {MenuBottom.map((item, index) => (
              <IonTabButton tab={`${item.title?.toString().replaceAll("/", "")}`} href={item.href} key={index}>
                {(item.title === locales._get("profile") && User.getAvatar() !== "") ?
                  (<>
                    <UserAvatar className="small" />
                  </>) :
                  (<><IonIcon icon={item.icon} /></>)}
                <IonLabel>{item.title}</IonLabel>
              </IonTabButton>
            ))}
          </IonTabBar>
        </IonTabs>
        <AniMenu activeMenu={activeMenu} />
        <ModalContent />
        <ModalContent type="staff" />
        <ModalContent type="characters" />
        <ModalContent type="news" />
        <ModalMedia />
        <SoftnyaAdsModal />
        <ScreenLoader
          imagePosition="floatBase"
        />
      </IonReactRouter>
      {checkDarkMode()}
    </IonApp >
  )
}

export default App
