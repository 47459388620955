import { Endpoints } from "api/Endpoints"
import { paramsRecentByGender } from "api/routes/params/Media"
import { Responses } from "types/Connect"
import Connect from ".."
import RefresherContent from "./Content"
import RefresherUser from "./User/Refresher"
import ClearUser from "./User/Clear"

class Refresher {
  public static splash = async (response: Function, force: boolean = false) =>
    await Refresher.discover(response, force)

  public static home = async (response: Function, force: boolean = false) =>
    await RefresherContent.slides(() => {
      RefresherContent.newcontent((callbackStatus: number) => response(callbackStatus), force, true)
    }, force)

  public static discover = async (response: Function, force: boolean = false) =>
    await RefresherContent.slides(() =>
      RefresherContent.genderstudioslist((status: number) => response(status), force), force)

  public static genderlist = async (gid: number, response: Function, force: boolean = false) => {
    const returnFail = () => response(400)

    const paramsGender = { ...paramsRecentByGender, ...{ gender: gid } }
    await Connect.postcached({ endpoint: Endpoints.recent, params: paramsGender, options: { force: force } }).then((res: Responses) => {
      response(res.status || 400)
    }).catch(() => returnFail())
  }

  public static user = async (response: Function, force: boolean = false) =>
    await RefresherUser.foruser(response, force)

  public static clearuser = async (response: Function) =>
    await ClearUser.foruser(response)
}

export default Refresher