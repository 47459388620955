import {
  informationCircleOutline,
  extensionPuzzleOutline,
  helpCircleOutline,
  fileTrayFullOutline
} from "ionicons/icons"

import { menuItems } from "types/Commons/Menu"
import locales from "config/Locales"

export const MenuApp = [
  {
    title: locales._get("about"),
    icon: informationCircleOutline,
    href: "/about",
  },
  {
    title: locales._get("tos"),
    icon: extensionPuzzleOutline,
    href: "/tos",
  },
  {
    title: locales._get("changelog"),
    icon: fileTrayFullOutline,
    href: "/changelog",
  },
  {
    title: locales._get("support"),
    icon: helpCircleOutline,
    href: "/support",
  }
] as menuItems[]