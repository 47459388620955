import { HeaderLogo } from "pages/_commons/AniHeader/HeaderLogo"
import { Goku } from "types/Anime/Images"
import { VersionContainer } from "config/Settings/Version"
import MenuAll from "config/Menu"
import Menu from "components/Commons/Menu"

import "./index.scss"

const AniMenu = (props: { activeMenu: string }) => {
  return (
    <Menu
      type="push"
      activeMenu={props.activeMenu}
      showHeader={false}
      items={MenuAll}
      background={{
        image: `${Goku}`,
      }}
      contentBottom={
        <VersionContainer showOutdated={true} />
      }
    >
      <HeaderLogo />
    </Menu>
  )
}

export default AniMenu