import { IonList } from "@ionic/react"
import { useEffect, useState } from "react"
import { videocamOutline } from "ionicons/icons"
import { useHistory } from "react-router-dom"

import { getStudiosListMenuItems } from "pages/_commons/Content/GerdersList/getStudiosList"
import { pushData } from "components/Commons/Picker/Modal/Methods/pushData"
import { menuItems } from "types/Commons/Menu"
import PickerModalList from "components/Commons/Picker/Modal/List"
import PickerModalNoItems from "components/Commons/Picker/Modal/NoItems"
import locales from "config/Locales"
import AniTitle from "pages/_commons/AniTitle"
import HeaderSlides from "pages/_commons/Content/HeaderSlides"
import PageContainer from "pages/_commons/PageContainer"
import Searchbar from "components/Commons/Searchbar"
import Meta from "components/Media/Meta"

import "./index.scss"

const Studios = () => {
  const [studios, setStudios] = useState<menuItems[]>([])
  const [onDisplay, setOnDisplay] = useState<menuItems[]>([])
  const [isInfiniteDisabled, setInfiniteDisabled] = useState<boolean>(false)
  const [loaded, setLoaded] = useState<boolean>(false)
  const history = useHistory()

  const sendPushData = () => pushData(studios, onDisplay, setOnDisplay, setInfiniteDisabled, setLoaded)

  useEffect(() => {
    setOnDisplay(() => {
      sendPushData()
      return []
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [studios])

  useEffect(() => {
    const _studios = getStudiosListMenuItems()

    setStudios(() => {
      _studios?.map((studio: menuItems) => studio.icon = videocamOutline)
      return _studios
    })
  }, [])

  return (
    <PageContainer
      withProgress={true}
      className="page-studios"
      callbackBottom={() => {
        if (isInfiniteDisabled) return
        sendPushData()
      }}
    >
      <>
        <HeaderSlides />
        <div className="ion-padding inner-container">
          <AniTitle
            title={locales._get("studios")}
          />
          <Searchbar
            data={studios}
            placeholder={locales._get("content.search-placeholder")}
            callbackResults={(_results: menuItems[], query: string) =>
              setOnDisplay(query === "" ? studios.splice(0, 20) : _results)
            }
          />
          <IonList>
            <PickerModalList
              items={onDisplay}
              onClick={(href: string) => history.push(href)}
              selected=""
            />
            {(onDisplay.length === 0 && loaded) &&
              <PickerModalNoItems />
            }
          </IonList>
        </div>
        <Meta
          title={locales._get("studios")}
        />
      </>
    </PageContainer>
  )
}

export default Studios