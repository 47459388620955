import {
  IonButtons,
  IonHeader,
  IonTitle,
  IonToolbar,
} from "@ionic/react"

import { HeaderProps, defaultHeaderProps } from "types/Commons/Header"

import "./index.scss"

const Header: React.FC<HeaderProps> = (props: HeaderProps) => {
  return (
    <IonHeader className={`component-header ${props.className}`}>
      <IonToolbar mode="md" color={props.color}>
        {props.startButtons && <IonButtons slot="start">{props.startButtons}</IonButtons>}
        {props.primaryButtons && <IonButtons slot="primary">{props.primaryButtons}</IonButtons>}
        <IonTitle slot={props.slot} className={props.html !== "" ? "withHTMLContent" : ""}>
          {props.name}
          {props.html}
        </IonTitle>
        {props.secondaryButtons && <IonButtons slot="secondary">{props.secondaryButtons}</IonButtons>}
        {props.endButtons && <IonButtons slot="end">{props.endButtons}</IonButtons>}
      </IonToolbar>
    </IonHeader>
  )
}

Header.defaultProps = defaultHeaderProps

export default Header
