import { IonModal, IonHeader, IonList } from "@ionic/react"
import { useEffect, useState } from "react"

import { PickerModalProps } from "types/Commons/Picker"
import { menuItems } from "types/Commons/Menu"
import { pushData } from "./Methods/pushData"
import { resetDisplay } from "./Methods/resetDisplay"
import ScrollContent from "components/Content/ScrollContent"
import locales from "config/Locales"
import Searchbar from "components/Commons/Searchbar"
import PickerModalNoItems from "./NoItems"
import PickerModalToolbar from "./Toolbar"
import PickerModalList from "./List"

export const PickerModal = (props: PickerModalProps) => {
  const [results, setResults] = useState<menuItems[]>([])
  const [onDisplay, setOnDisplay] = useState<menuItems[]>([])
  const [isInfiniteDisabled, setInfiniteDisabled] = useState<boolean>(false)
  const [loaded, setLoaded] = useState<boolean>(false)
  const [items, setItems] = useState<menuItems[]>(props.items)
  const [selected, setSelected] = useState<string>("")

  const sendPushData = () => pushData(results, onDisplay, setOnDisplay, setInfiniteDisabled, setLoaded)

  useEffect(() => {
    if (results.length === 0) setResults(items)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [items])

  useEffect(() => {
    setOnDisplay(() => {
      sendPushData()
      return []
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [results, setItems])

  useEffect(() => setItems(props.items), [props.items])

  useEffect(() => setSelected(props.selected), [props.selected])

  return (
    <>
      <IonModal
        className={`component-header-picker modal-${props.modalId}`}
        ref={props.modal}
        trigger={props.modalId}
        initialBreakpoint={0.75}
        onDidDismiss={() => resetDisplay(setOnDisplay, setInfiniteDisabled, setLoaded)}
        onDidPresent={() => sendPushData()}
      >
        <ScrollContent
          callbackBottom={() => {
            if (isInfiniteDisabled) return
            sendPushData()
          }}
        >
          <>
            <IonHeader className="logo-container">
              <PickerModalToolbar {...props} />
              {(items.length > (props.minItemsToSearch || 10)) &&
                <Searchbar
                  data={items}
                  placeholder={locales._get("content.search-placeholder")}
                  callbackResults={(_results: menuItems[]) =>
                    resetDisplay(setOnDisplay, setInfiniteDisabled, setLoaded, () => setResults(_results))
                  }
                />
              }
            </IonHeader>
            <IonList>
              {props.contentListTop}
              <PickerModalList
                items={onDisplay}
                onClick={props.onClick}
                selected={selected}
                showItemIndex={props.showItemIndex}
              />
              {(onDisplay.length === 0 && loaded) &&
                <PickerModalNoItems />
              }
              {props.contentListBottom}
            </IonList>
          </>
        </ScrollContent>
      </IonModal>
    </>
  )
}