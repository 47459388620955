import {
  IonButtons,
  IonButton,
  IonIcon,
} from "@ionic/react"
import { menuOutline } from "ionicons/icons"
import { useEffect, useRef, useState } from "react"

import { menuItems } from "types/Commons/Menu"
import { PickerProps } from "types/Commons/Picker"
import { PickerModal } from "./Modal"
import Strings from "utils/Strings"

import "./index.scss"

const Picker = (props: PickerProps) => {
  const modal = useRef<HTMLIonModalElement>(null)
  const [modalId] = useState(`picker-${Strings.getRandomNumber(7)}`)
  const [selected, setSelected] = useState<string>("")

  const setSelectedOption = async (value: string) => {
    props.items?.map((option: menuItems) => {
      if (option.href === value) setSelected(option.title)
      return option
    })
  }

  useEffect(() => {
    setSelectedOption(props.selected)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.selected, props.items])

  const onClickPicker = (optionValue: string) => {
    modal.current?.dismiss()
    setSelectedOption(optionValue)
    props.onClickPicker && props.onClickPicker(optionValue)
  }

  return (
    <div className="component-header-picker">
      <IonButtons>
        <IonButton
          id={modalId}
          onClick={() => modal.current?.present()}
          shape="round"
          color={props.color || "primary"}
          fill="solid"
        >
          <IonIcon icon={menuOutline} />
          {selected}
        </IonButton>
      </IonButtons>
      <PickerModal
        modal={modal}
        modalId={modalId}
        onClick={onClickPicker}
        selected={selected}
        items={props.items}
      />
    </div>
  )
}

export default Picker