import { IonImg } from "@ionic/react"

import { VegetaSSJ } from "types/Anime/Images"
import { SpinnerCircular } from "components/Commons/Spinner"
import locales from "config/Locales"

import "./index.scss"

const ModalMediaLoading = () => {
  return (
    <div className="component-anime-media-loading">
      <IonImg className="background" src={VegetaSSJ} />
      <div>
        <SpinnerCircular />
        {locales._get("loading")}
      </div>
    </div>
  )
}

export default ModalMediaLoading