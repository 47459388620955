import { IonImg } from "@ionic/react"

import { SmallLogoSrc } from "types/Anime/Images"
import { ImageProps } from "types/Media/Image"

import "./index.scss"

const Image = (props: ImageProps) => {
  const { isblured, className, onIonImgDidLoad, onIonImgWillLoad, onIonError, onClick, ...rest } = props
  return (
    <IonImg
      className={`component-image ${isblured ? "blured" : ""} ${className || ""}`}
      onClick={() => onClick && onClick()}
      onIonImgDidLoad={() => onIonImgDidLoad && onIonImgDidLoad()}
      onIonImgWillLoad={() => onIonImgWillLoad && onIonImgWillLoad()}
      onIonError={(e: any) => {
        onIonError && onIonError(e)
        if (props.onErrorShowDefault || props.srcBk) e.target.src = props.srcBk || SmallLogoSrc
      }}
      {...rest}
    />
  )
}

export default Image