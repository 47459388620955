import { useState } from "react"
import { IonButton, IonIcon } from "@ionic/react"
import { checkmarkCircleSharp, closeCircleSharp } from "ionicons/icons"

import { AppSettingsID } from "utils/AppSettings"
import locales from "config/Locales"
import IsLatest, { showIsLatest } from ".."

import "./index.scss"

const IsLatestCheck = (props: { showOutdated: boolean }) => {
  const [isLatest] = useState<boolean>(showIsLatest())
  const [latest] = useState<string>(AppSettingsID.getSetting("latest", locales._get("latest-version")))
  const [showAlert, setShowAlert] = useState<boolean>(false)

  return (
    <>
      <span className="component-version-container-is-lastest">
        {isLatest ? (
          <IonIcon icon={checkmarkCircleSharp} color="success" />
        ) : (
          <IonIcon icon={closeCircleSharp} color="danger" />
        )}
      </span>
      {(props.showOutdated && !isLatest) &&
        <>
          <IonButton
            className="component-version-container-is-lastest-button"
            fill="outline"
            size="small"
            color="danger"
            onClick={() => setShowAlert(true)}
          >
            {locales._get("latest-version.download", latest)}
          </IonButton>
          <IsLatest
            showAlert={showAlert}
            onClosed={() => setShowAlert(false)}
          />
        </>
      }
    </>
  )
}

export default IsLatestCheck