import { Clipboard } from "@capacitor/clipboard"
import { Capacitor } from "@capacitor/core"
import { AppAvailability } from "@awesome-cordova-plugins/app-availability"
import { CanShareResult, Share } from "@capacitor/share"
import { RateApp } from "capacitor-rate-app"

import { AppSettingsID } from "utils/AppSettings"
import { getSquema } from "./Squemas"
import { SocialSquema, SocialSquemaName } from "types/Commons/Social"
import locales from "config/Locales"
import settings from "config/Settings"
import JDate from "utils/JDate"

const check = (squemaName: SocialSquemaName, url: string) => {
  const squema: SocialSquema = getSquema(squemaName)
  if (!squema.device) return
  const ifAvailable = `${squema.device}${url}`
  const ifNotAvailable = `${squema.web}${url}`
  const squemaID = Capacitor.getPlatform() === "android" ? squema.android : squema.ios
  if (!Capacitor.isNativePlatform()) return share.open(ifNotAvailable)
  AppAvailability.check(squemaID).then((available: boolean) => {
    if (available) share.open(ifAvailable)
    else share.open(ifNotAvailable)
  }).catch(() => share.open(ifNotAvailable))
}

export const share = {
  open: (url: string) =>
    window.open(url, "_system", "location=no"),
  shareFacebook: (url: string) => {
    url = encodeURI(url) || ""
    check("facebook", url)
  },
  shareTwitter: (url: string) => {
    url = encodeURI(url) || ""
    check("twitter", url)
  },
  shareGoogle: (url: string, txt?: string) => {
    url = encodeURI(url) || ""
    txt = txt || ""
    check("google", txt)
  },
  sharePinterest: (url: string, img?: string) => {
    url = encodeURI(url) || ""
    img = img || ""
    check("pinterest", `${url}&media=${img}`)
  },
  shareWhatsapp: (txt: string) => {
    check("whatsapp", txt)
  },
  shareEmail: (txt: string) =>
    share.open(`mailto:?body=${txt}`),
  shareSMS: (phone: string, txt: string) =>
    share.open(`sms://${phone}/?body=${txt}`),
  shareCopy: async (txt: string) => {
    await Clipboard.write({
      string: txt
    })
  },
  shareOther: async (url: string) => {
    if (!Capacitor.isNativePlatform()) return share.open(url)
    try {
      await Share.share({
        title: locales._get("appname"),
        text: "",
        url: encodeURI(url) || "",
        dialogTitle: locales._get("content.share-other")
      })
    } catch (err) {
      share.open(url)
    }
  },
  canShareOther: async (): Promise<CanShareResult> =>
    Share.canShare(),
  rateApp: () => {
    if (!Capacitor.isNativePlatform()) return share.openPlayStore()
    try {
      RateApp.requestReview()
    } catch (err) { }
  },
  openPlayStore: () => share.open(settings.linkplaystore),
  showRateOnInterval: () => {
    const lastTimeCheckedRate = AppSettingsID.getSetting("lastTimeCheckedRate", 0)
    const todayTimeStamp = JDate.getToday().getTime()
    if (lastTimeCheckedRate === 0) AppSettingsID.setSetting("lastTimeCheckedRate", todayTimeStamp)
    if (JDate.agoNum(lastTimeCheckedRate, todayTimeStamp)?.ago < settings.minRateTimePlayStore * 60 * 1000) return
    share.rateApp()
    AppSettingsID.setSetting("lastTimeCheckedRate", todayTimeStamp)
  },
}