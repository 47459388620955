import { useEffect, useState } from "react"
import { useHistory } from "react-router-dom"
import showdown from "showdown"

import { PagerProps } from "types/Page/Pager"
import { paramsStaticContent } from "api/routes/params/Content"
import { paramsLang } from "api/routes/params/Media"
import { Responses } from "types/Connect"
import { StorageItems } from "utils/Storage/StorageItems/Storage"
import Connect from "components/Connect"
import locales from "config/Locales"
import Loader from "pages/_commons/Loader"
import PageContainer from "../PageContainer"
import PagerEmtpy from "./Empty"
import AdsBanner from "components/Media/Ads/Banner"

import "./index.scss"

const Pager = (props: PagerProps) => {
  const [loaded, setLoaded] = useState(false)
  const [showLoader, setShowLoader] = useState(true)
  const [content, setContent] = useState<string>("")
  const history = useHistory()

  const setFromLocal = async () => {
    if (!props.storageKey) return
    const local = await StorageItems.getItem(props.storageKey)
    if (local) setContent(replaceHTML(local))
  }

  const replaceHTML = (html: string) => {
    return html.replace("<h1", "<ion-text").replace("</h1>", "</ion-text>")
  }

  useEffect(() => {
    if (loaded) return
    setShowLoader(true)
    setLoaded(true)
    const converter = new showdown.Converter()

    if (props.fetchFile) {
      fetch(`/${props.fetchFile}`).then(res => res.text()).then(text => {
        setContent(() => {
          const html = (props.asMarkdown || false) ? converter.makeHtml(text || "") : text || ""
          return replaceHTML(html)
        })
        setShowLoader(false)
      })
      return
    }

    if (props.endpoint) {
      Connect.postcached({ endpoint: props.endpoint, params: paramsLang, options: paramsStaticContent }).then((res: Responses) => {
        if (res.status === 200)
          setContent(() => {
            const html = (props.asMarkdown || false) ? converter.makeHtml(res.data?.content || "") : res.data?.content || ""
            return replaceHTML(html)
          })
        setShowLoader(false)
      })
    } else return setShowLoader(false)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loaded])

  useEffect(() => {
    if (!props.storageKey) return
    if (content === "") {
      setFromLocal()
      return
    }
    StorageItems.setItem(props.storageKey, content)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [content, loaded])

  return (
    <PageContainer
      className={`component-pager ${props.className}`}
      showLogo={false}
      onClickBack={() => { props.gobackUrl ? history.push(props.gobackUrl) : history.goBack() }}
    >
      {showLoader ?
        <Loader text={locales._get("content")} />
        :
        <div className="ion-padding">
          <AdsBanner type="softnyacontent" softnyaposition="DETAILS_TOP" className="ion-margin-bottom" />
          {content !== "" ?
            <>
              <div dangerouslySetInnerHTML={{ __html: content }} />
            </>
            :
            <PagerEmtpy />
          }
          <AdsBanner type="softnyacontent" softnyaposition="DETAILS_BOTTOM" className="ion-margin-top" />
        </div>
      }
    </PageContainer>
  )
}

export default Pager