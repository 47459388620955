import { AlertProps } from "types/Commons/Alerts"
import locales from "config/Locales"
import settings from "config/Settings"
import Alerts from ".."

const AlertWithAds = (props: AlertProps) => {
  return (
    <Alerts
      title={locales._get("commons.alerts.with-ads")}
      showAlert={props.showAlert && new settings().showAlertWithAds === 1}
      cssClass="alert-with-ads"
      duration={4000}
      buttons={[
        {
          text: locales._get("commons.alerts.close"),
          role: "cancel",
        },
      ]}
    />
  )
}

export default AlertWithAds