import { useEffect, useRef, useState } from "react"
import { IonButton, IonIcon, IonInput } from "@ionic/react"
import { chatbubbleEllipsesOutline, paperPlaneOutline, warningOutline } from "ionicons/icons"

import { AnimeComment, AnimeCommentsProps } from "types/Anime"
import { Responses } from "types/Connect"
import { DefaultAvatar } from "types/Anime/Images"
import { menuItems } from "types/Commons/Menu"
import { getComments } from "../Methods/getComments"
import { PickerModal } from "components/Commons/Picker/Modal"
import { Item } from "components/Content/FormBuilder/Item"
import { Endpoints } from "api/Endpoints"
import { colorTypes } from "types/index"
import { SpinnerBubbles } from "components/Commons/Spinner"
import DropDownButton from "components/Commons/DropDownButton"
import locales from "config/Locales"
import User from "components/Users/User"
import settings from "config/Settings"
import AlertUserRequired from "components/Commons/Alerts/UserRequired"
import Connect from "components/Connect"
import LoaderOptions from "components/Content/ScreenLoader/LoaderOptions"
import Alerts from "components/Commons/Alerts"

import "./index.scss"

const AnimeComments = (props: AnimeCommentsProps) => {
  const modal = useRef<HTMLIonModalElement>(null)
  const [count, setCount] = useState<number>(0)
  const [lastComment, setLastComment] = useState<AnimeComment>({} as AnimeComment)
  const [loaded, setLoaded] = useState<boolean>(false)
  const [comments, setComments] = useState<menuItems[]>([] as menuItems[])
  const [newComment, setNewComment] = useState<string>("")
  const [showAlert, setShowAlert] = useState<boolean>(false)
  const [alertMsg, setAlertMsg] = useState<string>("")
  const [alertColor, setAlertColor] = useState<colorTypes>("warning")
  const [showAlertUserRequired, setShowAlertUserRequired] = useState<boolean>(false)

  const changeEpisode = (episode: number) => {
    props.changeEpisode && props.changeEpisode(episode)
    modal.current?.dismiss()
  }

  const openAlert = (msg: string, color: colorTypes) => {
    setAlertMsg(msg)
    setAlertColor(color)
    setShowAlert(true)
  }

  const submiteComment = () => {
    if (!User.isLoggedIn()) return setShowAlertUserRequired(true)
    if (newComment.length < 1) return
    LoaderOptions.openLoader(locales._get("content.comment-sending"))
    Connect.put({
      endpoint: Endpoints.detailscomments,
      body: {
        component: "episodios",
        cid: props.content?.id || 0,
        eid: props.episode?.eid || 0,
        comment: newComment,
      }
    }).then((response: Responses) => {
      LoaderOptions.closeLoader()
      if (response.status === 201) {
        const published: string = locales._get(`content.comment-${response.data?.published ? "published" : "moderated"}`)
        const color: colorTypes = response.data?.published ? "success" : "warning"
        openAlert(published, color)
        setNewComment("")
      } else {
        openAlert(locales._get("content.comment-error"), "danger")
      }
    }).catch(() => {
      LoaderOptions.closeLoader()
      openAlert(locales._get("content.comment-error"), "danger")
    })
  }

  useEffect(() => {
    setLoaded(false)
    getComments({ cid: props.content?.id || 0, force: showAlert }).then((_comments: AnimeComment[]) => {
      setCount(_comments.length)
      _comments.length > 0 && setLastComment(_comments[0])
      setComments(() => {
        const pickerComments: menuItems[] = []
        _comments?.map((comment: AnimeComment) => {
          pickerComments.push({
            title: comment.header,
            subtitle: comment.subheader,
            image: comment.avatar || DefaultAvatar,
            description: comment.comment,
            href: "",
            onClick: () => changeEpisode(comment.serie),
          })
          return comment
        })
        return pickerComments
      })
      setLoaded(true)
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.content, showAlert])

  return (
    <>
      <DropDownButton
        className="component-anime-comments"
        id="anime-comments"
        text={`${locales._get("content.comments")} <i>${count}</i>`}
        subtext={loaded ? lastComment?.comment || locales._get("content.no_comments") : "spinner"}
        icon={chatbubbleEllipsesOutline}
        size="large"
        color="base"
        sameClickAll={true}
        onClick={() => modal.current?.present()}
      />
      <PickerModal
        modal={modal}
        modalId="button-anime-comments"
        selected=""
        items={comments}
        contentListTop={
          <>
            <Item
              icon={warningOutline}
              className="anime-comments-notice"
              baseNode={locales._get("content.comments-notice")}
            />
            <Item
              className="anime-comments-input"
              image={User.getAvatar()}
              counter={true}
              stacked={true}
              baseNode={<>
                {props.episode?.serie ?
                  locales._get("content.comment-input-label", props.episode.serie) :
                  <SpinnerBubbles />
                }
              </>}
              endNode={
                <>
                  <IonInput
                    placeholder={locales._get("content.comment-placeholder")}
                    maxlength={settings.maxCommentInputLength}
                    onIonChange={(e: any) => setNewComment(e?.detail?.value || "")}
                  />
                  <IonButton
                    fill="clear"
                    slot="end"
                    onClick={() => submiteComment()}
                  >
                    <IonIcon icon={paperPlaneOutline} />
                  </IonButton>
                </>
              }
            />
          </>
        }
      />
      <AlertUserRequired
        showAlert={showAlertUserRequired}
        onShowAlert={(showAlert: boolean) => setShowAlertUserRequired(showAlert)}
      />
      <Alerts
        title={alertMsg}
        showAlert={showAlert}
        color={alertColor}
        duration={3000}
        position="bottom"
        onShowAlert={(show: boolean) => setShowAlert(show)}
      />
    </>
  )
}

export default AnimeComments