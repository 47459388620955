export const PublicUrl = process.env.PUBLIC_URL

export const PublicUrlImg = `${PublicUrl}/assets/img/`

export const PublicUrlIcon = `${PublicUrl}/assets/icon/`

export const HeaderLogoSrc = `${PublicUrlImg}logos/logo-horizontal.png`

export const SmallLogoSrc = `${PublicUrlImg}logos/logo2.png`

export const NormalLogoSrc = `${PublicUrlImg}logos/logo.png`

export const KonosubaDance = `${PublicUrlImg}konosuba-dance.gif`

export const AniyukiDance = `${PublicUrlImg}aniyuki-dance.gif`

export const InBarWindow = `${PublicUrlImg}in-bar-window.gif`

export const NarutoRun = `${PublicUrlImg}runner.gif`

export const Goku = `${PublicUrlImg}menu-bg.gif`

export const Vegeta = `${PublicUrlImg}vegeta-ssb.gif`

export const VegetaSSJ = `${PublicUrlImg}vegetafinal.gif`

export const AllMighty = `${PublicUrlImg}allmigthy-pose.gif`

export const AllMightyLaugh = `${PublicUrlImg}laughing-all-might.gif`

export const SaitamaLol = `${PublicUrlImg}saitama-lol.gif`

export const MeiDancing = `${PublicUrlImg}mei-dancing.gif`

export const PurpleEyes = `${PublicUrlImg}purple-eyes.gif`

export const NotRelevant = `${PublicUrlImg}not-relevant.gif`

export const LoginImg = `${PublicUrlImg}login_bg.gif`

export const SignupImg = `${PublicUrlImg}signup_bg.gif`

export const NotFoundImg = `${PublicUrlImg}not-found.gif`

export const NewsImg = `${PublicUrlImg}news.gif`

export const ThumbsImage = (thumb?: string) => `${PublicUrlImg}thumbs/${thumb || "260x370"}.jpg`

export const DefaultAvatar = `${PublicUrlImg}avatar/male/avatar1.jpg`

export const AdsBannerLarge = `${PublicUrlImg}adsremoval/banner-large.jpeg`

export const XiaomiLogo = `${PublicUrlIcon}Xiaomi_logo.svg`