import { useEffect, useState } from "react"

import { setSearchWords } from "../Methods/setSearchWords"
import { AnimeTabs } from "types/Anime"
import locales from "config/Locales"
import settings from "config/Settings"

const AnimeSubtitle = (props: AnimeTabs) => {
  const { content } = props
  const [subtitle, setSubtitle] = useState<string>("")

  useEffect(() => {
    const titleLanguage = new settings().titleLanguage
    const language = titleLanguage === "romaji" ? locales._get("langname") : "romaji"
    Object.entries(content)?.map(([key, value]) => {
      if (key === `title_${language}` && typeof value === "string") setSubtitle(value)
      return key
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [content.id])

  return (
    <div className="component-anime-subtitle">
      {subtitle && <>
        <div dangerouslySetInnerHTML={{ __html: setSearchWords(subtitle, props.currentSearch || "") }} />
      </>}
    </div>
  )
}

export default AnimeSubtitle