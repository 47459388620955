import { Endpoints } from "api/Endpoints"
import { paramsCheckSlides } from "api/routes/params/Content"
import { saveGenders } from "pages/_commons/Content/GerdersList/saveGenders"
import { saveStudios } from "pages/_commons/Content/GerdersList/saveStudios"
import { SlideInfo } from "types/Media/Slider"
import { Responses } from "types/Connect"
import {
  paramsLang,
  paramsRecentNewContent,
  paramsRecentNewContentPlay,
  paramsRecentNewContentPlayFormated,
  recentContentGridLimit,
  recentContentSlideLimit
} from "api/routes/params/Media"
import RefresherCallContent from "../callContent"
import Connect from "components/Connect"
import AnimeContentParser from "pages/_commons/Content/AnimeContent"

class RefresherContent {
  public static newcontent = async (response: Function, force: boolean = false, formated: boolean = false) => {
    const callContent = async (limit: number, callback: Function) =>
      await RefresherCallContent.callContent(Endpoints.recent, paramsRecentNewContent, force, async () =>
        await RefresherCallContent.callContent(Endpoints.recent, formated ? paramsRecentNewContentPlay : paramsRecentNewContentPlayFormated, force, (status: number) =>
          callback(status), limit), limit)
    await callContent(recentContentGridLimit, async () =>
      await callContent(recentContentSlideLimit, (status: number) => response(status))
    )
  }

  public static slides = async (response: Function, force: boolean = false) =>
    await Connect.postcached({ endpoint: Endpoints.slides, options: { ...paramsCheckSlides, force: force } }).then(() =>
      response(200)).catch(() => response(400))

  public static popular = async (response: Function, force: boolean = false) => {
    await RefresherCallContent.callContent(Endpoints.popular, {}, force, async () =>
      await RefresherCallContent.callContent(Endpoints.popular, {}, force, (status: number) =>
        response(status), recentContentGridLimit), recentContentSlideLimit)
  }

  public static genderstudioslist = async (response: Function, force: boolean = false) => {
    Connect.postcached({ endpoint: Endpoints.genderlist, params: paramsLang, options: { force: force } }).then((res: Responses) => {
      saveGenders(AnimeContentParser.fitSlideInfo(res.data.results?.allgenders || res.data.results?.genders || [] as SlideInfo[]))
      saveStudios(AnimeContentParser.fitSlideInfo(res.data.results?.allstudios || [] as SlideInfo[]))
      RefresherContent.newcontent(() =>
        RefresherContent.popular((callbackStatus: number) => response(callbackStatus), force), force)
    }).catch((e: any) => {
      console.warn("error", e)
      response(400)
    })
  }
}

export default RefresherContent