import Animate from "utils/Animate"

export const toggleNote = (show: boolean) => {
  const note = document.querySelector("#no-search-note") as HTMLIonNoteElement
  if (!note) return
  const inClass = Animate.setAnimation("fadeIn", false, false)
  const outClass = Animate.setAnimation("fadeOut", false, false)
  if (show) {
    note.classList.remove(outClass)
    note.classList.add(inClass)
  } else {
    note.classList.remove(inClass)
    note.classList.add(outClass)
  }
}