//import { AdLoadInfo, AdMobRewardItem } from "@capacitor-community/admob"

import { AdLoadInfo, AdMobRewardItem } from "types/Media/Ads/AdMob"
import { SoftnyaAdsBannerItem } from "types/Media/Ads/SoftnyaAds"
import { checklock } from "../Provider/AdMob/Methods/checklock"
import { Capacitor } from "@capacitor/core"
import AdMobClass from "../Provider/AdMob"
import SoftnyaAdsClass from "../Provider/SoftnyaAds"
import settings, { isDev } from "config/Settings"
import Strings from "utils/Strings"
import User from "components/Users/User"

class AdsVideo {
  withAds: boolean = false
  AdMob = new AdMobClass()
  SoftnyaAds = new SoftnyaAdsClass()

  randomShowAds = (average: number = (new settings().adsvideoshowaverage) / (User.inList("GOLD") ? 2 : 1)) =>
    Strings.getRandomBetween(0, 100) < average

  checkAds = async (): Promise<boolean> => {
    if (!this.randomShowAds())
      this.withAds = false
    else
      this.withAds = await this.AdMob.start() || await this.SoftnyaAds.start()
    isDev && console.warn("Check Ads with random value", this.withAds)
    return this.withAds
  }

  showRewardVideo = async (onRewarded: (item: AdMobRewardItem, withAds: boolean) => void, onLoaded?: (info: AdLoadInfo, video?: SoftnyaAdsBannerItem) => void, showInterstitial: boolean = false, showSoftnyaAds: boolean = false) => {
    if (!Capacitor.isNativePlatform() || showSoftnyaAds) {
      if (!isDev) return onRewarded({} as AdMobRewardItem, false)
      if (isDev) return onRewarded({} as AdMobRewardItem, false)//temporal to disable web video rewarded
      const video: SoftnyaAdsBannerItem = await this.SoftnyaAds.showVideo()
      if (!video?.id) return onRewarded({} as AdMobRewardItem, false)
      return onLoaded ? onLoaded({ adUnitId: video.id?.toString() } as AdLoadInfo, video) : onRewarded({} as AdMobRewardItem, false)
    }
    if (showInterstitial) return new AdsVideo().showVideo(() => { }, (info: AdLoadInfo) => {
      const withAds: boolean = !!info?.adUnitId
      onRewarded({
        type: "item",
        amount: withAds ? 3 : 0,
      } as AdMobRewardItem, withAds)
    })
    await this.AdMob.showRewardVideo((item: AdMobRewardItem) => {
      if (!item?.amount && !showInterstitial) return new AdsVideo().showRewardVideo(onRewarded, onLoaded, true, showSoftnyaAds)
      //if (!item?.amount && !showSoftnyaAds) return new AdsVideo().showRewardVideo(onRewarded, onLoaded, showInterstitial, true)
      onRewarded(item, true)
    })
  }

  showVideo = async (onLoad: (withAds: boolean) => void, onLoaded: (info: AdLoadInfo, video?: SoftnyaAdsBannerItem) => void) => {
    await this.checkAds()
    const videolock: boolean = checklock(new settings().getadslockvideotime)
    if (videolock) {
      this.withAds = false
      return onLoaded({} as AdLoadInfo)
    }
    const video: SoftnyaAdsBannerItem = await this.SoftnyaAds.showVideo()
    if (!video?.id) {
      this.withAds && await this.AdMob.showRandomVideo(
        (video: AdMobRewardItem) => onLoaded({} as AdLoadInfo),
        (info: AdLoadInfo) => onLoaded(info),
      )
      this.withAds = false
    }
    onLoad(this.withAds)
    if (!this.withAds) return onLoaded({} as AdLoadInfo)
    if (this.randomShowAds(new settings().softnyaadsvideoshowaverage) && video?.id)
      return onLoaded({ adUnitId: video.id?.toString() } as AdLoadInfo, video)
    await this.AdMob.showInterstitial((info: AdLoadInfo) => onLoaded(info))
  }
}

export default AdsVideo