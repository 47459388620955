import User from "api/routes/User"
import Media from "api/routes/Media"
import Content from "api/routes/Content"
import Ads from "api/routes/Ads"

export const Endpoints = {
  ...User,
  ...Media,
  ...Content,
  ...Ads,
}

