import { IonMenuButton } from "@ionic/react"

import { AniHeaderProps, defaultAniHeaderProps } from "types/Page/AniHeader"
import { HeaderLogo, SmallLogo } from "./HeaderLogo"
import Header from "components/Commons/Header"
import DarkMode from "config/Settings/DarkMode"

import "./index.scss"
import { HeaderBack } from "./HeaderBack"
import RemoveAds from "../../../components/Media/Ads/RemoveAds"

const AniHeader = (props: AniHeaderProps) => {
  return (
    <>
      <Header
        name={props.name || ""}
        className="component-ani-header"
        html={props.showLogo ? <HeaderLogo /> : <HeaderBack {...props} />}
        color={props.color || "tertiary"}
        slot={props.slot || "start"}
        startButtons={props.startButton || null}
        endButtons={props.endButtons || null}
        primaryButtons={props.primaryButtons || null}
        secondaryButtons={
          <>
            {props.showLogo &&
              <>
                <RemoveAds />
                <DarkMode />
                <IonMenuButton />
              </>
            }
            {!props.showLogo &&
              <SmallLogo />
            }
          </>
        }
      />
    </>
  )
}

AniHeader.defaultProps = defaultAniHeaderProps

export default AniHeader