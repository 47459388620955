import { useEffect, useState } from "react"
import { checkmarkOutline, closeOutline } from "ionicons/icons"
import { IonItem, IonLabel, IonToggle, IonThumbnail, IonIcon, IonRange, IonButton, IonSelect, IonSelectOption } from "@ionic/react"

import { AppSettingsItemProps, AppSettingsItemValues, AppSettingsSelectValues } from "types/Page/AppSettings"
import Image from "components/Media/Image"
import locales from "config/Locales"

import "./index.scss"

export const Item = (props: AppSettingsItemProps) => {
  const asButton: boolean = props.type === "button" || (props.onClick ? true : false)
  const [value, setValue] = useState<AppSettingsItemValues>(props.value || 0)
  const [initialValue] = useState<AppSettingsItemValues>(props.value || 0)
  const [rangeObject, setRangeObject] = useState<any>(null)
  const [editRange, setEditRange] = useState<boolean>(false)

  const saveRange = () => {
    setEditRange(false)
    props.onChange && props.onChange(rangeObject)
  }

  const cancelRange = () => {
    setEditRange(false)
    setValue(initialValue)
  }

  useEffect(() => setValue(props.value || 0), [props.value])

  return (
    <IonItem
      button={asButton}
      detail={asButton}
      counter={props.counter || false}
      color={props.color || undefined}
      onClick={() => props.onClick && props.onClick()}
      className={`component-profile-item ${props.image && "with-thumb"} ${props.asHeading && "as-heading"} ${props.asSubHeading && "as-sub-heading"} ${props.className || ""} item-type-${props.type}`}
    >
      {props.icon &&
        <IonIcon slot="start" icon={props.icon} />
      }
      {props.image &&
        <IonThumbnail slot="start">
          <Image
            src={props.image}
            srcBk={props.imageBk}
            alt={props.title?.toString() || ""}
            onErrorShowDefault={true}
          />
        </IonThumbnail>
      }
      <IonLabel
        position={props.stacked ? "stacked" : undefined}
      >
        {props.title && <h3>{props.title}</h3>}
        {props.subtitle && <p>{props.subtitle}</p>}
        {props.baseImage && <Image src={props.baseImage} alt={props.title?.toString() || ""} />}
        {props.baseNode && props.baseNode}
        {props.type === "range" &&
          <>
            {editRange ?
              <IonRange
                min={props.valueRange?.min || 0}
                max={props.valueRange?.max || 100}
                step={props.valueRange?.step || 1}
                value={parseInt(value?.toString() || "")}
                onIonChange={(e: any) => {
                  e.detail.value && setValue(e.detail.value)
                  props.onChanging && props.onChanging(e)
                }}
                onIonKnobMoveEnd={(e: any) => {
                  setValue(e?.detail?.value || value)
                  setRangeObject(e)
                }} />
              :
              <IonButton
                color="primary"
                fill="outline"
                size="small"
                expand="block"
                onClick={() => setEditRange(true)}
              >
                {locales._get("profile.settings-edit")}
              </IonButton>
            }
          </>
        }
      </IonLabel>
      {(props.type === "range" && editRange) &&
        <div slot="end" className="range-edit">
          <IonButton
            color="primary"
            fill="solid"
            size="small"
            shape="round"
            onClick={() => saveRange()}
          >
            <IonIcon icon={checkmarkOutline} />
          </IonButton>
          <IonButton
            color="tertiary"
            fill="solid"
            size="small"
            shape="round"
            onClick={() => cancelRange()}
          >
            <IonIcon icon={closeOutline} />
          </IonButton>
        </div>
      }
      {props.type === "toggle" &&
        <IonToggle slot="end" checked={props.value === 1} onIonChange={(e: any) => props.onChange && props.onChange(e)}></IonToggle>
      }
      {props.type === "select" &&
        <IonSelect
          interface="action-sheet"
          slot="end"
          value={value}
          title={props.title?.toString() || ""}
          onIonChange={(e: any) => {
            setValue(e.detail?.value || value)
            props.onChange && props.onChange(e)
          }}
        >
          {props.valueSelect?.map((item: AppSettingsSelectValues, index: number) =>
            <IonSelectOption key={index} value={item.id}>{item.title}</IonSelectOption>
          )}
        </IonSelect>
      }
      {props.type === "range" &&
        <IonLabel slot="end">
          {props.type === "range" && value}
          <span dangerouslySetInnerHTML={{ __html: props.valueSufix || "" }} />
        </IonLabel>
      }
      {props.endNode && props.endNode}
    </IonItem>
  )
}