import { menuItems } from "types/Commons/Menu"
import { MenuApp } from "./MenuApp"
import { MenuBottom } from "./MenuBottom"
import { MenuSide } from "./MenuSide"
import { MenuHidden } from "./MenuHidden"

const MenuAll = [
  ...MenuBottom,
  ...MenuSide,
  ...MenuApp,
] as menuItems[]

export const MenuWithHidden = [
  ...MenuAll,
  ...MenuHidden,
]

export default MenuAll