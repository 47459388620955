import { useEffect, useState } from "react"
import { useHistory } from "react-router-dom"

import { DropDownButtonProps } from "types/Commons/DropDownButton"
import { AnimeContent, AnimeContentStudio } from "types/Anime"
import Buttons from "components/Commons/Buttons"
import useAnime from "store/useAnime"

const studioLink = (id: number) => `/anime/studio/${id}`

const StudiosButtons = (props: { content: AnimeContent }) => {
  const history = useHistory()
  const setIsOpen = useAnime((state: any) => state.setIsOpen)

  return (
    <div className="studio-list">
      <Buttons
        buttons={[
          {
            text: props.content.studioid?.title || "",
            fill: "outline",
            onClick: () => {
              history.push(studioLink(props.content.studioid?.id || 0))
              setIsOpen(false)
            },
          },
        ]}
      />
    </div>
  )
}

export const StudiosOtherButtons = (props: { content: AnimeContent }) => {
  const [studioButtons, setStudioButtons] = useState<DropDownButtonProps[]>([] as DropDownButtonProps[])
  const history = useHistory()
  const setIsOpen = useAnime((state: any) => state.setIsOpen)

  useEffect(() => {
    setStudioButtons((props.content.studiosids || [])?.map((item: AnimeContentStudio) => {
      return {
        text: item.title,
        onClick: () => {
          history.push(studioLink(item.id))
          setIsOpen(false)
        },
      }
    }))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.content])
  return (
    <>
      {studioButtons?.length > 0 &&
        <div className="studio-list">
          <Buttons
            buttons={studioButtons}
          />
        </div>
      }
    </>
  )
}

export default StudiosButtons