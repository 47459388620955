import { IonButton, IonIcon } from "@ionic/react"
import { moon, sunny } from "ionicons/icons"
import { useState } from "react"

import StorageUtils from "utils/Storage"

export const getDarkMode = (defaultMode: boolean = true): boolean => {
  const storageDarkMode = StorageUtils.getItem("darkMode")
  const darkMode: boolean = storageDarkMode === "true" || (!storageDarkMode ? defaultMode : false)

  return darkMode
}

export const checkDarkMode = (): boolean => {
  const darkMode = getDarkMode()
  if (darkMode)
    document.body.classList.add("dark")
  else
    document.body.classList.remove("dark")

  return darkMode
}

const DarkMode = () => {
  const darkMode = getDarkMode()
  const [icon, setIcon] = useState(darkMode ? moon : sunny)

  function toggleDarkTheme(shouldAdd: boolean) {
    StorageUtils.setItem("darkMode", shouldAdd.toString())
    setIcon(shouldAdd ? moon : sunny)
    checkDarkMode()
  }

  return (
    <>
      <IonButton
        id="dark-mode"
        fill="clear"
        shape="round"
        onClick={() => toggleDarkTheme(!darkMode)}
      >
        <IonIcon icon={icon} />
      </IonButton>
    </>
  )
}

export default DarkMode