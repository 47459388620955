import { Endpoints } from "api/Endpoints"
import { paramsRecentForYou } from "api/routes/params/Media"
import Connect from "components/Connect"
import StorageUtils from "utils/Storage"

class ClearUser {
  public static foruser = async (response: Function) => {
    const remove = async (endpoint: string, params: {}) => {
      const query = Connect.getQuery({ endpoint, params })
      StorageUtils.removeItem(query)
    }
    await remove(Endpoints.recent, paramsRecentForYou)
    await remove(Endpoints.recentviewed, {})
    response()
  }
}

export default ClearUser