import { Endpoints } from "api/Endpoints"
import Meta from "components/Media/Meta"
import locales from "config/Locales"
import Pager from "pages/_commons/Pager"

const About = () => {
  return (
    <>
      <Pager
        endpoint={Endpoints.about}
        className="page-about"
        storageKey="About"
      />
      <Meta
        title={locales._get("about")}
      />
    </>
  )
}

export default About