import { useState } from "react"

import { Responses } from "types/Connect"
import { Endpoints } from "api/Endpoints"
import { SmallLogoSrc } from "types/Anime/Images"
import { AnimeTabs } from "types/Anime"
import { isParentalContentBlocked } from "pages/Discover/Anime/Methods/isParentalContentBlocked"
import Connect from "components/Connect"
import Image from "components/Media/Image"

import "./index.scss"

const AnimeDescription = (props: AnimeTabs) => {
  const { content } = props
  const [errorTriggered, setErrorTriggered] = useState(false)

  return (
    <div className="component-anime-description-tabs">
      {props.contentTop && <div className="component-anime-description-top">{props.contentTop}</div>}
      {!props.excludeMedia && <Image
        src={content.img || ""}
        srcBk={content.params?.img}
        isblured={isParentalContentBlocked(content)}
        className={content.content ? "floating-img" : "centered-img"}
        onIonError={(e: any) => {
          e.target.src = content.params?.img || SmallLogoSrc
          if (errorTriggered) return
          Connect.put({
            endpoint: Endpoints.detailsupdateimage,
            body: { id: content.id, from: "params.img", to: "img" },
          }).then((response: Responses) => {
            setErrorTriggered(true)
            if (response.status === 200 && response.data?.image) e.target.src = response.data?.image
          })
        }}
      />}
      <div dangerouslySetInnerHTML={{ __html: content.content || "" }} />
      {props.contentBottom && <div className="component-anime-description-bottom">{props.contentBottom}</div>}
    </div>
  )
}

export default AnimeDescription