import { UserProfile } from "types/User"
import LoaderOptions from "components/Content/ScreenLoader/LoaderOptions"
import Refresher from "components/Connect/Refresher"
import User from "components/Users/User"
import RedirectURL from "utils/Redirect"

export const changeUser = (props: { user: UserProfile, history: any }) => {
  LoaderOptions.openLoader("user.changing")
  User.setActiveUser({
    user: props.user,
    callback: () =>
      Refresher.user(() =>
        Refresher.clearuser(() =>
          Refresher.user(() => {
            //TODO: save avatar to server
            LoaderOptions.closeLoader()
            props.history.push("/welcome")
            RedirectURL()
          }, true)
        )
      )
  })
}