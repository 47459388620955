import {
  IonLabel,
  IonSegment,
  IonSegmentButton,
} from "@ionic/react"
import { useState } from "react"

import { LoginSectionTypes } from "types/User/Login"
import locales from "config/Locales"
import LoginForm from "./LoginForm"
import SignupForm from "./SignupForm"

import "./index.scss"

const Login = () => {
  const [activeSection, setActiveSection] = useState<LoginSectionTypes>("login")

  return (
    <>
      <IonSegment
        color="light"
        className="tab-bullet"
        value={activeSection}
        onIonChange={(e: any) => {
          setActiveSection(e.detail.value)
        }}
      >
        <IonSegmentButton value="signup">
          <IonLabel className="ion-text-uppercase">{locales._get("signup")}</IonLabel>
        </IonSegmentButton>
        <IonSegmentButton value="login">
          <IonLabel className="ion-text-uppercase">{locales._get("login")}</IonLabel>
        </IonSegmentButton>
      </IonSegment>
      <div>
        {activeSection === "login" && <LoginForm />}
        {activeSection === "signup" && <SignupForm />}
      </div>
    </>
  )
}

export default Login
