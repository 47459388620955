import { ellipsisHorizontal } from "ionicons/icons"
import { ReactNode } from "react"

import { colorTypes } from ".."

export type DropDownBadgeProps = {
  icon?: string,
  onClick?: Function,
  color?: colorTypes,
  children?: ReactNode,
  className?: string,
}

export const defaultDropDownBadge = {
  color: "primary" as colorTypes,
  icon: ellipsisHorizontal,
  disabled: false,
}