import { SlideInfo } from "types/Media/Slider"
import { AppSettingsID } from "utils/AppSettings"
import Strings from "utils/Strings"
import AnimeContentParser from "../AnimeContent"

export const saveGenders = (genders: SlideInfo[]) => {
  if (genders.length === 0) return
  const mainGenders = [] as SlideInfo[]

  AppSettingsID.setSetting("gender-list", AnimeContentParser.fitSlideInfo(genders)?.map((gender: SlideInfo) => {
    gender.title = Strings.encode(gender.title || "")
    if (gender.principal === 1) mainGenders.push(gender)
    return gender
  }))
  AppSettingsID.setSetting("gender-main", mainGenders)
  AppSettingsID.setSetting("gender-count", genders.length)
}