import { IonButtons, IonToolbar } from "@ionic/react"
import { chevronBackOutline, flameOutline } from "ionicons/icons"

import { HeaderToolbarProps } from "types/Commons/HeaderToolbar"
import { menuItems } from "types/Commons/Menu"
import { ToolbarButton } from "pages/_commons/ToolbarButton"
import locales from "config/Locales"

import "./index.scss"

const HeaderToolbar = (props: HeaderToolbarProps) => {
  return (
    <IonToolbar className="component-header-toolbar">
      <IonButtons>
        {props.asBack === true ? (
          <ToolbarButton text={locales._get("content.back")} icon={chevronBackOutline} asBack={true} />
        ) :
          <>
            {props.items?.map((item: menuItems, index: number) => (
              <ToolbarButton
                key={index}
                text={item.title}
                icon={index % 2 ? flameOutline : undefined}
                color={index % 2 ? undefined : "secondary"}
                link={item.href}
              />
            ))}
          </>
        }
      </IonButtons>
    </IonToolbar>
  )
}

export default HeaderToolbar