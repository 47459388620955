import { Endpoints } from "api/Endpoints"
import Meta from "components/Media/Meta"
import locales from "config/Locales"
import Pager from "pages/_commons/Pager"

const Tos = () => {
  return (
    <>
      <Pager
        endpoint={Endpoints.tos}
        className="page-tos"
        storageKey="Tos"
        asMarkdown={true}
      />
      <Meta
        title={locales._get("tos")}
      />
    </>
  )
}

export default Tos