import { IonModal } from "@ionic/react"
import { useEffect, useState } from "react"

import { SoftnyaAdsBannerItem } from "types/Media/Ads/SoftnyaAds"
import ModalMediaLoading from "pages/Discover/Commons/Modal/Media/Loading"
import ModalMediaControls from "pages/Discover/Commons/Modal/Media/Controls"
import LoaderOptions from "components/Content/ScreenLoader/LoaderOptions"
import SoftnyaAdsBannerSign from "components/Media/Ads/Provider/SoftnyaAds/Sign"
import useAds from "store/useAds"
import locales from "config/Locales"
import settings from "config/Settings"
import Animate from "utils/Animate"

import "./index.scss"

declare global {
  interface Window {
    YT: any,
  }
}

let player: any
const SoftnyaAdsModal = () => {
  const showSoftnyaVideo: boolean = useAds((state: any) => state.showSoftnyaVideo)
  const softnyaAds: SoftnyaAdsBannerItem = useAds((state: any) => state.softnyaAds)
  const setShowSoftnyaVideo = useAds((state: any) => state.setShowSoftnyaVideo)
  const setShowBanner = useAds((state: any) => state.setShowBanner)
  const [hasCloseButton, setHasCloseButton] = useState<boolean>(false)
  const container = document.querySelector(".counterSeen .time") as HTMLDivElement
  const animationDiv = Animate.setAnimation("bounceIn", false, false)
  let isPlaying: boolean = false
  let timer: number = new settings().softnyaadsvideotimeout
  let isCounting: boolean = false

  useEffect(() => {
    const tag = document.createElement("script")

    tag.src = "https://www.youtube.com/iframe_api"
    const head = document.querySelector("head") as HTMLHeadElement
    head.appendChild(tag)
    if (!showSoftnyaVideo || !window.YT || window.YT === null) {
      LoaderOptions.closeLoader()
      if (showSoftnyaVideo) activeCounter()
      else closeModal()
      return
    }
    player = new window.YT.Player("component-softnya-ads-player", {
      videoId: softnyaAds?.ytid,
      playerVars: {
        "autoplay": "1",
        "controls": "0",
        "fs": "1",
        "start": softnyaAds?.time || 0,
        "enablejsapi": "1",
        "modestbranding": "1",
        "showinfo": "false",
        "wmode": "opaque",
        "rel": "0",
      },
      events: {
        onStateChange: onPlayerStateChange,
        onReady: () => {
          LoaderOptions.closeLoader()
          activeCounter()
        },
        onError: () => {
          LoaderOptions.closeLoader()
          // eslint-disable-next-line react-hooks/exhaustive-deps
          isPlaying = true//metter video even if is not playing
          activeCounter()
          console.error("error on softnya ads video")
        },
      }
    })

    return () => {
      if (player) player.destroy()
    }
  }, [showSoftnyaVideo, softnyaAds])

  useEffect(() => {
    if (isCounting) return
    const check = setInterval(() => {
      activeCounter()
    }, 1000)

    return () => clearInterval(check)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isCounting])

  const activeCounter = () => {
    if (!isCounting) startCountDown()
    isCounting = true
  }

  const startCountDown = () => {
    if (timer > 0) {
      if (isPlaying) {
        timer -= 1
        setCounter(timer)
      }
      setTimeout(() => startCountDown(), 1500)
    } else {
      setCounter(0)
      isCounting = false
    }
  }

  const setCounter = (time: number) => {
    setHasCloseButton(time <= 0)
    if (!container) return
    if (time > 0) {
      container.innerText = `${time}`
      container.classList.remove("hidden")
      container.classList.add(animationDiv)
      setTimeout(() => container.classList.remove(animationDiv), 800)
    } else {
      container.classList.add("hidden")
    }
  }

  const onPlayerStateChange = (event: any) => {
    if (event.data === window.YT.PlayerState.PLAYING) isPlaying = true
    else if (
      event.data === window.YT.PlayerState.PAUSED ||
      event.data === window.YT.PlayerState.BUFFERING
    ) isPlaying = false
  }

  const closeModal = () => {
    LoaderOptions.closeLoader()
    setShowSoftnyaVideo(false)
    setHasCloseButton(false)
  }

  return (
    <IonModal
      isOpen={showSoftnyaVideo}
      backdropDismiss={false}
      keyboardClose={false}
      className="softnya-ads-modal"
      onIonModalWillPresent={() => setShowBanner(false)}
      onIonModalDidPresent={() => LoaderOptions.closeLoader()}
      onIonModalWillDismiss={() => setShowBanner(true)}
      onIonModalDidDismiss={() => closeModal()}
    >
      <ModalMediaControls
        alterTitle={softnyaAds?.name || ""}
        alterSubtitle={locales._get("ads.click-here")}
        showMenu={false}
        hasCloseButton={hasCloseButton}
        onClose={() => closeModal()}
        onClick={() => {
          softnyaAds.clickurl && window.open(softnyaAds.clickurl, "_blank")
        }}
      />
      <div id="component-softnya-ads-player">
        <iframe
          title="Softnya Ads"
          src={softnyaAds?.embed}
        />
      </div>
      <SoftnyaAdsBannerSign />
      <ModalMediaLoading />
    </IonModal>
  )
}

export default SoftnyaAdsModal