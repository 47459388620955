import { Slide } from "types/Media/Slider"
import Card from ".."

export const Item = (props: { item: Slide, onClick?: Function, }) => {
  const { conditionalBadge, contentOverTitle, contentOverHeader } = props.item
  delete props.item.conditionalBadge

  return (
    <Card
      {...props}
      contentBellowImg={
        <>
          {conditionalBadge && conditionalBadge()}
        </>
      }
      contentOverHeader={
        <>
          {contentOverHeader && contentOverHeader()}
        </>
      }
      contentOverTitle={
        <>
          {contentOverTitle && contentOverTitle()}
        </>
      }
    />
  )
}