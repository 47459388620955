import { useState } from "react"
import { arrowUpOutline, informationCircleOutline } from "ionicons/icons"

import { loadGenderItemsFromRemote } from "pages/Discover/HeaderToolbar"
import { getStudiosListMenuItems } from "pages/_commons/Content/GerdersList/getStudiosList"
import { NoSearchProps } from "types/Page/Search"
import { menuItems } from "types/Commons/Menu"
import { Slide } from "types/Media/Slider"
import { IonIcon, IonNote } from "@ionic/react"
import Animate from "utils/Animate"
import settings from "config/Settings"
import RecentSearch from "./RecentSearch"
import ExploreSlider from "./ExploreSlider"
import locales from "config/Locales"
import AdsBanner from "components/Media/Ads/Banner"

import "./index.scss"

const NoSearch = (props: NoSearchProps) => {
  const [genders] = useState<Slide[]>(loadGenderItemsFromRemote(settings.maxCallbackTimes))
  const [studios] = useState<Slide[]>(getStudiosListMenuItems()?.slice(0, genders.length || 10) || [] as Slide[])

  return (
    <div className="component-no-search">
      {props.showMinLengthAlert ?
        <IonNote
        >
          <IonIcon icon={informationCircleOutline} className={Animate.setAnimation("bounceInUp")} />
          {locales._get("content.search-explain-min-length", props.minQueryLength)}
        </IonNote>
        :
        <IonNote
          id="no-search-note"
          className={`${Animate.setAnimationBase()} ${Animate.setAnimationFast()}`}
        >
          <IonIcon icon={arrowUpOutline} className={Animate.setAnimation("bounceInUp")} />
          {locales._get("content.search-explain")}
        </IonNote>
      }
      <AdsBanner type="softnyacontent" softnyaposition="DETAILS_TOP" />
      <RecentSearch />
      <ExploreSlider
        title={locales._get("content.expore", locales._get("genders"))}
        buttonText={locales._get("content.search-see")}
        className="slider-genders"
        items={genders || [] as menuItems[]}
      />
      <ExploreSlider
        title={locales._get("content.expore", locales._get("studios"))}
        buttonText={locales._get("content.search-see")}
        className="slider-studios"
        items={studios || [] as menuItems[]}
        firstColor="secondary"
        secondColor="primary"
      />
      <AdsBanner type="softnyacontent" softnyaposition="DETAILS_BOTTOM" />
    </div>
  )
}

export default NoSearch