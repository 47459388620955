import { ReactNode } from "react"
import { colorTypes } from ".."

type alignTypes = "center" | "left" | "right"

export type NotificationProps = {
  title?: string,
  subtitle?: string,
  children?: ReactNode,
  titlealign?: alignTypes,
  subtitlealign?: alignTypes,
  htmlalign?: alignTypes,
  color?: colorTypes,
  className?: string,
  compact?: boolean,
}

export const defaultNotificationProps: NotificationProps = {
  title: "",
  subtitle: "",
  children: null,
  titlealign: "center",
  subtitlealign: "center",
  htmlalign: "center",
  color: "primary",
  className: "",
  compact: false,
}