import { IonList } from "@ionic/react"
import { useEffect, useState } from "react"

import { AppSettingsID } from "utils/AppSettings"
import { Responses } from "types/Connect"
import { SaitamaLol } from "types/Anime/Images"
import { Item } from "components/Content/FormBuilder/Item"
import OverCard from "components/Content/OverCard"
import locales from "config/Locales"
import User from "components/Users/User"
import ErrorHandlerErrorSending from "../ErrorSending"

import "./index.scss"

const ErrorHandlerErrorMessage = () => {
  const [error_response] = useState<Responses>(AppSettingsID.getSetting("error_response", {} as Responses))

  useEffect(() => {
    return () => {
      AppSettingsID.setSetting("error_response", {} as Responses)
    }
  }, [])

  return (
    <>
      <OverCard
        className="component-error-handler-message"
        title={locales._get(error_response.status ? "error.content-title-status" : "error.content-title")}
        subtitle={locales._get(error_response.status ? "error.content-description-status" : "error.content-description")}
        image={SaitamaLol}
      />
      {error_response.status &&
        <>
          {!User.isOwner() && <ErrorHandlerErrorSending />}
          {User.isOwner() && (
            <IonList className="component-error-handler-message">
              <Item
                title={locales._get("error.modal-title")}
                subtitle={locales._get("error.modal-subtitle")}
                asHeading={true}
              />
              <Item
                title={locales._get("error.description")}
                subtitle={error_response.statusText || locales._get("error.no-description")}
              />
              <Item
                title={locales._get("error.status-code")}
                subtitle={error_response.status?.toString() || locales._get("error.no-status-code")}
              />
              <Item
                title={locales._get("error.end-point")}
                subtitle={error_response.endpoint || locales._get("error.no-end-point")}
              />
              <Item
                title={locales._get("error.headers")}
                subtitle={JSON.stringify(error_response.headers || {}) || locales._get("error.no-headers")}
              />
              <Item
                title={locales._get("error.body")}
                subtitle={JSON.stringify(error_response.body || {}) || locales._get("error.no-body")}
              />
            </IonList>
          )}
        </>
      }
    </>
  )
}

export default ErrorHandlerErrorMessage