import { AlertProps } from "types/Commons/Alerts"
import locales from "config/Locales"
import Alerts from ".."

const AlertHasNetwork = (props: AlertProps) => {
  return (
    <Alerts
      title={props.title || ""}
      showAlert={props.showAlert && props.title !== ""}
      cssClass="alert-has-network"
      buttons={[
        {
          text: locales._get("network.ok"),
          role: "cancel",
        }
      ]}
      color={props.color || "primary"}
    />
  )
}

export default AlertHasNetwork