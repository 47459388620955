import Welcome from "pages/Welcome"
import Profile from "pages/Profile"
import ProfileSettings from "pages/Profile/Settings"
import ProfileHistory from "pages/Profile/History"

const routes = [
  {
    path: "welcome",
    component: <Welcome />,
  },
  {
    path: "profile",
    component: <Profile />,
  },
  {
    path: "profile/history",
    component: <ProfileHistory />,
  },
  {
    path: "profile/settings",
    component: <ProfileSettings />,
  },
]

export default routes