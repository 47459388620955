import { useEffect, useState } from "react"

import { AdsProps } from "types/Media/Ads"
import { SoftnyaAdsBannerItem } from "types/Media/Ads/SoftnyaAds"
import { checklock } from "../Provider/AdMob/Methods/checklock"
import { removeAdPosition } from "../RemoveAds/Methods/removeAdPosition"
import JDate, { JDateAgoNumType } from "utils/JDate"
import settings from "config/Settings"
import AdMobClass from "../Provider/AdMob"
import SoftnyaAdsClass from "../Provider/SoftnyaAds"
import SoftnyaAdsBannerSign from "../Provider/SoftnyaAds/Sign"
import useAds from "store/useAds"
import useAnime from "store/useAnime"
import Image from "components/Media/Image"
//import User from "components/Users/User"
import AdsTerra, { showAdsTerra } from "../Provider/AdsTerra"
import RemoveAdsButton from "../RemoveAds/RemoveAdsButton"

import "./index.scss"

// https://github.com/capacitor-community/admob

const AdsBanner = (props: AdsProps) => {
  const [AdMob] = useState(new AdMobClass())
  const [SoftnyaAds] = useState(new SoftnyaAdsClass())
  const [withAdMob, setWithAdMob] = useState(false)
  const [withSoftnyaAds, setWithSoftnyaAds] = useState(false)
  const [bannerClass] = useState<string>(`component-softnya-ads-banner ${props.softnyaposition} ${props.className || ""}`)
  const [softnyaBanner, setSoftnyaBanner] = useState<SoftnyaAdsBannerItem>({} as SoftnyaAdsBannerItem)
  const showBanner = useAds((state: any) => state.showBanner)
  //const setShowBanner = useAds((state: any) => state.setShowBanner)
  const setShowSoftnyaVideo = useAds((state: any) => state.setShowSoftnyaVideo)
  const setSoftnyaAds = useAds((state: any) => state.setSoftnyaAds)
  const isOpenAnime: boolean = useAnime((state: any) => state.isOpen)
  const bannerlargelock: boolean = checklock(new settings().getadslocklargetime)
  const bannercontentlock: boolean = checklock(new settings().getadslockcontenttime)
  const [adslocklarge, setAdsLockLarge] = useState<JDateAgoNumType>(JDate.agoNum(new settings().getadslocklargetime))
  const [adslockcontent, setAdsLockContent] = useState<JDateAgoNumType>(JDate.agoNum(new settings().getadslockcontenttime))
  const [showingAds, setShowingAds] = useState(false)

  const showLargeBanner = () => (props.type === "bannertop" && bannerlargelock) || props.isDemo

  const showContentBanner = () => (props.type === "bannercenter" && bannercontentlock) || props.isDemo

  const showBannerBottom = () => (props.type === "bannerbottom" && bannercontentlock) || props.isDemo

  useEffect(() => {
    if (showLargeBanner()) return setWithAdMob(false)
    if (showContentBanner()) return setWithAdMob(false)
    if (showBannerBottom()) return setWithAdMob(false)
    AdMob.start().then((hasAds: boolean) => setWithAdMob(hasAds))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [AdMob])

  useEffect(() => {
    SoftnyaAds.start().then((hasAds: boolean) => {
      setWithSoftnyaAds(hasAds)
      if (hasAds) SoftnyaAds.showVideo().then((video: SoftnyaAdsBannerItem) => {
        setConfig(video)
        setSoftnyaAds(video)
      })
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [SoftnyaAds])

  useEffect(() => {
    if (!withAdMob) return
    if (showBanner && !isOpenAnime) {
      props.type === "bannertop" && AdMob.showBannerTop()
      props.type === "bannerbottom" && AdMob.showBannerBottom()
      props.type === "bannercenter" && AdMob.showBannerCenter()
    }
    else AdMob.hideBanner()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showBanner, withAdMob, AdMob.isStarted])

  useEffect(() => {
    if (!withSoftnyaAds) return
    if (showBanner)
      SoftnyaAds.showBanner({ position: props.softnyaposition })
        .then((banner: SoftnyaAdsBannerItem) => {
          setConfig(banner)
          setSoftnyaBanner(banner)
        })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showBanner, withSoftnyaAds, SoftnyaAds.isStarted])

  /*useEffect(() => {
    if (showBanner && isOpenAnime) setShowBanner(false)
    if (props.force && User.isFree()) setShowBanner(true)
    if ((!showBanner || !withAdMob) && !props.force) AdMob.hideBanner()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showBanner, props.force, withAdMob])*/

  const setConfig = (item: SoftnyaAdsBannerItem) => {
    if (!item?.config) return
    const _settings = new settings()
    _settings.adsvideoshowaverage = item?.config?.average?.ads || _settings.adsvideoshowaverage
    _settings.softnyaadsvideoshowaverage = item?.config?.average?.softnya || _settings.softnyaadsvideoshowaverage
    _settings.softnyaadsvideotimeout = item?.config?.timeout || _settings.softnyaadsvideotimeout
  }

  const showAds = (): boolean => {
    if (
      (props.type === "softnyacontent" && showContentBanner() && softnyaBanner?.params?.imageurl) ||
      (props.type === "softnyacontent" && showContentBanner() && softnyaBanner?.custombannercode && softnyaBanner?.custombannercode !== "")
    ) return true
    return showAdsTerra(props.softnyaposition)
  }

  const RemoveButton = () => {
    if (!props.softnyaposition || props.isDemo) return <></>
    return (
      <div className={`ion-text-center ${props.softnyaposition}`}>
        <RemoveAdsButton
          onClick={() => removeAdPosition({
            position: props.softnyaposition === "TOP" ? "LARGE" : "CONTENT",
            setShowingAds,
            setAdsLockLarge,
            setAdsLockContent,
            setSoftnyaAds,
            setShowSoftnyaVideo,
          })}
          position={props.softnyaposition === "TOP" ? "LARGE" : "CONTENT"}
          time={props.softnyaposition === "TOP" ? adslocklarge : adslockcontent}
          showingAds={showingAds}
          isOnBanner={true}
        />
      </div>
    )
  }
  if ((!showBanner || !withSoftnyaAds || (!softnyaBanner?.params?.imageurl && !softnyaBanner?.custombannercode)) && !props.isDemo)
    return <><AdsTerra position={props.softnyaposition} className={props.className} />{showAdsTerra(props.softnyaposition) && <RemoveButton />}</>
  return (
    <>
      {(props.type === "softnyacontent" && showContentBanner() && softnyaBanner?.params?.imageurl) && (
        <div className={bannerClass}>
          <Image
            src={softnyaBanner?.params?.imageurl || ""}
            alt={softnyaBanner?.params?.alt || ""}
            onClick={() => window.open(softnyaBanner?.clickurl || "", "_blank")}
            onIonError={() => setSoftnyaBanner({} as SoftnyaAdsBannerItem)}
          />
          <SoftnyaAdsBannerSign />
        </div>
      )}
      {(props.type === "softnyacontent" && showContentBanner() && softnyaBanner?.custombannercode && softnyaBanner?.custombannercode !== "") && (
        <div className={`${bannerClass} component-softnya-ads-code`}>
          <div dangerouslySetInnerHTML={{ __html: softnyaBanner?.custombannercode || "" }} />
          <SoftnyaAdsBannerSign />
        </div>
      )}
      <AdsTerra position={props.softnyaposition} className={props.className} isDemo={props.isDemo} />
      {showAds() && <RemoveButton />}
    </>
  )
}

export default AdsBanner