import Strings from "utils/Strings"
import { defaultVideoProps, VideoProps } from "types/Media/Video"

const VideoPlayer = (props: VideoProps) => {
  return (
    <video
      className="component-video-player"
      autoPlay={props.autoPlay || true}
      muted={props.muted || true}
      loop={props.loop || true}
      playsInline
      preload="auto"
    >
      <source
        src={`${props.sourceMP4}#t=${props.randomStartPosition ? Strings.getRandomBetween(props.maxRandomStartPosition || 0) : 0}`}
        type="video/mp4"
      />
    </video >
  )
}

VideoPlayer.defaultProps = defaultVideoProps

export default VideoPlayer