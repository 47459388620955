export type VideoProps = {
  sourceMP4: string,
  autoPlay?: boolean,
  muted?: boolean,
  loop?: boolean,
  randomStartPosition?: boolean,
  maxRandomStartPosition?: number,
}

export const defaultVideoProps = {
  autoPlay: true,
  muted: true,
  loop: true,
  randomStartPosition: true,
  maxRandomStartPosition: 35,
} as VideoProps