import { useRef } from "react"
import { IonButton, IonIcon, IonModal } from "@ionic/react"
import { close } from "ionicons/icons"

import { calcRelevanceLevel } from "components/Media/drawSlideItems/RelevanceAlert"
import locales from "config/Locales"
import ScrollContent from "components/Content/ScrollContent"
import Header from "components/Commons/Header"
import settings from "config/Settings"

import "./index.scss"

const RelevanceInfo = () => {
  const modal = useRef<HTMLIonModalElement>(null)
  return (
    <>
      <IonButton
        size="small"
        fill="outline"
        id="relevance-info"
        expand="block"
      >
        {locales._get("content.relevance-what-is")}
      </IonButton>
      <IonModal
        className="component-modal-relevance-info modal-alert"
        ref={modal}
        trigger="relevance-info"
        initialBreakpoint={0.75}
      >
        <ScrollContent>
          <>
            <Header
              name={locales._get("content.relevance-what-is")}
              endButtons={
                <IonButton
                  size="small"
                  fill="clear"
                  onClick={() => {
                    modal.current?.dismiss()
                  }}
                >
                  <IonIcon icon={close} />
                </IonButton>
              }
            />
            <div className="ion-padding">
              <>
                <div dangerouslySetInnerHTML={{
                  __html:
                    locales._get("content.relevance-what-is-desc", {
                      "%1a": settings.relevance.high,
                      "%1b": settings.relevance.high,
                      "%2a": settings.relevance.medium,
                      "%2b": settings.relevance.medium,
                      "%3": settings.relevance.low,
                      "%4": settings.relevance.low,
                      "%c1": calcRelevanceLevel(settings.relevance.high),
                      "%c2": calcRelevanceLevel(settings.relevance.medium),
                      "%c3": calcRelevanceLevel(settings.relevance.low),
                      "%c4": calcRelevanceLevel(0),
                    })
                }} />
              </>
            </div>
          </>
        </ScrollContent>
      </IonModal>
    </>
  )
}

export default RelevanceInfo