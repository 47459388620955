import { IonButtons } from "@ionic/react"

import { ButtonsProps } from "types/Commons/Buttons"
import { DropDownButtonProps } from "types/Commons/DropDownButton"

import "./index.scss"
import InlineButton from "./Button"

const Buttons = (props: ButtonsProps) => {
  return (
    <IonButtons className="component-buttons">
      {props.buttons?.map((button: DropDownButtonProps, index: number) =>
        <InlineButton {...button} index={index} id={`drop-item-${index}`} key={`component-buttons-${index}`} />
      )}
    </IonButtons>
  )
}

export default Buttons