import { Endpoints } from "api/Endpoints"
import { Responses } from "types/Connect"
import { UserProfile } from "types/User"
import settings from "config/Settings"
import StorageUtils from "utils/Storage"
import Connect from "components/Connect"
import Refresher from "components/Connect/Refresher"

class UserMultiSession {
  static userExists = (username: string) => {
    const userList = JSON.parse(StorageUtils.getItem("userlist") || "[]")
    const filtered = userList.filter((user: any) => user.username === username)

    return filtered.length > 0 ? true : false
  }

  static setUserList = async (props: { username: string, token: string, authorityList?: {}, name?: string, email?: string, avatar?: string }) => {
    const userList: UserProfile[] = User.getUserList()
    const { username, token, authorityList, name, email, avatar } = props
    if (!User.userExists(username)) {
      userList.push({
        username,
        token,
        authorityList,
        name,
        email,
        avatar,
      } as UserProfile)
    } else {//edit user
      userList?.map((_user: UserProfile) => {
        if (_user.username === username) {
          _user.username = username
          _user.token = token
          _user.authorityList = authorityList
          _user.name = name
          _user.email = email
          _user.avatar = avatar
        }
        return _user
      })
    }
    StorageUtils.setItem("userlist", JSON.stringify(userList))

    //active user
    StorageUtils.setItem("token", token)
    StorageUtils.setItem("authorityList", JSON.stringify(authorityList))
    StorageUtils.setItem("username", username)
    StorageUtils.setItem("name", name || "")
    StorageUtils.setItem("email", email || "")
    StorageUtils.setItem("avatar", avatar || "")
  }

  static getUserList = (): UserProfile[] => {
    const userList: UserProfile[] = JSON.parse(StorageUtils.getItem("userlist") || "[]")
    return userList
  }

  static removeUserList = (username: string) => {
    if (username === "") return

    const userList: UserProfile[] = User.getUserList()
    const filtered = userList.filter((u: any) => u.username !== username)
    StorageUtils.setItem("userlist", JSON.stringify(filtered))
  }
}
class User extends UserMultiSession {
  static setUser = (props: { username: string, token: string, authorityList?: {}, name?: string, email?: string, avatar?: string }) => {
    const { username, token, authorityList, name, email, avatar } = props
    if (!username || !token) return

    //multisession data
    User.setUserList({ username, token, authorityList, name, email, avatar })
  }

  static setActiveUser = (props: { user: UserProfile, callback: Function, callRefresher?: boolean }) => {
    const callRefresher = props.callRefresher || true
    User.setUser({
      username: props.user?.username,
      token: props.user?.token,
      authorityList: props.user?.authorityList,
      name: props.user?.name,
      email: props.user?.email,
      avatar: props.user?.avatar || settings.avatars["avatarmale1"],
    })
    User.setDataUser(props.user)
    if (!callRefresher) return props.callback()
    Refresher.user(() => props.callback())
  }

  static getActiveUser = () => {
    return {
      username: User.getUsername(),
      token: User.getToken(),
      authorityList: User.getAuthorityList(),
      name: User.getName(),
      email: User.getEmail(),
      avatar: User.getAvatar()
    } as UserProfile
  }

  static updateUser = (props: { user: UserProfile, callback?: Function, callRefresher?: boolean }) => {
    const callRefresher = props.callRefresher || true
    let activeUser = User.getActiveUser()
    if (activeUser.username === props.user.username) activeUser = props.user
    User.setActiveUser({
      user: props.user,
      callback: () => User.setActiveUser({ user: activeUser, callback: () => props.callback && props.callback(), callRefresher }),
      callRefresher
    })
  }

  static logout = () => {
    Refresher.clearuser(() => {
      User.removeUserList(StorageUtils.getItem("username") || "")
      StorageUtils.removeItem("token")
      StorageUtils.removeItem("authorityList")
      StorageUtils.removeItem("username")
      StorageUtils.removeItem("dataUser")
      StorageUtils.removeItem("avatar")

      const userlist = User.getUserList()
      if (userlist.length > 0) {
        User.setActiveUser({
          user: userlist[0], callback: () => {
            window.location.reload()
          }
        })
      }
    })
  }

  static isLoggedIn = () => {
    return StorageUtils.getItem("token") !== null &&
      StorageUtils.getItem("username") !== null
  }

  static isGold = () => User.inList("GOLD")

  static isPremium = () => User.inList("PREMIUM")

  static isFree = () => !User.isGold() && !User.isPremium()

  static isOwner = () => User.inList("OWNER")

  static checkSession = () => {
    if (!User.isLoggedIn() && User.getAuthorityListCount() === 0) {
      Connect.post({ endpoint: Endpoints.guest }).then((response: Responses) => {
        if (response.data?.code === 200)
          StorageUtils.setItem("authorityList", JSON.stringify(response.data?.authorityList || "[]"))
      })
    }
  }

  static refreshToken = (callback?: Function) => {
    if (!User.isLoggedIn()) return
    Connect.post({
      endpoint: Endpoints.refresh,
      body: {
        username: User.getUsername(),
      }
    }).then((response: Responses) => {
      if (response.status === 200) User.setUser({
        username: User.getUsername(),
        token: response.data?.token,
        authorityList: User.getAuthorityList(),
        name: User.getName(),
        email: User.getEmail(),
        avatar: User.getAvatar()
      })
      if (callback) callback(response.status)
    }).catch(() => callback && callback(400))
  }

  static inList = (key: string) => {
    const authorityList = User.getAuthorityList()
    if (Object.keys(authorityList).length === 0) return false
    return authorityList?.map((item: string) => item.toUpperCase()).includes(key.toUpperCase()) || false
  }

  static setDataUser = (data: { [key: string]: any }) => {
    StorageUtils.setItem("dataUser", JSON.stringify(data))
  }

  static getDataUser = () => JSON.parse(StorageUtils.getItem("dataUser") || "{}")

  static getAuthorityList = () => JSON.parse(StorageUtils.getItem("authorityList") || "[]")

  static getAuthorityListCount = () => User.getAuthorityList().length

  static getUsername = (_default: string = "") => StorageUtils.getItem("username") || _default

  static getName = (_default: string = "") => StorageUtils.getItem("name") || _default

  static getEmail = (_default: string = "") => StorageUtils.getItem("email") || _default

  static getToken = (_default: string = "") => StorageUtils.getItem("token") || _default

  static getAvatar = (_default: string = "") => StorageUtils.getItem("avatar") || _default

}

export const LoggedOut = () => {
  return (
    <div className="logged-out" style={
      { padding: "1rem", textAlign: "center", fontSize: "1.5rem" }
    }>
      <h1>Logged Out</h1>
      <p>You must login to see this content.</p>
    </div>
  )
}

export default User