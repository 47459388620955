import { Item } from "components/Content/FormBuilder/Item"
import { KonosubaDance } from "types/Anime/Images"
import OverCard from "components/Content/OverCard"
import locales from "config/Locales"

const ProfileHistoryEmpty = () => {
  return (
    <Item
      baseNode={
        <OverCard
          image={KonosubaDance}
          title={locales._get("profile.no-history")}
          subtitle={locales._get("profile.no-history-subtitle")}
        />
      }
    />
  )
}

export default ProfileHistoryEmpty