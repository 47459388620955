import { useState } from "react"
import { sendOutline } from "ionicons/icons"
import { IonList, useIonToast } from "@ionic/react"

import { Endpoints } from "api/Endpoints"
import { AppSettingsID } from "utils/AppSettings"
import { Responses } from "types/Connect"
import { Item } from "components/Content/FormBuilder/Item"
import locales from "config/Locales"
import Connect from "components/Connect"
import LoaderOptions from "components/Content/ScreenLoader/LoaderOptions"

const ErrorHandlerErrorSending = () => {
  const [error_response] = useState<Responses>(AppSettingsID.getSetting("error_response", {} as Responses))
  const [toast] = useIonToast()

  const sendReport = () => {
    const { status, statusText, endpoint, headers, body } = error_response

    LoaderOptions.openLoader(locales._get("error.sending"))
    Connect.post({
      endpoint: Endpoints.senderror,
      body: {
        status,
        statusText,
        endpoint,
        headers: JSON.stringify(headers),
        body: JSON.stringify(body),
      },
    }).then((response: Responses) => {
      LoaderOptions.closeLoader()
      if (response.status === 200) {
        toast({
          message: locales._get("error.sent"),
          duration: 2000,
          color: "success",
          position: "top",
        })
      } else {
        toast({
          message: locales._get("error.not-sent"),
          duration: 2000,
          color: "danger",
          position: "top",
        })
      }
    })
  }

  if (!error_response.status) return <></>
  return (
    <IonList className="component-error-handler-message">
      <Item
        title={locales._get("error.modal-title")}
        asHeading={true}
      />
      <Item
        title={locales._get("error.can-send")}
        subtitle={locales._get("error.can-send-description", locales._get("appname"))}
      />
      <Item
        title={locales._get("error.send")}
        color="primary"
        icon={sendOutline}
        onClick={() => sendReport()}
      />
    </IonList>
  )
}

export default ErrorHandlerErrorSending