import { IonAvatar } from "@ionic/react"
import { ReactNode } from "react"

import settings from "config/Settings"
import Image from "components/Media/Image"
import User from "components/Users/User"

export const UserAvatar = ({ ...props }) => {
  const { className } = props
  const avatar: string = props?.avatar || User.getAvatar() || settings.avatars["avatarmale1"]
  const startNode: ReactNode = props?.startNode || <></>
  delete props.className
  delete props.avatar
  delete props.startNode

  return (
    <>
      <IonAvatar className={`component-user-avatar ${className}`} {...props}>
        {startNode}
        <Image alt={User.getUsername()} src={avatar} />
      </IonAvatar>
    </>
  )
}