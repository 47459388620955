import { useState } from "react"

import { HomeProps } from "types/Page/Home"
import { colorTypes } from "types/index"
import { MenuSelector } from "config/Menu/MenuSelector"
import { menuItems } from "types/Commons/Menu"
import Refresher from "components/Content/Refresher"
import LoaderOptions from "components/Content/ScreenLoader/LoaderOptions"
import locales from "config/Locales"
import HeaderSlides from "pages/_commons/Content/HeaderSlides"
import PageContainer from "pages/_commons/PageContainer"
import UserRefresh from "components/Connect/Refresher"
import NewContent from "pages/_commons/Content/NewContent"
import ContinueWatching from "pages/_commons/Content/ContinueWatching"
import HeaderPicker from "./HeaderPicker"
import ForYou from "pages/Discover/ForYou"
import Popular from "pages/_commons/Content/Popular"
import Alerts from "components/Commons/Alerts"
import Meta from "components/Media/Meta"

import "./index.scss"

const Home = (props: HomeProps) => {
  const [watchLastCall, setWatchLastCall] = useState<boolean>(true)
  const [loadContent, setLoadContent] = useState<boolean>(true)
  const [resetPage, setResetPage] = useState<boolean>(true)
  const [calledOnBottom, setCalledOnBottom] = useState<boolean>(false)
  const [alertMsg, setAlertMsg] = useState<string>("")
  const [alertColor, setAlertColor] = useState<colorTypes>("warning")

  const toggleLastCall = () => setWatchLastCall((prevWatch: boolean) => !prevWatch)

  const toggleLoadContent = () => setLoadContent((prevLoad: boolean) => !prevLoad)

  const toggleResetPage = () => setResetPage((prevPage: boolean) => !prevPage)

  const selectedMenu = (view: string): string => {
    const menu = MenuSelector.find((menu: menuItems) => menu.href.toString().indexOf(`/${view}`) > -1)
    return menu?.title || locales._get("releases")
  }

  return (
    <PageContainer
      withProgress={true}
      withToTop={true}
      className="page-home"
      callbackBottom={(e: any) => {
        if (calledOnBottom) return

        setCalledOnBottom(true)
        toggleLoadContent()
      }}
    >
      <HeaderSlides />
      <HeaderPicker />
      <div className="ion-padding inner-container">
        <Refresher
          callback={(closeRefreser: Function) => {
            LoaderOptions.openLoader("refreshing")
            UserRefresh.home((callbackStatus: number) => {
              closeRefreser()
              toggleLastCall()
              toggleLoadContent()
              toggleResetPage()
              setAlertMsg(locales._get(callbackStatus === 200 ? "refreshed" : "not_refreshed"))
              setAlertColor(callbackStatus === 200 ? "success" : "secondary")
              LoaderOptions.closeLoader()
            }, true)
          }}
        />
        {(props.view === "home" || props.view === "anime") &&
          <NewContent
            watchLastCall={watchLastCall}
            loadContent={loadContent}
            resetPage={resetPage}
            mode="grid"
            show={props.view === "home" ? "newcontent" : "newcontentplay"}
          />
        }
        {props.view === "foryou" &&
          <ForYou
            watchLastCall={watchLastCall}
            loadContent={loadContent}
            mode="grid"
          />
        }
        {props.view === "continue" &&
          <ContinueWatching
            loadContent={loadContent}
            resetPage={resetPage}
            mode="grid"
          />
        }
        {props.view === "popular" &&
          <Popular
            loadContent={loadContent}
            resetPage={resetPage}
            mode="grid"
          />
        }
        <Alerts
          title={alertMsg}
          showAlert={alertMsg !== ""}
          duration={3000}
          color={alertColor}
          position="top"
        />
      </div>
      <Meta
        title={selectedMenu(props.view)}
      />
    </PageContainer>
  )
}

export default Home