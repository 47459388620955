import { useEffect, useState } from "react"
import { useHistory, useParams } from "react-router-dom"

import { getGender, getGenderListMenuItems } from "pages/_commons/Content/GerdersList/getGenderList"
import { setHeaderGenderLink } from "../HeaderToolbar"
import { paramsRecentByGender } from "api/routes/params/Media"
import { SliderViewDefault } from "types/Media/Slider"
import { cubeOutline } from "ionicons/icons"
import { Endpoints } from "api/Endpoints"
import { menuItems } from "types/Commons/Menu"
import { colorTypes } from "types/index"
import Picker from "components/Commons/Picker"
import HeaderSlides from "pages/_commons/Content/HeaderSlides"
import PageContainer from "pages/_commons/PageContainer"
import Content from "pages/_commons/Content/Content"
import Skeleton from "components/Commons/Skeleton"
import Refresher from "components/Content/Refresher"
import UserRefresh from "components/Connect/Refresher"
import LoaderOptions from "components/Content/ScreenLoader/LoaderOptions"
import locales from "config/Locales"
import HeaderToolbar from "components/Commons/HeaderToolbar"
import Alerts from "components/Commons/Alerts"
import Strings from "utils/Strings"
import AdsBanner from "components/Media/Ads/Banner"
import Meta from "components/Media/Meta"

import "./index.scss"

const GenderView = () => {
  const [isScrollingBottom, setIsScrollingBottom] = useState(false)
  const [title, setTitle] = useState<string>("")
  const [description, setDescription] = useState<string>("")
  const [genders, setGenders] = useState<menuItems[]>([])
  const [paramsGender, setParamsGender] = useState<{} | null>(null)
  const [watchLastCall, setWatchLastCall] = useState<boolean>(true)
  const [loadContent, setLoadContent] = useState<boolean>(true)
  const [resetPage, setResetPage] = useState<boolean>(true)
  const [gid, setGid] = useState<number>(0)
  const [alertMsg, setAlertMsg] = useState<string>("")
  const [alertColor, setAlertColor] = useState<colorTypes>("warning")
  const params = useParams()
  const history = useHistory()

  const toggleLastCall = () => setWatchLastCall((prevWatch: boolean) => !prevWatch)

  const toggleLoadContent = () => setLoadContent((prevLoad: boolean) => !prevLoad)

  useEffect(() => {
    JSON.parse(JSON.stringify(params), (key: string, value: string) => {
      if (key === "gid") setGid(() => {
        const newGid = parseInt(value)
        return newGid
      })
    })
    if (gid === 0) return
    setParamsGender({ ...paramsRecentByGender, ...{ gender: gid } })
    setResetPage((prevReset: boolean) => !prevReset)

    const _genders = getGenderListMenuItems()
    const _gender = getGender(gid)

    setGenders(() => {
      _genders?.map((gender: menuItems) => gender.icon = cubeOutline)
      return _genders
    })
    setTitle(Strings.encode(_gender?.title || ""))
    setDescription(Strings.encode(_gender?.description || ""))
  }, [params, gid])

  useEffect(() => {
    if (!isScrollingBottom) return

    toggleLoadContent()
  }, [isScrollingBottom])

  return (
    <PageContainer
      withProgress={true}
      withToTop={true}
      className="page-discover"
      callbackStart={() => setIsScrollingBottom(false)}
      callbackBottom={() => setIsScrollingBottom(true)}
    >
      <>
        <HeaderSlides />
        <Picker
          items={genders}
          color="secondary"
          selected={setHeaderGenderLink(gid)}
          onClickPicker={(href: string) => {
            history.replace(href)
            window.location.reload()
          }}
        />
        <HeaderToolbar asBack={true} />
        {description && <div className="description" dangerouslySetInnerHTML={{ __html: description }} />}
        <div className="ion-padding inner-container">
          <Refresher
            callback={(closeRefreser: Function) => {
              if (paramsGender === null) return closeRefreser()

              LoaderOptions.openLoader("refreshing")
              UserRefresh.genderlist(gid, (callbackStatus: number) => {
                toggleLastCall()
                toggleLoadContent()
                closeRefreser()
                setAlertMsg(locales._get((callbackStatus === 200) ? "refreshed" : "not_refreshed"))
                setAlertColor(callbackStatus === 200 ? "success" : "secondary")
                LoaderOptions.closeLoader()
              }, true)
            }}
          />
          {paramsGender === null ? (
            <Skeleton cols={SliderViewDefault.s} />
          ) :
            <>
              <AdsBanner type="softnyacontent" softnyaposition="DETAILS_TOP" />
              <Content
                title={title}
                endpoint={Endpoints.recent}
                params={paramsGender}
                slidesPerView={SliderViewDefault}
                watchLastCall={watchLastCall}
                loadContent={loadContent}
                resetPage={resetPage}
                type="item"
                mode="grid"
              />
              <Meta
                title={title}
                description={description}
              />
            </>
          }
        </div>
        <Alerts
          title={alertMsg}
          showAlert={alertMsg !== ""}
          duration={3000}
          color={alertColor}
          position="top"
        />
      </>
    </PageContainer>
  )
}

export default GenderView