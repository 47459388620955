import { useState } from "react"

import { Endpoints } from "api/Endpoints"
import { SliderViewContinue } from "types/Media/Slider"
import { limitsContinueWatching } from "api/routes/params/Media"
import { ContentProps, defaultContentProps } from "types/Page/Content"
import { filtersPremium } from "types/Content/filters"
import User from "components/Users/User"
import locales from "config/Locales"
import WithoutWatching from "./WithoutWatching"
import Content from "../Content"
import AniTitle from "pages/_commons/AniTitle"

const ContinueWatching = (props: ContentProps) => {
  const [hasContent, setHasContent] = useState<boolean>(props.loadContent || false)

  return (
    <>
      {User.isLoggedIn() &&
        <>
          {hasContent ? (
            <>
              <Content
                title={locales._get("continue_watching")}
                href={props.mode === "slider" ? "/home/continue" : ""}
                watchLastCall={false}
                loadContent={props.loadContent}
                resetPage={props.resetPage}
                refreshGrid={true}
                show="all"
                mode={props.mode || "slider"}
                type="player"
                endpoint={Endpoints.recentviewed}
                force={true}
                slidesPerView={SliderViewContinue}
                slideLimit={props.mode === "slider" ? limitsContinueWatching.slide : limitsContinueWatching.grid}
                onEmptySlider={(isEmpty: boolean) => {
                  setHasContent(!isEmpty)
                }}
                filterRepeated={true}
                filters={props.mode === "grid" ? [
                  ...[filtersPremium],
                ] : []}
              />
            </>
          ) : (
            <>
              <AniTitle
                title={locales._get("continue_watching")}
                href={props.mode === "slider" ? "/home/continue" : ""}
              />
              <WithoutWatching />
            </>
          )}
        </>
      }
    </>
  )
}

ContinueWatching.defaultProps = defaultContentProps

export default ContinueWatching