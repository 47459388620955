import { IonMenu, IonHeader, IonToolbar, IonButtons, IonButton, IonIcon, IonTitle, IonContent, IonText } from "@ionic/react"
import { chevronForward } from "ionicons/icons"
import { useEffect, useState, useRef } from "react"
import { useHistory } from "react-router-dom"

import { defaultMenuProps, MenuProps, targetTypes } from "types/Commons/Menu"

import "./index.scss"


const Menu = (props: MenuProps) => {
  const menu = useRef<HTMLIonMenuElement>(null)
  const [loaded, setLoaded] = useState<boolean>(false)
  const [activeMenu, setActiveMenu] = useState<string>("")
  const history = useHistory()

  useEffect(() => {
    if (props.background?.image && !loaded) {
      const css = `
      .component-menu {
        background-image: url(${props.background?.image});
      }
    `
      //add css to head
      const head = document.head || document.getElementsByTagName("head")[0]
      const clear = document.getElementById("menu-bg")
      if (clear) clear.remove()
      const style = document.createElement("style") as HTMLStyleElement
      style.id = "menu-bg"
      head.appendChild(style)
      style.appendChild(document.createTextNode(css))
      setLoaded(true)
    }
  }, [props.background?.image, loaded])

  useEffect(() => {
    setActiveMenu(props.activeMenu || "")
  }, [props.activeMenu])

  const redirect = (href: string, target: targetTypes) => {
    target === "_blank" && window.open(href, "_blank")
    target === "_self" && history.push(href)
    menu.current?.close()

    target !== "_blank" && setActiveMenu(href)
  }

  return (
    <>
      <IonMenu
        ref={menu}
        side={props.side || "start"}
        type={props.type || "overlay"}
        maxEdgeStart={0}
        contentId="main-app"
        className={`component-menu ${props.background?.image ? "has-bg" : ""}`}
      >
        {props.showHeader &&
          <IonHeader>
            <IonToolbar color={props.color || "tertiary"}>
              {props.startButtons &&
                <IonButtons slot="start">
                  {props.startButtons}
                </IonButtons>
              }
              <IonTitle>{props.title || ""}</IonTitle>
              {props.endButtons &&
                <IonButtons slot="end">
                  {props.endButtons}
                </IonButtons>
              }
            </IonToolbar>
          </IonHeader>
        }
        <IonContent className="ion-padding">
          {props.contentTop && <div>{props.contentTop}</div>}
          {props.children && <div>{props.children}</div>}
          {props.items?.map((item, index) => {
            return (
              <IonButton
                key={index}
                onClick={() => item.onClick ? item.onClick() : redirect(item.href, item.target || "_self")}
                fill="clear"
                expand="full"
                color={`${props.background?.image ? "light" : "secondary"}`}
                className={`href-${item.href?.replaceAll("/", "")} ${activeMenu === item.href ? "active-menu" : ""} ${item.className || ""}`}
              >
                <div>
                  {item.icon && <IonIcon icon={item.icon} />}
                  {item.title && <IonText>{item.title}</IonText>}
                  <IonIcon icon={chevronForward} className="icon-arrow" />
                </div>
              </IonButton>
            )
          })}
          {props.contentBottom && <div className="footer">{props.contentBottom}</div>}
        </IonContent >
      </IonMenu >
    </>
  )
}

Menu.defaultProps = defaultMenuProps

export default Menu