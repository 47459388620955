import { AllMighty } from "types/Anime/Images"
import OverCard from "components/Content/OverCard"
import locales from "config/Locales"

const WithoutWatching = () => {
  return (
    <OverCard
      title={locales._get("content.no_watching")}
      subtitle={locales._get("content.no_watching_subtitle")}
      image={AllMighty}
    />
  )
}

export default WithoutWatching