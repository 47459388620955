import { useEffect, useRef, useState } from "react"
import { IonActionSheet } from "@ionic/react"
import { Capacitor } from "@capacitor/core"
import videojs from "video.js"
import "videojs-youtube"
import "videojs-playlist"
import "videojs-overlay"
import "video.js/dist/video-js.css"
import "@videojs/themes/dist/city/index.css"
import "@videojs/themes/dist/fantasy/index.css"
import "@videojs/themes/dist/forest/index.css"
import "@videojs/themes/dist/sea/index.css"

import { NotFoundImg, SmallLogoSrc } from "types/Anime/Images"
import { VideoJSProps } from "types/Media/VideoJS"
import { OneZeroTypes } from "types/index"
import { removeAdPosition } from "../Ads/RemoveAds/Methods/removeAdPosition"
import { RemoveAdPositionProps } from "types/Media/Ads"
import { SoftnyaAdsBannerItem } from "types/Media/Ads/SoftnyaAds"
import { SpinnerCircular } from "components/Commons/Spinner"
import JDate, { JDateAgoNumType } from "utils/JDate"
import StorageUtils from "utils/Storage"
import Animate from "utils/Animate"
import Strings from "utils/Strings"
import locales from "config/Locales"
import settings from "config/Settings"
//import ChromeCast from "./ChromeCast"
import RemoveAdsButton from "../Ads/RemoveAds/RemoveAdsButton"
import SoftnyaAdsClass from "../Ads/Provider/SoftnyaAds"
import AdsVideo from "../Ads/Video"
import AdsBanner from "../Ads/Banner"
import Image from "../Image"
import Alerts from "components/Commons/Alerts"

import "./index.scss"
import "./themes/default/index.scss"
import "./themes/city/index.scss"
import "./themes/fantasy/index.scss"
import "./themes/forest/index.scss"
import "./themes/sea/index.scss"

let player: any

const VideoJS = (props: VideoJSProps) => {
  const [animations] = useState<string[]>(Animate.getAnimations(["flip", "rubberBand", "tada", "wobble", "shakeX", "swing"]))
  const [nextVideoTime] = useState<number>(props.nextVideoTime || settings.introEndingTime)//time in seconds
  const [nextVideoTimeCount] = useState<number>(settings.nextEpisodeCount)
  const [animation, setAnimation] = useState<string>("")
  const [showControls, setShowControls] = useState<boolean>(false)
  const [isPlaying, setIsPlaying] = useState<boolean>(true)
  const [isLoading, setIsLoading] = useState<boolean>(true)
  const [showingAds, setShowingAds] = useState(false)
  const [adslockcontent, setAdsLockContent] = useState<JDateAgoNumType>(JDate.agoNum(new settings().getadslockcontenttime))
  const [isVideoAd, setIsVideoAd] = useState<boolean>(false)
  const [showAlert, setShowAlert] = useState<boolean>(false)
  const [showAction, setShowAction] = useState<boolean>(false)
  const [startTime] = useState<number>(parseFloat(StorageUtils.getItem(props.media) || "0"))
  const [theme] = useState<string>(new settings().playerTemplate)
  const [pip] = useState<OneZeroTypes>(new settings().playerPip)
  const SoftnyaAds = new SoftnyaAdsClass()
  const next = document.querySelector("#anime-media-options-next") as HTMLIonButtonElement
  const Video = new AdsVideo()
  const videoRef = useRef<HTMLVideoElement>(null)
  const options = {
    autoplay: true,
    responsive: true,
    controls: true,
    fluid: true,
    sources: [{
      src: props.media,
      type: "video/mp4",
    }],
    poster: props.poster || "",
    width: "100%",
    height: "100%",
    nativeControlsForTouch: false,
    playbackRates: [0.25, 0.5, 0.75, 1, 1.25, 1.5, 1.75, 2],
    notSupportedMessage: locales._get("videojs.not-supported-message"),
    plugins: {},
    controlBar: {
      skipButtons: {
        forward: 30,
        backward: 10,
      },
    },
    ...props.options,
  }
  let currentOptions = options
  let currentTime: number = 0
  let duration: number = 0
  let wasPlaying: boolean = false
  let cancelNextEpisode: boolean = false
  let adsSkipTime = new settings().softnyaadsvideotimeout

  const callRemoveAdPosition = (props: RemoveAdPositionProps) => {
    setShowingAds(true)
    removeAdPosition({
      position: props.position,
      setAdsLockContent,
      setShowingAds,
    })
  }

  const gotoNextEpisode = () => {
    next?.click()
  }

  const loadPlayer = (options: any, onReady?: () => {}) => {
    const videoElement = videoRef.current as HTMLVideoElement
    if (startTime > 0) options.autoplay = false
    player = videojs(videoElement, options)
    const setOverlayNextEpisode = () => {
      duration = player.duration() || 0

      if (duration === 0) {
        setTimeout(() => setOverlayNextEpisode(), 1000)
        return
      }
      const nextControl = document.querySelector(".side-controls .next") as HTMLElement
      const showIn: number = parseInt(duration.toString()) - nextVideoTime
      if (nextControl?.classList.contains("fab-button-disabled")) {
        props.recommended && player.overlay({
          overlays: [{
            content: `
            <div class="recommended-container">
              ${props.recommended || ""}
            </div>
            `,
            start: showIn,
            end: "ended",
          }]
        })
        return
      }
      player.overlay({
        overlays: [{
          content: `
          <ion-buttons id="next-episode-btn-container">
            <ion-button id="next-episode-btn">${locales._get("videojs.next-episode-in", 0)}</ion-button>
            <ion-button id="next-episode-cancel-btn">
              X
            </ion-button>
          </ion-buttons>
          `,
          start: showIn,
          end: "ended",
        }]
      })
      document.querySelector("#next-episode-btn")?.addEventListener("click", () => gotoNextEpisode())
      document.querySelector("#next-episode-cancel-btn")?.addEventListener("click", () => {
        cancelNextEpisode = true
        document.querySelector("#next-episode-btn-container")?.remove()
      })
    }
    setOverlayNextEpisode()
    currentOptions = options
    player.ready(() => {
      onReady && onReady()
      if (startTime > 0) {
        setShowAction(true)
        //player.pause()
      }
    })
    return player
  }

  const callVideoAds = async () => {
    const withAds: boolean = await Video.checkAds()
    if (withAds) {
      const video: SoftnyaAdsBannerItem = await SoftnyaAds.showVideo()
      if (video?.ytid !== "") {
        const _options = {
          ...options,
          sources: [],
        }
        player = loadPlayer(_options)
        player.playlist([
          {
            sources: [
              {
                src: `https://www.youtube.com/watch?v=${video.ytid}`,
                type: "video/youtube",
              },
            ],
            poster: `https://img.youtube.com/vi/${video.ytid}/hqdefault.jpg`,
          },
          {
            sources: [...options.sources],
            poster: props.poster || "",
          },
        ])
        player.playlist.autoadvance(1)
        player.playlist.currentItem(0)
        setIsVideoAd(true)
        return
      }
    }
    player = loadPlayer(options)
  }

  useEffect(() => {
    if (videoRef.current) {
      callVideoAds().then(() => {
        player.on("userinactive", () => setShowControls(!isPlaying))
        player.on("useractive", () => setShowControls(true))
        player.on("playing", () => setIsPlaying(true))
        player.on("pause", () => setIsPlaying(false))
        player.on("ended", () => setIsPlaying(false))
        player.on("error", () => setIsPlaying(false))
        player.on("ready", () => {
          props.onReady && props.onReady()
        })
        player.on("loadeddata", () => setIsLoading(false))
        player.on("canplaythrough", () => setIsLoading(false))
        player.on("waiting", () => setIsLoading(true))
        player.on("stalled", () => setIsLoading(true))
        player.on("error", () => {
          const error = player.error()
          setIsLoading(true)
          if (error && error.code > 0) {
            if (error.code === 2) {//network error, show a toast
              setShowAlert(true)
              return
            }
            setIsLoading(true)
            player?.dispose()
            // eslint-disable-next-line react-hooks/exhaustive-deps
            player = loadPlayer(currentOptions)
            player.ready(() => {
              player.currentTime(currentTime)
              setIsLoading(false)
            })
          }
        })
        player.on("timeupdate", () => {
          const time: number = Math.floor(player.currentTime() || currentTime || 0)
          // eslint-disable-next-line react-hooks/exhaustive-deps
          currentTime = time
          props.onStream && props.onStream(time, player.duration() || 0)
          StorageUtils.setItem(props.media, time.toString())
        })
        player.on("contextmenu", (event: any) => event.preventDefault())
      })
      return () => {
        //player?.dispose()
        // eslint-disable-next-line react-hooks/exhaustive-deps
        currentTime = 0
        // eslint-disable-next-line react-hooks/exhaustive-deps
        duration = 0
        // eslint-disable-next-line react-hooks/exhaustive-deps
        cancelNextEpisode = false
        setIsPlaying(false)
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.media])

  useEffect(() => {
    if (!isVideoAd) return
    // eslint-disable-next-line react-hooks/exhaustive-deps
    adsSkipTime = new settings().softnyaadsvideotimeout
    const timer = setInterval(async () => {
      if (isPlaying) adsSkipTime -= 1
      if (adsSkipTime < 0) {
        const div = document.querySelector(".ads-skip div") as HTMLDivElement
        div.innerHTML = locales._get("videojs.skip-now")
        clearInterval(timer)
        return
      }
      const span = document.querySelector(".ads-skip span") as HTMLSpanElement
      if (span) span.innerHTML = adsSkipTime.toString()
    }, 1000)
    return () => clearTimeout(timer)
  }, [isVideoAd])

  useEffect(() => {
    props.onisPlaying && props.onisPlaying(isPlaying)
    if (isLoading) {
      // eslint-disable-next-line react-hooks/exhaustive-deps
      wasPlaying = !player?.paused()
      player?.pause()
    } else if (!isLoading && wasPlaying) {
      player?.play()
    }
    if (!isPlaying) setShowControls(true)
    const play = document.querySelector(".video-js .vjs-big-play-button") as HTMLButtonElement
    if (!play) return
    if (!isPlaying && !isLoading) play.classList.add("show")
    else play.classList.remove("show")
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isPlaying, isLoading])

  useEffect(() => {
    const formatAnimation = () => setAnimation(animations[Strings.getRandomBetween(animations.length - 1)])

    formatAnimation()
    const timeout = setInterval(() => {
      formatAnimation()
      const clearAnimation = setTimeout(() => setAnimation(""), 3000)

      return () => clearTimeout(clearAnimation)
    }, Strings.getRandomBetween(10, 60) * 1000)

    //next button
    const countNetxt = setInterval(() => {
      const timeLeft = parseInt(duration.toString()) - parseInt(currentTime.toString()) - nextVideoTime + nextVideoTimeCount
      const button = document.querySelector("#next-episode-btn") as HTMLButtonElement
      if (!button) return
      const nextContainer = document.querySelector("#next-episode-btn-container") as HTMLIonButtonsElement
      button.innerHTML = locales._get("videojs.next-episode-in", timeLeft)
      if (timeLeft < 0) nextContainer?.classList?.add("vjs-hidden")
      else nextContainer?.classList?.remove("vjs-hidden")
      if (timeLeft === 0 || cancelNextEpisode) {
        gotoNextEpisode()
        clearInterval(countNetxt)
      }
    }, 1000)

    return () => {
      clearTimeout(timeout)
      clearTimeout(countNetxt)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [animations])

  return (
    <div className="video-js-container">
      <>
        <div data-vjs-player>
          <video
            ref={videoRef}
            className={`video-js vjs-theme-${theme} is-${Capacitor.isNativePlatform() ? "native" : "web"} pip-${pip === 1 ? "on" : "off"}`}
            poster={props.poster || ""}
          />
        </div>
      </>
      {isLoading && <>
        <SpinnerCircular />
        {(props.poster && isLoading) && <Image src={props.poster || ""} className="loading-poster" />}
      </>}
      <div className={`watermark position-${showControls ? "above" : "bellow"}`}>
        <Image src={SmallLogoSrc} className={animation} />
      </div>
      {(showControls || !isPlaying) &&
        <div className={`banner-video ${Animate.setAnimation("slideInUp")}`}>
          <AdsBanner softnyaposition="CONTENT_PLAYER" type="softnyacontent" />
          <RemoveAdsButton
            onClick={() => callRemoveAdPosition({ position: "CONTENT" })}
            position="CONTENT"
            time={adslockcontent}
            showingAds={showingAds}
            isOnBanner={true}
          />
        </div>
      }
      {isVideoAd &&
        <div className="ads-skip" onClick={() => {
          const span = document.querySelector(".ads-skip span") as HTMLSpanElement
          if (span) return
          player = loadPlayer(options)
          player?.playlist?.next()
          setIsVideoAd(false)
        }}>
          <div dangerouslySetInnerHTML={{ __html: locales._get("videojs.skip", `<span>${adsSkipTime}</span>`) }}></div>
        </div>
      }
      <Alerts
        title={locales._get("videojs.error-network")}
        showAlert={showAlert}
        image={NotFoundImg}
        buttons={[
          {
            text: locales._get("videojs.ok"),
            role: "cancel",
            handler: () => props.onError && props.onError()
          }
        ]}
        color={"warning"}
        onDismiss={() => setShowAlert(false)}
      />
      <IonActionSheet
        isOpen={showAction}
        onDidDismiss={() => setShowAction(false)}
        cssClass="video-jump"
        buttons={[
          {
            text: locales._get("videojs.continue", JDate.timeFormat(startTime)),
            role: "selected",
            handler: async () => {
              await player?.currentTime(startTime)
              player?.play()
              setShowAction(false)
            }
          },
          {
            text: locales._get("videojs.at-start"),
            role: "begin",
            handler: async () => {
              await player?.currentTime(0)
              player?.play()
              setShowAction(false)
            }
          }
        ]}
      />
    </div>
  )
}

export default VideoJS