import settings from "config/Settings"

const routes = {
  about: `${settings.apiUrl}app.about`,
  tos: `${settings.apiUrl}app.tos`,
  support: `${settings.apiUrl}app.support`,
  news: `${settings.apiUrl}content.news`,
  newsDetails: `${settings.apiUrl}content.news.details`,
}

export default routes