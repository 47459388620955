import { IonButtons, IonButton, IonIcon, IonLabel } from "@ionic/react"
import { chevronDown, chevronUp } from "ionicons/icons"
import { useState } from "react"

import { defaultDropDownButton, DropDownButtonProps } from "types/Commons/DropDownButton"
import { SpinnerBubbles } from "components/Commons/Spinner"
import DropDownContainer from "components/Commons/DropDownContainer"

import "./index.scss"
import Strings from "utils/Strings"

const DropDownButton = (props: DropDownButtonProps) => {
  const [isActive, setIsActive] = useState<boolean>(false)
  const [randId] = useState(Strings.getRandomNumber())

  return (
    <div className="component-dropdown-button">
      <IonButtons className={`ion-margin-top ${props.className}`}>
        <IonButton
          color={props.color}
          size={props.size}
          fill={props.fill}
          disabled={props.disabled}
          className="button button-dropdown"
          id={`button-${props.id || "default"}`}
          onClick={() => props.onClick && props.onClick()}
        >
          <span className={`inner-drop-button ${props.inClassName}`}>
            {props.icon &&
              <>
                {props.icon === "spinner" && <SpinnerBubbles />}
                {props.icon !== "spinner" && <IonIcon icon={props.icon} className={props.subtext && "with-subtext"} />}
                &nbsp;
              </>
            }
            <IonLabel>
              <div dangerouslySetInnerHTML={{ __html: props.text }} />
              {(props.subtext && props.subtext !== "spinner") && <small>{props.subtext}</small>}
              {(props.subtext && props.subtext === "spinner") && <SpinnerBubbles />}
            </IonLabel>
          </span>
        </IonButton>
        <IonButton
          color={props.color}
          size={props.size}
          fill={props.fill}
          id={!props.id ? "menu-popover" : `button-dropdown-${props.id || ""}-${randId}`}
          className="dropdown"
          disabled={!props.children && !(props.sameClickAll || false)}
          onClick={() => (props.sameClickAll && props.onClick) && props.onClick()}
        >
          <IonIcon icon={isActive ? chevronUp : chevronDown} />&nbsp;
        </IonButton>
      </IonButtons>
      {props.children && <DropDownContainer
        id={props.id}
        trigger={!props.id ? "menu-popover" : `button-dropdown-${props.id || ""}-${randId}`}
        className="component-dropdown-button-popover"
        onPresent={() => setIsActive(true)}
        onDismiss={() => setIsActive(false)}
      >
        {props.children || <></>}
      </DropDownContainer>}
    </div>
  )
}

DropDownButton.defaultProps = defaultDropDownButton

export default DropDownButton