import { AvatarHeroes } from "./Avatars/Heroes"
import { AvatarMale } from "./Avatars/Male"
import { AvatarFemale } from "./Avatars/Femile"
import { AppSettingsID } from "utils/AppSettings"
import { OneZeroTypes } from "types"
import { settings_ads } from "./Ads"

class settings extends settings_ads {
  static forceDebug: boolean = false//force debug mode, change on isDev and Ads.adsIsTesting
  static signupUrl: string = "#"
  static membershipUrl: string = "#"
  static apiUrl: string = "https://api.animeplay.app/v1/"
  static cdnUrl: string = "https://cdn.animeplay.app/"
  static apikey: string = "Uft3hf5tiHJT5U9pMQHF3NfgxAT1l7xLo4cV3j6P8Iqxnx9ew4c469iStCKe"
  static linkplaystore: string = "https://play.google.com/store/apps/details?id=com.anime.play.app"
  static linkXiami: string = "https://global.app.mi.com/details?lo=ID&la=es_ES&id=com.anime.play.app"
  static linkgithub: string = "https://github.com/Softnya-Soporte/Anime-Play-App-Oficial/releases"
  static linkweb: string = "https://www.animeplay.us/es/app"
  static checkShorterInterval = 1//minutes
  static checkInterval = 15//minutes
  static checkDayInterval = 60 * 24//one day
  static checkMonthInterval = 60 * 24 * 30//one month
  static callbackTimeout = 10000//in seconds
  static maxCardDescriptionLength = 200
  static maxCallbackTimes = 3
  static mediaMarkAsSeenTime = 120//in seconds
  static maxCommentInputLength = 300
  static maxFreeSpace = 10 * 1024//mb
  static minRateTimePlayStore = 3 * 24 * 60//minutes (3 days)
  static relevance = {
    high: 50,
    medium: 10,
    low: 5,
  }
  static maxCachedEpisodes = 3
  static introEndingTime = 60//in seconds
  static nextEpisodeCount = 10
  static markAsSeenPercent = 0.85//only for premium player, rest is mediaMarkAsSeenTime
  static GAid = "G-2PEV7NJZBK"

  static maxSessons = 3
  get maxCachedPercent(): number {
    return AppSettingsID.getSetting("maxcachedpercent", 90)//max percent used
  }
  set maxCachedPercent(value: number) {
    AppSettingsID.setSetting("maxcachedpercent", value)
  }
  get maxDbPercent(): number {
    return AppSettingsID.getSetting("maxdbpercent", 90)//max percent used
  }
  set maxDbPercent(value: number) {
    AppSettingsID.setSetting("maxdbpercent", value)
  }
  get showAlertWithAds(): OneZeroTypes {
    return AppSettingsID.getSetting("showalertwithads", 1)//show alert with ads
  }
  set showAlertWithAds(value: OneZeroTypes) {
    AppSettingsID.setSetting("showalertwithads", value)
  }
  get titleLanguage(): string {
    return AppSettingsID.getSetting("titlelanguage", "romaji")//title language
  }
  set titleLanguage(value: string) {
    AppSettingsID.setSetting("titlelanguage", value)
  }
  get playerTemplate(): string {
    return AppSettingsID.getSetting("playertemplate", "default")//player template
  }
  set playerTemplate(value: string) {
    AppSettingsID.setSetting("playertemplate", value)
  }
  get playerPip(): OneZeroTypes {
    return AppSettingsID.getSetting("playerpip", 1)//player pip
  }
  set playerPip(value: OneZeroTypes) {
    AppSettingsID.setSetting("playerpip", value)
  }
  get initialPage(): string {
    return AppSettingsID.getSetting("initialpage", "default")//initial page
  }
  set initialPage(value: string) {
    AppSettingsID.setSetting("initialpage", value)
  }
  get parentalControl(): OneZeroTypes {
    return AppSettingsID.getSetting("parentalControl", 1)//is parental control active?
  }
  set parentalControl(value: OneZeroTypes) {
    AppSettingsID.setSetting("parentalControl", value)
  }
  get showNotificationControls(): OneZeroTypes {
    return AppSettingsID.getSetting("shownotificationcontrols", 1)//show notification controls
  }
  set showNotificationControls(value: OneZeroTypes) {
    AppSettingsID.setSetting("shownotificationcontrols", value)
  }
  get maxHistoryRecords(): number {
    return AppSettingsID.getSetting("maxhistoryrecords", 100)//max history records
  }
  set maxHistoryRecords(value: number) {
    AppSettingsID.setSetting("maxhistoryrecords", value)
  }
  get maxHistorySearch(): number {
    return AppSettingsID.getSetting("maxhistorysearch", 10)//max history search
  }
  set maxHistorySearch(value: number) {
    AppSettingsID.setSetting("maxhistorysearch", value)
  }
  get clearSearchOnFocus(): OneZeroTypes {
    return AppSettingsID.getSetting("clearsearchonfocus", 0)//clear search on focus
  }
  set clearSearchOnFocus(value: OneZeroTypes) {
    AppSettingsID.setSetting("clearsearchonfocus", value)
  }
  static avatars = {
    ...AvatarHeroes,
    ...AvatarMale,
    ...AvatarFemale,
  }
  static releaseDate = "20221125"
}

export default settings

export const isDev = !process.env.NODE_ENV || process.env.NODE_ENV === "development" || settings.forceDebug