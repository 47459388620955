import { useState } from "react"

import { AnimeEpisodesAccordionProps } from "types/Anime/AnimeEpisodesAccordion"
import AnimeEpisodesAccordionItem from "./Item"

import "./index.scss"

const AnimeEpisodesAccordion = (props: AnimeEpisodesAccordionProps) => {
  const [maxItems] = useState<number>(props.episodes?.slice(-1)[0].episode || 0)

  return (
    <>
      {Array.from(Array(Math.ceil(maxItems / props.tabGroups)).keys()).reverse().map((item, index) =>
        <AnimeEpisodesAccordionItem {...props} item={item} maxItems={maxItems} key={`component-anime-episodes-accordion-${index}`} />
      )}
    </>
  )
}

export default AnimeEpisodesAccordion