import { IonItem } from "@ionic/react"

import { PurpleEyes } from "types/Anime/Images"
import locales from "config/Locales"
import OverCard from "components/Content/OverCard"

const PickerModalNoItems = () => {
  return (
    <IonItem className="no-item">
      <OverCard
        image={PurpleEyes}
        title={locales._get("component.picker-no-items")}
      />
    </IonItem>
  )
}

export default PickerModalNoItems