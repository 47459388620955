import "./index.scss"

const SoftnyaAdsBannerSign = () => {
  return (
    <>
      <small className="component-softnya-sign"
        onClick={() => window.open("https://www.softnya.com.mx/ads", "_blank")}>
        Softnya Ads
      </small>
    </>
  )
}

export default SoftnyaAdsBannerSign