import "./index.scss"

type WaveProps = {
  type?: "circle" | "contrast" | "",
  visible?: boolean,
  onClick?: Function,
  className?: string,
}

const Wave = (props: WaveProps) => {
  return (
    <div className={`charm ${props.type || ""} ${props.visible === false && "hidden"} ${props.className || ""}`}
      onClick={() => props.onClick && props.onClick()}
    >
      <div className="waveform"><div></div></div>
    </div>
  )
}

export default Wave