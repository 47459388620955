import { IonListHeader, IonLabel } from "@ionic/react"

export const UserListHeader = (props: { html: string }) => {
  return (
    <IonListHeader lines="inset">
      <IonLabel>
        <div dangerouslySetInnerHTML={{ __html: props.html }} />
      </IonLabel>
    </IonListHeader>
  )
}