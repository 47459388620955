import { create } from "zustand"
import { devtools } from "zustand/middleware"

const useAnime = create(devtools((set: any) => ({
  isOpen: false,
  isOpenMedia: false,
  isOpenCharacter: false,
  isOpenStaff: false,
  isOpenNews: false,
  refreshGrids: false,
  id: 0,
  eid: 0,
  mid: 0,
  media: 0,
  chid: 0,
  stid: 0,
  nid: 0,
  lastSeenId: 0,
  lastSeenSerie: 0,
  lastLoadedId: 0,
  lastLoadingId: 0,
  lastMedia: "",
  setIsOpen: (isOpen: boolean) => set({ isOpen }),
  setIsOpenMedia: (isOpenMedia: boolean) => set({ isOpenMedia }),
  setIsOpenCharacter: (isOpenCharacter: boolean) => set({ isOpenCharacter }),
  setIsOpenStaff: (isOpenStaff: boolean) => set({ isOpenStaff }),
  setIsOpenNews: (isOpenNews: boolean) => set({ isOpenNews }),
  setRefreshGrids: (refreshGrids: boolean) => set({ refreshGrids }),
  setId: (id: number) => set({ id }),
  setEid: (eid: number) => set({ eid }),
  setMid: (mid: number) => set({ mid }),
  setChid: (chid: number) => set({ chid }),
  setStid: (stid: number) => set({ stid }),
  setNid: (nid: number) => set({ nid }),
  setLastSeenId: (lastSeenId: number) => set({ lastSeenId }),
  setLastSeenSerie: (lastSeenSerie: number) => set({ lastSeenSerie }),
  setLastLoadedId: (lastLoadedId: number) => set({ lastLoadedId }),
  setLastLoadingId: (lastLoadingId: number) => set({ lastLoadingId }),
  setLastMedia: (lastMedia: string) => set({ lastMedia }),
}), { name: "useAnime" }))

export default useAnime