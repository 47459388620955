import { useEffect, useState } from "react"
import { useHistory, useParams } from "react-router-dom"
import { IonBadge, IonLabel } from "@ionic/react"

import { AnimeContent } from "types/Anime"
import { setSearchWords } from "../Anime/Methods/setSearchWords"
import { getNews } from "./Methods/getNews"
import { NewsDetailsContent, NewsDetailsProps } from "types/NewsDetails"
import { formatSearchContent } from "pages/_commons/Methods/formatSearchContent"
import useHistoryRecord, { saveLastHistoryRecordRemote } from "store/useHistoryRecord"
import useHistorySearch from "store/useHistorySearch"
import ErrorHandler from "components/Commons/ErrorHandler"
import PageContainer from "pages/_commons/PageContainer"
import Skeleton from "components/Commons/Skeleton"
import AdsBanner from "components/Media/Ads/Banner"
import useAnime from "store/useAnime"
import Strings from "utils/Strings"
import JDate from "utils/JDate"
import User from "components/Users/User"
import AnimeTopBar from "../Commons/TopBar"
import AnimeDescription from "../Commons/Tabs/Description"
import NewsParser from "pages/_commons/Content/NewsDetails"

import "./../Anime/index.scss"

const NewsDetails = (props: NewsDetailsProps) => {
  const [nid, setNid] = useState<number>(0)
  const [showError, setShowError] = useState<boolean>(false)
  const [imgBG, setImgBG] = useState<string>("")
  const [loaded, setLoaded] = useState<boolean>(false)
  const [content, setContent] = useState<NewsDetailsContent>({} as NewsDetailsContent)
  const setIsOpenNews = useAnime((state) => state.setIsOpenNews)
  const nidStore: number = useAnime((state) => state.nid)
  const addHistoryRecord = useHistoryRecord<Function>((state: any) => state.addHistoryRecord)
  const currentSearch = useHistorySearch<string>((state: any) => state.currentSearch)
  const params = useParams()
  const history = useHistory()

  const addHistoryContent = (args: NewsDetailsContent) => {
    addHistoryRecord({
      path: window.location.pathname,
      title: args?.title || "",
      cid: args.id || 0,
      provider: "",
      image: args?.img || "",
      type: "news",
      date: JDate.now(),
      user: User.getActiveUser(),
    })
    saveLastHistoryRecordRemote()
  }

  const setSearchWordsParams = (str: string): string =>
    setSearchWords(str, currentSearch)

  const getPathId = () => {
    const path: string[] = window.location.pathname.split("/")
    if (path[1] !== "news") return 0
    return parseInt(path[2]) || 0
  }

  const updateInfo = () => {
    const _nid = getPathId()
    if (_nid > 0 && _nid !== nid) setNid(_nid)
    if (nid === 0)
      if (props.id) setNid(props.id)
      else Strings.parseParams("nid", params, (_stid: number) =>
        setNid(_stid))
    if (nid === 0 || isNaN(nid)) return
    setLoaded(false)
    setContent({} as NewsDetailsContent)
    nid !== content?.id && getNews({ nid, force: showError }).then((content: NewsDetailsContent) => {
      if (!content?.id) return setShowError(true)
      setContent(() => {
        addHistoryContent(content)
        if (!content?.id) setShowError(true)
        return NewsParser.fitNewsContent(content)
      })
      setLoaded(true)
    })
  }

  useEffect(() => {
    updateInfo()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [params, nid, showError])

  useEffect(() => {
    if (!nid) setContent({} as NewsDetailsContent)
    if (nid === 0) setContent({} as NewsDetailsContent)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [nid])

  useEffect(() => {
    setImgBG(content?.img2)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [content])

  useEffect(() => {
    const _nid = getPathId()
    _nid === 0 && setNid(nidStore)
  }, [nidStore])

  return (
    <PageContainer
      className="page-anime-details"
      showLogo={false}
      onClickBack={() => {
        const _nid = getPathId()
        _nid > 0 && history.push("/news")
        setIsOpenNews(false)
      }}
    >
      <div className={"background"}
        style={{
          "backgroundImage": `url(${imgBG})`,
        }}
      >
      </div>
      <div className={`ion-padding inner-container ${showError && "withError"}`}>
        <div>
          <ErrorHandler showError={showError}>
            {!loaded && <>
              <Skeleton articleLike={true} />
            </>}
            {loaded &&
              <>
                <AnimeTopBar
                  link={content.share}
                  title={content.title}
                  image={content.img}
                />
                <IonLabel className="title">
                  <span dangerouslySetInnerHTML={{ __html: setSearchWordsParams(content.title) }} />
                </IonLabel>
                {content.publish_up &&
                  <IonBadge color="secondary">{content.publish_up}</IonBadge>
                }
                <AdsBanner type="softnyacontent" softnyaposition="DETAILS_TOP" force={true} />
                <AnimeDescription
                  content={formatSearchContent(content as unknown as AnimeContent, currentSearch)}
                  excludeMedia={true}
                />
                <AdsBanner type="softnyacontent" softnyaposition="DETAILS_BOTTOM" force={true} />
              </>
            }
          </ErrorHandler>
        </div>
      </div>
    </PageContainer>
  )
}

export default NewsDetails