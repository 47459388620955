import { IonBadge, IonIcon, IonLabel } from "@ionic/react"
import { informationCircleOutline } from "ionicons/icons"

import locales from "config/Locales"

const AnimeMediaOptionsCantView = () => {
  return (
    <>
      <IonBadge color="secondary">
        <IonIcon icon={informationCircleOutline} />
        <IonLabel>{locales._get("content.media-info-bellow")}</IonLabel>
      </IonBadge>
    </>
  )
}

export default AnimeMediaOptionsCantView