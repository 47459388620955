import {
  IonList,
  IonItem,
  IonButton,
  IonIcon,
  useIonAlert,
} from "@ionic/react"
import { closeCircleOutline, logInOutline, personAddOutline, mailOutline } from "ionicons/icons"
import { useEffect, useState } from "react"
import { useHistory } from "react-router-dom"

import { LoginImg, SignupImg } from "types/Anime/Images"
import { UserListHeader } from "../Methods/UserListHeader"
import { doLogin } from "./Methods/doLogin"
import { doReset } from "./Methods/doReset"
import locales from "config/Locales"
import useModal from "store/useModal"
import FormItem from "../Methods/FormItem"
import Animate from "utils/Animate"
import AdsBanner from "components/Media/Ads/Banner"

const LoginForm: React.FC = () => {
  const [username, setUsername] = useState<string>("")
  const [password, setPassword] = useState<string>("")
  const [loginmsg, setLoginmsg] = useState<string>(locales._get("login"))
  const [remembermsg, setRemembermsg] = useState<string>(locales._get("send_email"))
  const [isRecover, toggleRecover] = useState<boolean>(false)
  const [presentAlert] = useIonAlert()
  const closeActiveModal = useModal((state: any) => state.closeActiveModal)
  const [loaded, setLoaded] = useState<boolean>(false)
  const history = useHistory()

  useEffect(() => {
    if (loaded) return

    const css = `
      .component-login ion-list {
        background-image: url(${LoginImg});
      }
      .component-login.signup ion-list {
        background-image: url(${SignupImg});
      }
    `
    //add css to head
    const head = document.head || document.getElementsByTagName("head")[0]
    const clear = document.getElementById("login-bg")
    if (clear) clear.remove()
    const style = document.createElement("style")
    style.id = "login-bg"
    head.appendChild(style)
    style.type = "text/css"
    style.appendChild(document.createTextNode(css))
    setLoaded(true)
  }, [loaded])

  const setLoginAlert = (msg: string) => {
    if (msg === "") {
      setLoginmsg(locales._get("login"))
      return
    }
    setLoginmsg(msg)
    setTimeout(() => {
      setLoginmsg(locales._get("login"))
    }, 3000)
  }

  const setRememberAlert = (msg: string) => {
    if (msg === "") {
      setRemembermsg(locales._get("send_email"))
      return
    }
    setRemembermsg(msg)
    setTimeout(() => {
      setRemembermsg(locales._get("send_email"))
    }, 3000)
  }

  return (
    <div className={`component-login input-format-square ${Animate.setAnimation("fadeIn", true)}`}>
      <IonList className={isRecover === true ? "ion-hide" : ""}>
        <UserListHeader html={locales._get("signin_msg")} />
        <FormItem
          value={username}
          onIonChange={(e: any) => setUsername(e.target.value || "")}
          className={loginmsg === locales._get("loginfailed") ? "inputRequiredError" : ""}
          inputMode="email"
          placeholder={locales._get("username_email_placeholder")}
        />
        <FormItem
          value={password}
          type="password"
          onIonChange={(e: any) => setPassword(e.detail.value || "")}
          className={loginmsg === locales._get("loginfailed") ? "inputRequiredError" : ""}
          placeholder={locales._get("password_placeholder")}
        />
        <IonItem>
          <IonButton
            color={loginmsg === locales._get("loginfailed") ? "danger" : "primary"}
            expand="full"
            fill="solid"
            size="default"
            onClick={() => { doLogin({ username, password, loginmsg, setLoginAlert, closeActiveModal, history }) }}>
            <IonIcon
              icon={loginmsg === locales._get("loginfailed") ? closeCircleOutline : logInOutline}
              slot="start"></IonIcon>
            {loginmsg}
          </IonButton>
        </IonItem>
        <IonItem>
          <IonButton
            fill="clear"
            size="small"
            onClick={() => toggleRecover(true)}>
            {locales._get("forgot_password")}
          </IonButton>
        </IonItem>
        <IonItem>
          <AdsBanner type="softnyacontent" softnyaposition="LOGIN" force={true} />
        </IonItem>
        <IonItem>
          <IonButton
            color="dark"
            expand="full"
            fill="solid"
            size="default"
            onClick={() => { history.replace("/signup") }}>
            <IonIcon icon={personAddOutline} slot="start"></IonIcon>
            {locales._get("signup")}
          </IonButton>
        </IonItem>
      </IonList>
      <IonList className={isRecover === true ? "" : "ion-hide"}>
        <UserListHeader html={locales._get("forgot_msg")} />
        <FormItem
          value={username}
          onIonChange={(e: any) => setUsername(e.target.value || "")}
          className={remembermsg === locales._get("sendfailed") ? "inputRequiredError" : ""}
          inputMode="email"
          placeholder={locales._get("email_placeholder")}
        />
        <IonItem>
          <IonButton
            color={remembermsg === locales._get("sendfailed") ? "danger" : "primary"}
            expand="full"
            fill="solid"
            size="default"
            onClick={() => { doReset({ username, remembermsg, setRememberAlert, presentAlert, history }) }}>
            <IonIcon
              icon={remembermsg === locales._get("sendfailed") ? closeCircleOutline : mailOutline}
              slot="start"></IonIcon>
            {remembermsg}
          </IonButton>
        </IonItem>
        <IonItem>
          <IonButton
            fill="clear"
            size="small"
            onClick={() => toggleRecover(false)}>
            {locales._get("login")}
          </IonButton>
        </IonItem>
      </IonList>
    </div>
  )
}

export default LoginForm