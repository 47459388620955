import { useIonAlert } from "@ionic/react"
import { useEffect, useState } from "react"

import { OneZeroTypes } from "types"
import { Item } from "../../../../components/Content/FormBuilder/Item"
import useHistoryRecord, { getHistoryRecord } from "store/useHistoryRecord"
import locales from "config/Locales"
import User from "components/Users/User"
import settings from "config/Settings"
import { AppSettingsInitialPage, AppSettingsInitialPageGlobal, AppSettingsTitleLanguage } from "types/Page/AppSettings"

const SettingsContent = () => {
  const [presentAlert] = useIonAlert()
  const [titleLanguage, setTitleLanguage] = useState<string>(new settings().titleLanguage)
  const [initialPage, setIniticalPage] = useState<string>(new settings().initialPage)
  const [parentalControl, setParentalControl] = useState<OneZeroTypes>(new settings().parentalControl)
  const [showAlertWithAds, setShowAlertWithAds] = useState<OneZeroTypes>(new settings().showAlertWithAds)
  const [showNotificationControls, setShowNotificationControls] = useState<OneZeroTypes>(new settings().showNotificationControls)
  const [historyRecordLimit, setHistoryRecordLimit] = useState<number>(new settings().maxHistoryRecords)
  const setHistoryRecord = useHistoryRecord<Function>((state: any) => state.setHistoryRecord)

  useEffect(() => {
    new settings().titleLanguage = titleLanguage
  }, [titleLanguage])

  useEffect(() => {
    new settings().parentalControl = parentalControl
  }, [parentalControl])

  useEffect(() => {
    new settings().showAlertWithAds = showAlertWithAds
  }, [showAlertWithAds])

  useEffect(() => {
    new settings().showNotificationControls = showNotificationControls
  }, [showNotificationControls])

  useEffect(() => {
    new settings().maxHistoryRecords = historyRecordLimit
  }, [historyRecordLimit])

  useEffect(() => {
    new settings().initialPage = initialPage
  }, [initialPage])

  return (
    <>
      <Item
        title={locales._get("profile.settings-content")}
        asSubHeading={true}
      />
      <Item
        title={locales._get("settings.title-language")}
        subtitle={locales._get("settings.title-language-subtitle")}
        type="select"
        value={titleLanguage}
        valueSelect={AppSettingsTitleLanguage}
        onChange={(e: any) => setTitleLanguage(e.detail?.value || titleLanguage)}
      />
      <Item
        title={locales._get("settings.parental-control")}
        subtitle={locales._get("settings.parental-control-subtitle")}
        type="toggle"
        value={parentalControl}
        onChange={(e: any) => {
          const checked: OneZeroTypes = e.target.checked ? 1 : 0
          if (checked === 0) {
            presentAlert({
              header: locales._get("settings.parental-control"),
              message: locales._get("settings.parental-control-confirm"),
              buttons: [
                {
                  text: locales._get("settings.cancel"),
                  role: "cancel",
                  handler: () => e.target.checked = true
                },
                {
                  text: locales._get("settings.accept"),
                  handler: () => setParentalControl(checked)
                }
              ]
            })
            return
          }
          setParentalControl(checked)
        }}
      />
      <Item
        title={locales._get("settings.show-alert-with-ads")}
        subtitle={locales._get("settings.show-alert-with-ads-subtitle")}
        type="toggle"
        value={showAlertWithAds}
        onChange={(e: any) => {
          const checked: OneZeroTypes = e.target.checked ? 1 : 0
          if (checked === 0) {
            presentAlert({
              header: locales._get("settings.show-alert-with-ads"),
              message: locales._get("settings.show-alert-with-ads-confirm"),
              buttons: [
                {
                  text: locales._get("settings.cancel"),
                  role: "cancel",
                  handler: () => e.target.checked = true
                },
                {
                  text: locales._get("settings.accept"),
                  handler: () => setShowAlertWithAds(checked)
                }
              ]
            })
            return
          }
          setShowAlertWithAds(checked)
        }}
      />
      <Item
        title={locales._get("settings.show-notification-controls")}
        subtitle={locales._get("settings.show-notification-controls-subtitle")}
        type="toggle"
        value={showNotificationControls}
        onChange={(e: any) => setShowNotificationControls(e.target.checked ? 1 : 0)}
      />
      <Item
        title={locales._get("settings.history-save-limit")}
        subtitle={locales._get("settings.history-save-limit-subtitle")}
        type="range"
        value={historyRecordLimit}
        valueRange={{
          min: 0,
          max: 500,
          step: 10,
        }}
        onChange={(e: any) => {
          getHistoryRecord(User.getUsername()).then((historyRecord: any) => {
            const value = parseInt(e.target.value) || 0
            if (value >= historyRecord.length) {
              setHistoryRecordLimit(value)
            } else
              presentAlert({
                header: locales._get("settings.history-save-limit"),
                message: locales._get("settings.history-save-limit-confirm", {
                  "%s": value,
                  "%t": historyRecord.length,
                }),
                buttons: [
                  {
                    text: locales._get("settings.cancel"),
                    role: "cancel",
                    handler: () => {
                      e.target.value = historyRecordLimit
                    }
                  },
                  {
                    text: locales._get("settings.accept"),
                    handler: () => {
                      const newHistoryRecord = historyRecord.slice(historyRecord.length - value)
                      setHistoryRecord(newHistoryRecord)
                      setHistoryRecordLimit(value)
                    }
                  }
                ]
              })
          })
        }}
      />
      <Item
        title={locales._get("profile.settings-startup")}
        asSubHeading={true}
      />
      <Item
        title={locales._get("profile.settings-initial-page")}
        subtitle={locales._get("profile.settings-initial-page-subtitle")}
        type="select"
        value={initialPage}
        valueSelect={User.isLoggedIn() ? AppSettingsInitialPage : AppSettingsInitialPageGlobal}
        onChange={(e: any) => setIniticalPage(e.detail?.value || initialPage)}
      />
    </>
  )
}

export default SettingsContent