import { useEffect, useState } from "react"
import { IonList } from "@ionic/react"
import { cubeOutline } from "ionicons/icons"
import { useHistory } from "react-router-dom"

import { getGenderListMenuItems } from "pages/_commons/Content/GerdersList/getGenderList"
import { pushData } from "components/Commons/Picker/Modal/Methods/pushData"
import { menuItems } from "types/Commons/Menu"
import PickerModalList from "components/Commons/Picker/Modal/List"
import PickerModalNoItems from "components/Commons/Picker/Modal/NoItems"
import locales from "config/Locales"
import AniTitle from "pages/_commons/AniTitle"
import HeaderSlides from "pages/_commons/Content/HeaderSlides"
import PageContainer from "pages/_commons/PageContainer"
import Searchbar from "components/Commons/Searchbar"
import Meta from "components/Media/Meta"

import "./index.scss"

const Genders = () => {
  const [genders, setGenders] = useState<menuItems[]>([])
  const [onDisplay, setOnDisplay] = useState<menuItems[]>([])
  const [isInfiniteDisabled, setInfiniteDisabled] = useState<boolean>(false)
  const [loaded, setLoaded] = useState<boolean>(false)
  const [selected, setSelected] = useState<string>("")
  const history = useHistory()

  const sendPushData = () => pushData(genders, onDisplay, setOnDisplay, setInfiniteDisabled, setLoaded, selected)

  useEffect(() => {
    setOnDisplay(() => {
      sendPushData()
      return []
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [genders])

  useEffect(() => {
    const _genders = getGenderListMenuItems()

    setGenders(() => {
      _genders?.map((gender: menuItems) => gender.icon = cubeOutline)
      return _genders
    })
  }, [])

  return (
    <PageContainer
      withProgress={true}
      className="page-genders"
      callbackBottom={() => {
        if (isInfiniteDisabled) return
        sendPushData()
      }}
    >
      <>
        <HeaderSlides />
        <div className="ion-padding inner-container">
          <AniTitle
            title={locales._get("genders")}
          />
          <Searchbar
            data={genders}
            placeholder={locales._get("content.search-placeholder")}
            callbackResults={(_results: menuItems[], query: string) => {
              setOnDisplay(query === "" ? genders : _results)
              setSelected(query)
            }}
          />
          <IonList>
            <PickerModalList
              items={onDisplay}
              onClick={(href: string) => history.push(href)}
              selected=""
            />
            {(onDisplay.length === 0 && loaded) &&
              <PickerModalNoItems />
            }
          </IonList>
        </div>
        <Meta
          title={locales._get("genders")}
        />
      </>
    </PageContainer>
  )
}

export default Genders