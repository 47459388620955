import settings from "config/Settings"

const routes = {
  slides: `${settings.apiUrl}content.slides`,
  genderlist: `${settings.apiUrl}genderlist`,
  recent: `${settings.apiUrl}content.recent`,
  recentviewed: `${settings.apiUrl}content.viewed`,
  popular: `${settings.apiUrl}content.popular`,
  details: `${settings.apiUrl}content.details`,
  detailsepisodes: `${settings.apiUrl}content.details.episodes`,
  detailsepisodesmedia: `${settings.apiUrl}content.details.episodes.media`,
  detailsrelatedrecommended: `${settings.apiUrl}content.details.related.recommended`,
  detailscharacters: `${settings.apiUrl}content.details.characters`,
  detailsstaff: `${settings.apiUrl}content.details.staff`,
  detailscomments: `${settings.apiUrl}content.details.comments`,
  detailsfollow: `${settings.apiUrl}content.details.follow`,
  detailsupdateimage: `${settings.apiUrl}content.details.update.image`,
  character: `${settings.apiUrl}content.character`,
  staff: `${settings.apiUrl}content.staff`,
  contenthistorysearch: `${settings.apiUrl}content.history.search`,
  contenthistoryrecord: `${settings.apiUrl}content.history.record`,
  senderror: `${settings.apiUrl}senderror`,
}

export default routes